import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import { cn, uniqueIdGenerator } from '../util';
import { switchStyles } from './styles';
import { CmdLabel } from '../typography';

const CmdSwitch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    onLabel?: React.ReactNode;
    offLabel?: React.ReactNode;
    tooltip?: React.ReactElement | string;
    onChange?: (checked: boolean) => void;
  }
>(({ className, disabled = false, onLabel, offLabel, tooltip, ...props }, ref) => {
  const uniqueIdRef = React.useRef<number | null>(null);

  if (uniqueIdRef.current === null) {
    const nextId = uniqueIdGenerator.next().value;
    uniqueIdRef.current = typeof nextId === 'number' ? nextId : null;
  }

  const uniqueId = uniqueIdRef.current;

  return (
    <div className="flex items-center gap-sm">
      <SwitchPrimitives.Root
        className={cn(switchStyles({ disabled: disabled }), className)}
        disabled={disabled}
        {...props}
        ref={ref}
        onCheckedChange={props.onCheckedChange ?? props.onChange}
        id={`cmd-switch-${uniqueId}`}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'h-[14px] w-[14px] rounded-full bg-gray100 shadow-sm transform transition-transform data-[state=checked]:translate-x-lg data-[state=unchecked]:translate-x-[0px]',
            { 'bg-contentDisabled': disabled, 'cursor-not-allowed': disabled },
          )}
        />
      </SwitchPrimitives.Root>
      {onLabel || offLabel ? (
        <CmdLabel htmlFor={`cmd-switch-${uniqueId}`} disabled={disabled} tooltip={tooltip} className="cursor-pointer">
          {props.checked ? onLabel : offLabel}
        </CmdLabel>
      ) : null}
    </div>
  );
});

export default CmdSwitch;

CmdSwitch.displayName = 'CmdSwitch';

export { CmdSwitch };
