import React, { type FC, useCallback, useEffect, useMemo } from 'react';
import { useSlate } from 'slate-react';

import {
  CmdToolbarButton,
  CmdToolbarSeparator,
  CmdToolbarToggleGroup,
  CmdToolbarToggleItem,
  CmdTooltip,
} from '@commandbar/design-system/cmd';
import { Bold01, Italic01, Link04, OrderedList, UnorderedList } from '@commandbar/design-system/icons/react';
import { ToolbarType, useEditorToolbar } from '.';
import { Commands } from '../../commands';
import { ALL_MARKS, EMPTY_VALUE, ElementType, Mark } from '../../constants';
import type { LinkElement, ListElement } from '../../types';

interface SelectionActionTooltipProps {
  name: string;
  shortcut?: string;
}

const SelectionActionTooltip: FC<SelectionActionTooltipProps> = ({ children, name, shortcut }) => (
  <CmdTooltip
    message={
      <div className="flex flex-col items-center justify-center font-medium text-contentInverse leading-5">
        <span>{name}</span>
        {shortcut && <span className="text-contentBase">{shortcut}</span>}
      </div>
    }
  >
    {children}
  </CmdTooltip>
);

enum AriaLabels {
  FormatToggleGroup = 'Text formatting',
  BoldToggle = 'Toggle bold',
  ItalicToggle = 'Toggle italic',
  ListToggleGroup = 'List type',
  OrderedListToggle = 'Toggle ordered list',
  UnOrderedListToggle = 'Toggle unordered list',
  CreateLink = 'Create link',
}

const MarkToggles = () => {
  const editor = useSlate();

  const handleMarkToggle = useCallback(
    (values: Mark[]) => {
      for (const value of ALL_MARKS) {
        if (values.includes(value)) {
          Commands.activateMark(editor, value);
        } else {
          Commands.deactivateMark(editor, value);
        }
      }
    },
    [editor],
  );

  return (
    <CmdToolbarToggleGroup
      value={Commands.getActiveMarks(editor)}
      onValueChange={handleMarkToggle}
      type="multiple"
      aria-label={AriaLabels.FormatToggleGroup}
    >
      <SelectionActionTooltip name="Bold" shortcut="⌘+B">
        <CmdToolbarToggleItem value={Mark.Bold} aria-label={AriaLabels.BoldToggle}>
          <Bold01 />
        </CmdToolbarToggleItem>
      </SelectionActionTooltip>

      <SelectionActionTooltip name="Italics" shortcut="⌘+I">
        <CmdToolbarToggleItem value={Mark.Italic} aria-label={AriaLabels.ItalicToggle}>
          <Italic01 />
        </CmdToolbarToggleItem>
      </SelectionActionTooltip>
    </CmdToolbarToggleGroup>
  );
};

const LinkButton = () => {
  const editor = useSlate();
  const { setType, setLinkElement, linkElement } = useEditorToolbar();

  useEffect(() => {
    setLinkElement(Commands.getEnclosingElementOfType<LinkElement>(editor, ElementType.Link));
  }, [editor, setLinkElement, editor.selection]);

  return (
    <SelectionActionTooltip name="Link" shortcut="⌘+K">
      <CmdToolbarButton
        value={ElementType.Link}
        onClick={() => {
          setType(ToolbarType.EditLink);
        }}
        aria-label={AriaLabels.CreateLink}
        active={!!linkElement}
      >
        <Link04 />
      </CmdToolbarButton>
    </SelectionActionTooltip>
  );
};

const ListToggles = () => {
  const editor = useSlate();

  const listGroupValue = useMemo(() => {
    const activeBlock = Commands.getFirstSelectedBlock(editor);
    if (activeBlock?.type === ElementType.List) {
      return activeBlock.ordered ? ElementType.OrderedList : ElementType.UnorderedList;
    }

    return EMPTY_VALUE;
  }, [editor, editor.selection]);

  const handleListToggle = useCallback(
    (value: ElementType.OrderedList | ElementType.UnorderedList | typeof EMPTY_VALUE) => {
      const newElement: ListElement = {
        type: ElementType.List,
        ordered: value === ElementType.OrderedList,
        spread: false,
        children: [],
      };

      if (value && Commands.isBlockActive(editor, ElementType.List)) {
        Commands.convertBlockToParagraph(editor);
        Commands.setBlockType(editor, newElement);
      } else {
        Commands.toggleBlockType(editor, newElement);
      }
    },
    [editor],
  );

  return (
    <CmdToolbarToggleGroup
      value={listGroupValue}
      onValueChange={handleListToggle}
      type="single"
      aria-label={AriaLabels.ListToggleGroup}
    >
      <SelectionActionTooltip name="Bullet List">
        <CmdToolbarToggleItem value={ElementType.UnorderedList} aria-label={AriaLabels.UnOrderedListToggle}>
          <UnorderedList />
        </CmdToolbarToggleItem>
      </SelectionActionTooltip>

      <SelectionActionTooltip name="Numbered List">
        <CmdToolbarToggleItem value={ElementType.OrderedList} aria-label={AriaLabels.OrderedListToggle}>
          <OrderedList />
        </CmdToolbarToggleItem>
      </SelectionActionTooltip>
    </CmdToolbarToggleGroup>
  );
};

export const TextSelection = () => {
  const { editorLayout } = useEditorToolbar();

  return (
    <div
      className="flex gap-xxs"
      onMouseDown={(e) => {
        // INFO: keep the toolbar open when clicking on it
        e.preventDefault();
      }}
    >
      <MarkToggles />
      <LinkButton />

      {editorLayout === 'multi-line' && (
        <>
          <CmdToolbarSeparator />
          <ListToggles />
        </>
      )}
    </div>
  );
};
