/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { CSSObject, css, jsx } from '@emotion/core';
import StyledIconContainer from '../shared/StyledIconContainer';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import ButtonWithReset from '../shared/ButtonWithReset';

const Star: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M11.3452 3.4533C11.5756 2.98637 11.6909 2.7529 11.8473 2.6783C11.9834 2.6134 12.1416 2.6134 12.2777 2.6783C12.4342 2.7529 12.5494 2.98637 12.7799 3.4533L14.9666 7.88327C15.0346 8.02112 15.0686 8.09005 15.1183 8.14356C15.1624 8.19095 15.2152 8.22934 15.2738 8.25661C15.3401 8.28741 15.4161 8.29853 15.5682 8.32076L20.4595 9.03569C20.9746 9.11098 21.2321 9.14862 21.3513 9.27443C21.455 9.38388 21.5037 9.53428 21.484 9.68376C21.4613 9.85557 21.2749 10.0372 20.902 10.4004L17.3639 13.8464C17.2537 13.9538 17.1985 14.0075 17.1629 14.0715C17.1314 14.128 17.1112 14.1902 17.1034 14.2545C17.0946 14.3271 17.1076 14.403 17.1336 14.5547L17.9685 19.4221C18.0565 19.9355 18.1005 20.1922 18.0178 20.3445C17.9458 20.477 17.8179 20.57 17.6696 20.5975C17.4991 20.6291 17.2686 20.5078 16.8076 20.2654L12.4349 17.9658C12.2986 17.8942 12.2305 17.8583 12.1587 17.8443C12.0952 17.8318 12.0298 17.8318 11.9663 17.8443C11.8945 17.8583 11.8264 17.8942 11.6902 17.9658L7.31742 20.2654C6.85642 20.5078 6.62591 20.6291 6.45547 20.5975C6.30718 20.57 6.17922 20.477 6.10724 20.3445C6.0245 20.1922 6.06853 19.9355 6.15657 19.4221L6.99139 14.5547C7.01741 14.403 7.03043 14.3271 7.02162 14.2545C7.01382 14.1902 6.99361 14.128 6.96211 14.0715C6.92652 14.0075 6.87138 13.9538 6.76109 13.8464L3.22306 10.4004C2.85016 10.0372 2.66371 9.85557 2.64103 9.68376C2.62129 9.53428 2.67005 9.38388 2.77375 9.27443C2.89294 9.14862 3.15047 9.11098 3.66554 9.03569L8.55681 8.32076C8.70892 8.29853 8.78498 8.28741 8.85122 8.25661C8.90986 8.22934 8.96266 8.19095 9.00669 8.14356C9.05641 8.09005 9.09043 8.02112 9.15847 7.88327L11.3452 3.4533Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RatingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--form-control-gap);
  align-self: stretch;
  user-select: none;
`;

const styles: CSSObject = {
  display: 'flex',
  height: 'var(--form-control-height)',
  width: 'var(--form-control-width)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--form-control-border-color)',
  background: 'var(--form-control-background)',
  cursor: 'pointer',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  '&:hover': {
    borderColor: 'var(--form-control-border-color-hover)',
    background: 'var(--form-control-background-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
};

const selectedStyles: CSSObject = {
  borderColor: 'var(--base-accent)',
  background: 'var(--form-control-active-background)',

  '&:hover': {
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-active-background)',
  },

  '& svg': {
    fill: 'var(--nudge-rating-star-color-selected)',
  },
};

const StarContainer = styled(StyledIconContainer)`
  color: var(--nudge-rating-star-color);

  &:hover {
    color: var(--nudge-rating-star-color);
  }
`;

const StarSelectedContainer = styled(StyledIconContainer)`
  color: var(--nudge-rating-star-color-selected);
`;

const slug = 'nudgeRatingStars';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  numOptions: number;
  selected: (index: number) => boolean;
  onClick: (index: number) => void;
};

const StyledNudgeRatingStars = ({ themeV2, numOptions, selected, onClick }: Props) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <RatingContainer>
      {[...Array(numOptions)].map((_, i) => {
        const index = i + 1;
        const isSelected = selected(index);
        const Container = isSelected ? StarSelectedContainer : StarContainer;

        return (
          <ButtonWithReset
            css={[overrides, css(styles), isSelected && css(selectedStyles)].filter(Boolean)}
            key={index}
            onClick={() => onClick(index)}
          >
            <Container>
              <Star />
            </Container>
          </ButtonWithReset>
        );
      })}
    </RatingContainer>
  );
};

StyledNudgeRatingStars.styles = styles;
StyledNudgeRatingStars.slug = slug;
StyledNudgeRatingStars.activeStyles = selectedStyles;
StyledNudgeRatingStars.renderMock = (
  themeV2: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode',
  active: boolean,
  onClick: () => void,
) => (
  <StyledNudgeRatingStars
    themeV2={{ themeV2: themeV2, mode: mode }}
    numOptions={1}
    selected={() => !!active}
    onClick={onClick}
  />
);

export default StyledNudgeRatingStars;
