import * as t from 'io-ts';
import { decodeThrowing } from '../decode';

export type IThemeAnimatedWidgetType = 'modal' | 'popover' | 'tooltip' | 'spotlight' | 'checklist' | 'helphub';
export type IThemeAnimationType = 'instant' | 'fade' | 'spring' | 'scale';

export const ThemeModeV = t.type({
  var_defaults: t.record(t.string, t.union([t.string, t.number])),
  var_overrides: t.record(t.string, t.union([t.string, t.number])),
  component_overrides: t.record(t.string, t.any),
  mobile_overrides: t.record(t.string, t.any),
});

export const ThemeV2V = t.union([
  t.type({
    light_mode: ThemeModeV,
    dark_mode: ThemeModeV,
  }),
  t.null,
  t.undefined,
]);
export type IThemeV2Type = t.TypeOf<typeof ThemeV2V> & unknown;

export const ThemeV = t.type(
  {
    id: t.string,
    uuid: t.union([t.string, t.null, t.undefined]),
    slug: t.string,
    name: t.string,
    default: t.boolean,
    themeV2: ThemeV2V,
    themeV2_draft: ThemeV2V,
    organization: t.string,
    archived: t.boolean,
  },
  'Theme',
);
export type IThemeType = t.TypeOf<typeof ThemeV> & unknown;

const defaults = {};

export const ThemePatchV = t.partial({
  id: t.string,
  slug: t.string,
  name: t.string,
  default: t.boolean,
  themeV2: ThemeV2V,
  themeV2_draft: ThemeV2V,
  organization: t.string,
  archived: t.boolean,
});
export type IThemePatch = t.TypeOf<typeof ThemePatchV> & unknown;

export const ThemeSlugReponseV = t.type({
  slug: t.string,
});

export const decode = (data: any) => decodeThrowing(ThemeV, { ...defaults, ...data });
