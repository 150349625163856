import { Editor, Element, Node, Transforms } from 'slate';

import { ElementType } from '../constants';

export const withSingleLineLayout = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.insertBreak = () => null;

  editor.normalizeNode = (entry) => {
    if (editor.children.length > 1) {
      Transforms.removeNodes(editor, {
        at: [],
        mode: 'highest',
        match: (_, path) => path[0] > 0,
      });
      return;
    }

    const [firstNode] = editor.children;

    // If the remaining node is a list, we need to convert it to a paragraph with items
    // joined by spaces
    if (Element.isElementType(firstNode, ElementType.List)) {
      // Concatenate text from all list items
      const textContent = firstNode.children.map((item) => Node.string(item)).join(' ');

      Editor.withoutNormalizing(editor, () => {
        Transforms.removeNodes(editor, { at: [0] });
        Transforms.insertNodes(
          editor,
          {
            type: ElementType.Paragraph,
            children: [{ text: textContent }],
          },
          { at: [0] },
        );
      });
      return;
    }

    normalizeNode(entry);
  };

  return editor;
};
