import type { IThemeV2Type } from '@cb/types/entities/theme';

// if this DEFAULT_THEME is edited, ALSO, edit DEFAULT_THEME in theme_defaults.py!!!!
export const DEFAULT_THEME: NonNullable<IThemeV2Type> = {
  dark_mode: {
    var_defaults: {
      '--base-accent': 'rgb(120, 86, 255)',
      '--base-white': 'rgb(255, 255, 255)',
      '--base-black': 'rgb(0, 0, 0)',

      '--font-font-family':
        'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

      '--font-weight-regular': 400,
      '--font-weight-medium': 500,
      '--font-weight-semibold': 600,
      '--font-weight-bold': 700,

      '--font-size-small': '12px',
      '--font-size-base': '14px',
      '--font-size-large': '16px',
      '--font-size-extra-large': '22px',

      '--font-line-height-none': '1',
      '--font-line-height-tight': '1.2',
      '--font-line-height-snug': '1.375',
      '--font-line-height-normal': '1.5',

      '--content-primary': 'rgb(255, 255, 255)',
      '--content-secondary': 'rgb(167, 167, 167)',
      '--content-disabled': 'rgb(86, 86, 86)',
      '--content-primary-hover': 'var(--content-primary)',
      '--content-secondary-hover': 'var(--content-secondary)',

      '--content-link': 'var(--base-accent)',
      '--content-link-hover': 'color-mix(in srgb, var(--content-link) 80%, white)',
      '--content-link-visited': 'color-mix(in srgb, var(--content-link) 80%, black)',

      '--border-primary': 'rgb(59, 59, 59)',
      '--border-primary-hover': 'rgb(71, 71, 71)',
      '--border-primary-disabled': 'rgb(54, 54, 54)',

      '--background-primary': 'rgb(30, 30, 30)',
      '--background-primary-hover': 'rgb(43, 43, 43)',
      '--background-secondary': 'rgb(15, 15, 15)',
      '--background-secondary-hover': 'rgb(28, 28, 28)',
      '--background-disabled': 'var(--background-secondary)',

      '--header-height': '40px',
      '--height-small': '16px',
      '--form-control-height': '32px',
      '--form-control-width': '100%',
      '--form-control-padding-x': '8px',
      '--form-control-gap': '8px',
      '--form-control-active-background': 'rgb(from var(--base-accent) r g b / .10)',
      '--form-control-focus-ring-color': 'rgb(from var(--base-accent) r g b / .24)',
      '--form-control-focus-ring-width': '3px',

      '--layout-radius-button': '6px',
      '--layout-radius-card': '8px',
      '--layout-radius-circle': '100px',
      '--layout-padding': '12px',
      '--layout-gap': '8px',
      '--layout-shadow': '0px 4px 16px 0px',
      '--layout-shadow-color': 'rgba(0, 0, 0, 0.08)',
      '--form-control-shadow': 'none',
      '--form-control-shadow-color': 'rgba(0, 0, 0, 0)',

      '--form-control-background': 'var(--background-primary)',
      '--form-control-background-hover': 'var(--background-primary-hover)',
      '--form-control-background-disabled': 'var(--background-secondary)',
      '--form-control-border-color': 'var(--border-primary)',
      '--form-control-border-color-hover': 'var(--border-primary-hover)',
      '--form-control-border-color-disabled': 'var(--border-primary-disabled)',

      '--tooltip-max-width': '300px',
      '--popover-width': '300px',
      '--modal-width': '360px',

      '--checklist-width': '360px',
      '--checklist-max-height': '500px',

      '--helphub-width': '440px',
      '--helphub-height': 'calc(100vh - 24px)',

      '--spotlight-width': '670px',
      '--spotlight-max-height': '400px',

      '--button-primary-background': 'var(--base-accent)',
      '--button-primary-background-hover': 'color-mix(in srgb, var(--base-accent) 80%, white)',
      '--button-primary-border-color': 'var(--button-primary-background)',
      '--button-primary-border-color-hover': 'var(--button-primary-background-hover)',
      '--button-primary-content': 'rgb(255, 255, 255)',
      '--button-primary-content-hover': 'rgb(255, 255, 255)',
      '--button-secondary-background': 'var(--background-primary)',
      '--button-secondary-background-hover': 'var(--background-primary-hover)',
      '--button-secondary-border-color': 'var(--border-primary)',
      '--button-secondary-border-color-hover': 'var(--border-primary-hover)',
      '--button-secondary-content': 'var(--content-secondary)',
      '--button-secondary-content-hover': 'var(--content-primary)',
      '--button-tertiary-background': 'rgba(255, 255, 255, 0)',
      '--button-tertiary-background-hover': 'var(--background-primary-hover)',
      '--button-tertiary-border-color': 'rgba(255, 255, 255, 0)',
      '--button-tertiary-border-color-hover': 'rgba(255, 255, 255, 0)',
      '--button-tertiary-content': 'var(--content-secondary)',
      '--button-tertiary-content-hover': 'var(--content-primary)',

      '--nudge-rating-star-color': 'rgb(221, 158, 35)',
      '--nudge-rating-star-color-selected': 'var(--nudge-rating-star-color)',
      '--highlight-color': 'rgb(240, 233, 187)',

      '--anim-transition-duration': '260ms',
      '--anim-transform-initial--spring': 'scale(.85)',
      '--anim-transform-animated--spring': 'scale(1)',

      '--anim-transform-initial--scale': 'scale(0.85)',
      '--anim-transform-animated--scale': 'scale(1)',

      '--anim-transition-property--instant': 'none',
      '--anim-transition-property--fade': 'opacity',
      '--anim-transition-property--spring': 'opacity, transform',
      '--anim-transition-property--scale': 'opacity, transform',

      '--anim-transition-timing--instant': 'none',
      '--anim-transition-timing--fade': 'ease-in-out',
      '--anim-transition-timing--spring': 'cubic-bezier(0.33, 1.56, 0.60, 1)',
      '--anim-transition-timing--scale': 'cubic-bezier(0.33, 1.25, 0.65, 1)',

      '--modal-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--modal-anim-transition-duration': 'var(--anim-transition-duration)',
      '--modal-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--popover-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--popover-anim-transition-duration': 'var(--anim-transition-duration)',
      '--popover-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--tooltip-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--tooltip-anim-transition-duration': 'var(--anim-transition-duration)',
      '--tooltip-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--spotlight-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--spotlight-anim-transition-duration': 'var(--anim-transition-duration)',
      '--spotlight-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--helphub-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--helphub-anim-transition-duration': 'var(--anim-transition-duration)',
      '--helphub-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--checklist-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--checklist-anim-transition-duration': 'var(--anim-transition-duration)',
      '--checklist-anim-transition-timing': 'var(--anim-transition-timing--instant)',
    },
    var_overrides: {},
    component_overrides: {},
    mobile_overrides: {},
  },
  light_mode: {
    var_defaults: {
      '--base-accent': 'rgb(120, 86, 255)',
      '--base-black': 'rgb(0, 0, 0)',
      '--base-white': 'rgb(255, 255, 255)',

      '--font-font-family':
        'system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

      '--font-weight-regular': 400,
      '--font-weight-medium': 500,
      '--font-weight-semibold': 600,
      '--font-weight-bold': 700,

      '--font-size-small': '12px',
      '--font-size-base': '14px',
      '--font-size-large': '16px',
      '--font-size-extra-large': '22px',

      '--font-line-height-none': '1',
      '--font-line-height-tight': '1.2',
      '--font-line-height-snug': '1.375',
      '--font-line-height-normal': '1.5',

      '--content-primary': 'rgb(55, 53, 48)',
      '--content-secondary': 'rgb(125, 124, 120)',
      '--content-disabled': 'rgb(192, 192, 192)',
      '--content-primary-hover': 'var(--content-primary)',
      '--content-secondary-hover': 'var(--content-secondary)',

      '--content-link': 'var(--base-accent)',
      '--content-link-hover': 'color-mix(in srgb, var(--content-link) 80%, white)',
      '--content-link-visited': 'color-mix(in srgb, var(--content-link) 80%, black)',

      '--border-primary': 'rgb(226, 226, 226)',
      '--border-primary-hover': 'rgb(201, 201, 201)',
      '--border-primary-disabled': 'rgb(232, 232, 232)',

      '--background-primary': 'rgb(255, 255, 255)',
      '--background-primary-hover': 'rgb(242, 242, 242)',
      '--background-secondary': 'rgb(247, 247, 247)',
      '--background-secondary-hover': 'rgb(235, 235, 235)',
      '--background-disabled': 'var(--background-secondary)',

      '--header-height': '40px',
      '--height-small': '16px',
      '--form-control-height': '32px',
      '--form-control-width': '100%',
      '--form-control-padding-x': '8px',
      '--form-control-gap': '8px',
      '--form-control-active-background': 'rgb(from var(--base-accent) r g b / .10)',
      '--form-control-focus-ring-color': 'rgb(from var(--base-accent) r g b / .24)',
      '--form-control-focus-ring-width': '3px',

      '--layout-radius-button': '6px',
      '--layout-radius-card': '8px',
      '--layout-radius-circle': '100px',
      '--layout-padding': '12px',
      '--layout-gap': '8px',
      '--layout-shadow': '0px 4px 16px 0px',
      '--layout-shadow-color': 'rgba(0, 0, 0, 0.08)',
      '--form-control-shadow': 'none',
      '--form-control-shadow-color': 'rgba(0, 0, 0, 0)',

      '--form-control-background': 'var(--background-primary)',
      '--form-control-background-hover': 'var(--background-primary-hover)',
      '--form-control-background-disabled': 'var(--background-secondary)',
      '--form-control-border-color': 'var(--border-primary)',
      '--form-control-border-color-hover': 'var(--border-primary-hover)',
      '--form-control-border-color-disabled': 'var(--border-primary-disabled)',

      '--tooltip-max-width': '300px',
      '--popover-width': '300px',
      '--modal-width': '360px',

      '--checklist-width': '360px',
      '--checklist-max-height': '500px',

      '--helphub-width': '440px',
      '--helphub-height': 'calc(100vh - 24px)',

      '--spotlight-width': '670px',
      '--spotlight-max-height': '400px',

      '--button-primary-background': 'var(--base-accent)',
      '--button-primary-background-hover': 'color-mix(in srgb, var(--base-accent) 80%, white)',
      '--button-primary-border-color': 'var(--button-primary-background)',
      '--button-primary-border-color-hover': 'var(--button-primary-background-hover)',
      '--button-primary-content': 'rgb(255, 255, 255)',
      '--button-primary-content-hover': 'rgb(255, 255, 255)',
      '--button-secondary-background': 'var(--background-primary)',
      '--button-secondary-background-hover': 'var(--background-primary-hover)',
      '--button-secondary-border-color': 'var(--border-primary)',
      '--button-secondary-border-color-hover': 'var(--border-primary-hover)',
      '--button-secondary-content': 'var(--content-secondary)',
      '--button-secondary-content-hover': 'var(--content-primary)',
      '--button-tertiary-background': 'rgba(255, 255, 255, 0)',
      '--button-tertiary-background-hover': 'var(--background-primary-hover)',
      '--button-tertiary-border-color': 'rgba(255, 255, 255, 0)',
      '--button-tertiary-border-color-hover': 'rgba(255, 255, 255, 0)',
      '--button-tertiary-content': 'var(--content-secondary)',
      '--button-tertiary-content-hover': 'var(--content-primary)',

      '--nudge-rating-star-color': 'rgb(221, 158, 35)',
      '--nudge-rating-star-color-selected': 'var(--nudge-rating-star-color)',
      '--highlight-color': 'rgb(240, 233, 187)',

      '--anim-transition-duration': '260ms',
      '--anim-transform-initial--spring': 'scale(.85)',
      '--anim-transform-animated--spring': 'scale(1)',

      '--anim-transform-initial--scale': 'scale(0.85)',
      '--anim-transform-animated--scale': 'scale(1)',

      '--anim-transition-property--instant': 'none',
      '--anim-transition-property--fade': 'opacity',
      '--anim-transition-property--spring': 'opacity, transform',
      '--anim-transition-property--scale': 'opacity, transform',

      '--anim-transition-timing--instant': 'none',
      '--anim-transition-timing--fade': 'ease-in-out',
      '--anim-transition-timing--spring': 'cubic-bezier(0.33, 1.56, 0.60, 1)',
      '--anim-transition-timing--scale': 'cubic-bezier(0.33, 1.25, 0.65, 1)',

      '--modal-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--modal-anim-transition-duration': 'var(--anim-transition-duration)',
      '--modal-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--popover-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--popover-anim-transition-duration': 'var(--anim-transition-duration)',
      '--popover-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--tooltip-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--tooltip-anim-transition-duration': 'var(--anim-transition-duration)',
      '--tooltip-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--spotlight-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--spotlight-anim-transition-duration': 'var(--anim-transition-duration)',
      '--spotlight-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--helphub-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--helphub-anim-transition-duration': 'var(--anim-transition-duration)',
      '--helphub-anim-transition-timing': 'var(--anim-transition-timing--instant)',

      '--checklist-anim-transition-property': 'var(--anim-transition-property--instant)',
      '--checklist-anim-transition-duration': 'var(--anim-transition-duration)',
      '--checklist-anim-transition-timing': 'var(--anim-transition-timing--instant)',
    },
    var_overrides: {},
    component_overrides: {},
    mobile_overrides: {},
  },
};
