import { Editor, Element, Path, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { ALL_MARKS, ElementType, type Mark } from './constants';
import type { LinkElement, MentionElement } from './types';

const getEnclosingElementOfType = <T extends Element = Element>(editor: Editor, elementType: T['type']): T | null => {
  const { selection } = editor;
  if (!selection || Range.isCollapsed(selection)) return null;

  const [start, end] = Range.edges(selection);

  // Find the closest ancestor element of the specified type at the start of the selection.
  const startElementEntry = Editor.above<T>(editor, {
    at: start,
    match: (node) => Element.isElementType(node, elementType),
  });

  // Find the closest ancestor element of the specified type at the end of the selection.
  const endElementEntry = Editor.above<T>(editor, {
    at: end,
    match: (node) => Element.isElementType(node, elementType),
  });

  if (startElementEntry && endElementEntry) {
    const [startElement, startPath] = startElementEntry;
    const [, endPath] = endElementEntry;

    // Return the element if both start and end points are within the same element.
    // This ensures the selection is entirely within a single element of the specified type.
    if (Path.equals(startPath, endPath)) {
      return startElement;
    }
  }

  // Return null if selection spans multiple elements or none match the specified type.
  return null;
};

const isBlockActive = (editor: Editor, format: Element['type']) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      // Use Editor.unhangRange to consider hanging ranges, ensuring accurate block detection
      // Reference: https://docs.slatejs.org/api/nodes/editor#editor.unhangrange-editor-editor-range-range-options-greater-than-range
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === format,
    }),
  );

  return !!match;
};

const getFirstSelectedBlock = (editor: Editor): Element | undefined => {
  const { selection } = editor;
  if (!selection) return;

  const [match] = Array.from(
    Editor.nodes<Element>(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && Element.isElement(node),
    }),
  );

  return match[0];
};

// Converts the current block to a paragraph by unwrapping from list elements.
// This ensures that list formatting is removed, and the content becomes a standard paragraph.
const convertBlockToParagraph = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElementType(node, ElementType.List),
    split: true,
  });

  Transforms.setNodes(editor, { type: ElementType.Paragraph });
};

const setBlockType = (editor: Editor, element: Element) => {
  // When activating a list, wrap the selected nodes with a list element to group them.
  // For other block types, set the node type directly without wrapping.
  Transforms.setNodes(editor, { type: element.type === ElementType.List ? ElementType.ListItem : element.type });

  if (element.type === ElementType.List) {
    Transforms.wrapNodes(editor, element);
  }
};

const toggleBlockType = (editor: Editor, element: Element) => {
  const isActive = isBlockActive(editor, element.type);

  if (isActive) {
    convertBlockToParagraph(editor);
  } else {
    setBlockType(editor, element);
  }
};

const deactivateMark = (editor: Editor, key: Mark) => {
  Editor.removeMark(editor, key);
};

const activateMark = (editor: Editor, key: Mark) => {
  Editor.addMark(editor, key, true);
};

const toggleMark = (editor: Editor, key: Mark) => {
  // Toggle the mark: remove it if active, add it if inactive
  const marks = Editor.marks(editor);
  if (marks && key in marks) {
    deactivateMark(editor, key);
  } else {
    activateMark(editor, key);
  }
};

const isSupportedMark = (
  marks: NonNullable<ReturnType<typeof Editor.marks>>,
  maybeMark: string,
): maybeMark is keyof NonNullable<ReturnType<typeof Editor.marks>> => maybeMark in marks;

const isMarkActive = (editor: Editor, key: string) => {
  const marks = Editor.marks(editor);
  return !!(marks && isSupportedMark(marks, key) && marks[key] === true);
};

// Determines if there have been any changes to the document structure, excluding selection changes.
// This is useful for optimizing renders by ignoring operations that don't affect content.
const isAstChange = (editor: Editor) => editor.operations.some(({ type }) => type !== 'set_selection');

const getActiveMarks = (editor: Editor) => ALL_MARKS.filter((mark) => Commands.isMarkActive(editor, mark));

// Removes hyperlink formatting by unwrapping link elements from the selected text.
// Useful when a user opts to remove a link without altering the text content.
const removeLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) => Element.isElementType(node, ElementType.Link),
  });
};

const applyLink = (editor: Editor, url: string) => {
  removeLink(editor);

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: ElementType.Link,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  // If there's no text selected, insert the URL as the link text and apply the link.
  // If text is selected, wrap it with a link element using the provided URL.
  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const updateLinkAtPath = (editor: Editor, url: string, path: Path) => {
  Transforms.setNodes(editor, { url }, { at: path });
};

const removeLinkAtPath = (editor: Editor, path: Path) => {
  Transforms.unwrapNodes(editor, {
    at: path,
    match: (node) => Element.isElementType(node, ElementType.Link),
  });
};

const collapseSelectionToEnd = (editor: Editor) => {
  Transforms.collapse(editor, { edge: 'end' });
};

const focusEditor = (editor: Editor) => {
  ReactEditor.focus(editor);
};

// Collapses the current selection to a single point
const collapseSelection = (editor: Editor) => {
  Transforms.collapse(editor);
};

const applyMention = (editor: Editor, element: Element, value: MentionElement['value']) => {
  const path = ReactEditor.findPath(editor, element);
  Transforms.setNodes(editor, { type: ElementType.Mention, value }, { at: path });

  const edge = Editor.end(editor, path);

  Transforms.select(editor, edge);
  Transforms.move(editor);

  focusEditor(editor);
};

const deleteActiveBlock = (editor: Editor, element: Element) => {
  Transforms.delete(editor, {
    at: ReactEditor.findPath(editor, element),
    unit: 'block',
  });
};

const insertText = (editor: Editor, value: string) => {
  Transforms.insertText(editor, value);
};

export const Commands = {
  getEnclosingElementOfType,
  setBlockType,
  convertBlockToParagraph,
  isBlockActive,
  toggleBlockType,
  getFirstSelectedBlock,
  activateMark,
  deactivateMark,
  isMarkActive,
  toggleMark,
  getActiveMarks,
  isAstChange,
  applyLink,
  removeLink,
  updateLinkAtPath,
  removeLinkAtPath,
  collapseSelectionToEnd,
  focusEditor,
  collapseSelection,
  applyMention,
  deleteActiveBlock,
  insertText,
};
