import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { Caret01 } from '@commandbar/design-system/icons/react';
import { cn } from '../util';
import { CmdTypography } from '../typography';

type CmdAccordionBaseProps = React.ForwardRefExoticComponent<
  Omit<AccordionPrimitive.AccordionSingleProps, 'type'> & {
    children?: React.ReactNode;
  } & React.RefAttributes<HTMLDivElement>
>;

interface CmdAccordionComponent
  extends React.ForwardRefExoticComponent<
    React.ComponentProps<CmdAccordionBaseProps> & React.RefAttributes<HTMLDivElement>
  > {
  Item: typeof Item;
  Trigger: typeof Trigger;
  Content: typeof Content;
}

const Item = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('shadow-sm border border-[1px] border-solid border-elementBase rounded-md bg-surfaceBase', className)}
    {...props}
  />
));
Item.displayName = 'AccordionItem';

type TriggerProps = React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & { caret?: boolean };

const Trigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, TriggerProps>(
  ({ className, children, caret = true, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex m-0">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex flex-1 bg-transparent border-solid border-b-[1px] border-x-0 border-t-0 border-transparent [&[data-state=open]]:border-elementBase py-sm px-md h-12 items-center transition-all hover:cursor-pointer [&[data-state=closed]>svg]:rotate-180',
          className,
        )}
        {...props}
      >
        <CmdTypography.Body>{children}</CmdTypography.Body>
        {caret && <Caret01 className="transition-transform ml-2 h-3 w-3" />}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ),
);
Trigger.displayName = 'AccordionTrigger';

const Content = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content ref={ref} className={cn('overflow-hidden transition-all p-md', className)} {...props}>
    <div className={cn(className)}>{children}</div>
  </AccordionPrimitive.Content>
));
Content.displayName = 'AccordionContent';

type CmdAccordionRootProps = React.ComponentProps<typeof AccordionPrimitive.Root> & {
  type?: 'single' | 'multiple';
};

const CmdAccordionRoot = React.forwardRef<HTMLDivElement, CmdAccordionRootProps>((props, ref) => (
  <AccordionPrimitive.Root ref={ref} {...props}>
    {props.children}
  </AccordionPrimitive.Root>
));

const CmdAccordionBase = React.forwardRef<HTMLDivElement, React.ComponentProps<CmdAccordionBaseProps>>((props, ref) => (
  <CmdAccordionRoot ref={ref} {...props} type="single">
    {props.children}
  </CmdAccordionRoot>
));
CmdAccordionBase.displayName = 'CmdAccordion';

const CmdAccordion: CmdAccordionComponent = Object.assign(CmdAccordionBase, {
  Item,
  Trigger,
  Content,
});

export { CmdAccordion };
