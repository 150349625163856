import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';

import { Copy04, Play } from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';
import Sender from 'editor/src/management/Sender';

import { DEBUG_NUDGE_PARAM, isValidURL } from '@commandbar/internal/util/location';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import { isNudgeLive } from './utils';
import { usePreview } from 'editor/src/hooks/usePreview';
import { StudioPreviewSetupInstructions } from '../components/PreviewButton/StudioPreviewSetupIntructions';
import { useIsChromeExtensionInsalled } from 'editor/src/hooks';
import { CmdButton, CmdDropdown, CmdTooltip } from '@commandbar/design-system/cmd';

const Container = styled.div`
  position: relative;
`;

interface StandaloneEditorButtonProps {
  nudge: INudgeType;
  isDirty: boolean;
  onChange: (nudge: INudgeType) => void;
  isSaving: boolean;
}

export const StandaloneEditorPreviewButton = ({ nudge, isDirty, isSaving }: StandaloneEditorButtonProps) => {
  const [showExtensionSetupInstructions, setShowExtensionSetupInstructions] = useState(false);

  const { organization } = useAppContext();
  const { isChromeExtensionInstalled } = useIsChromeExtensionInsalled();
  const { flags } = useAppContext();
  const { onStart } = usePreview();

  const isUnsavedItem = Number(nudge.id) < 0 || isSaving;
  const isPreviewDisabled = isUnsavedItem || (isNudgeLive(nudge) && isDirty);

  const handlePlayClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (flags['release-preview-v-2']) {
      onStart({ type: 'preview', skipPrompt: e.metaKey, experience: { type: 'nudge', nudge } });
    } else if (isChromeExtensionInstalled && isValidURL(organization.base_url, true)) {
      let baseUrl = organization.base_url;
      if (!baseUrl.includes('://') && baseUrl.includes('.')) {
        baseUrl = `https://${baseUrl}`;
      }
      const url = new URL(baseUrl);
      const urlParams = url.searchParams;
      urlParams.set(DEBUG_NUDGE_PARAM, encodeURIComponent(nudge.id));
      window.open(url, '_blank');
    } else {
      setShowExtensionSetupInstructions(!showExtensionSetupInstructions);
    }
  };

  return (
    <Container>
      <CmdTooltip
        message={
          (flags['release-preview-v-2'] && isPreviewDisabled) || isUnsavedItem
            ? 'Save your nudge before previewing'
            : 'Test this nudge in your app'
        }
      >
        <CmdButton
          onClick={handlePlayClick}
          disabled={flags['release-preview-v-2'] ? isPreviewDisabled : isUnsavedItem}
          icon={<Play />}
        />
      </CmdTooltip>
      {showExtensionSetupInstructions && !flags['release-preview-v-2'] && (
        <StudioPreviewSetupInstructions
          isChromeExtensionInstalled={isChromeExtensionInstalled}
          onClose={() => setShowExtensionSetupInstructions(false)}
        />
      )}
    </Container>
  );
};

interface PreviewButtonProps {
  nudge: INudgeType;
  isDisabled?: boolean;
}

export const PreviewButton = ({ nudge, isDisabled }: PreviewButtonProps) => {
  const { isStudio } = useAppContext();

  const openInNewTab = useCallback(
    (url: string) => {
      const currentUrl = new URL(url);
      const urlParams = currentUrl.searchParams;
      urlParams.set(DEBUG_NUDGE_PARAM, encodeURIComponent(nudge.id));
      window.open(currentUrl, '_blank');
    },
    [nudge.id],
  );

  return (
    <CmdTooltip message="Save your nudge before previewing" showIf={isDisabled}>
      <CmdDropdown.Menu>
        <CmdDropdown.Trigger
          disabled={isDisabled}
          onClick={() => {
            Sender.closeNudgeMocks();
            Sender.hideEditor();
            Sender.startNudgeDebug(nudge);
          }}
          asSplitButton
        >
          <Play />
        </CmdDropdown.Trigger>

        <CmdDropdown.Content>
          <CmdDropdown.Item
            onClick={() => {
              Sender.closeNudgeMocks();
              Sender.hideEditor();
              Sender.startNudgeDebug(nudge);
            }}
          >
            <Play />
            Test
          </CmdDropdown.Item>

          <CmdDropdown.Item
            onClick={async () => {
              const { url } = await Sender.getHostUrl(isStudio);
              openInNewTab(url);
            }}
          >
            <Copy04 />
            Test in a new tab
          </CmdDropdown.Item>
        </CmdDropdown.Content>
      </CmdDropdown.Menu>
    </CmdTooltip>
  );
};
