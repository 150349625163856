/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import StyledIconContainer from './StyledIconContainer';
import { XClose } from '@commandbar/design-system/icons/react';
import ButtonWithReset from './ButtonWithReset';

const styles: CSSObject = {
  display: 'flex',
  width: 'var(--form-control-height)',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'var(--form-control-gap)',
  color: 'var(--button-tertiary-content)',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--button-tertiary-border-color)',
  background: 'var(--button-tertiary-background)',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  userSelect: 'none',

  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-semibold)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'var(--button-tertiary-border-color-hover)',
    background: 'var(--button-tertiary-background-hover)',
    color: 'var(--button-tertiary-content-hover)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
    background: 'var(--button-tertiary-background-hover)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: 'var(--button-tertiary-border-color)',
    background: 'var(--button-tertiary-background)',
    color: 'var(--button-tertiary-content)',
  },
};

const slug = 'tertiaryIconButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledTertiaryIconButton = ({
  themeV2,
  children,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset css={[overrides, css(styles)]} {...props}>
      <StyledIconContainer>{children}</StyledIconContainer>
    </ButtonWithReset>
  );
};

StyledTertiaryIconButton.styles = styles;
StyledTertiaryIconButton.slug = slug;
StyledTertiaryIconButton.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledTertiaryIconButton themeV2={{ themeV2: themeV2, mode: mode }}>
    <XClose />
  </StyledTertiaryIconButton>
);

export default StyledTertiaryIconButton;
