import * as Toolbar from '@radix-ui/react-toolbar';
import { type VariantProps, cva } from 'class-variance-authority';
import React, { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react';

import { cn } from '../util';

const CmdToolbar = forwardRef<ElementRef<typeof Toolbar.Root>, ComponentPropsWithoutRef<typeof Toolbar.Root>>(
  ({ children, className, ...props }, ref) => (
    <Toolbar.Root
      ref={ref}
      className={cn('flex min-w-max gap-xxs border border-elementBase bg-surfaceBase p-xs', className)}
      {...props}
    >
      {children}
    </Toolbar.Root>
  ),
);

const buttonStyles = cva(
  [
    'flex',
    'h-8',
    'items-center',
    'justify-items-center',
    'rounded-button',
    'border-none',
    'bg-surfaceBase',
    'p-sm',
    'text-base',
    'text-contentMid',
    'cursor-pointer',
    'hover:bg-surfaceMid',
    'hover:text-contentMid',
  ],
  {
    variants: {
      variant: {
        default: [],
        primary: [
          'bg-blue700',
          'text-gray100',
          'border border-solid border-transparent',
          'hover:bg-blue800',
          '[&>svg:not(#cmdSpinner)]:text-blue200',
          'focus:border-blue700',
        ],
        link: [
          'bg-transparent',
          'border border-solid border-transparent',
          'text-gray700',
          'hover:text-gray1000',
          'hover:bg-elementBase',
          'shadow-none',
          'hover:[>svg:not(#cmdSpinner)]:text-gray800',
          '[>svg:not(#cmdSpinner)]:text-gray600',
        ],
        destructive: [
          'bg-gray100',
          'border border-solid',
          'border-red900',
          'hover:border-red800',
          'text-red900',
          'hover:text-red800',
          'focus:shadow-inputError',
          'focus:border-red800',
        ],
        inverse: [
          'bg-transparent',
          'border border-solid border-transparent',
          'text-contentBase',
          'hover:text-contentInverse',
          'hover:bg-backgroundHoverInverse',
          'focus:border-blue700',
          'focus:shadow-button',
        ],
      },
      modifier: {
        filled: ['border border-transparent', 'bg-transparent', 'shadow-none'],
        outline: ['bg-gray100', 'border border-current'],
        blueHover: ['hover:text-blue800', 'hover:bg-blue200'],
      },
      disabled: {
        true: [
          'bg-gray200',
          'text-gray600',
          'cursor-not-allowed',
          'hover:bg-gray200',
          'hover:text-gray600',
          'active:top-0',
          'shadow-none',
          '[&>svg:not(#cmdSpinner)]:text-gray400',
        ],
      },
      loading: {
        true: ['cursor-not-allowed active:top-0'],
      },
      active: {
        true: ['bg-blue100', 'text-blue900', 'hover:bg-blue100', 'hover:text-blue900'],
      },
    },
    compoundVariants: [
      {
        disabled: true,
        variant: 'default',
        class:
          'border text-contentDisabled border-elementDisabled bg-surfaceDisabled hover:bg-surfaceDisabled hover:border-elementDisabled hover:text-contentDisabled',
      },
      {
        disabled: true,
        variant: 'link',
        class: 'border-transparent bg-transparent hover:bg-transparent hover:border-transparent',
      },
    ],
    defaultVariants: {
      variant: 'default',
    },
  },
);

type ToolbarButtonProps = ComponentPropsWithoutRef<typeof Toolbar.Button> &
  VariantProps<typeof buttonStyles> & { active?: boolean };

const CmdToolbarButton = forwardRef<ElementRef<typeof Toolbar.Button>, ToolbarButtonProps>(
  ({ children, className, variant, modifier, loading, disabled, active, ...props }, ref) => (
    <Toolbar.Button
      ref={ref}
      disabled={disabled}
      className={cn(buttonStyles({ variant, modifier, disabled, loading, active }), className)}
      {...props}
    >
      {children}
    </Toolbar.Button>
  ),
);

const CmdToolbarSeparator = forwardRef<
  ElementRef<typeof Toolbar.Separator>,
  ComponentPropsWithoutRef<typeof Toolbar.Separator>
>(({ children, className, ...props }, ref) => (
  <Toolbar.Separator ref={ref} className={cn('w-px bg-elementBase', className)} {...props} />
));

const CmdToolbarLink = forwardRef<ElementRef<typeof Toolbar.Link>, ComponentPropsWithoutRef<typeof Toolbar.Link>>(
  ({ children, className, ...props }, ref) => (
    <Toolbar.Link
      ref={ref}
      className={cn(
        'flex h-8 cursor-pointer items-center justify-items-center rounded-button border-none bg-surfaceBase p-sm text-base text-contentMid hover:bg-surfaceMid hover:text-contentMid',
        className,
      )}
      {...props}
    >
      {children}
    </Toolbar.Link>
  ),
);

const CmdToolbarToggleGroup = forwardRef<
  ElementRef<typeof Toolbar.ToggleGroup>,
  ComponentPropsWithoutRef<typeof Toolbar.ToggleGroup>
>(({ children, className, ...props }, ref) => (
  <Toolbar.ToggleGroup ref={ref} className={cn('flex gap-xxs', className)} {...props}>
    {children}
  </Toolbar.ToggleGroup>
));

const CmdToolbarToggleItem = forwardRef<
  ElementRef<typeof Toolbar.ToggleItem>,
  ComponentPropsWithoutRef<typeof Toolbar.ToggleItem>
>(({ children, className, ...props }, ref) => (
  <Toolbar.ToggleItem
    ref={ref}
    className={cn(
      'flex h-8 cursor-pointer items-center justify-items-center rounded-button border-none bg-surfaceBase p-sm text-contentMid hover:bg-surfaceMid data-[state=on]:bg-blue100 data-[state=on]:text-blue900',
      className,
    )}
    {...props}
  >
    {children}
  </Toolbar.ToggleItem>
));

export {
  CmdToolbar,
  CmdToolbarButton,
  CmdToolbarLink,
  CmdToolbarSeparator,
  CmdToolbarToggleGroup,
  CmdToolbarToggleItem,
};
