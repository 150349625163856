import React, { useState, useEffect } from 'react';
import { ConditionTypeSelect } from '../ConditionTypeSelect';
import * as Sentry from '@sentry/react';

import { EndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import { IUserProperty, UserProperty } from '@commandbar/internal/middleware/userProperty';
import { GenericAutoComplete } from '../GenericAutoComplete';
import { useConditionEditor } from '../../state/useConditionEditor';
import type { UserPropertyCondition as UserPropertyConditionType } from '@commandbar/internal/middleware/conditions';
import { Col, Row } from '@commandbar/design-system/components/antd';
import { OperatorSelect } from '../OperatorSelect';
import { ConditionValueInput } from '../ConditionValueInput';
import { ConditionProps } from '../../types';
import { useAppContext } from 'editor/src/AppStateContext';

export const UserPropertyCondition: React.FC<ConditionProps<UserPropertyConditionType>> = ({ condition }) => {
  const { flags } = useAppContext();
  const { onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  const [propertyType, setPropertyType] = useState<null | IUserProperty['type']>(null);

  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
      type?: IUserProperty['type'];
    }[]
  >([]);

  useEffect(() => {
    if (flags?.['release-user-property-management']) {
      UserProperty.list()
        .then((properties) => {
          setOptions(
            properties.map((property) => ({ label: property.name, value: property.name, type: property.type })),
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    } else {
      EndUserAdmin.properties()
        .then((properties) => {
          setOptions(properties.keys.map((key) => ({ label: key, value: key })));
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Row style={{ gap: '4px' }} align="middle" wrap={false}>
        <ConditionTypeSelect />
        <Col flex="1 2 auto">
          <GenericAutoComplete
            value={condition.field}
            options={options}
            onChange={(field) => {
              setPropertyType(options.find((option) => option.value === field)?.type ?? null);
              onConditionChange({ ...condition, field });
            }}
            allowArbitraryValue
            disabled={disabled}
            onBlur={() => setHasBlurred(true)}
          />
        </Col>
        <OperatorSelect propertyType={propertyType} />
      </Row>
      <ConditionValueInput />
    </div>
  );
};
