/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react';
import styled from '@emotion/styled';

import { Expand02, Minus, XClose } from '@commandbar/design-system/icons/react';

import StyledIconContainer from './StyledIconContainer';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import StyledTertiaryIconButton from './StyledTertiaryIconButton';

const styles: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexShrink: 0,
  alignSelf: 'stretch',
  background: 'var(--form-control-active-background)',
  height: 'var(--header-height)',
  color: 'var(--content-primary)',
};

const ButtonContainer = styled.div`
  padding: var(--layout-padding);
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Icon = styled(StyledIconContainer)`
  cursor: pointer;
  color: var(--content-secondary);
`;

const Title = styled.div`
  /* label/base/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);
`;

const slug = 'assistanceModalHeader';

type ModalHeaderProps = {
  title: string;
  onClose?: () => void;
  onMinimize?: () => void;
  isMinimized: boolean;
  isMobileDevice: boolean;
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledAssistanceModalHeader = Object.assign(
  React.forwardRef<HTMLDivElement, ModalHeaderProps & React.HTMLAttributes<HTMLDivElement>>(
    ({ children, title, onClose, onMinimize, isMinimized, isMobileDevice, themeV2, ...props }, ref) => {
      const { overrides } = useComponentOverrides(slug, themeV2);

      return (
        <div css={[overrides, css(styles)]} ref={ref} {...props}>
          <ButtonContainer data-draggable="drag-zone" data-testid="drag-zone" style={{ flex: 1 }}>
            <Title>{title}</Title>
          </ButtonContainer>
          <ButtonContainer>
            {/* In the Mobile SDK's, HelpHub is rendered in a WebView and closed by the SDK itself, closing HelpHub via the normal way would cause the webview to render white page */}
            {!window._cbIsWebView && (
              <>
                {!isMobileDevice && (
                  <StyledTertiaryIconButton themeV2={themeV2} onClick={onMinimize}>
                    {isMinimized ? <Expand02 /> : <Minus />}
                  </StyledTertiaryIconButton>
                )}
                {!!onClose && (
                  <StyledTertiaryIconButton themeV2={themeV2} onClick={onClose}>
                    <XClose />
                  </StyledTertiaryIconButton>
                )}
              </>
            )}
          </ButtonContainer>
        </div>
      );
    },
  ),
  {
    slug: slug,
    styles: styles,
    renderMock: (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
      <StyledHeaderMock themeV2={themeV2} mode={mode} />
    ),
  },
);

const StyledHeaderMock = ({ themeV2, mode }: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const { overrides } = useComponentOverrides(slug, { themeV2: themeV2, mode: mode });
  return (
    <div css={[overrides, css(styles)]} style={{ width: '350px' }}>
      <ButtonContainer data-draggable="drag-zone" data-testid="drag-zone" style={{ flex: 1 }}>
        <Title>Title bar</Title>
      </ButtonContainer>
      <ButtonContainer>
        <Icon>
          <Minus />
        </Icon>
        <Icon>
          <XClose />
        </Icon>
      </ButtonContainer>
    </div>
  );
};

export default StyledAssistanceModalHeader;
