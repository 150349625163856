import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { Select } from '@commandbar/design-system/components/antd';

import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import Sender from '../management/Sender';
import { DeviceType } from '@commandbar/internal/util/operatingSystem';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import { useAppContext } from '../AppStateContext';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

import { Caret01, Circle, Monitor01, Phone01, Settings04, PaintPour } from '@commandbar/design-system/icons/react';
import {
  CmdAccordion,
  CmdButton,
  CmdButtonTabs,
  CmdPopover,
  CmdPopoverContent,
  CmdPopoverSelectTrigger,
  CmdStatusDot,
  CmdTextarea,
  CmdTooltip,
  CmdTypography,
  cmdToast,
} from '@commandbar/design-system/cmd';
import { EndUserAdmin, IEndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import { CodeSnippet, Code } from '../editor/copilot/ChatDebugPanel';
import { LoadingSave } from '../editor/themes/shared';
import EndUserChooser from '../editor/components/EndUserChooser';
import { hasUnpublishedChanges } from '../services/themes';

export const enum Widget {
  Bar,
  HelpHub,
  Copilot,
  Surveys,
  ProductTours,
  Announcements,
  Checklist,
}

const routePrefixToWidget: [string, Widget][] = [
  [editorRoutes.SPOTLIGHT_ROUTE, Widget.Bar],
  [editorRoutes.ACTIONS_ROUTE, Widget.Bar],
  [editorRoutes.PAGES_ROUTE, Widget.Bar],
  [editorRoutes.HELPHUB_ROUTE, Widget.HelpHub],
  [editorRoutes.COPILOT_PARENT_ROUTE, Widget.Copilot],
  [editorRoutes.SURVEYS_ROUTE, Widget.Surveys],
  [editorRoutes.PRODUCT_TOURS_ROUTE, Widget.ProductTours],
  [editorRoutes.ANNOUNCEMENTS_ROUTE, Widget.Announcements],
  [editorRoutes.CHECKLIST_ROUTE, Widget.Checklist],
];

const advancedSkinsEditorPathPrefix = `${editorRoutes.DESIGN_ROUTE}/skins/$default/advanced`;

const widgetToThemeEditorPath: Record<Widget, string | undefined> = {
  [Widget.Bar]: `${advancedSkinsEditorPathPrefix}/bar`,
  [Widget.HelpHub]: `${advancedSkinsEditorPathPrefix}/help-hub`,
  [Widget.Copilot]: `${advancedSkinsEditorPathPrefix}/help-hub`,
  [Widget.Surveys]: `${advancedSkinsEditorPathPrefix}/nudges`,
  [Widget.ProductTours]: `${advancedSkinsEditorPathPrefix}/nudges`,
  [Widget.Announcements]: `${advancedSkinsEditorPathPrefix}/nudges`,
  [Widget.Checklist]: `${advancedSkinsEditorPathPrefix}/checklists`,
};
const widgetTypeSearchParam: Record<Widget, string | undefined> = {
  [Widget.Bar]: `?widgetType=spotlight`,
  [Widget.HelpHub]: `?widgetType=helphub`,
  [Widget.Copilot]: `?widgetType=copilot`,
  [Widget.Surveys]: `?widgetType=nudge`,
  [Widget.ProductTours]: `?widgetType=nudge`,
  [Widget.Announcements]: `?widgetType=nudge`,
  [Widget.Checklist]: `?widgetType=checklist`,
};

const widgets = [
  { name: 'HelpHub', value: Widget.HelpHub },
  { name: 'Copilot', value: Widget.Copilot },
  { name: 'Announcements', value: Widget.Announcements },
  { name: 'Tours', value: Widget.ProductTours },
  { name: 'Surveys', value: Widget.Surveys },
  { name: 'Checklists', value: Widget.Checklist },
  { name: 'Bar', value: Widget.Bar },
] as const;

const showNudge = (nudges: INudgeType[]) => {
  if (nudges.length) {
    const [nudge] = nudges;
    const [step] = nudge.steps;
    Sender.showNudgeStepMock(
      {
        ...nudge,
        steps: [
          {
            ...step,
            form_factor: {
              type: 'popover',
              position: 'center',
            },
          },
        ],
      },
      0,
    );
    return () => Sender.closeNudgeMocks();
  }
};

const EditedIndicator = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  right: -4px;
  height: 8px;
  width: 8px;
  & svg {
    opacity: 100%;
    fill: #2754ee;
    & path {
      stroke: #2754ee;
    }
  }
`;

const PrefixedSelect = styled(Select)`
  z-index: ${Z.Z_INDEX_MAX} !important;

  .ant-select-selector::before {
    content: 'Preview: ';
    font-weight: 600;
    color: #000;
    margin-right: 4px;
    align-self: center;
  }
` as unknown as typeof Select;

const WidgetSelector = ({
  activeWidget,
  onWidgetChange,
}: {
  activeWidget: Widget;
  onWidgetChange: (w: Widget) => void;
}) => {
  const { nudges, checklists, commandBarReady } = useAppContext();
  useEffect(() => {
    if (!commandBarReady) return;

    // open the active widget
    switch (activeWidget) {
      case Widget.HelpHub:
        Sender.openHelpHub();
        return () => {
          Sender.closeHelpHub();
        };
      case Widget.Copilot:
        Sender.openHelpHub({ chatOnly: true });
        return () => {
          Sender.closeHelpHub();
        };
      case Widget.Announcements:
        const announcementNudges = nudges.filter((n) => n.type === 'announcement');
        return showNudge(announcementNudges);
      case Widget.Surveys:
        const surveyNudges = nudges.filter((n) => n.type === 'survey');
        return showNudge(surveyNudges);
      case Widget.ProductTours:
        const productTourNudges = nudges.filter((n) => n.type === 'product_tour');
        return showNudge(productTourNudges);
      case Widget.Checklist:
        if (checklists.length) {
          Sender.previewChecklist(checklists[0], false);
          return () => Sender.stopChecklistPreview();
        }
        break;
      case Widget.Bar:
        Sender.openBar();
        return () => Sender.closeBar();
    }
  }, [activeWidget, commandBarReady]);

  return (
    <PrefixedSelect value={activeWidget} onChange={onWidgetChange} dropdownStyle={{ zIndex: Z.Z_INDEX_MAX }}>
      {widgets.map(({ name, value }) => (
        <Select.Option key={value} value={value}>
          {name}
        </Select.Option>
      ))}
    </PrefixedSelect>
  );
};
const EditStyles = ({ currentWidget }: { currentWidget: Widget | null }) => {
  const { organization, nudges, checklists, commandBarReady, themes } = useAppContext();
  const history = useHistory();
  const location = useLocation();

  const editorPath = location.pathname.split('/').slice(0, 3).join('/');

  useEffect(() => {
    if (!commandBarReady) return;

    // we just need a way to trigger the panzoom refresh, we do it by changing the widgetTableauSelection value
    Sender.showWidgetTableau(false, { id: `${Math.random()}`, type: 'all' });
  }, [commandBarReady, editorPath]);

  const currentNudge = useMemo(() => {
    if (currentWidget === null) return null;

    const pathSegments = editorPath.split('/');

    const lastSegment = pathSegments[pathSegments.length - 1];

    const parsedInt = parseInt(lastSegment, 10);

    if ([Widget.Announcements, Widget.Surveys, Widget.ProductTours].includes(currentWidget)) {
      return nudges.find((nudge) => nudge.id === parsedInt);
    } else {
      return null;
    }
  }, [currentWidget, editorPath, nudges]);

  const currentChecklist = useMemo(() => {
    if (currentWidget === null) return null;

    const pathSegments = editorPath.split('/');

    const lastSegment = pathSegments[pathSegments.length - 1];

    const parsedInt = parseInt(lastSegment, 10);

    if ([Widget.Checklist].includes(currentWidget)) {
      return checklists.find((checklist) => checklist.id === parsedInt);
    } else {
      return null;
    }
  }, [currentWidget, editorPath, checklists]);

  // Cached variable computation
  const currentThemeSlug = useMemo(() => {
    if (currentWidget === null) return null;

    let themeId: null | string = null;

    if (currentChecklist && currentChecklist.custom_theme) {
      themeId = currentChecklist.custom_theme;
    } else if (currentNudge && currentNudge.custom_theme) {
      themeId = currentNudge.custom_theme;
    } else {
      if (currentWidget === Widget.Announcements && organization.announcements_custom_theme) {
        themeId = organization.announcements_custom_theme;
      } else if (currentWidget === Widget.Surveys && organization.surveys_custom_theme) {
        themeId = organization.surveys_custom_theme;
      } else if (currentWidget === Widget.ProductTours && organization.product_tours_custom_theme) {
        themeId = organization.product_tours_custom_theme;
      } else if (currentWidget === Widget.Checklist && organization.checklists_custom_theme) {
        themeId = organization.checklists_custom_theme;
      } else if (currentWidget === Widget.Bar && organization.spotlight_custom_theme) {
        themeId = organization.spotlight_custom_theme;
      } else if (
        (currentWidget === Widget.HelpHub || currentWidget === Widget.Copilot) &&
        organization.helphub_custom_theme
      ) {
        themeId = organization.helphub_custom_theme;
      }
    }
    if (themeId) {
      return themes.find((theme) => theme.uuid === themeId)?.slug;
    } else {
      return themes.find((theme) => theme.default)?.slug;
    }
  }, [
    currentWidget,
    editorPath,
    organization.helphub_custom_theme,
    organization.spotlight_custom_theme,
    organization.product_tours_custom_theme,
    organization.surveys_custom_theme,
    organization.announcements_custom_theme,
    organization.checklists_custom_theme,
    currentChecklist,
    currentNudge,
  ]);

  //path looks like e.g. /editor/themes/foocorp-theme/base?widgetType=nudge&widgetId=1
  const getThemesV2ExperiencePath = () => {
    if (currentWidget === null) {
      return editorRoutes.THEME_ROUTE;
    }

    const widgetTypeParam = widgetTypeSearchParam[currentWidget];

    const pathSegments = editorPath.split('/');

    const lastSegment = pathSegments[pathSegments.length - 1];

    const parsedInt = parseInt(lastSegment, 10);

    if (!isNaN(parsedInt) && parsedInt > 0) {
      return `${editorRoutes.THEME_ROUTE}/${currentThemeSlug}/base${widgetTypeParam}&widgetId=${parsedInt}`;
    } else {
      const typeRoute = `${editorRoutes.THEME_ROUTE}/${currentThemeSlug}/base${widgetTypeParam}`;

      if (currentWidget === Widget.Announcements) {
        return `${typeRoute}&widgetId=announcement`;
      } else if (currentWidget === Widget.Surveys) {
        return `${typeRoute}&widgetId=survey`;
      } else if (currentWidget === Widget.ProductTours) {
        return `${typeRoute}&widgetId=tour`;
      } else {
        return typeRoute;
      }
    }
  };

  const themeEditorPath = getThemesV2ExperiencePath();

  useEffect(() => {
    if (!commandBarReady) return;

    const currentTheme = themes.find((theme) => theme.slug === currentThemeSlug);
    if (currentTheme) {
      Sender.setThemeV2(currentTheme.themeV2_draft, 'light_mode');
    }
  }, [currentThemeSlug, commandBarReady]);

  const isCustomizeThemePage = location.pathname.includes('/customize-theme');

  if (!themeEditorPath || isCustomizeThemePage) return null;

  const currentTheme = themes.find((theme) => theme.slug === currentThemeSlug);

  if (currentNudge || currentChecklist) {
    return null;
  }
  return (
    <CmdButton
      onClick={async () => {
        const path = await getThemesV2ExperiencePath();
        history.push(path);
        return;
      }}
    >
      <PaintPour />
      Edit Theme
      {currentTheme && hasUnpublishedChanges(currentTheme) && (
        <CmdTooltip message="Theme has unpublished changes">
          <CmdStatusDot style={{ marginLeft: '8px' }} variant="warning" />
        </CmdTooltip>
      )}
    </CmdButton>
  );
};

const WidgetSelectorOrEditStylesButton = () => {
  const { user } = useAuth();
  const location = useLocation();
  const editorPath = location.pathname;
  const [lastWidget, setLastWidget] = useState<Widget | null>(null);
  const currentWidget =
    routePrefixToWidget
      .map(([routePrefix, widget]) => {
        if (editorPath.startsWith(routePrefix)) return widget;
        return null;
      })
      .find((route) => route !== null) ?? null;

  useEffect(() => {
    if (currentWidget !== null) setLastWidget(currentWidget);
  }, [currentWidget]);

  const { flags } = useAppContext();

  const themeEditorPathForCurrentWidget = currentWidget !== null ? widgetToThemeEditorPath[currentWidget] : null;

  // themes V1 widget selector
  if (editorPath.startsWith(editorRoutes.DESIGN_ROUTE) && !editorPath.includes('/advanced')) {
    const activeWidget = lastWidget ?? Widget.HelpHub;

    // show a widget selector, with the last-used widget selected
    return (
      <WidgetSelector
        activeWidget={activeWidget}
        onWidgetChange={(widget: Widget) => {
          setLastWidget(widget);
        }}
      />
    );
  }

  if (flags['release-themes-v2'] && themeEditorPathForCurrentWidget && hasRequiredRole(user, 'contributor')) {
    return <EditStyles currentWidget={currentWidget} />;
  }

  return null;
};

const DeviceSelector = () => {
  const {
    dispatch: { setEditorPreviewDevice },
    editorPreviewDevice,
  } = useAppContext();

  useEffect(() => {
    Sender.updateEditorPreviewDevice(editorPreviewDevice);
  }, [editorPreviewDevice]);

  return (
    <CmdButtonTabs
      variant="group"
      activeKey={editorPreviewDevice}
      onChange={(deviceType: string) => setEditorPreviewDevice(deviceType as DeviceType)}
      tabs={[
        {
          label: <Monitor01 />,
          key: 'desktop',
        },
        {
          label: <Phone01 />,
          key: 'mobile',
        },
      ]}
    />
  );
};

const ImpersonateEndUserSettings = () => {
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [editingUserProperties, setEditingUserProperties] = useState(false);

  const [endUser, setEndUser] = useState<string>();
  const [userProperties, setUserProperties] = useState<Record<string, any>>();
  const [userPropertiesString, setUserPropertiesString] = useState<string>();
  const [filter, setFilter] = useState<string[]>([]);

  const [currUser, setCurrUser] = useState<IEndUserAdmin>(); // this is the IEndUserAdmin for the currently logged in user

  const { user } = useAuth();

  const {
    dispatch: { setEditorCopilotOverrides },
    editorCopilotOverrides,
    commands,
  } = useAppContext();

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (editingUserProperties) {
      textAreaRef.current?.focus();
      // start cursor at the end of the last user property
      textAreaRef.current?.setSelectionRange(
        textAreaRef.current.value.length - 2,
        textAreaRef.current.value.length - 2,
      );
    }
  }, [editingUserProperties]);

  const uniqueLabels = new Set<string>();
  commands.forEach((command) => {
    command.labels?.forEach((label) => uniqueLabels.add(label));
  });

  const savedChanges =
    (!!editorCopilotOverrides.user && editorCopilotOverrides.user !== user?.profile.user) ||
    (!!editorCopilotOverrides.userProperties && editorCopilotOverrides.userProperties !== currUser?.user_properties) ||
    (!!editorCopilotOverrides.filter && editorCopilotOverrides.filter.length > 0);

  const edited = endUser !== user?.profile.user || userProperties !== currUser?.user_properties || filter.length > 0;

  const submitButtonDisabled = savedChanges
    ? endUser === editorCopilotOverrides.user &&
      userProperties === editorCopilotOverrides.userProperties &&
      filter === editorCopilotOverrides.filter
    : !edited;

  useEffect(() => {
    const getCurrUser = async () => {
      if (user) {
        const fetchedUser = await EndUserAdmin.read(user?.profile.user);
        setCurrUser(fetchedUser);
        setEndUser(fetchedUser?.slug);
        setUserProperties(fetchedUser?.user_properties);
      }
    };
    getCurrUser();
  }, [user]);

  useEffect(() => {
    return () => {
      setEditorCopilotOverrides({});
    };
  }, []);

  useEffect(() => {
    // format user properties string to have newlines + spacing so that it matches visual look of the "code" div
    const newlineString = JSON.stringify(userProperties)?.split(',').join(',\n  ')?.split(':').join(': ');
    setUserPropertiesString(`{\n  ${newlineString?.slice(1, -1)}\n}`);
  }, [userProperties]);

  useEffect(() => {
    Sender.updateEditorCopilotOverrides(editorCopilotOverrides);
  }, [editorCopilotOverrides]);

  const applyChanges = () => {
    if (endUser) {
      setEditorCopilotOverrides({
        user: endUser,
        userProperties: userProperties,
        filter: filter,
      });
    }
    setPopoverOpen(false);
  };

  const resetChanges = () => {
    setEndUser(currUser?.slug);
    setUserProperties(currUser?.user_properties);
    setFilter([]);
  };

  return (
    <CmdPopover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <CmdPopoverSelectTrigger
        caret={false}
        style={{
          position: 'relative',
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Settings04 />
        {savedChanges && (
          <EditedIndicator>
            <Circle />
          </EditedIndicator>
        )}
      </CmdPopoverSelectTrigger>
      <CmdPopoverContent
        style={{
          position: 'absolute',
          right: '-16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          padding: '12px',
          width: '400px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <CmdTypography variant="contentMid">Impersonate user</CmdTypography>
          <CmdTypography variant="secondary" fontSize="sm">
            Chat with Copilot as a specific user
          </CmdTypography>
          {endUser ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <CmdTooltip
                message={
                  !hasRequiredRole(user, 'editor')
                    ? 'You must be an admin or editor to impersonate a different user'
                    : undefined
                }
              >
                <EndUserChooser
                  endUserSlugs={[endUser]}
                  onEndUserChange={(newEndUser) => {
                    setEndUser(newEndUser.slug);
                    setUserProperties(newEndUser.user_properties);
                  }}
                  disabled={!hasRequiredRole(user, 'editor')}
                />
              </CmdTooltip>

              <CmdAccordion defaultValue="properties" collapsible>
                <CmdAccordion.Item
                  value="properties"
                  style={{ background: '#F9F9F9', border: 'none', boxShadow: 'none' }}
                >
                  <CmdAccordion.Trigger
                    caret={false}
                    style={{ height: 'fit-content', border: 'none', padding: '8px' }}
                    onClick={() => setAccordionOpen((open) => !open)}
                  >
                    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <Caret01
                        style={{ rotate: accordionOpen ? '180deg' : '90deg', height: '12px', width: '12px' }}
                        opacity="50%"
                      />
                      <CmdTypography variant="contentMid">User properties</CmdTypography>
                    </div>
                  </CmdAccordion.Trigger>
                  <CmdAccordion.Content style={{ padding: '4px 8px 8px 8px' }}>
                    {editingUserProperties ? (
                      <CmdTextarea
                        fullWidth
                        style={{
                          padding: '8px 8px 8px 20px',
                          fontFamily: 'monospace',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '150%',
                          color: '#79b8ff',
                          background: '#24292e',
                          height: '200px',
                        }}
                        ref={textAreaRef}
                        placeholder={userPropertiesString}
                        value={userPropertiesString}
                        onChange={(e) => setUserPropertiesString(e.target.value)}
                        onBlur={() => {
                          try {
                            if (userPropertiesString && userPropertiesString.length > 0) {
                              setUserProperties(JSON.parse(userPropertiesString));
                            } else {
                              setUserProperties({});
                            }
                          } catch {
                            cmdToast.error('Invalid JSON');
                          }
                          setEditingUserProperties(false);
                        }}
                      />
                    ) : (
                      <CodeSnippet
                        style={{ height: '200px', padding: '8px 8px 8px 20px' }}
                        onClick={() => {
                          setEditingUserProperties(true);
                        }}
                      >
                        <Code>
                          {JSON.stringify(userProperties, null, 2)
                            ?.split('\n')
                            .map((line, index) => (
                              <span key={index}>{line}</span>
                            ))}
                        </Code>
                      </CodeSnippet>
                    )}
                  </CmdAccordion.Content>
                </CmdAccordion.Item>
              </CmdAccordion>
            </div>
          ) : (
            <LoadingSave />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <CmdTypography variant="contentMid">Content filter</CmdTypography>
          <CmdTypography variant="secondary" fontSize="sm">
            Use tags to filter which content Copilot can reference
          </CmdTypography>
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Add tags to filter content on..."
            onChange={(labels) => {
              setFilter(labels);
            }}
            value={filter}
            options={Array.from(uniqueLabels).map((label) => ({ value: label }))}
          />
        </div>
        <div style={{ display: 'flex', gap: '8px', alignSelf: 'flex-end' }}>
          <CmdButton onClick={resetChanges} disabled={!edited}>
            Reset
          </CmdButton>
          <CmdButton variant="primary" onClick={applyChanges} disabled={submitButtonDisabled}>
            Apply changes
          </CmdButton>
        </div>
      </CmdPopoverContent>
    </CmdPopover>
  );
};

const Container = () => {
  const history = useHistory();
  const { flags } = useAppContext();

  return (
    <div
      style={{
        ...(history.location.pathname.startsWith(editorRoutes.THEME_ROUTE) && { visibility: 'hidden' }),
        zIndex: Z.Z_EDITOR,
        position: 'absolute',
        top: 16,
        ...(history.location.pathname.includes('/customize-theme') && { right: 'calc(35% + 16px)' }),
        ...(!history.location.pathname.includes('/customize-theme') && { right: 16 }),
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        width: 'fit-content',
      }}
    >
      <WidgetSelectorOrEditStylesButton />
      <DeviceSelector />
      {history.location.pathname.startsWith(editorRoutes.COPILOT_PARENT_ROUTE) &&
        flags['release-copilot-impersonate-users'] && <ImpersonateEndUserSettings />}
    </div>
  );
};

export default Container;
