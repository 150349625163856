import React, { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { Col, Input, InputNumber } from '@commandbar/design-system/components/antd';
import { useConditionEditor } from '../state/useConditionEditor';
import { CmdTextarea, CmdTypography } from '@commandbar/design-system/cmd';
import { List } from '@commandbar/design-system/icons/react';
import { booleanOperatorTypes, numericOperatorTypes } from '../types';

type ConditionValueInputProps = {
  suffix?: React.ReactNode;
};

type InputProps = ConditionValueInputProps & {
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
  setHasBlurred: React.Dispatch<React.SetStateAction<boolean>>;
};

const TextInput: React.FC<InputProps> = ({ disabled, suffix, value, onChange, setHasBlurred }) => {
  return (
    <Col flex="1 3 auto">
      <Input
        disabled={disabled}
        value={value}
        placeholder="value"
        style={{ width: '100%', height: '24px' }}
        onBlur={() => setHasBlurred(true)}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        size="small"
        suffix={suffix}
      />
    </Col>
  );
};

const NumberInput: React.FC<InputProps> = ({ disabled, value, onChange, setHasBlurred }) => {
  return (
    <Col flex="1 3 auto">
      <InputNumber
        disabled={disabled}
        value={value}
        placeholder="number"
        style={{ width: '100%' }}
        onBlur={() => setHasBlurred(true)}
        onChange={(value) => {
          onChange(value?.toString() || '');
        }}
        size="small"
      />
    </Col>
  );
};

const MultiValueInput: React.FC<InputProps> = ({ disabled, value, onChange, setHasBlurred }) => {
  const [localValue, setLocalValue] = React.useState<string>('');

  React.useEffect(() => {
    try {
      setLocalValue(JSON.parse(value).join('\n'));
    } catch (e) {}
  }, [value]);

  const count = useMemo(() => {
    try {
      return localValue
        .split('\n')
        .map((v) => v.trim())
        .filter((v) => v.length > 0).length;
    } catch (e) {
      return null;
    }
  }, [localValue]);

  return (
    <div>
      <CmdTextarea
        rows={10}
        fullWidth
        disabled={disabled}
        value={localValue}
        placeholder={'A list of values, one per line\nTip: Try pasting a column of values from a spreadsheet!'}
        style={{ width: '100%', height: '96px' }}
        onBlur={() => setHasBlurred(true)}
        onChange={(e) => {
          setLocalValue(e.target.value);

          const items = e.target.value
            .split('\n')
            .map((v) => v.trim())
            .filter((v) => v.length > 0);

          onChange(items.length > 0 ? JSON.stringify(items) : '');
        }}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'right',
          fontSize: '12px',
          color: '#666',
          marginTop: '4px',
        }}
      >
        <List style={{ marginLeft: 'auto', marginRight: '4px' }} />

        <CmdTypography.Body variant="secondary" fontSize="sm">
          Count: {count}
        </CmdTypography.Body>
      </div>
    </div>
  );
};

export const ConditionValueInput: React.FC<ConditionValueInputProps> = ({ suffix }) => {
  const { condition, onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  if (!('operator' in condition)) {
    throw new Error('ConditionValueInput: Condition must have an operator');
  } else if (!('value' in condition)) {
    Sentry.captureException(new Error('ConditionValueInput: Condition must have a value'));
    return null;
  }

  const isUnaryOperator =
    !!condition.operator && [...booleanOperatorTypes, 'isDefined', 'isNotDefined'].includes(condition.operator);
  const isNumericOperator = !!condition.operator && numericOperatorTypes.includes(condition.operator);
  const isMultiValueOperator =
    !!condition.operator && (condition.operator === 'isInList' || condition.operator === 'isNotInList');

  if (isUnaryOperator) {
    return null;
  }

  return isNumericOperator ? (
    <NumberInput
      disabled={disabled}
      value={condition.value as string}
      onChange={(value) => onConditionChange({ ...condition, value })}
      setHasBlurred={setHasBlurred}
    />
  ) : isMultiValueOperator ? (
    <MultiValueInput
      disabled={disabled}
      value={condition.value as string}
      onChange={(value) => onConditionChange({ ...condition, value })}
      setHasBlurred={setHasBlurred}
      suffix={suffix}
    />
  ) : (
    <TextInput
      disabled={disabled}
      value={condition.value as string}
      onChange={(value) => onConditionChange({ ...condition, value })}
      setHasBlurred={setHasBlurred}
      suffix={suffix}
    />
  );
};
