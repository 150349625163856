import * as t from 'io-ts';

import { decodeThrowing, decodeToPromise } from '@cb/types/decode';

import * as generics from './generics';
import { CommandCategoryV } from './commandCategory';
import { ContextV } from './context';
import { OrganizationV, defaults } from './OrganizationV';
import { PlaceholderV } from './placeholder';

import { NamedRuleV } from './helpers/rules';
import { CommandV, EditorCommandLiteV, EditorCommandV } from './command';
import * as axiosInstance from './network';

export { RecordSettingsV, RecordSettingsByContextKeyV } from './RecordSettingsV';
export { OrganizationV, unknownOrganization } from './OrganizationV';

export const OrganizationStatusV = t.type({
  id: t.union([t.number, t.string]),
  uuid: t.string,
  errors_72h: t.number,
  suggestions_72h: t.number,
  executions_total: t.number,
  users_total: t.number,
});

export const KeyEventV = t.union([
  t.literal('Client-Error'),
  t.literal('Command execution'),
  t.literal('Command suggestion'),
  t.literal('Abandoned search'),
  t.literal('Search miss'),
]);

// type of map of string to string for integration settings
export const InternalSettingsV = t.type({
  id: t.string,
  integrations: t.record(t.string, t.record(t.string, t.any)),
});

export const read = generics.readObject(OrganizationV, 'organizations');
export const update = generics.updateObject(OrganizationV, OrganizationV, 'organizations');
export const listCommands = (orgUID: string) =>
  generics.listObject(EditorCommandLiteV, `organizations/${orgUID}/commands_lite`)();

export const getCommandByUrl = async (orgUID: string, url: string) => {
  const result = await axiosInstance.get(`organizations/${orgUID}/commands/by_url/?url=${encodeURIComponent(url)}`);
  return await decodeToPromise(CommandV, result.data);
};

export const getCommandById = async (orgUID: string, commandID: string) => {
  const result = await axiosInstance.get(`organizations/${orgUID}/commands/${commandID}/?`);
  return await decodeToPromise(EditorCommandV, result.data);
};

export const listAnswers = (orgUID: string) => generics.listObject(EditorCommandV, `organizations/${orgUID}/answers`)();
export const listCommandCategories = (orgUID: string) =>
  generics.listObject(CommandCategoryV, `organizations/${orgUID}/categories`)();
export const listContexts = (orgUID: string) => generics.listObject(ContextV, `organizations/${orgUID}/contexts`)();
export const listPlaceholders = (orgUID: string) =>
  generics.listObject(PlaceholderV, `organizations/${orgUID}/placeholders`)();
export const listRules = (orgUID: string) => generics.listObject(NamedRuleV, `organizations/${orgUID}/rules`)();
export const readHealth = generics.readObjectDetail(OrganizationStatusV, 'organizations', 'health');

export const readInternal = generics.readObjectDetail(InternalSettingsV, 'organizations', 'internal');
export const updateInternal = generics.updateObjectDetail(
  InternalSettingsV,
  InternalSettingsV,
  'organizations',
  'internal',
);

export const decode = (data: any) => decodeThrowing(OrganizationV, { ...defaults, ...data });
