import React, { useEffect } from 'react';
import { Route, RouteComponentProps, Switch, useHistory } from 'react-router';

import { useAppContext } from 'editor/src/AppStateContext';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { NotificationBox, CheckSquareBroken, SearchSm } from '@commandbar/design-system/icons/react';

import { TabPane, Tabs, CB_COLORS, ConfigProvider, Empty } from '@commandbar/design-system/components/antd';

import { PaddingContainer, ScrollContainer } from '../Router';
import CopilotSettings from './CopilotSettings';
import APIs from './APIs';
import LogoutHeader from '../components/LogoutHeader';
import LaunchCTA from './LaunchCTA';
import { nudgeTitleDisplay } from '../nudges/NudgeList';
import Sender from '../../management/Sender';
import { getCommandRoute } from '../PagesOrActions';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import XRay from './XRay';
import Workflows from './Workflows';
import { SimplePaginatedTable } from '../components/SimplePaginatedTable';

type CopilotEditorProps = RouteComponentProps<Record<string, never>>;

const useCopilotSuggestedExperiences = () => {
  const { commands, nudges, checklists } = useAppContext();
  return {
    commands: commands.filter((c) => c.copilot_suggest),
    nudges: nudges.filter((n) => n.copilot_suggest),
    checklists: checklists.filter((c) => c.copilot_suggest),
  };
};

const CopilotEditor: React.FC<CopilotEditorProps> = ({ match }) => {
  const history = useHistory();
  const { isStudio, commandBarReady } = useAppContext();
  const { user } = useAuth();

  useEffect(() => {
    if (isStudio && commandBarReady) {
      Sender.openHelpHub({ chatOnly: true, articleId: null });
      return () => {
        Sender.closeHelpHub();
      };
    }
  }, [commandBarReady]);

  const copilotSuggestedExperiences = useCopilotSuggestedExperiences();
  const combinedExperiences = [
    ...copilotSuggestedExperiences.commands.map((c) => ({
      key: `command-${c.id}`,
      name: c.text,
      goto: () => history.push(`${getCommandRoute(c)}/${c.id}`),
      Icon: SearchSm,
      item: c,
    })),
    ...copilotSuggestedExperiences.nudges.map((n) => ({
      key: `nudge-${n.id}`,
      name: nudgeTitleDisplay(n),
      goto: () => history.push(`${editorRoutes.getNudgeRoute(n)}/${n.id}`),
      Icon: NotificationBox,
      item: n,
    })),
    ...copilotSuggestedExperiences.checklists.map((q) => ({
      key: `checklist-${q.id}`,
      name: q.title,
      goto: () => history.push(`${editorRoutes.CHECKLIST_ROUTE}/${q.id}`),
      Icon: CheckSquareBroken,
      item: q,
    })),
  ];
  combinedExperiences.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  // On refresh, if the url ends in /apis/-1, redirect to /apis
  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith('/apis/-1')) {
      history.replace(`/copilot/apis${history.location.search}`);
    }
  }, [history]);

  // On refresh, if the url ends in /workflows/-1, redirect to /workflows
  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith('/workflows/-1')) {
      history.replace(`/copilot/workflows${history.location.search}`);
    }
  }, [history]);

  return (
    <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
      <Switch>
        <Route exact path={`${match.path}/:page?/:id?`}>
          {({ match }) => {
            return (
              <>
                <LogoutHeader />
                <Tabs
                  activeKey={
                    match?.params?.page
                      ? `${editorRoutes.COPILOT_PARENT_ROUTE}/${match.params.page}`
                      : editorRoutes.COPILOT_PARENT_ROUTE
                  }
                  onChange={(key) => {
                    const searchParams = new URLSearchParams(history.location.search);
                    searchParams.delete('page');
                    history.push(`${key}?${searchParams}`);
                  }}
                  isStudio={isStudio}
                  destroyInactiveTabPane={true}
                  type="card"
                  tabBarStyle={{
                    paddingTop: isStudio ? '9px' : 0,
                    marginTop: -10,
                    paddingLeft: '16px',
                    // Hide tabs when viewing a detail page
                    display: match?.params.id ? 'none' : 'block',
                  }}
                >
                  <TabPane tab="Overview" key={editorRoutes.COPILOT_PARENT_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <div style={{ display: 'flex', gap: 16, flexDirection: 'column', height: '100%' }}>
                          <LaunchCTA />
                          <div
                            style={{
                              border: `1px solid ${CB_COLORS.neutral1000}3D`,
                              borderRadius: 4,
                            }}
                          >
                            <ConfigProvider
                              renderEmpty={() => (
                                <Empty
                                  description={
                                    <p>
                                      Toggle the <b>Suggest in Copilot</b> setting in any nudge, page, or action to
                                      allow Copilot to suggest it to users
                                    </p>
                                  }
                                />
                              )}
                            >
                              <SimplePaginatedTable
                                pageSize={8}
                                columns={[
                                  {
                                    title: 'Enabled Experiences',
                                    dataIndex: 'name',
                                    ellipsis: true,
                                    render: (_value: any, { name, Icon }) => (
                                      <span
                                        style={{
                                          display: 'flex',
                                          gap: 4,
                                          alignItems: 'center',
                                          textDecoration: 'underline',
                                        }}
                                      >
                                        {name} <Icon style={{ width: 16, height: 16 }} />
                                      </span>
                                    ),
                                  },
                                ]}
                                dataSource={combinedExperiences}
                                onRow={(record) => ({
                                  onClick: () => {
                                    if ('goto' in record && record.goto) {
                                      record.goto();
                                    }
                                  },
                                })}
                              />
                            </ConfigProvider>
                          </div>
                        </div>
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>
                  <TabPane tab="APIs" key={editorRoutes.COPILOT_APIS_ROUTE}>
                    <APIs />
                  </TabPane>
                  <TabPane tab="Workflows" key={editorRoutes.COPILOT_WORKFLOWS_ROUTE}>
                    <Workflows />
                  </TabPane>
                  {hasRequiredRole(user, 'editor') && (
                    <TabPane tab="Settings" key={editorRoutes.COPILOT_SETTINGS_ROUTE}>
                      <ScrollContainer>
                        <PaddingContainer>
                          <CopilotSettings />
                        </PaddingContainer>
                      </ScrollContainer>
                    </TabPane>
                  )}
                  <TabPane tab="X-Ray" key={editorRoutes.COPILOT_XRAY_ROUTE}>
                    <ScrollContainer>
                      <PaddingContainer>
                        <XRay />
                      </PaddingContainer>
                    </ScrollContainer>
                  </TabPane>
                </Tabs>
              </>
            );
          }}
        </Route>
      </Switch>
    </div>
  );
};

export default CopilotEditor;
