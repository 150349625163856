/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { CSSObject, css, jsx } from '@emotion/core';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import useComponentOverrides from '../../helpers/useComponentOverrides';

const styles: CSSObject = {
  display: 'flex',
  width: '100%',
  minHeight: '48px',
  padding: 'var(--form-control-padding-x)',
  alignItems: 'flex-start',
  gap: 'var(--layout-gap)',
  color: 'var(--content-primary)',

  /* label/base/medium */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--form-control-border-color)',
  background: 'var(--form-control-background)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  '&:hover': {
    borderColor: 'var(--form-control-border-color-hover)',
    background: 'var(--form-control-background-hover)',
    color: 'var(--content-primary)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-background)',
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:disabled': {
    borderColor: 'var(--form-control-border-color-disabled)',
    background: 'var(--form-control-background-disabled)',
    color: 'var(--content-disabled)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&::placeholder': {
    color: 'var(--content-secondary)',
  },
};

const slug = 'textarea';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const TextareaWithReset = styled.textarea`
  all: initial;
  box-sizing: border-box;
`;

const StyledTextArea = Object.assign(
  React.forwardRef<HTMLTextAreaElement, Props & React.TextareaHTMLAttributes<HTMLTextAreaElement>>(
    ({ themeV2, ...props }, ref) => {
      const { overrides } = useComponentOverrides(slug, themeV2);

      return <TextareaWithReset ref={ref} css={[overrides, css(styles)]} {...props} />;
    },
  ),
  {
    slug: slug,
    styles: styles,
    renderMock: (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
      <StyledTextAreaMock themeV2={themeV2} mode={mode} />
    ),
  },
);

const StyledTextAreaMock = ({ themeV2, mode }: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const { overrides } = useComponentOverrides(slug, { themeV2: themeV2, mode: mode });
  return <TextareaWithReset css={[overrides, css(styles)]} style={{ maxHeight: '400px' }} placeholder="Text area" />;
};

export default StyledTextArea;
