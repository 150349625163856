import * as t from 'io-ts';

import type { WidgetTableauSelection } from '@cb/types/misc/widgetTableau';
import type { IThemePatch } from '@cb/types/entities/theme';
import { ThemeV, ThemePatchV } from '@cb/types/entities/theme';
import type { IThemeType } from '@cb/types/entities/theme';

import { decodeToPromise } from '@cb/types/decode';

import * as axiosInstance from '@commandbar/internal/middleware/network';
import * as generics from '@commandbar/internal/middleware/generics';

const ThemeSlugReponseV = t.type({
  slug: t.string,
});

export const getDefaultThemeSlug = async (widgetType: WidgetTableauSelection['type'], widgetId?: number | string) => {
  let url = `themes/get_theme_slug_for_experience/?widgetType=${widgetType}`;
  if (widgetId !== undefined) {
    url += `&widgetId=${widgetId}`;
  }
  const result = await axiosInstance.get(url);
  return await decodeToPromise(t.exact(ThemeSlugReponseV), result.data);
};

export const hasUnpublishedChanges = (theme: IThemeType): boolean => {
  return JSON.stringify(theme.themeV2) !== JSON.stringify(theme.themeV2_draft);
};

export const list = generics.listObject(ThemeV, 'themes');
export const read = generics.readObject(ThemeV, 'themes');
export const update = (theme: IThemePatch) => generics.update(ThemeV, ThemePatchV, `themes/${theme.slug}`)(theme);
export const create = generics.createObject(ThemeV, ThemePatchV, 'themes');
export const del = generics.deleteObject(ThemeV, 'themes');
