import type { Flags } from '@cb/types/flags';

export const disableSearchIfNotExperienceSearch = <
  T extends { show_in_helphub_search: boolean; show_in_spotlight_search: boolean },
>(
  experience: T,
  flags: Flags,
): T =>
  // Turn off the searchable flags if the respective feature flag is not enabled
  ({
    ...experience,
    show_in_helphub_search: experience.show_in_helphub_search && flags['release-search-experiences-in-help-hub'],
    show_in_spotlight_search: experience.show_in_spotlight_search && flags['release-search-experiences-in-spotlight'],
  });
