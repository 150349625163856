import React, { type ComponentProps, useCallback, useMemo } from 'react';

import { CmdRichTextEditor } from './rich-text-editor';
import { markdownToSlate, slateToMarkdown } from './serialization/markdown';
import type { MarkdownString, ParsedMarkdown } from './types';

interface CmdMarkdownEditorProps
  extends Omit<ComponentProps<typeof CmdRichTextEditor>, 'initialValue' | 'onValueChange'> {
  onValueChange: (value: ParsedMarkdown) => void;
  initialValue?: MarkdownString;
}

export const CmdMarkdownEditor = ({ initialValue, onValueChange, ...rest }: CmdMarkdownEditorProps) => {
  const deserializedInitialValue = useMemo(() => {
    if (initialValue) {
      const cleanedValue = initialValue.trim();

      if (cleanedValue.length > 0) {
        return markdownToSlate(initialValue);
      }
    }

    return undefined;
  }, [initialValue]);

  const handleEditorChange: ComponentProps<typeof CmdRichTextEditor>['onValueChange'] = useCallback(
    (value) => {
      const serializedValue = slateToMarkdown(value);
      onValueChange(serializedValue);
    },
    [onValueChange],
  );

  return <CmdRichTextEditor initialValue={deserializedInitialValue} onValueChange={handleEditorChange} {...rest} />;
};
