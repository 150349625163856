import React from 'react';
import { Redirect, useHistory } from 'react-router';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';

import {
  Bookmark,
  Hand,
  BookOpen01,
  FaceIdSquare,
  Plus,
  ReverseLeft,
  SearchSm,
} from '@commandbar/design-system/icons/react';

import {
  Alert,
  AlertTitle,
  CmdButton,
  CmdCard,
  CmdLabel,
  CmdStatusDot,
  CmdSwitch,
  CmdTag,
  CmdTooltip,
  CmdTypography,
} from '@commandbar/design-system/cmd';
import { Page } from '@commandbar/design-system/components/antd';

import type { IThemeType } from '@cb/types/entities/theme';

import { ColorSwatch, ThemeDropdown } from './shared';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';
import { hasUnpublishedChanges } from 'editor/src/services/themes';
import Sender from 'editor/src/management/Sender';
import { reportErrorToUser } from '../utils/ErrorReporting';
import dayjs from 'dayjs';
import { getCurrentTheme } from '../nudges/NudgeThemeEditFloatingDropdown';
import { fetchFlag } from '@commandbar/internal/middleware/flags';

const ThemeList = () => {
  const history = useHistory();
  const { user } = useAuth();

  const { themes, nudges, checklists, organization, flags, dispatch } = useAppContext();

  // we need to get the _actual_ flag value, disregarding local storage flags,
  // because we might have switched orgs
  const [themesV2Enabled, setThemesV2Enabled] = React.useState<boolean>();
  React.useEffect(() => {
    const fetchThemesV2Flag = async () => {
      const enabled = await fetchFlag(organization.id.toString(), 'release-themes-v2');
      setThemesV2Enabled(enabled);
    };
    fetchThemesV2Flag();
  }, []);

  const themeUuidToNudgesUsage = React.useMemo(() => {
    const dict: { [key: string]: number } = {};

    const nudgesAndChecklists = [...nudges, ...checklists];

    for (const nudge of nudgesAndChecklists) {
      const theme = getCurrentTheme(nudge, themes, organization);
      if (theme?.uuid) {
        if (dict?.[theme.uuid]) {
          dict[theme.uuid] = dict[theme.uuid] + 1;
        } else {
          dict[theme.uuid] = 1;
        }
      }
    }

    return dict;
  }, []);

  const getThemeForWidget = (widgetType: 'helphub' | 'copilot' | 'spotlight') => {
    const defaultTheme = themes.find((theme) => theme.default);

    if (widgetType === 'helphub' || widgetType === 'copilot') {
      if (organization.helphub_custom_theme) {
        return organization.helphub_custom_theme;
      }
    } else if (widgetType === 'spotlight') {
      if (organization.spotlight_custom_theme) {
        return organization.spotlight_custom_theme;
      }
    }

    return defaultTheme?.uuid;
  };

  const spotlightThemeUuid = getThemeForWidget('spotlight');
  const helphubThemeUuid = getThemeForWidget('helphub');
  const copilotThemeUuid = getThemeForWidget('copilot');

  const setActiveTheme = (theme: IThemeType) => {
    history.push(`${editorRoutes.THEME_ROUTE}/${theme.id}/base`);
  };

  const createTheme = async () => {
    const theme = await dispatch.themes.save({});
    if (theme) {
      setActiveTheme(theme);
    }
  };

  const ThemeCard = ({ theme }: { theme: IThemeType }) => {
    return (
      <CmdCard
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer',
          ...(!theme.default && { height: '56px' }),
        }}
        onClick={() => history.push(`${editorRoutes.THEME_ROUTE}/${theme.id}/base`)}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <CmdTypography.H3 fontSize="base" fontWeight="semi-bold" style={{ display: 'flex', gap: '8px' }}>
            {hasUnpublishedChanges(theme) && (
              <CmdTooltip message="Theme has unpublished changes">
                <CmdStatusDot style={{ marginLeft: '8px' }} variant="warning" />
              </CmdTooltip>
            )}
            {theme.name}

            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              {copilotThemeUuid === theme.uuid && (
                <CmdTooltip message="Theme is used by Copilot">
                  <CmdTag style={{ padding: '2px 8px', height: '24px', borderRadius: '1000px' }}>
                    <FaceIdSquare />
                  </CmdTag>
                </CmdTooltip>
              )}
              {helphubThemeUuid === theme.uuid && (
                <CmdTooltip message="Theme is used by HelpHub">
                  <CmdTag style={{ padding: '2px 8px', height: '24px', borderRadius: '1000px' }}>
                    <BookOpen01 />
                  </CmdTag>
                </CmdTooltip>
              )}
              {spotlightThemeUuid === theme.uuid && (
                <CmdTooltip message="Theme is used by Spotlight">
                  <CmdTag style={{ padding: '2px 8px', height: '24px', borderRadius: '1000px' }}>
                    <SearchSm />
                  </CmdTag>
                </CmdTooltip>
              )}
              {theme.uuid && themeUuidToNudgesUsage[theme.uuid] && (
                <CmdTooltip
                  message={`Theme is used by ${themeUuidToNudgesUsage[theme.uuid]} Nudge${
                    themeUuidToNudgesUsage[theme.uuid] > 1 ? 's' : ''
                  }`}
                >
                  <CmdTag style={{ padding: '2px 8px', height: '24px', borderRadius: '1000px' }}>
                    {themeUuidToNudgesUsage[theme.uuid]}
                  </CmdTag>
                </CmdTooltip>
              )}
            </div>
          </CmdTypography.H3>
        </div>

        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '-4px' }}>
            <ColorSwatch
              large
              color={
                theme.themeV2_draft?.light_mode.var_overrides['--base-accent'] ??
                theme.themeV2_draft?.light_mode.var_defaults['--base-accent']
              }
            />
            <ColorSwatch
              large
              overlap
              color={
                theme.themeV2_draft?.light_mode.var_overrides['--background-primary'] ||
                theme.themeV2_draft?.light_mode.var_defaults['--background-primary']
              }
            />
            <ColorSwatch
              large
              overlap
              color={
                theme.themeV2_draft?.light_mode.var_overrides['--content-primary'] ||
                theme.themeV2_draft?.light_mode.var_defaults['--content-primary']
              }
            />
          </div>
          {hasRequiredRole(user, 'contributor') && <ThemeDropdown theme={theme} themes={themes} />}
        </div>
      </CmdCard>
    );
  };

  const defaultTheme = themes.find((theme) => theme.default);
  const otherThemes = themes.filter(
    (theme) => !theme.default && !theme.archived && !theme.name.startsWith('commandai__'),
  );

  const selfServeMigrationDate = organization.themes_v2_self_serve_migration_date;
  /* Show this banner iff:
      a) themes V2 is not actually enabled yet (flag not turned on), or
      b) if they used the self-serve migration to enable themes V2, it was within the last two weeks */
  const shouldShowBanner =
    !themesV2Enabled || !!(selfServeMigrationDate && dayjs().diff(selfServeMigrationDate, 'days') < 14);

  const toggleThemesV2Flag = async (enabled: boolean) => {
    axiosInstance
      .patch('/organizations/toggle_themes_v2_flag/', { enabled })
      .then((_response: any) => {
        dispatch.organization.updateSetting({
          themes_v2_self_serve_migration_date: enabled ? dayjs().toISOString() : null,
        });
      })
      .catch((err) => {
        reportErrorToUser(err);
      });
  };

  if (!flags['release-themes-v2']) return <Redirect to={`${editorRoutes.DESIGN_ROUTE}`} />;

  return themesV2Enabled !== undefined ? (
    <Page
      style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', background: '#F9F9F9' }}
      title="Themes"
      headerChildren={
        hasRequiredRole(user, 'contributor') && (
          <CmdButton variant="primary" icon={<Plus />} onClick={createTheme}>
            New theme
          </CmdButton>
        )
      }
      banner={
        shouldShowBanner && (
          <Alert
            className="justify-between align-center rounded-none"
            style={{ paddingTop: '8px', paddingBottom: '8px', paddingRight: '8px' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Hand style={{ rotate: '45deg' }} />
              <AlertTitle>You're previewing our new themes experience.</AlertTitle>
              <CmdTag
                variant="info"
                suffixElement={<Bookmark />}
                onClick={() => window.CommandBar.openHelpHub({ articleId: 612700 })} // theming overview doc
              >
                Learn more
              </CmdTag>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <CmdButton
                onClick={async () => {
                  localStorage.removeItem('commandbar.flags:release-themes-v2');
                  await dispatch.flags.reload();
                  await Sender.reload(['reloadFlags']);
                  history.push(editorRoutes.DESIGN_ROUTE);
                }}
                disabled={!!selfServeMigrationDate}
              >
                <ReverseLeft />
                Back to legacy themes
              </CmdButton>
              <CmdButton>
                <CmdLabel tooltip="If enabled, the new themes experience will be shown to all of your end users">
                  Enabled
                </CmdLabel>
                <CmdSwitch checked={!!selfServeMigrationDate} onCheckedChange={toggleThemesV2Flag} />
              </CmdButton>
            </div>
          </Alert>
        )
      }
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        {defaultTheme && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              <CmdTypography.H3 variant="contentMid" fontSize="base" fontWeight="medium">
                Default theme
              </CmdTypography.H3>
              <CmdTypography.Body variant="secondary" fontSize="sm" fontWeight="medium">
                Applied to all Nudge and Assist experiences by default
              </CmdTypography.Body>
            </div>
            <ThemeCard key={`theme-${defaultTheme.slug}`} theme={defaultTheme} />
          </div>
        )}
        {!!otherThemes.length && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <CmdTypography.H3 variant="contentMid" fontSize="base" fontWeight="medium">
              Other themes
            </CmdTypography.H3>
            {otherThemes.map((theme) => (
              <ThemeCard key={`theme-${theme.slug}`} theme={theme} />
            ))}
          </div>
        )}
      </div>
    </Page>
  ) : null;
};

export default ThemeList;
