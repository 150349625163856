import React, { useState } from 'react';

import { Panel, Tooltip } from '@commandbar/design-system/components/antd';

import { Container } from '../helphub/shared';
import { AISettings } from './AISettings';
import InteractionSettings from './InteractionSettings';
import FallbackSettings from './FallbackSettings';
import PersonalitySettings from './PersonalitySettings';
import TextCustomizationSettings from './TextCustomizationSettings';
import { useAppContext } from 'editor/src/AppStateContext';
import ThemeCustomizationSettings from '../components/ThemeCustomizationSettings';
import { Link } from 'react-router-dom';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import { ICopilotFallback } from '@commandbar/internal/middleware/types';

enum PanelKey {
  GENERAL = 'general',
  PERSONALITY = 'personality',
  INTERACTIONS = 'interactions',
  FALLBACKS = 'fallbacks',
  STRINGS = 'strings',
  THEME = 'theme',
}

const CopilotSettings = () => {
  const [activeKeys, setActiveKeys] = React.useState<PanelKey[]>([PanelKey.GENERAL]);
  const { flags, organization } = useAppContext();
  const [displayTooltipFallback, setDisplayTooltipFallback] = useState(false);

  const handleDisplayTooltipFallback = (copilotFallbacks: ICopilotFallback[]) => {
    setDisplayTooltipFallback(
      copilotFallbacks.filter((f) => f.trigger_event === 'no_answer').length > 2 ||
        copilotFallbacks.filter((f) => f.trigger_event === 'negative_feedback').length > 1,
    );
  };

  return (
    <Container>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.GENERAL} header="General">
        <AISettings />
      </Panel>
      <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.PERSONALITY} header="Personality">
        <PersonalitySettings />
      </Panel>
      <Panel
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        panelKey={PanelKey.INTERACTIONS}
        header="Interactions"
      >
        <InteractionSettings />
      </Panel>
      {flags?.['release-copilot-fallbacks-v-2'] && (
        <Panel
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          panelKey={PanelKey.FALLBACKS}
          header={
            <div
              style={{
                display: 'flex',
                gap: '4px',
              }}
            >
              Fallbacks{' '}
              {displayTooltipFallback && (
                <Tooltip
                  content={
                    'When a user matches multiple non-default fallbacks, the topmost fallback in the list will be applied.'
                  }
                  placement="top"
                  containerStyle={{
                    display: 'flex',
                    margin: 'auto',
                  }}
                >
                  <InfoCircle height={16} width={16} />
                </Tooltip>
              )}
            </div>
          }
        >
          <FallbackSettings handleDisplayTooltipFallback={handleDisplayTooltipFallback} />
        </Panel>
      )}
      {flags?.['release-themes-v2'] && (
        <Panel
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
          panelKey={PanelKey.STRINGS}
          header="Text customization"
        >
          <TextCustomizationSettings />
        </Panel>
      )}
      {flags['release-themes-v2'] && (
        <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.THEME} header="Theme">
          <ThemeCustomizationSettings
            themeUUID={organization.helphub_custom_theme}
            disabled
            note={
              <>
                Theme settings are shared with HelpHub.{' '}
                <Link style={{ color: CB_COLORS.blue400 }} to="/help-hub/settings">
                  Go to HelpHub settings →
                </Link>
              </>
            }
          />
        </Panel>
      )}
    </Container>
  );
};

export default CopilotSettings;
