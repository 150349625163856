import { cva, VariantProps } from 'class-variance-authority';
import React, { forwardRef } from 'react';
import { cn } from '../util';

export type TypographyProps = {
  children?: React.ReactNode;
  text?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
} & VariantProps<typeof typographyStyles>;

export const typographyStyles = cva(['m-0'], {
  variants: {
    fontWeight: {
      medium: ['font-medium'],
      'semi-bold': ['font-semibold'],
      bold: ['font-bold'],
    },
    variant: {
      primary: ['text-gray1000'],
      secondary: ['text-contentBase'],
      tertiary: ['text-gray800'],
      contentMid: ['text-contentMid'],
    },
    fontSize: {
      sm: 'text-sm',
      base: 'text-base',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      xxl: 'text-2xl',
      xxxl: 'text-3xl',
    },
  },
  defaultVariants: {
    fontWeight: 'medium',
    variant: 'primary',
    fontSize: 'base',
  },
});

const CmdTypography = ({ children, variant, fontWeight, fontSize, className, style }: TypographyProps) => {
  return (
    <Body variant={variant} fontWeight={fontWeight} fontSize={fontSize} className={cn(className)} style={style}>
      {children}
    </Body>
  );
};

const H1 = forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ children, variant, fontWeight, fontSize = 'xxl', className, style }, ref) => {
    return (
      <h1 ref={ref} className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
        {children}
      </h1>
    );
  },
);

const H2 = forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ children, variant, fontWeight, fontSize = 'xl', className, style }, ref) => {
    return (
      <h2 ref={ref} className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
        {children}
      </h2>
    );
  },
);

const H3 = forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ children, variant, fontWeight, fontSize = 'lg', className, style }, ref) => {
    return (
      <h3 ref={ref} className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
        {children}
      </h3>
    );
  },
);

const Body = ({ children, variant, fontWeight, fontSize = 'base', className, style }: TypographyProps) => {
  return (
    <h4 className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </h4>
  );
};

const HelpText = ({ children, variant, fontWeight, fontSize = 'sm', className, style }: TypographyProps) => {
  return (
    <p className={cn('text-sm', typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
      {children}
    </p>
  );
};

const Paragraph = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ children, variant, fontWeight, fontSize = 'base', className, style }, ref) => {
    return (
      <p ref={ref} className={cn(typographyStyles({ variant, fontWeight, fontSize }), className)} style={style}>
        {children}
      </p>
    );
  },
);

CmdTypography.H3 = H3;
CmdTypography.H2 = H2;
CmdTypography.H1 = H1;
CmdTypography.Body = Body;
CmdTypography.HelpText = HelpText;
CmdTypography.Paragraphy = Paragraph;

export { CmdTypography };
