import React, { useCallback, useEffect } from 'react';

import { IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';
import { defaults, getConditions, INamedRule } from '@commandbar/internal/middleware/helpers/rules';

import {
  Alert,
  Tooltip,
  Typography,
  Heading,
  Input,
  Empty,
  SimplePanel,
} from '@commandbar/design-system/components/antd';
import { ConditionRule } from '../../conditions/types';

import { groupBy, mapValues, uniqBy } from 'lodash';
import { useAppContext } from 'editor/src/AppStateContext';
import { useAsyncCallback } from '@commandbar/internal/util/useAsyncCallback';
import { builtInRules } from './builtInRulesDefintions';
import { AsyncClickButton } from '../../audiences/AsyncClickButton';
import { Edit03, Plus, Trash04 } from '@commandbar/design-system/icons/react';
import { CmdButton } from '@commandbar/design-system/cmd';
import ConditionGroupEditor from '../../conditions/ConditionGroupEditor';
import { isConditionGroupValid } from '../../conditions/validate';

// NOTE: this component is DEPRECATED and has been replaced with the Audiences tab in `monobar/editor/src/editor/audiences/AudiencesList.tsx`
// This component is still used to edit "legacy" Rules which aren't "Audiences"
// We can remove this once all Rules have their "is_audience" flag set to `true`

const BLANK_CONDITION: ConditionRule = {
  type: 'context',
  operator: 'is',
  value: '',
};

const EditingRule = ({
  initialRule,
  onCancel,
  onSave,
  isDuplicateName,
  allowAddingAndRemovingRules = true,
  omitNamedRuleType = false,
}: {
  initialRule: INamedRule;
  onCancel: () => void;
  onSave: (rule: INamedRule) => Promise<void>;
  isDuplicateName: (rule: INamedRule) => boolean;
  allowAddingAndRemovingRules?: boolean;
  omitNamedRuleType?: boolean;
}) => {
  const [rule, setRule] = React.useState<INamedRule>(initialRule);

  const conditions = getConditions(rule.expression);
  const valid =
    conditions.length &&
    isConditionGroupValid(rule.expression) &&
    rule.name !== '' &&
    !(isDuplicateName && isDuplicateName(rule));

  const save = useCallback(async () => {
    if (valid) await onSave(rule);
  }, [valid, rule, onSave]);

  const [containerNode, setContainerNode] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!containerNode) return;

    const handleUserKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        onCancel();
      }
      if (event.key === 'Enter' || event.keyCode === 13) {
        save();
      }
    };

    containerNode.addEventListener('keydown', handleUserKeyPress);
    return () => {
      containerNode.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [save, onCancel, containerNode]);

  const setName = (name: string) => setRule((rule) => rule && { ...rule, name });

  return (
    <div ref={setContainerNode}>
      <div style={{ display: 'flex', columnGap: 8 }}>
        <Input autoFocus placeholder="<rule name>" value={rule.name} onChange={(e) => setName(e.target.value)} />
        <AsyncClickButton onClick={save} disabled={!valid} variant="primary">
          Save
        </AsyncClickButton>
      </div>
      <div style={{ marginTop: 8, display: 'grid', rowGap: 8 }}>
        <ConditionGroupEditor
          onChange={(expr) => {
            setRule((rule) => ({ ...rule, expression: expr }));
          }}
          expr={rule.expression}
          disabled={!allowAddingAndRemovingRules}
          excludeConditionTypes={omitNamedRuleType ? ['named_rule', 'audience'] : ['audience']}
        />
      </div>
    </div>
  );
};

const Rule = ({
  rule,
  isDuplicateName,
  commandsUsingThisRule = [],
  onSave,
  onDelete,
  onEditing,
  initialEditing = false,
  allowAddingAndRemovingRules = true,
  omitNamedRuleType = false,
}: {
  rule: Partial<INamedRule>;
  isDuplicateName: (rule: INamedRule) => boolean;
  commandsUsingThisRule?: IEditorCommandTypeLite[];
  initialEditing?: boolean;
  allowAddingAndRemovingRules?: boolean;
  onSave: (rule: INamedRule) => Promise<void>;
  onDelete?: () => Promise<void>;
  onEditing?: (editing: boolean) => void;
  omitNamedRuleType?: boolean;
}) => {
  const { expression = { type: 'AND', exprs: [] }, name } = rule;
  const [error, setError] = React.useState<string | null>(null);
  const [editing, setEditing] = React.useState<boolean>(initialEditing);

  const onSaveRule = useAsyncCallback(function* (rule: INamedRule) {
    try {
      yield onSave(rule);
      setError(null);
    } catch (e) {
      setError(String(e));
      throw e;
    }
    setEditing(false);
  });

  const numCommandsUsingThisRule = commandsUsingThisRule.length;

  useEffect(() => {
    onEditing && onEditing(editing);
  }, [onEditing, editing]);

  const isBuiltIn = typeof rule.id === 'string';

  if (!editing) {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{ flex: 1, cursor: !isBuiltIn ? 'pointer' : 'not-allowed' }}
            onClick={!isBuiltIn ? () => setEditing(true) : undefined}
          >
            <Typography.Text>{name || '<name>'}</Typography.Text> {!isBuiltIn && <Edit03 />}
          </div>
          {numCommandsUsingThisRule > 0 && (
            <Tooltip placement="top" content={'in use by: ' + commandsUsingThisRule.map((c) => c.text).join(', ')}>
              <Typography.Text type="secondary" style={{ cursor: 'help', marginRight: 12 }} italic>
                (used by {numCommandsUsingThisRule} {numCommandsUsingThisRule > 1 ? 'commands' : 'command'})
              </Typography.Text>
            </Tooltip>
          )}
          {onDelete && (
            <div>
              <AsyncClickButton variant="ghost" onClick={onDelete} icon={<Trash04 />}></AsyncClickButton>
            </div>
          )}
        </div>
        <ConditionGroupEditor expr={expression} disabled />
      </div>
    );
  }

  return (
    <>
      {error && (
        <Alert
          style={{ marginTop: 4, marginBottom: 4 }}
          message={
            <span>
              Error saving rule:
              <Typography.Text code>{error.slice(0, 100)}</Typography.Text>
            </span>
          }
          type="error"
        />
      )}
      <EditingRule
        initialRule={{
          ...defaults,
          id: -1,
          name: '',
          expression: { type: 'AND', exprs: [] },
          created: undefined,
          ...rule,
        }}
        allowAddingAndRemovingRules={allowAddingAndRemovingRules}
        omitNamedRuleType={omitNamedRuleType}
        isDuplicateName={isDuplicateName}
        onCancel={() => {
          setEditing(false);
        }}
        onSave={onSaveRule}
      />
    </>
  );
};

// forces inference of a Tuple type rather than an Array type
// see https://github.com/Microsoft/TypeScript/pull/24897 "Rest elements in Tuple types"
function tuple<T extends any[]>(...data: T) {
  return data;
}

const Rules = () => {
  const {
    rules: _rules,
    commands,
    dispatch: {
      rules: { addRule, changeRule, removeRule },
    },
  } = useAppContext();
  const rules = _rules.filter((r) => !r.is_audience);

  const [showNewRule, setShowNewRule] = React.useState<boolean>(false);

  const commandsByCondition = mapValues(
    groupBy(
      commands.flatMap((command) =>
        [command.availability_expression, command.recommend_expression]
          .flatMap(getConditions)
          .filter((rule) => {
            return rule.type !== 'named_rule';
          })
          .map(
            ({
              reason /* remove the "reason" from the rule, since we want rules with different reasons to be grouped together */,
              ...rule
            }) => tuple(rule, command),
          ),
      ),
      ([rule]) => JSON.stringify(rule),
    ),
    (tuples) => ({
      rule: tuples.length > 0 ? tuples[0][0] : null,
      commands: tuples.map(([, command]) => command),
    }),
  );

  const unnamedRules = Object.values(commandsByCondition);

  const isDuplicateName = (rule: INamedRule) =>
    !!rules.filter(({ name, id }) => name.toLowerCase().trim() === rule.name.toLowerCase().trim() && id !== rule.id)
      .length;

  const commandsByNamedRuleId = mapValues(
    groupBy(
      commands.flatMap((command) =>
        [...getConditions(command.availability_expression), ...getConditions(command.recommend_expression)].map(
          (rule) => {
            if (rule.type !== 'named_rule') return tuple(null, command);

            return tuple(rule, command);
          },
        ),
      ),
      ([rule]) => (rule ? rule.rule_id : -1),
    ),
    (tuples) =>
      uniqBy(
        tuples.map(([, command]) => command),
        (command) => JSON.stringify(command),
      ),
  );

  return (
    <SimplePanel>
      <div className="RulesTab">
        <div style={{ marginBottom: 48 }}>
          <Heading
            text="Rules"
            style={{ marginTop: 0 }}
            rightActions={
              <CmdButton
                onClick={() => {
                  setShowNewRule(true);
                }}
                icon={<Plus />}
                variant="primary"
              >
                New Rule
              </CmdButton>
            }
            leftActions={<div></div>}
          />
          {showNewRule && (
            <div style={{ marginBottom: 16 }}>
              <Rule
                omitNamedRuleType
                initialEditing
                isDuplicateName={isDuplicateName}
                rule={{
                  id: -1,
                  name: '',
                  expression: { type: 'AND', exprs: [{ type: 'CONDITION', condition: BLANK_CONDITION }] },
                }}
                onDelete={async () => {
                  setShowNewRule(false);
                }}
                onSave={async (rule) => {
                  await addRule(rule, false);
                  setShowNewRule(false);
                }}
                onEditing={(editing) => {
                  if (!editing) {
                    setShowNewRule(false);
                  }
                }}
              />
            </div>
          )}

          {!showNewRule && !rules.length ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"You don't have any named rules. Create one by clicking the 'New Rule' button above."}
            />
          ) : null}

          {rules.map((rule, idx) => (
            <div key={idx} style={{ marginBottom: 16 }}>
              <Rule
                rule={rule}
                commandsUsingThisRule={commandsByNamedRuleId[rule.id] || []}
                onSave={changeRule(rule.id)}
                onDelete={removeRule(rule.id)}
                omitNamedRuleType
                isDuplicateName={isDuplicateName}
              />
            </div>
          ))}
        </div>

        {unnamedRules.length ? (
          <div style={{ marginBottom: 48 }}>
            <Heading text="Unnamed conditions" />
            {unnamedRules.map(({ rule }, idx) => {
              return (
                rule && (
                  <Rule
                    allowAddingAndRemovingRules={false}
                    onSave={(rule) => addRule(rule, true)}
                    key={idx}
                    rule={{ expression: { type: 'AND', exprs: [{ type: 'CONDITION', condition: rule }] } }}
                    isDuplicateName={isDuplicateName}
                  />
                )
              );
            })}
          </div>
        ) : null}

        <Heading text="Built-in Persona rules" />
        <Alert
          message="Using built-in rules"
          description={
            <div>
              These named rules are built-in to the system and cannot be edited. They are based on end-user personas and
              hence require identity-verification via HMAC authentication to work correctly.
              <br />
              Missing something? You can also use these condition types to build your own rules.
            </div>
          }
          style={{ marginBottom: 16 }}
          type="info"
          showIcon
        />
        {builtInRules.map((rule, idx) => (
          <div key={idx} style={{ marginBottom: 16 }}>
            <Rule
              rule={rule}
              commandsUsingThisRule={commandsByNamedRuleId[rule.id] || []}
              onSave={() => {
                throw new Error("Can't change built-in rule");
              }}
              omitNamedRuleType
              isDuplicateName={isDuplicateName}
            />
          </div>
        ))}
      </div>
    </SimplePanel>
  );
};

export default Rules;
