import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import * as S from '../styled';
import { Trash04, Lock01 } from '@commandbar/design-system/icons/react';
import {
  FormFactorDropdown,
  FORM_FACTORS,
  IconContainer as FormFactorIconContainer,
  DISPLAY_VALUE_GAP,
} from './FormFactorDropdown';
import { FormFactorPositionInput } from './FormFactorPositionInput';
import { PopoverPosition, TNudgeOffset } from './FormFactorPositionInput/metadata';
import { SortableHandle } from 'react-sortable-hoc';
import Sender from '../../../management/Sender';
import { useAppContext } from 'editor/src/AppStateContext';

import type {
  ElementInformation,
  INudgeBannerStepType,
  INudgePinStepType,
  INudgeStepType,
  INudgeTooltipStepType,
} from '@commandbar/internal/middleware/types';
import { Tooltip, CB_COLORS } from '@commandbar/design-system/components/antd';
import { ReactComponent as ElementNotFoundSvg } from '../../../img/element_not_found.svg';
import { OverlayDisplayValue } from '../../components/styled';
import { ExperienceState } from '@commandbar/internal/client/extension/shared';
import { transformIfJSPath } from '@commandbar/internal/util/dom';

const Container = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: ${CB_COLORS.white};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const TitleRow = styled(OverlayDisplayValue)`
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:hover {
    background-color: transparent;
  }
`;

export const ElementNotFoundIcon = styled(ElementNotFoundSvg)`
  width: 16px;
  color: #a36a00 !important;
`;

type FormFactor = INudgeStepType['form_factor'];
type FormFactorType = INudgeStepType['form_factor']['type'];

export interface IHeader {
  index: number;
  hasTooltip: boolean;
  expanded?: boolean;
  title: string;
  value: FormFactor;
  onUpdate: (formFactor: FormFactor, saveChanges?: boolean) => void;
  onDelete?: () => void;
  onStartClickRecorder?: (type: ExperienceState['field'], skipPrompt?: boolean) => void;
  locked?: boolean;
  recorderDisabled: boolean;
  hasSurveyBlock: boolean;
}

export const Header = ({
  index,
  expanded,
  title,
  value,
  onDelete,
  onUpdate,
  locked,
  onStartClickRecorder,
  recorderDisabled,
  hasSurveyBlock,
}: IHeader) => {
  const { Icon: FormFactorIcon, label: FormFactorTypeLabel } = FORM_FACTORS[value.type];
  const [mostRecentAnchor, setMostRecentAnchor] = React.useState<string>(
    value.type === 'pin' || value.type === 'tooltip' ? value.anchor : '',
  );
  const [mostRecentAdvanceTrigger, setMostRecentAdvanceTrigger] = React.useState<string>(
    value.type === 'pin' ? value.advance_trigger || '' : '',
  );
  const appContext = useAppContext();

  const onFormFactorChange = (type: FormFactorType) => {
    switch (type) {
      case 'modal': {
        return onUpdate({ type });
      }
      case 'popover': {
        return onUpdate({ type, position: 'center' });
      }
      case 'pin': {
        return onUpdate(
          {
            type,
            anchor: mostRecentAnchor,
            advance_trigger: mostRecentAdvanceTrigger,
            is_open_by_default: true,
            is_showing_mask: false,
            offset: {
              x: '0px',
              y: '0px',
            },
          },
          true,
        );
      }
      case 'tooltip':
        return onUpdate(
          {
            type,
            anchor: mostRecentAnchor,
            show_on: 'hover',
            marker: {
              type: 'beacon',
              positioning: {
                position: 'right',
                offset: {
                  x: '0px',
                  y: '0px',
                },
              },
            },
          },
          true,
        );
      case 'banner':
        return onUpdate({
          type,
          position: 'top',
          placement: 'default',
        });
    }
  };

  const onFormFactorPositionChange = (position: PopoverPosition) => {
    if (value.type === 'popover') {
      onUpdate({
        ...value,
        position,
      });
    }
  };

  const onFormFactorBannerPositionChange = (position: INudgeBannerStepType['form_factor']['position']) => {
    if (value.type === 'banner') {
      onUpdate({
        ...value,
        position,
      });
    }
  };

  const onFormFactorAnchorChange = (anchor: ElementInformation) => {
    anchor.selector = transformIfJSPath(anchor.selector);
    if (value.type === 'pin' || value.type === 'tooltip') {
      onUpdate({
        ...value,
        anchor: anchor.selector,
        anchor_selector: anchor,
      });

      setMostRecentAnchor(anchor.selector);
    }
  };

  const onPlacementChange = (
    placement: (INudgeStepType & { form_factor: { type: 'banner' } })['form_factor']['placement'],
  ) => {
    if (value.type === 'banner') {
      onUpdate({
        ...value,
        placement: placement,
      });
    }
  };

  const onFormFactorAdvanceTriggerChange = (trigger: string) => {
    const shadowDOMEnabledTrigger = transformIfJSPath(trigger);
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        advance_trigger: shadowDOMEnabledTrigger,
      });

      setMostRecentAdvanceTrigger(shadowDOMEnabledTrigger);
    }
  };

  const onToggleIsOpenByDefault = () => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        is_open_by_default: !value.is_open_by_default,
      });
    }
  };

  const onToggleIsShowingMask = () => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        is_showing_mask: !value.is_showing_mask,
      });
    }
  };

  const onOffsetChange = (offset: TNudgeOffset) => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        offset,
      });
    } else if (value.type === 'tooltip') {
      onUpdate({
        ...value,
        marker: {
          ...value.marker,
          positioning: {
            ...value.marker.positioning,
            offset,
          },
        },
      });
    }
  };

  const onMarkerChange = (marker: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['marker']) => {
    if (value.type === 'tooltip') {
      onUpdate({
        ...value,
        marker,
      });
    }
  };

  const onMarkerPositionChange = (
    position: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'],
  ) => {
    if (value.type === 'tooltip') {
      onUpdate({
        ...value,
        marker: {
          ...value.marker,
          positioning: {
            ...value.marker.positioning,
            position,
          },
        },
      });
    }
  };

  const pinPosition = (() => {
    if (value.type !== 'pin') {
      return undefined;
    }

    if (value.position) {
      return value.position;
    }

    return 'auto';
  })();

  const pinAlignment = useMemo(() => {
    if (value.type !== 'pin') {
      return 'center';
    }

    if (value.alignment) {
      return value.alignment;
    }

    return 'center';
  }, [value]);

  const onPinPositionChange = (position: INudgePinStepType['form_factor']['position']) => {
    if (value.type === 'pin') {
      let _alignment: INudgePinStepType['form_factor']['alignment'];
      switch (position) {
        case 'top':
        case 'bottom': {
          _alignment = 'left';
          break;
        }
        case 'left':
        case 'right':
        case 'auto':
        default: {
          _alignment = 'top';
          break;
        }
      }
      onUpdate({
        ...value,
        position,
        alignment: _alignment,
      });
    }
  };

  const onPinAlignmentChange = (alignment: INudgePinStepType['form_factor']['alignment']) => {
    if (value.type === 'pin') {
      onUpdate({
        ...value,
        alignment,
      });
    }
  };

  const onShowOnChange = (
    showOn: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['show_on'],
  ) => {
    if (value.type === 'tooltip') {
      onUpdate({
        ...value,
        show_on: showOn,
      });
    }
  };

  const onLayoutChange = (layout: INudgeStepType['form_factor']['layout']) => {
    if (value.type !== 'banner') {
      onUpdate({
        ...value,
        layout,
      });
    }
  };

  const [selectorState, setSelectorState] = useState<'ok' | 'invisible' | 'not_found'>('ok');

  useEffect(() => {
    if ((value.type !== 'pin' && value.type !== 'tooltip') || value.anchor === '') {
      setSelectorState('ok');
    } else {
      Sender.checkIfSelectorValid(
        value.anchor_selector || { selector: value.anchor, tag: '', text: '', attributes: {} },
      ).then((result) => {
        if (result.elementFound && result.elementVisible) {
          setSelectorState('ok');
        } else if (result.elementFound && !result.elementVisible) {
          setSelectorState('invisible');
        } else {
          setSelectorState('not_found');
        }
      });
    }
  }, [value]);

  return (
    <Container>
      {expanded ? (
        <FormFactorDropdown
          value={value.type}
          onChange={onFormFactorChange}
          exclude={index !== 0 ? ['banner', 'tooltip'] : []}
        />
      ) : (
        <TitleRow gap={DISPLAY_VALUE_GAP}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '12px',
            }}
          >
            <FormFactorIconContainer>
              <FormFactorIcon />
            </FormFactorIconContainer>
            <div>{title === '' ? FormFactorTypeLabel : title}</div>
          </div>
          {selectorState !== 'ok' && !appContext?.isStudio && (
            <Tooltip
              placement="bottom"
              content={
                selectorState === 'invisible'
                  ? 'Target element found on this page, but is hidden'
                  : 'Target element not found on this page'
              }
            >
              <ElementNotFoundIcon style={{ marginTop: '8px' }} />
            </Tooltip>
          )}
        </TitleRow>
      )}

      <Row>
        {locked && (
          <Tooltip
            content={
              "A response was already collected for this step's survey. Therefore, it can't be edited anymore. Create a new step to ask a new question."
            }
          >
            <Lock01 width={16} style={{ marginTop: 5, marginRight: 8 }} />
          </Tooltip>
        )}

        {expanded && (
          <FormFactorPositionInput
            type={value.type}
            onChange={onFormFactorPositionChange}
            positionValue={value.type === 'popover' || value.type === 'banner' ? value.position : 'center'}
            onFormFactorBannerPositionChange={onFormFactorBannerPositionChange}
            onAnchorChange={onFormFactorAnchorChange}
            anchorValue={
              value.type === 'pin' || value.type === 'tooltip'
                ? value.anchor_selector || { selector: value.anchor, tag: '', text: '', attributes: {} }
                : { selector: '', tag: '', text: '', attributes: {} }
            }
            onAdvanceTriggerChange={onFormFactorAdvanceTriggerChange}
            advanceTriggerValue={value.type === 'pin' ? value.advance_trigger || '' : ''}
            onToggleIsOpenByDefault={onToggleIsOpenByDefault}
            placement={value.type === 'banner' ? value.placement : undefined}
            onPlacementChange={onPlacementChange}
            isOpenByDefault={
              value.type === 'pin' ? (value.is_open_by_default === undefined ? true : value.is_open_by_default) : true
            }
            onToggleIsShowingMask={onToggleIsShowingMask}
            isShowingMask={value.type === 'pin' && !!value.is_showing_mask}
            onOffsetChange={onOffsetChange}
            offsetValue={(() => {
              if (value.type === 'pin') {
                return value.offset === undefined ? { x: '0px', y: '0px' } : value.offset;
              }

              if (value.type === 'tooltip') {
                return value.marker.positioning.offset;
              }

              return { x: '', y: '' };
            })()}
            showOn={value.type === 'tooltip' ? value.show_on : 'click'}
            onShowOnChange={onShowOnChange}
            marker={
              value.type === 'tooltip'
                ? value.marker
                : {
                    type: 'beacon',
                    positioning: {
                      position: 'left',
                      offset: {
                        x: '0px',
                        y: '0px',
                      },
                    },
                  }
            }
            onMarkerChange={onMarkerChange}
            markerPosition={value.type === 'tooltip' ? value.marker.positioning.position : 'right'}
            onMarkerPositionChange={onMarkerPositionChange}
            pinPosition={pinPosition ?? 'auto'}
            onPinPositionChange={onPinPositionChange}
            pinAlignment={pinAlignment}
            onPinAlignmentChange={onPinAlignmentChange}
            onStartClickRecorder={onStartClickRecorder}
            recorderDisabled={recorderDisabled}
            layout={value.layout ?? 'classic'}
            onLayoutChange={onLayoutChange}
            disableLayoutSelection={hasSurveyBlock}
          />
        )}
        {!!onDelete ? (
          <S.DeleteIconContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          >
            <Trash04 width={16} />
          </S.DeleteIconContainer>
        ) : null}
      </Row>
    </Container>
  );
};

const SortableHeader = SortableHandle(Header);

type HeaderComponentProps = IHeader & { shouldSort: boolean };
export const HeaderComponent = ({ shouldSort, ...props }: HeaderComponentProps) => {
  if (shouldSort) {
    return <SortableHeader {...props} />;
  }

  return <Header {...props} />;
};
