import { IAPI, IWorkflow } from '@commandbar/internal/middleware/types';

export function convertTitleToToolName(title: string) {
  return title
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase();
}

export function allAPIAndWorkflowToolNames(APIs: IAPI[], workflows: IWorkflow[], currentTitle: string) {
  // TODO: Rename function and potentially update to `reduce`s to optimize
  const allTitles = [
    ...APIs.map((a) => convertTitleToToolName(a.title)),
    ...workflows.map((w) => convertTitleToToolName(w.title)),
  ];
  return allTitles.filter((t) => t !== convertTitleToToolName(currentTitle));
}
