import * as t from 'io-ts';

import type { IIntegrations } from '../organization/integrations';

export const AdminAction = t.type({
  type: t.literal('admin'),
  value: t.string,
});

export const CallbackAction = t.type({
  type: t.literal('callback'),
  value: t.string,
});

export const OpenBarAction = t.intersection([
  t.type({
    type: t.literal('open_bar'),
  }),
  t.partial({
    value: t.string,
    categoryFilter: t.number,
  }),
]);

export const OpenHelpHubAction = t.type({ type: t.literal('open_helphub') });

export const OpenCopilotAction = t.intersection([
  t.type({ type: t.literal('open_copilot') }),
  t.partial({ query: t.string }),
]);

export const LinkAction = t.intersection([
  t.type({
    type: t.literal('link'),
    value: t.string,
  }),
  t.partial({
    operation: t.union([t.literal('router'), t.literal('self'), t.literal('blank'), t.undefined]),
    meta: t.type({
      command: t.string,
    }),
  }),
]);
export type ILinkAction = t.TypeOf<typeof LinkAction> & unknown;

export const OpenChatActionTypeV = t.union([
  t.literal('intercom'),
  t.literal('helpscout'),
  t.literal('freshdesk'),
  t.literal('freshchat'),
  t.literal('crisp'),
  t.literal('zendesk'),
  t.literal('liveChat'),
  t.literal('gist'),
  t.literal('olark'),
  t.literal('hubspot'),
  t.literal('drift'),
  t.literal('pylon'),
  t.literal('talkdesk_v2'),
  t.literal('zendesk_handoff'),
  t.literal(''),
]);
export type OpenChatActionType = t.TypeOf<typeof OpenChatActionTypeV> & unknown;

export const OpenChatActionV = t.type({
  type: t.literal('open_chat'),
  meta: t.type({
    type: OpenChatActionTypeV,
  }),
});
export type OpenChatAction = t.TypeOf<typeof OpenChatActionV> & unknown;

export const ChatHandoffActionTypeV = t.union([
  t.literal('intercom'),
  t.literal('freshchat'),
  t.literal('zendesk'),
  t.literal('sfdc_chat'),
  t.literal('sfdc_messaging'),
  t.literal(''),
]);
export type ChatHandoffActionType = t.TypeOf<typeof ChatHandoffActionTypeV> & unknown;

export const ChatHandoffActionV = t.type({
  type: t.literal('chat_handoff'),
  meta: t.type({
    type: ChatHandoffActionTypeV,
  }),
});
export type ChatHandoffAction = t.TypeOf<typeof ChatHandoffActionV> & unknown;

export const handoffNameToOrgKey: Record<Exclude<ChatHandoffActionType, ''>, keyof IIntegrations> = {
  intercom: 'intercom',
  freshchat: 'freshchat',
  zendesk: 'sunshine',
  sfdc_chat: 'sfdc_chat',
  sfdc_messaging: 'sfdc_messaging',
};

export const DismissAction = t.type({
  type: t.literal('dismiss'),
});

export const StepBackAction = t.type({
  type: t.literal('step_back'),
});

export const SnoozeInterval = t.union([t.literal('hour'), t.literal('day'), t.literal('week')]);
export const SnoozeValue = t.number;

export const SnoozeAction = t.intersection([
  t.type({
    type: t.literal('snooze'),
  }),
  t.partial({
    interval: SnoozeInterval,
    value: SnoozeValue,
  }),
]);

export const ClickAction = t.type({
  type: t.union([t.literal('click'), t.literal('clickBySelector'), t.literal('clickByXpath')]),
  value: t.array(t.string),
});
export type IClickAction = t.TypeOf<typeof ClickAction> & unknown;

export const BuiltInAction = t.type({
  type: t.literal('builtin'),
  value: t.string,
});

export const WebhookAction = t.type({
  type: t.literal('webhook'),
  value: t.string,
});

export const AppcuesAction = t.type({
  type: t.literal('appcues'),
  value: t.string,
});

export const PendoGuideAction = t.type({
  type: t.literal('pendo_guide'),
  value: t.string,
});

export const ScriptAction = t.type({
  type: t.literal('script'),
  value: t.string,
});

export const VideoAction = t.type({
  type: t.literal('video'),
  value: t.string,
});

export const HelpDocAction = t.intersection([
  t.type({
    type: t.literal('helpdoc'),
    value: t.string,
  }),
  t.partial({
    operation: t.union([
      t.literal('router'),
      t.literal('self'),
      t.literal('blank'),
      t.literal('help_hub'),
      t.undefined,
    ]),
    doc_metadata: t.partial({
      content_type: t.string,
      date: t.string,
    }),
  }),
]);
export type IHelpDocAction = t.TypeOf<typeof HelpDocAction> & unknown;

export const CommandActionMetaTypeV = t.union([
  t.literal('action'),
  t.literal('link'),
  t.literal('helpdoc'),
  t.literal('video'),
]);
export type CommandActionMetaType = t.TypeOf<typeof CommandActionMetaTypeV> & unknown;

export const CommandActionV = t.type({
  type: t.literal('execute_command'),
  meta: t.intersection([
    t.type({
      command: t.string,
    }),
    t.partial({
      type: CommandActionMetaTypeV,
    }),
  ]),
});
export type CommandAction = t.TypeOf<typeof CommandActionV> & unknown;

export const NoAction = t.type({ type: t.literal('no_action') });

export const ClickActionNew = t.type({
  type: t.literal('click'),
  value: t.string,
});

export const NudgeActionV = t.type({
  type: t.literal('nudge'),
  value: t.number,
});
export type NudgeAction = t.TypeOf<typeof NudgeActionV> & unknown;

export const GoToNudgeStepActionV = t.type({
  type: t.literal('go_to_step'),
  value: t.number,
});

export const QuestlistActionV = t.type({
  type: t.literal('questlist'),
  value: t.number,
});
export type QuestlistAction = t.TypeOf<typeof QuestlistActionV> & unknown;

export const ActionV = t.union([
  CommandActionV,
  NoAction,
  ClickActionNew,
  ClickAction,
  LinkAction,
  OpenChatActionV,
  ChatHandoffActionV,
  DismissAction,
  SnoozeAction,
  QuestlistActionV,
  NudgeActionV,
  GoToNudgeStepActionV,
  StepBackAction,
  OpenBarAction,
  OpenHelpHubAction,
  OpenCopilotAction,
]);
export type Action = t.TypeOf<typeof ActionV> & unknown;

export const LabeledActionV = t.type({
  cta: t.string,
  action: ActionV,
});
export type LabeledAction = t.TypeOf<typeof LabeledActionV> & unknown;

export const isAction = (o: string | number | LabeledAction): o is LabeledAction => {
  return (o as LabeledAction).action !== undefined;
};

/** Request */

const MethodV = t.union([
  t.literal('get'),
  t.literal('delete'),
  t.literal('head'),
  t.literal('options'),
  t.literal('post'),
  t.literal('put'),
  t.literal('patch'),
]);

export const RequestV = t.intersection([
  t.type({
    method: MethodV,
    url: t.string,
  }),
  t.partial({
    headers: t.UnknownRecord,
    body: t.UnknownRecord,
    onSend: t.string,
    onSuccess: t.string,
    onError: t.string,
  }),
]);
export type RequestType = t.TypeOf<typeof RequestV> & unknown;

export const RequestAction = t.type({
  type: t.literal('request'),
  value: RequestV,
});

export const TriggerAction = t.type({
  type: t.literal('trigger'),
  value: ActionV,
});
