import { useRef } from 'react';
import styled from '@emotion/styled';

import type {
  IAIMessageExtraType,
  IChecklist,
  IEditorCommandTypeLite,
  IMessageType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import { CmdButton, CmdCard, CmdTag, CmdTypography } from '@commandbar/design-system/cmd';
import React from 'react';
import { Panel } from '@commandbar/design-system/components/antd';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { Space } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { getContentType } from '@commandbar/internal/middleware/command';
import {
  ArrowUpRight,
  BookClosed,
  BookmarkCheck,
  Caret01,
  CheckCircleBroken,
  MessageChatSquare,
  CodeBrowser,
  ZapCircle,
  DotsHorizontal,
  File06,
  Globe01,
  Play,
  FaceSad,
  StopCircle,
  CheckSquareBroken,
  Announcement02,
  Flag03,
  MessageSmileSquare,
  ZapFast,
  Link04,
  MessageTextSquare01,
  MessageSquare01,
  Bookmark,
  FaceIdSquare,
  SearchSm,
  Square,
} from '@commandbar/design-system/icons/react';
import { getDaysSinceDate } from '@commandbar/internal/util/time';
import { LoadingSave } from '../themes/shared';
import { getCommandType } from '../PagesOrActions';
import CmdSpinner from '@commandbar/design-system/cmd/spinner/spinner';

const LoadingStateContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;

const TextClamp = styled.div<{ lines: number }>`
  width: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lines};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled(IconContainer)`
  margin-right: 4px;
  color: #797c85;
`;

export const CodeSnippet = styled.div`
  display: flex;
  align-self: stretch;
  padding: 10px 10px 10px 50px;
  border-radius: 4px;
  background: #24292e;
  gap: 16px;
  overflow-x: auto;
`;

export const Code = styled.code`
  display: block;
  white-space: pre;
  font-family: monospace;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: #79b8ff;

  & span {
    display: block;
    position: relative;
  }
`;

const LineNumberedCode = styled(Code)`
  counter-reset: linenumber;

  & span::before {
    content: counter(linenumber);
    counter-increment: linenumber;
    position: absolute;
    left: -40px;
    width: 30px;
    text-align: right;
    color: rgba(225, 228, 232, 0.3);
  }
`;

const Header = styled(CmdCard.Header)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
`;

interface ChatDebugInfo {
  user_properties: Record<any, any>;
  messages: ChatMessageDebugInfo[];
}

interface API {
  id: number;
  title: string;
}

interface Workflow {
  id: number;
  title: string;
}

interface UsedAPI {
  id: number;
  title: string;
  arguments: Record<string, any>;
  response_status: number;
  response_content: string;
}

interface ChatMessageDebugInfo {
  chat_message_uuid: string;
  question: string;
  ai_response: string;
  incomplete: boolean;
  answer_sources: Source[];
  prompt_sources: Source[];
  apis: API[];
  workflows: Workflow[];
  used_apis: UsedAPI[];
  used_workflows: Workflow[];
  reasoning?: string;
  reasoning_loading?: boolean;
  experience_map?: Record<string, string>;
  no_answer: boolean;
  aborted: boolean;
  extras: IAIMessageExtraType[];
}

interface Source {
  id: number;
  passage: string;
  passage_heading: string;
  passage_text: string;
  passage_markdown: string;
  parent_doc_id: number;
  parent_doc_title: string;
  parent_doc_source: string | null | undefined;
}

export const ChatDebugPanel = (props: {
  selectedChat?: {
    id: string;
    messages: IMessageType[];
  } | null;
  usingXray?: boolean;
}) => {
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const [messages, setMessages] = React.useState<ChatMessageDebugInfo[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const reasoningLoadingTimeout = useRef<number | null>(null);

  const loadDebug = () => {
    axiosInstance
      .get(`debug_chat/${props.selectedChat?.id}/`)
      .then((res) => {
        const data = res.data as ChatDebugInfo;
        const messages = data.messages.filter((msg) => msg.question.length > 0);
        setMessages(messages);

        if (reasoningLoadingTimeout.current) {
          clearTimeout(reasoningLoadingTimeout.current);
        }

        // check if any messages are reasoning loading
        if (messages.some((msg) => msg.reasoning_loading)) {
          // call loadDebug again after 1 second
          reasoningLoadingTimeout.current = window.setTimeout(loadDebug, 1000);
        } else {
          if (messages.length > 0) {
            setActiveKeys((activeKeys) =>
              props.usingXray
                ? [messages.slice(-1)[0].chat_message_uuid]
                : [...activeKeys, messages[0].chat_message_uuid],
            );
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (props.selectedChat) {
      loadDebug();
    } else {
      setMessages([]);
      setIsLoading(false);
    }
  }, [props.selectedChat]);

  return isLoading ? (
    <div
      style={{
        margin: 'auto',
        paddingTop: '24px',
      }}
    >
      <CmdSpinner size="2xl" />
    </div>
  ) : (
    <Space direction="vertical">
      {messages?.map((msg) => {
        return (
          <Panel
            key={msg.chat_message_uuid}
            panelKey={msg.chat_message_uuid}
            activeKeys={activeKeys}
            setActiveKeys={setActiveKeys}
            header={
              <TextClamp lines={1}>
                <CmdTypography>
                  <span style={{ fontWeight: 600 }}>Q: </span>
                  {msg.question}
                </CmdTypography>
              </TextClamp>
            }
          >
            {msg.incomplete ? (
              <LoadingStateContainer>
                <LoadingSave />
              </LoadingStateContainer>
            ) : (
              <MessageDetailsCard msgInfo={msg} />
            )}
          </Panel>
        );
      })}
    </Space>
  );
};

const MessageDetailsCard = (props: { msgInfo: ChatMessageDebugInfo }) => {
  const { msgInfo } = props;

  const usedInAnswer = (source: Source) => {
    return msgInfo.answer_sources.find((s) => s.id === source.id);
  };

  const { commands, answers } = useAppContext();

  const URLprefix = window.location.hostname === 'localhost' ? 'http://localhost:4000' : 'https://app.commandbar.com';

  const NoSourcesFound = ({ msgInfo }: { msgInfo: ChatMessageDebugInfo }) => {
    const aborted = msgInfo.aborted;
    const showNoSources = msgInfo.no_answer && msgInfo.answer_sources.length === 0;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div>
          <CmdTypography
            style={{ color: aborted ? '#4A70F2' : '#EA580C', display: 'flex', gap: '4px', alignItems: 'center' }}
          >
            {aborted
              ? 'Response canceled'
              : showNoSources
              ? 'No sources found.'
              : 'Source content no longer available.'}{' '}
            {aborted ? <StopCircle /> : <FaceSad />}
          </CmdTypography>
          <CmdTypography variant="secondary" fontSize="sm">
            {aborted
              ? 'The user canceled this response before it was complete.'
              : showNoSources
              ? 'No relevant sources were found to generate an answer'
              : 'The source(s) used to generate this answer have since been edited or deleted'}
          </CmdTypography>
        </div>

        {showNoSources && (
          <a href={`${URLprefix}/content`} target="_blank" rel="noopener noreferrer">
            <CmdButton>
              Add content
              <ArrowUpRight />
            </CmdButton>
          </a>
        )}
      </div>
    );
  };

  const SourceDisplay = ({ source }: { source: Source }) => {
    const [expanded, setExpanded] = React.useState(!!usedInAnswer(source));
    const [overflowActive, setOverflowActive] = React.useState<boolean>(true);
    const overflowingText = React.useRef<HTMLDivElement | null>(null);

    const checkOverflow = (textContainer: HTMLDivElement): boolean => {
      return textContainer.offsetHeight < textContainer.scrollHeight;
    };

    React.useLayoutEffect(() => {
      if (overflowingText.current) {
        setOverflowActive(checkOverflow(overflowingText.current));
      }
    }, []);

    let command = commands.find((c) => c.id === source.parent_doc_id);
    if (!command) {
      command = answers.find((a) => a.id === source.parent_doc_id);
    }

    let url = command?.template?.type === 'helpdoc' ? command?.template?.value : undefined;
    let leftIcon = undefined;

    const type = command ? getContentType(command) : undefined;

    if (command && type) {
      switch (type) {
        case 'source':
          if (!url) url = `${URLprefix}/content/sources/${command.integration?.id}/?id=${command.id}`;
          leftIcon =
            command.third_party_source === 'web' || command.third_party_source === 'web_v2' ? (
              <Globe01 />
            ) : (
              <BookClosed />
            );
          break;
        case 'answer':
          if (!url) url = `${URLprefix}/content/${type}s/?id=${command.id}`;
          leftIcon = <BookmarkCheck />;
          break;
        case 'file':
          if (!url) url = `${URLprefix}/content/${type}s/?id=${command.id}`;
          leftIcon = <File06 />;
          break;
        case 'video':
          if (!url) url = `${URLprefix}/content/${type}s/?id=${command.id}`;
          leftIcon = <Play />;
          break;
        default:
          break;
      }
    }

    return (
      <CmdCard key={source.id} style={{ padding: '4px' }}>
        <Header>
          <div
            style={{ display: 'flex', gap: '4px', alignItems: 'center', cursor: 'pointer', flex: 1 }}
            onClick={() => setExpanded((expanded) => !expanded)}
          >
            {leftIcon && <LogoContainer>{leftIcon}</LogoContainer>}

            <TextClamp lines={1}>
              <CmdTypography fontWeight="semi-bold">{source.parent_doc_title}</CmdTypography>
            </TextClamp>

            <IconContainer>
              <Caret01
                style={{
                  color: '#797C85',
                  transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                  transition: 'transform 0.3s',
                }}
              />
            </IconContainer>
          </div>

          {url && expanded && (
            <a
              href={url}
              target={`cb-response-sources-${props.msgInfo.chat_message_uuid}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CmdButton
                variant="ghost"
                style={{ height: '22px', width: '22px' }}
                icon={
                  <IconContainer>
                    <ArrowUpRight />
                  </IconContainer>
                }
              />
            </a>
          )}
        </Header>
        {expanded && (
          <CmdCard.Content
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              padding: '4px 12px 8px 12px',
              gap: '12px',
            }}
          >
            <TextClamp
              ref={overflowingText}
              lines={overflowActive ? 4 : 0}
              style={{ textOverflow: 'clip', width: '100%' }}
            >
              <CmdTypography className={`source-content-${source.id}`} variant="secondary">
                {source.passage_text}
              </CmdTypography>
              {overflowActive && (
                <DotsHorizontal
                  style={{
                    position: 'absolute',
                    right: '8px',
                    bottom: type === 'source' ? '42px' : '8px',
                    background: 'white',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOverflowActive(false)}
                />
              )}
            </TextClamp>
            {type === 'source' && (
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
                <a
                  href={`${URLprefix}/content/sources/${command?.integration?.id}/?id=${command?.id}`}
                  target={`cb-response-sources-${props.msgInfo.chat_message_uuid}`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CmdTypography variant="secondary" style={{ fontSize: '11px' }}>
                    LAST UPDATED{' '}
                    {getDaysSinceDate(command?.modified) ? `${getDaysSinceDate(command?.modified)}D AGO` : 'TODAY'}
                  </CmdTypography>
                </a>
                {command?.training_only && <CmdTag variant="warning">TRAINING ONLY</CmdTag>}
              </div>
            )}
          </CmdCard.Content>
        )}
      </CmdCard>
    );
  };

  const SourceListDisplay = ({ sources }: { sources: Source[]; cited?: boolean }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {sources.map((source: Source) => (
          <SourceDisplay key={source.id} source={source} />
        ))}
      </div>
    );
  };

  const citedSources: Source[] = [];
  msgInfo.prompt_sources.forEach((s) => {
    if (usedInAnswer(s)) {
      if (citedSources.find((t) => t.parent_doc_id === s.parent_doc_id) === undefined) {
        citedSources.push(s);
      }
    }
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
      {citedSources.length === 0 &&
      msgInfo.used_workflows.length === 0 &&
      msgInfo.used_apis.length === 0 &&
      msgInfo.extras.length === 0 ? (
        <NoSourcesFound msgInfo={msgInfo} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {(msgInfo.reasoning || msgInfo.reasoning_loading) && <Reasoning msgInfo={msgInfo} />}
          {msgInfo.used_workflows.length > 0 && <WorkflowCallHistory workflows={msgInfo.used_workflows} />}
          {msgInfo.used_apis.length > 0 && <APICallHistory apis={msgInfo.used_apis} />}
          {msgInfo.extras.length > 0 && <Extras extras={msgInfo.extras} />}
          {citedSources.length > 0 && (
            <>
              <div>
                <CmdTypography style={{ color: '#3A6A0B', gap: '4px', display: 'flex', alignItems: 'center' }}>
                  Cited sources
                  <CheckCircleBroken />
                </CmdTypography>
                <CmdTypography variant="secondary" fontSize="sm">
                  The following sources were used to generate an answer
                </CmdTypography>
              </div>

              <SourceListDisplay sources={citedSources} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

type TypedExtra = (IEditorCommandTypeLite | INudgeType | IChecklist) & {
  extraType:
    | 'page'
    | 'action'
    | 'checklist'
    | 'announcement'
    | 'product_tour'
    | 'survey'
    | 'open_chat'
    | 'chat_handoff'
    | 'open_bar'
    | 'open_helphub'
    | 'open_copilot';
};

const Extras = (props: { extras: any[] }) => {
  const URLprefix = window.location.hostname === 'localhost' ? 'http://localhost:4000' : 'https://app.commandbar.com';
  const { commands, nudges, checklists } = useAppContext();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {props.extras.map((extra, idx) => {
        const extraItem = (() => {
          if (extra.action?.type === 'execute_command') {
            const command = commands.find((c) => c.id === parseInt(extra.action.meta.command));
            return command ? ({ ...command, extraType: getCommandType(command) } as TypedExtra) : undefined;
          } else if (extra.action?.type === 'nudge') {
            const nudge = nudges.find((n) => n.id === extra.action?.value);
            return nudge ? ({ ...nudge, extraType: nudge?.type } as TypedExtra) : undefined;
          } else if (extra.action?.type === 'questlist') {
            const checklist = checklists.find((c) => c.id === extra.action?.value);
            return checklist ? ({ ...checklist, extraType: 'checklist' } as TypedExtra) : undefined;
          }
          return undefined;
        })();

        let leftIcon;
        let extraTypeLabel = '';
        const type = extraItem?.extraType ?? extra.action?.type;
        switch (type) {
          case 'announcement':
            leftIcon = <Announcement02 />;
            extraTypeLabel = 'Announcement';
            break;
          case 'product_tour':
            leftIcon = <Flag03 />;
            extraTypeLabel = 'Tour';
            break;
          case 'survey':
            leftIcon = <MessageSmileSquare />;
            extraTypeLabel = 'Survey';
            break;
          case 'checklist':
            leftIcon = <CheckSquareBroken />;
            extraTypeLabel = 'Checklist';
            break;
          case 'page':
            leftIcon = <Link04 />;
            extraTypeLabel = 'Page';
            break;
          case 'action':
            leftIcon = <ZapFast />;
            extraTypeLabel = 'Action';
            break;
          case 'open_chat':
            leftIcon = <MessageSquare01 />;
            extraTypeLabel = 'Open third-party chat';
            break;
          case 'chat_handoff':
            leftIcon = <MessageTextSquare01 />;
            extraTypeLabel = 'Chat handoff';
            break;
          case 'open_bar':
            leftIcon = <SearchSm />;
            extraTypeLabel = 'Open Spotlight';
            break;
          case 'open_helphub':
            leftIcon = <Bookmark />;
            extraTypeLabel = 'Open HelpHub';
            break;
          case 'open_copilot':
            leftIcon = <FaceIdSquare />;
            extraTypeLabel = 'Open Copilot';
            break;
          default:
            leftIcon = <Square />;
            extraTypeLabel = 'Confirmation button';
            break;
        }

        return (
          <CmdCard key={`${extra.action?.value}-${idx}`} style={{ padding: '4px' }}>
            <Header>
              <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <LogoContainer>{leftIcon}</LogoContainer>
                <CmdTag variant="info">{extraTypeLabel}</CmdTag>
                <TextClamp lines={1}>
                  <CmdTypography fontWeight="semi-bold">{extra.text}</CmdTypography>
                </TextClamp>
              </div>

              {extraItem && (
                <a
                  href={`${URLprefix}/editor/${extraItem.extraType.replace(/_/g, '-')}s/${extraItem.id}`}
                  target={`cb-response-sources-${idx}`}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <CmdButton
                    variant="ghost"
                    style={{ height: '22px', width: '22px' }}
                    icon={
                      <IconContainer>
                        <ArrowUpRight />
                      </IconContainer>
                    }
                  />
                </a>
              )}
            </Header>
          </CmdCard>
        );
      })}
    </Space>
  );
};

const WorkflowCallHistory = (props: { workflows: Workflow[] }) => {
  const URLprefix = window.location.hostname === 'localhost' ? 'http://localhost:4000' : 'https://app.commandbar.com';

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {props.workflows.map((workflow, idx) => {
        return (
          <CmdCard key={`${workflow.id}-${idx}`} style={{ padding: '4px' }}>
            <Header>
              <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <LogoContainer>
                  <ZapCircle />
                </LogoContainer>
                <CmdTag variant="info">Workflow</CmdTag>
                <TextClamp lines={1}>
                  <CmdTypography fontWeight="semi-bold">{workflow.title}</CmdTypography>
                </TextClamp>
              </div>

              <a
                href={`${URLprefix}/editor/copilot/workflows/${workflow.id}`}
                target={`cb-response-sources-${idx}`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CmdButton
                  variant="ghost"
                  style={{ height: '22px', width: '22px' }}
                  icon={
                    <IconContainer>
                      <ArrowUpRight />
                    </IconContainer>
                  }
                />
              </a>
            </Header>
          </CmdCard>
        );
      })}
    </Space>
  );
};

const APICallHistory = (props: { apis: UsedAPI[] }) => {
  const URLprefix = window.location.hostname === 'localhost' ? 'http://localhost:4000' : 'https://app.commandbar.com';
  const formatJson = (json: string | Record<string, any>, messageOnly = false) => {
    try {
      const parsedContent = typeof json === 'string' ? JSON.parse(json) : json;
      const parsed = JSON.stringify(messageOnly ? parsedContent.message : parsedContent, null, 2);
      return parsed.split('\n').map((line, index) => <span key={index}>{line}</span>);
    } catch (e) {
      return <span>{json}</span>;
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {props.apis.map((api, idx) => {
        return (
          <CmdCard key={`${api.id}-${idx}`} style={{ padding: '4px' }}>
            <Header>
              <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <LogoContainer>
                  <CodeBrowser />
                </LogoContainer>
                <CmdTag variant="info">API</CmdTag>
                <TextClamp lines={1}>
                  <CmdTypography fontWeight="semi-bold">{api.title}</CmdTypography>
                </TextClamp>
              </div>

              <a
                href={`${URLprefix}/editor/copilot/apis/${api.id}`}
                target={`cb-response-sources-${idx}`}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CmdButton
                  variant="ghost"
                  style={{ height: '22px', width: '22px' }}
                  icon={
                    <IconContainer>
                      <ArrowUpRight />
                    </IconContainer>
                  }
                />
              </a>
            </Header>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                padding: '0px 8px 8px 8px',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <CmdTypography>Parameters:</CmdTypography>
                <CodeSnippet>
                  <LineNumberedCode>{formatJson(api.arguments)}</LineNumberedCode>
                </CodeSnippet>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <CmdTypography style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  Response:{' '}
                  <CmdTag variant={api.response_status < 300 ? 'success' : 'failure'}>{api.response_status}</CmdTag>
                </CmdTypography>
                <CodeSnippet>
                  <LineNumberedCode>{formatJson(api.response_content)}</LineNumberedCode>
                </CodeSnippet>
              </div>
            </div>
          </CmdCard>
        );
      })}
    </Space>
  );
};

const Reasoning = (props: { msgInfo: ChatMessageDebugInfo }) => {
  const { msgInfo } = props;
  const { commands, nudges, checklists } = useAppContext();

  const apiMap = msgInfo.apis.reduce((acc: Record<string, API>, api) => {
    const key = api.title
      .toLowerCase()
      .replace(/[^a-z0-9 ]/g, '')
      .replace(/ /g, '_');
    acc[key] = api;
    return acc;
  }, {});

  let formattedReasoning;
  if (msgInfo.reasoning) {
    formattedReasoning = msgInfo.reasoning.replace(/`([^`]+)`/g, (match, p1) => {
      const toolKey = p1.toLowerCase().replace(/ /g, '_');

      // Replace API tool_names with their titles + API detail links
      const api = apiMap[toolKey];
      if (api) {
        return `<a href="/editor/copilot/apis/${api.id}" target="_blank" style="text-decoration: none; color: #0a2e59; background: #ebecf0; font-family: monospace; padding: 1.5px">${api.title}</a>`;
      }

      // Replace action tool_names with their display names
      const action = msgInfo.experience_map?.[toolKey];
      if (action) {
        const actionJSON = JSON.parse(action.replace(/'/g, '"'));
        const foundToolOption = () => {
          if (actionJSON.action?.type === 'execute_command') {
            const command = commands.find((c) => c.id === parseInt(actionJSON.action?.meta.command));
            return command?.text;
          } else if (actionJSON.action?.type === 'nudge') {
            const nudge = nudges.find((n) => n.id === actionJSON.action?.value);
            return nudge?.slug;
          } else if (actionJSON.action?.type === 'questlist') {
            const checklist = checklists.find((c) => c.id === actionJSON.action?.value);
            return checklist?.title;
          } else if (actionJSON.action?.type === 'open_chat' || actionJSON.action?.type === 'chat_handoff') {
            return actionJSON.action?.meta?.type;
          } else if (
            actionJSON.action?.type === 'open_bar' ||
            actionJSON.action?.type === 'open_helphub' ||
            actionJSON.action?.type === 'open_copilot'
          ) {
            return actionJSON.cta;
          }
        };
        const toolOption = foundToolOption();
        if (toolOption) {
          return `<span style="text-decoration: none; color: #0a2e59; background: #ebecf0; font-family: monospace; padding: 1.5px">${toolOption}</span>`;
        }
      }

      return match;
    });
  }

  return (
    <div style={{ marginBottom: '8px' }}>
      <CmdTypography style={{ color: '#0d3c73', gap: '4px', display: 'flex', alignItems: 'center' }}>
        Copilot's reasoning
        <MessageChatSquare />
      </CmdTypography>
      {msgInfo.reasoning_loading && (
        <LoadingStateContainer style={{ marginTop: '4px' }}>
          <LoadingSave />
        </LoadingStateContainer>
      )}
      {formattedReasoning && (
        <CmdCard.Content style={{ marginTop: '4px' }}>
          <CmdTypography>
            <div dangerouslySetInnerHTML={{ __html: formattedReasoning }} />
          </CmdTypography>
        </CmdCard.Content>
      )}
    </div>
  );
};
