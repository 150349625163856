import React from 'react';

import { CmdToolbarButton, cmdToast } from '@commandbar/design-system/cmd';
import { Copy02, Link04, PencilLine, Trash04 } from '@commandbar/design-system/icons/react';
import { ToolbarType, useEditorToolbar } from '.';

enum AriaLabels {
  Edit = 'Edit URL',
  Copy = 'Copy URL',
  Delete = 'Remove URL',
}

const Edit = () => {
  const { setType } = useEditorToolbar();

  return (
    <CmdToolbarButton
      aria-label={AriaLabels.Edit}
      onClick={() => {
        setType(ToolbarType.EditLink);
      }}
    >
      <PencilLine />
    </CmdToolbarButton>
  );
};

const Copy = () => {
  const { linkElement } = useEditorToolbar();

  return (
    <CmdToolbarButton
      aria-label={AriaLabels.Copy}
      onClick={() => {
        if (linkElement?.url) {
          navigator.clipboard
            .writeText(linkElement.url)
            .then(() => {
              cmdToast.success('Copied URL to clipboard!');
            })
            .catch(() => {
              cmdToast.error('Failed to copy URL to clipboard.');
            });
        } else {
          cmdToast.error('No URL to copy.');
        }
      }}
    >
      <Copy02 />
    </CmdToolbarButton>
  );
};

const Delete = () => {
  const { removeLink } = useEditorToolbar();

  return (
    <CmdToolbarButton aria-label={AriaLabels.Delete} onClick={removeLink}>
      <Trash04 />
    </CmdToolbarButton>
  );
};

export const PreviewLink = () => {
  const { linkElement } = useEditorToolbar();

  return (
    <>
      <div className="flex items-center gap-xs pl-2">
        <Link04 className="text-contentBase" />
        {linkElement?.url && (
          <a className="flex items-center px-1" href={linkElement.url} rel="noreferrer" target="_blank">
            <span className="max-w-[220px] flex-1 overflow-hidden text-ellipsis whitespace-nowrap font-medium text-base text-contentTop">
              {linkElement.url}
            </span>
          </a>
        )}
      </div>

      <div className="flex items-center gap-xxs">
        <Edit />
        <Copy />
        <Delete />
      </div>
    </>
  );
};
