import { Editor, Element, Point, Range, Transforms } from 'slate';

import { ElementType, EMPTY_VALUE, HeadingDepth } from '../constants';

// List of element types that should not be converted to paragraphs on backspace at the start
const BACKSPACE_UNAFFECTED_TYPES = [ElementType.List, ElementType.ListItem, ElementType.Paragraph];

// Markdown shortcuts and their corresponding actions
enum MarkdownShortcut {
  UnorderedList = '-',
  OrderedList = '1.',
  HeadingOne = '#',
  HeadingTwo = '##',
  HeadingThree = '###',
  HeadingFour = '####',
  HeadingFive = '#####',
  HeadingSix = '######',
}

const MARKDOWN_SHORTCUT_ACTIONS = {
  [MarkdownShortcut.UnorderedList]: { type: ElementType.ListItem, ordered: false },
  [MarkdownShortcut.OrderedList]: { type: ElementType.ListItem, ordered: true },
  [MarkdownShortcut.HeadingOne]: { type: ElementType.Heading, depth: HeadingDepth.One },
  [MarkdownShortcut.HeadingTwo]: { type: ElementType.Heading, depth: HeadingDepth.Two },
  [MarkdownShortcut.HeadingThree]: { type: ElementType.Heading, depth: HeadingDepth.Three },
  [MarkdownShortcut.HeadingFour]: { type: ElementType.Heading, depth: HeadingDepth.Four },
  [MarkdownShortcut.HeadingFive]: { type: ElementType.Heading, depth: HeadingDepth.Five },
  [MarkdownShortcut.HeadingSix]: { type: ElementType.Heading, depth: HeadingDepth.Six },
} as const;

const isValidMarkdownShortcut = (maybeShortcut: string): maybeShortcut is keyof typeof MARKDOWN_SHORTCUT_ACTIONS =>
  maybeShortcut in MARKDOWN_SHORTCUT_ACTIONS;

const createNewNode = (shortcutAction: (typeof MARKDOWN_SHORTCUT_ACTIONS)[MarkdownShortcut]) =>
  shortcutAction.type === ElementType.ListItem
    ? {
        type: ElementType.ListItem,
        spread: false,
        children: [],
      }
    : {
        type: ElementType.Heading,
        depth: shortcutAction.depth,
        children: [],
      };

export const withMarkdownShortcutHandling = (editor: Editor) => {
  const { deleteBackward, insertText, insertBreak } = editor;

  // Deletes the typed markdown shortcut to prepare for node transformation
  const deleteShortcutText = (shortcutText: Range) => {
    Transforms.select(editor, shortcutText);
    Transforms.delete(editor);
  };

  // Wrap a list item in a list element
  const wrapListItemInList = ({
    ordered,
  }: (typeof MARKDOWN_SHORTCUT_ACTIONS)[MarkdownShortcut.OrderedList | MarkdownShortcut.UnorderedList]) => {
    Transforms.wrapNodes(
      editor,
      {
        type: ElementType.List,
        ordered,
        children: [],
        spread: false,
      },
      {
        match: (node) => Element.isElementType(node, ElementType.ListItem),
      },
    );
  };

  // Apply the markdown shortcut transformation to the current node
  const applyMarkdownShortcut = (shortcutAction: (typeof MARKDOWN_SHORTCUT_ACTIONS)[MarkdownShortcut]) => {
    const newNode = createNewNode(shortcutAction);
    Transforms.setNodes(editor, newNode, {
      match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
    });

    if (shortcutAction.type === ElementType.ListItem) {
      wrapListItemInList(shortcutAction);
    }
  };

  // Override insertText to transform markdown shortcuts (e.g., '#' for headings) when space is entered
  editor.insertText = (text) => {
    const { selection } = editor;

    // Check if the user typed a space after a markdown shortcut
    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const shortcutText = Editor.string(editor, range);

      if (isValidMarkdownShortcut(shortcutText)) {
        const shortcutAction = MARKDOWN_SHORTCUT_ACTIONS[shortcutText];

        // Prevent list shortcuts from activating inside a list
        if (shortcutAction.type === ElementType.ListItem) {
          const isInList = Editor.above(editor, {
            match: (node) => Element.isElementType(node, ElementType.List),
          });

          if (isInList) {
            // Already inside a list, so skip applying the shortcut
            insertText(text);
            return;
          }
        }

        deleteShortcutText(range);
        applyMarkdownShortcut(shortcutAction);
        return;
      }
    }

    insertText(text);
  };

  // Use Transforms.unwrapNodes to remove the list wrapper while preserving the list item content
  const unwrapFromListElement = () => {
    Transforms.unwrapNodes(editor, {
      match: (node) => Element.isElementType(node, ElementType.List),
      split: true,
    });
  };

  // Convert the current block to a paragraph element
  const convertToParagraphElement = (blockType: ElementType) => {
    Transforms.setNodes(editor, { type: ElementType.Paragraph });

    if (blockType === ElementType.ListItem) {
      unwrapFromListElement();
    }
  };

  // Override deleteBackward to convert certain block types back to paragraphs when at the start
  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        // If the current block is a heading or other convertible type and the cursor is at the start,
        // convert it back to a paragraph on backspace
        if (
          Element.isElement(block) &&
          !BACKSPACE_UNAFFECTED_TYPES.includes(block.type) &&
          Point.equals(selection.anchor, start)
        ) {
          convertToParagraphElement(block.type);
          return;
        }
      }

      deleteBackward(...args);
    }
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (n) => Element.isElementType(n, ElementType.Heading),
      });

      if (match) {
        Transforms.insertNodes(
          editor,
          { type: ElementType.Paragraph, children: [{ text: EMPTY_VALUE }] },
          { mode: 'lowest' },
        );
        return;
      }
    }

    insertBreak();
  };

  return editor;
};
