export const CB_COLORS = {
  primary: '#2755f0',
  white: '#ffffff',
  black: '#000000',
  neutral0: '#F7F7F8',
  neutral100: '#EDEDEE',
  neutral200: '#E7E7E9',
  neutral300: '#DFDFE2',
  neutral400: '#CDCDD0',
  neutral500: '#A2A2A9',
  neutral600: '#7A7A85',
  neutral700: '#51515C',
  neutral800: '#42424D',
  neutral900: '#1E1E29',
  neutral1000: '#0A0A0F',
  blue0: '#F5F7FF',
  blue100: '#E6ECFE',
  blue200: '#C4D1FD',
  blue300: '#90A8F9',
  blue400: '#6887EE',
  blue500: '#456BE8',
  blue600: '#2755F0',
  blue700: '#0E3FDD',
  blue800: '#072EAB',
  blue900: '#031963',
  blue1000: '#030F38',
  green500: '#2C5009',
  red500: '#9A3412',
};
