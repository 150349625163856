import styled from '@emotion/styled';

export const DropdownRow = styled.div`
  display: flex;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  border-radius: 6px;
  background: #fff;

  color: #3f424a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export const DropdownRowLabel = styled.span`
  color: #3f424a;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export const DropdownColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  gap: 8px;

  border-radius: 6px;
  background: #fff;

  color: #3f424a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;
