import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  ListItem,
  Icon,
  commandDefault,
  StatusBadge,
  Table,
  Row,
  Col,
  Typography,
  FeatureAnnouncementCard,
  SimplePanel,
} from '@commandbar/design-system/components/antd';

import { ContextSettingsModal } from '../context/contextSettings/ContextSettingsModal';
import {
  IEditorCommandType,
  IEditorCommandTypeLite,
  IRecordSettingsByContextKey,
} from '@commandbar/internal/middleware/types';
import ActiveBadge, { KeyStatus } from '../context/ActiveBadge';
import { ContextDataObject, useContextPartition } from '../context/contextSettings/useContextPartition';
import { useContextSettings } from '../context/contextSettings/useContextSettings';
import * as Command from '@commandbar/internal/middleware/command';
import useLinkedCommand from '../context/useLinkedCommand';
import { useReportEvent } from '../../hooks/useEventReporting';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdButton, CmdSearchInput, CmdTag, CmdTooltip } from '@commandbar/design-system/cmd';
import { Plus, Settings03 } from '@commandbar/design-system/icons/react';

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }
  .record-actions-container {
    cursor: default;
    border-bottom-width: 2px;
  }
  .record-actions-container > td {
    padding: 2px 12px 12px 32px;
  }
  tr:not(.record-actions-container) > td {
    border: none;
    background: #f5f5f5;
  }
`;

export const RecordsTable = ({ setActiveCommand }: { setActiveCommand: (command: IEditorCommandType) => void }) => {
  const [selected, setSelected] = useState<ContextDataObject | undefined>();
  const [searchTerm, setSearchTerm] = React.useState('');
  const { records } = useContextPartition();
  const { current: contextSettings } = useContextSettings();
  const { organization } = useAppContext();

  const emptyTextMessage =
    'Records are searchable data sets. Add a record set by using window.CommandBar.addRecords().';

  const locale = {
    emptyText: emptyTextMessage,
  };

  const filteredRecords = useMemo(() => {
    return records.filter((recordData: ContextDataObject) =>
      recordData.key.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, records]);

  const openRecordSettings = (record: ContextDataObject) => {
    setSelected(record);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FeatureAnnouncementCard
        identifier="bar-records"
        title={<Row align="middle">Setting up Records</Row>}
        docsLink="https://www.commandbar.com/docs/nudges/spotlight/adding-records/"
      >
        <span>
          Use records to make data searchable in the Bar. Connect all your data sources to build a universal search
          experience.
        </span>
      </FeatureAnnouncementCard>

      <SimplePanel>
        <Row align="middle" style={{ marginBottom: 20 }} gutter={8}>
          <Col flex="1 1 auto">
            <CmdSearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Col>
          <CmdTooltip
            message={
              <span>
                To add a new searchable record, use the{' '}
                <a
                  href="https://www.commandbar.com/docs/sdk/records/addrecords/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#2754ee' }}
                >
                  addRecords SDK method
                </a>{' '}
                or use an{' '}
                <a
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/integrations/algolia`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#2754ee' }}
                >
                  Algolia integration
                </a>
                .
              </span>
            }
          >
            <Col>
              <CmdButton variant="ghost" icon={<Plus />} disabled id="new-record-button" style={{ width: '36px' }} />
            </Col>
          </CmdTooltip>
        </Row>
        <div style={{ flexGrow: 1, overflowY: 'auto' }}>
          <StyledTable
            locale={locale}
            dataSource={filteredRecords}
            pagination={false}
            onRow={(record: any, _rowIndex: any) => {
              return {
                onClick: (_e: React.MouseEvent) => {
                  openRecordSettings(record);
                },
              };
            }}
            expandable={{
              // expand all rows with record actions by default
              expandedRowKeys: records.map((d) => d.key),
              expandedRowRender: (data) => (
                <RecordActionsList
                  data={data as ContextDataObject}
                  contextSettings={contextSettings}
                  setActiveCommand={setActiveCommand}
                />
              ),
              expandedRowClassName: () => 'record-actions-container',
              expandIcon: () => null,
              columnWidth: 0,
            }}
          >
            <Table.Column
              dataIndex="key"
              title="Records"
              key="Title"
              width="21%"
              render={(key: string) => (
                <div style={{ fontWeight: 600, wordWrap: 'break-word', wordBreak: 'break-word' }}>{key}</div>
              )}
            />
            <Table.Column
              dataIndex="active"
              title="Active"
              key="active"
              render={(active: KeyStatus, record: ContextDataObject) => {
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <ActiveBadge
                      active={active}
                      inactiveTooltipContent={
                        <span>
                          No values have been provided for this record. Use{' '}
                          <Typography.Text code>window.CommandBar.addRecords</Typography.Text> to add values
                        </span>
                      }
                    />
                    {organization?.integrations?.algolia?.indexes?.hasOwnProperty(record.key) && (
                      <CmdTooltip
                        key={'right-tooltip-third-party-source'}
                        message={'Record created via Algolia integration'}
                      >
                        <CmdTag variant="info">Algolia</CmdTag>
                      </CmdTooltip>
                    )}
                  </div>
                );
              }}
            />
            <Table.Column
              dataIndex="settings"
              title=""
              key="settings"
              render={(_settings: any, record: ContextDataObject) => (
                <Settings03 onClick={() => openRecordSettings(record)} />
              )}
            />
          </StyledTable>
          <ContextSettingsModal onClose={() => setSelected(undefined)} data={selected} isRecord={true} />
        </div>
      </SimplePanel>
    </div>
  );
};

const RecordActionsList = (props: {
  contextSettings: IRecordSettingsByContextKey;
  data: ContextDataObject;
  setActiveCommand: (command: IEditorCommandType) => void;
}) => {
  const { contextSettings, data } = props;
  const { createLinkedCommand, openExistingLinkedCommand, openNewLinkedCommand } = useLinkedCommand();
  const settings = contextSettings[data.key];
  const defaultCommandId = settings?.default_command_id;
  const { reportEvent } = useReportEvent();

  const statusBadge = (command: IEditorCommandTypeLite) => {
    if (command.is_live) {
      return <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />;
    } else {
      return <StatusBadge color="orange" text="Draft" />;
    }
  };

  return (
    <div>
      <div style={{ marginTop: 5, marginBottom: 5, fontWeight: 600, marginLeft: 5, color: 'hsl(0,0%,45%)' }}>
        Actions
      </div>
      {props.data.commands.map((c) => {
        const isProgrammatic = Command.isProgrammatic(c);
        return (
          <ListItem
            key={c.id}
            text={
              <div style={{ display: 'flex', alignItems: 'center', gap: 12, justifyContent: 'space-between' }}>
                <div>{c.text}</div>
                <div>{statusBadge(c)}</div>
              </div>
            }
            disabled={isProgrammatic}
            tooltipText={
              isProgrammatic ? `This action is set via the sdk and can't be edited in the editor` : undefined
            }
            icon={{
              icon: (
                <Icon
                  style={{
                    color: c.icon_color || 'rgba(0, 0, 0, 0.85)',
                  }}
                  allowDefaultSVGColorOverride
                  useDefaultSVGColor
                  icon={c.icon || commandDefault(c)}
                />
              ),
            }}
            tag={defaultCommandId?.toString() === Command.commandUID(c) ? 'Default' : undefined}
            onClick={() => {
              openExistingLinkedCommand(c);
            }}
          />
        );
      })}
      <CmdButton
        variant="ghost"
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          const newLinkedCommand = createLinkedCommand(data.key);
          props.setActiveCommand(newLinkedCommand);
          openNewLinkedCommand(newLinkedCommand);
          reportEvent('record action added', {
            segment: true,
            highlight: true,
            slack: true,
            payloadMessage: data.key,
          });
        }}
      >
        <Plus />
        {!!props.data.commands.length
          ? 'Add another record action'
          : 'Add a record action to make this record searchable'}
      </CmdButton>
    </div>
  );
};
