import React from 'react';
import AutoCompleteTextArea from './AutoCompleteTextArea/AutoCompleteTextArea';
import useWindowInfo from '../../hooks/useWindowInfo';

type Props = React.ComponentProps<typeof AutoCompleteTextArea>;

// same props as AutoCompleteTextArea, with `options` being optional
const TextAreaWithInterpolation = (props: Omit<Props, 'options'> & Partial<Pick<Props, 'options'>>) => {
  const { context } = useWindowInfo();

  const contextKeys = Object.keys(context).map((s) => ({ value: `metadata.${s}`, addOn: 'Metadata' }));

  return <AutoCompleteTextArea {...props} options={[...(props.options ?? []), ...contextKeys]} />;
};

export default TextAreaWithInterpolation;
