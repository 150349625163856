import { useEffect, useRef } from 'react';

export const useEventListener = <K extends string, T extends EventTarget = EventTarget, E extends Event = Event>(
  eventType: K,
  callback: (event: E) => void,
  element?: T | null,
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const targetElement = element ?? (typeof window !== 'undefined' ? window : null);

    if (!targetElement?.addEventListener) {
      return;
    }

    const eventListener = (event: Event) => callbackRef.current(event as E);

    targetElement.addEventListener(eventType, eventListener);

    return () => {
      targetElement.removeEventListener(eventType, eventListener);
    };
  }, [eventType, element]);
};
