import { CmdTypography } from '@commandbar/design-system/cmd';
import React from 'react';
import { ReactComponent as EndUserError } from '../../../img/end_user_error.svg';
import { ReactComponent as EndUser } from '../../../img/end_user.svg';

export const NoResult = ({ message }: { message: string }) => {
  return (
    <div>
      <EndUser height={43} width={59} />
      <span className="flex flex-col gap-sm">
        <CmdTypography.Body variant="secondary" fontWeight="semi-bold">
          {message}
        </CmdTypography.Body>
      </span>
    </div>
  );
};

const ErrorMessage = () => {
  return (
    <div>
      <EndUserError height={43} width={59} />
      <span className="flex flex-col gap-sm">
        <CmdTypography.Body variant="secondary" fontWeight="semi-bold">
          We encountered an error
        </CmdTypography.Body>
        <span className="items-center">
          <CmdTypography.Body variant="secondary" fontWeight="medium">
            Something went wrong while trying to load your users.
          </CmdTypography.Body>
          <CmdTypography.Body variant="secondary" fontWeight="medium">
            If this issue persists, please{' '}
            <a className="text-blue800" href="mailto:support@command.ai">
              contact us.
            </a>
          </CmdTypography.Body>
        </span>
      </span>
    </div>
  );
};

export default ErrorMessage;
