import React, { type ComponentProps, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useSlate } from 'slate-react';

import { CmdInput, CmdToolbarButton } from '@commandbar/design-system/cmd';
import { Check, Link04, XClose } from '@commandbar/design-system/icons/react';
import { useEditorToolbar } from '.';
import { Commands } from '../../commands';

const validateLink = (input: string): boolean => {
  if (!input) return false;

  try {
    new URL(input, 'http://localhost');
    return true;
  } catch {
    return false;
  }
};

enum AriaLabels {
  Edit = 'Edit URL',
  Confirm = 'Confirm',
  Cancel = 'Cancel',
}

export const EditLink = () => {
  const editor = useSlate();
  const ref = useRef<HTMLInputElement>(null);
  const { linkElement, updateLink, close } = useEditorToolbar();
  const [url, setUrl] = useState(linkElement?.url || '');

  useEffect(() => {
    setUrl(linkElement?.url || '');
  }, [linkElement]);

  useEffect(() => {
    if (!ref.current) return;

    ref.current.focus();

    return () => {
      Commands.focusEditor(editor);
    };
  }, [editor]);

  const isValidLink = useMemo(() => validateLink(url), [url]);

  const handleConfirm = useCallback(() => {
    updateLink(url);
  }, [updateLink, url]);

  const cancelEdit = useCallback(() => {
    close();
    Commands.collapseSelectionToEnd(editor);
    Commands.focusEditor(editor);
  }, [close, editor]);

  const handleKeyboardShortcuts: NonNullable<ComponentProps<typeof CmdInput>['onKeyDown']> = useCallback(
    ({ key }) => {
      switch (key) {
        case 'Enter': {
          if (isValidLink) {
            handleConfirm();
          }
          break;
        }
        case 'Escape': {
          cancelEdit();
          break;
        }
        default:
          break;
      }
    },
    [cancelEdit, handleConfirm, isValidLink],
  );

  return (
    <div className="flex gap-xs">
      <CmdInput
        ref={ref}
        value={url}
        onKeyDown={handleKeyboardShortcuts}
        onChange={({ target }) => {
          setUrl(target.value);
        }}
        placeholder="Enter URL"
        prefixElement={<Link04 />}
        aria-label={AriaLabels.Edit}
      />

      <div className="flex gap-xxs">
        <CmdToolbarButton
          aria-label={AriaLabels.Confirm}
          onClick={handleConfirm}
          variant="primary"
          disabled={!isValidLink}
        >
          <Check />
        </CmdToolbarButton>

        <CmdToolbarButton aria-label={AriaLabels.Cancel} onClick={cancelEdit}>
          <XClose />
        </CmdToolbarButton>
      </div>
    </div>
  );
};
