import React, { type FC } from 'react';
import type { RenderLeafProps } from 'slate-react';

import { Mark } from '../constants';
import type { CustomText, EmptyText } from '../types';
import { cn } from '../../util';

const isEmptyText = (leaf: CustomText | EmptyText): leaf is EmptyText => !leaf.text;

interface FormattedProps {
  leaf: CustomText;
}

const Formatted: FC<FormattedProps> = ({ children, leaf }) => {
  const Comp = leaf[Mark.Code] ? 'code' : 'span';

  return <Comp className={cn(leaf[Mark.Italic] && 'italic', leaf[Mark.Bold] && 'font-bold')}>{children}</Comp>;
};

export const Leaf: FC<RenderLeafProps> = ({ attributes, children, leaf }) => (
  <span {...attributes}>{isEmptyText(leaf) ? children : <Formatted leaf={leaf}>{children}</Formatted>}</span>
);
