import { CmdButton, CmdTypography } from '@commandbar/design-system/cmd';
import React from 'react';
import { GoogleChrome } from '@commandbar/design-system/icons/react';
import { ChromeExtensionGraphic } from './ChromeExtensionGraphic';
import { PreviewModal } from './PreviewModal';

type Props = {
  open: boolean;
  onCancel: () => void;
  onInstall: () => void;
};
export const ChromeExtensionInstallModal: React.FC<Props> = ({ onCancel, open, onInstall }) => {
  return (
    <PreviewModal maxWidth={380} open={open} onCancel={onCancel}>
      <CmdTypography variant="primary" fontSize="base">
        Chrome extension needed
      </CmdTypography>
      <CmdTypography variant="secondary" fontSize="base">
        To preview experiences and select elements in your app you'll need to install our Chrome extension.
      </CmdTypography>
      <ChromeExtensionGraphic />
      <div style={{ justifyContent: 'flex-end', display: 'flex', gap: 4 }}>
        <CmdButton variant="ghost" onClick={onCancel}>
          Nevermind
        </CmdButton>
        <CmdButton variant="primary" onClick={onInstall}>
          Install
          <GoogleChrome />
        </CmdButton>
      </div>
    </PreviewModal>
  );
};
