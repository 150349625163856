import { CmdDropdown, CmdStatusDot, CmdTag } from '@commandbar/design-system/cmd';
import { PaintPour, Check } from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';
import { isNudge } from '@commandbar/internal/middleware/helpers/pushTrigger';
import type { IChecklist, INudgeType, IOrganizationType } from '@commandbar/internal/middleware/types';
import type { IThemeType } from '@cb/types/entities/theme';
import { THEME_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { useAppContext } from 'editor/src/AppStateContext';
import React from 'react';
import { useHistory } from 'react-router';
import { hasUnpublishedChanges } from 'editor/src/services/themes';

export const getDefaultTheme = (
  nudge: IChecklist | INudgeType,
  themes: IThemeType[],
  organization: IOrganizationType,
): IThemeType | undefined => {
  let categoryThemeOverride: null | string = null;
  if (isNudge(nudge)) {
    switch (nudge.type) {
      case 'announcement':
        categoryThemeOverride = organization.announcements_custom_theme;
        break;
      case 'product_tour':
        categoryThemeOverride = organization.product_tours_custom_theme;
        break;
      case 'survey':
        categoryThemeOverride = organization.surveys_custom_theme;
        break;
    }
  } else {
    categoryThemeOverride = organization.checklists_custom_theme;
  }
  if (categoryThemeOverride) {
    const retval = themes.find((theme) => theme.uuid === categoryThemeOverride);
    if (retval) return retval;
  }

  return themes.find((theme) => theme.default);
};
export const getCurrentTheme = (
  nudge: IChecklist | INudgeType,
  themes: IThemeType[],
  organization: IOrganizationType,
): IThemeType | undefined => {
  if (nudge.custom_theme) {
    const retval = themes.find((theme) => theme.uuid === nudge.custom_theme);
    if (retval) return retval;
  }

  return getDefaultTheme(nudge, themes, organization);
};

export const getThemeEditingPathForNudge = (nudge: IChecklist | INudgeType, theme: IThemeType): string => {
  let typeRoute = `${THEME_ROUTE}/${theme.slug}/base`;

  if (isNudge(nudge)) {
    typeRoute += `?widgetType=nudge&widgetId=${nudge.id}`;
  } else {
    typeRoute += `?widgetType=checklist&widgetId=${nudge.id}`;
  }
  return typeRoute;
};

export const NudgeThemeEditFloatingDropdown = ({
  nudge,
  currentTheme,
  onThemeSelect,
}: {
  nudge: IChecklist | INudgeType;
  currentTheme: IThemeType;
  onThemeSelect: (themeUUID: string) => void;
}) => {
  const { themes, organization } = useAppContext();
  const themesToShow = themes.filter((theme) => !theme.archived && !theme.name.startsWith('commandai__'));
  const history = useHistory();

  const defaultTheme = getDefaultTheme(nudge, themes, organization);

  const hasCustomThemeOverrides = currentTheme.name.startsWith('commandai__');

  if (!currentTheme) return null;

  return (
    <div
      style={{
        zIndex: Z.Z_EDITOR,
        position: 'absolute',
        top: 16,
        ...(!history.location.pathname.includes('/customize-theme') && { right: 132 }),
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        width: 'fit-content',
      }}
    >
      <CmdDropdown.Menu>
        <CmdDropdown.Trigger
          asSplitButton
          onButtonClick={() => {
            if (hasCustomThemeOverrides) {
              history.replace(history.location.pathname + '/customize-theme');
            } else {
              history.push(getThemeEditingPathForNudge(nudge, currentTheme));
            }
          }}
        >
          <PaintPour />
          {hasCustomThemeOverrides ? 'Customize' : 'Edit Theme'}
        </CmdDropdown.Trigger>
        <CmdDropdown.Content side="bottom" style={{ width: '247' }}>
          <CmdDropdown.Group key={'custom-styles'}>
            <CmdDropdown.Label className="text-contentBase text-sm">
              {'Apply custom styles to this nudge'}
            </CmdDropdown.Label>
            <CmdDropdown.Item
              onClick={() => {
                history.replace(history.location.pathname + '/customize-theme');
              }}
            >
              {'Custom...'}
              {hasCustomThemeOverrides && <Check style={{ marginLeft: 'auto' }} />}
            </CmdDropdown.Item>
          </CmdDropdown.Group>
          <CmdDropdown.Separator />
          <CmdDropdown.Group key={'theme'}>
            <CmdDropdown.Label className="text-contentBase text-sm">{'Use a theme'}</CmdDropdown.Label>
            {themesToShow.map((theme) => (
              <CmdDropdown.Item
                key={theme.uuid}
                onClick={() => {
                  if (theme.uuid) {
                    onThemeSelect(theme.uuid);
                  }
                }}
              >
                {theme.name} {hasUnpublishedChanges(theme) && <CmdStatusDot variant="warning" />}{' '}
                {theme.uuid === defaultTheme?.uuid && <CmdTag>Default</CmdTag>}
                {currentTheme?.uuid === theme.uuid && !hasCustomThemeOverrides && <Check />}
              </CmdDropdown.Item>
            ))}
          </CmdDropdown.Group>
        </CmdDropdown.Content>
      </CmdDropdown.Menu>
    </div>
  );
};
