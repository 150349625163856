import React from 'react';
import styled from '@emotion/styled';
import { Play } from '@commandbar/design-system/icons/react';
import { useAppContext } from 'editor/src/AppStateContext';
import { StudioPreviewSetupInstructions } from './StudioPreviewSetupIntructions';
import { useIsChromeExtensionInsalled } from '../../../hooks';
import { CmdButton } from '@commandbar/design-system/cmd';

const Container = styled.div`
  position: relative;
`;

interface IPreviewButton {
  /**
   * If in studio and enableClickInStudio is off, we'll show extension installation instructions when clicked
   * Else: we'll call onClick when clicked
   */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  enableClickInStudio?: boolean;
}

export const PreviewButton = ({ onClick, enableClickInStudio }: IPreviewButton) => {
  const { isStudio } = useAppContext();
  const [showPreview, setShowPreview] = React.useState(false);
  const { isChromeExtensionInstalled } = useIsChromeExtensionInsalled();

  if (isStudio && !enableClickInStudio) {
    return (
      <Container>
        <CmdButton onClick={() => setShowPreview(!showPreview)} icon={<Play />} />
        {showPreview && isStudio && (
          <StudioPreviewSetupInstructions
            isChromeExtensionInstalled={isChromeExtensionInstalled}
            onClose={() => setShowPreview(false)}
          />
        )}
      </Container>
    );
  }

  return <CmdButton onClick={onClick} icon={<Play />} />;
};
