import { Editor, Element, Node, type Path, Point, Range, Text, Transforms } from 'slate';

import { ElementType } from '../constants';
import type { ListElement, ListItemElement } from '../types';

// Maximum indent level for nested lists. Currently set to 0 due to snarkdown limitations.
// Can be increased if a different Markdown parser is used in the future.
const MAX_INDENT_LEVEL = 0;

const isList = (node: Node): node is ListElement => Element.isElementType<ListElement>(node, ElementType.List);

const isListItem = (node: Node): node is ListItemElement =>
  Element.isElementType<ListItemElement>(node, ElementType.ListItem);

/**
 * Lifts list item nodes out of their parent list.
 * This is used when removing list formatting.
 */
const unwrapListItems = (editor: Editor) => {
  const [parentListMatch] = Editor.nodes<ListElement>(editor, {
    match: (node) => isList(node),
  });

  if (!parentListMatch) return;

  // Lift list items up one level, effectively unwrapping them from their parent list
  Transforms.liftNodes(editor, { match: (node) => isListItem(node) });
};

const unwrapAndConvertListItems = (editor: Editor) => {
  unwrapListItems(editor);

  const [parentListMatch] = Editor.nodes<ListElement>(editor, {
    match: (node) => isList(node),
  });

  if (parentListMatch) return;

  Transforms.setNodes(editor, { type: ElementType.Paragraph }, { match: (node) => isListItem(node) });
};

// Calculate depth based on the number of list ancestors
const getListDepth = (editor: Editor, path: Path): number =>
  path.filter((_, index) => {
    const ancestor = Node.get(editor, path.slice(0, index));
    return isList(ancestor);
  }).length;

/**
 * Enhances the Slate editor with list handling capabilities.
 * This plugin overrides insertBreak and deleteBackward to handle list-specific behaviors.
 * @param editor The Slate Editor instance
 * @returns The modified editor with list handling capabilities
 */
export const withLists = (editor: Editor) => {
  const { insertBreak, deleteBackward, normalizeNode } = editor;

  /**
   * Handles backspace and enter key events in lists.
   * If at the start of a list item, it lifts the item out of the list.
   */
  const handleExitFromEmptyListItem = (originalBehavior: typeof insertBreak) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      // Match an empty list item at the current selection
      const [listItemMatch] = Editor.nodes<ListItemElement>(editor, {
        match: (node) => isListItem(node),
      });

      if (listItemMatch) {
        const [, path] = listItemMatch;
        const start = Editor.start(editor, path);

        if (Point.equals(selection.anchor, start)) {
          unwrapAndConvertListItems(editor);
          return;
        }
      }
    }

    originalBehavior();
  };

  // Override insertBreak to handle enter key in lists
  editor.insertBreak = () => {
    handleExitFromEmptyListItem(insertBreak);
  };

  // Override deleteBackward to handle backspace key in lists
  editor.deleteBackward = (unit) => {
    handleExitFromEmptyListItem(() => deleteBackward(unit));
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (isListItem(node)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Text.isText(child) && child.text.includes('\n')) {
          Transforms.select(editor, { path: childPath, offset: child.text.indexOf('\n') });
          // Split the nodes at the splitPoint, up to the list item level
          Transforms.splitNodes(editor);
          Transforms.delete(editor, {
            unit: 'character',
          });
          Transforms.liftNodes(editor);
          Transforms.setNodes(editor, { type: ElementType.Paragraph });

          return;
        }
      }
    }

    if (isList(node)) {
      // Ensure all children are list items
      for (const [child, childPath] of Node.children(editor, path)) {
        if (!isListItem(child)) {
          // Convert invalid child to a list item
          Transforms.setNodes(editor, { type: ElementType.ListItem }, { at: childPath });
          return;
        }
      }

      // Merge with the next sibling if it's a list of the same type
      const nextSibling = Editor.next(editor, { at: path });
      if (nextSibling) {
        const [nextNode, nextPath] = nextSibling;
        if (isList(nextNode) && nextNode.ordered === node.ordered) {
          // Move all children from the next list into the current list
          Transforms.mergeNodes(editor, { at: nextPath });
          return;
        }
      }

      // Merge with the previous sibling if it's a list of the same type
      const prevSibling = Editor.previous(editor, { at: path });
      if (prevSibling) {
        const [prevNode] = prevSibling;
        if (isList(prevNode) && prevNode.ordered === node.ordered) {
          // Move all children from the current list into the previous list
          Transforms.mergeNodes(editor, { at: path });
          return;
        }
      }
    }

    normalizeNode(entry);
  };

  return editor;
};

/**
 * Decreases the indentation level of the current list item.
 * If the item is at the top level, it's converted to a paragraph.
 */
export const decreaseIndentation = (editor: Editor) => {
  const { selection } = editor;

  if (!(selection && Range.isCollapsed(selection))) return;

  const [listItemMatch] = Editor.nodes<ListItemElement>(editor, {
    match: (node) => isListItem(node),
  });

  if (!listItemMatch) return;

  unwrapAndConvertListItems(editor);
};

/**
 * Increases the indentation level of the current list item.
 * This is limited by the MAX_INDENT_LEVEL constant, which is currently 0,
 * so indentation is effectively disabled until MAX_INDENT_LEVEL is increased.
 */
export const increaseIndentation = (editor: Editor) => {
  const { selection } = editor;

  if (!(selection && Range.isCollapsed(selection))) return;

  const [listItemMatch] = Editor.nodes<ListItemElement>(editor, {
    match: (node) => isListItem(node),
  });

  if (!listItemMatch) return;

  // Find the lowest list ancestor to determine current indentation level
  const [parentListMatch] = Editor.nodes<ListElement>(editor, {
    mode: 'lowest',
    match: (node) => isList(node),
  });

  const [firstListMatch, firstListPath] = parentListMatch;

  // Check if current list depth is within MAX_INDENT_LEVEL
  if (!(getListDepth(editor, firstListPath) <= MAX_INDENT_LEVEL)) return;

  // Wrap the current item in a new list of the same type
  Transforms.wrapNodes(editor, {
    ...firstListMatch,
    type: firstListMatch.type,
    children: [],
  });
};
