// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const UnorderedList = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 17 16" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.5 8h-8m8-4h-8m8 8h-8M3.8333 8A.6667.6667 0 1 1 2.5 8a.6667.6667 0 0 1 1.3333 0Zm0-4A.6667.6667 0 1 1 2.5 4a.6667.6667 0 0 1 1.3333 0Zm0 8A.6667.6667 0 1 1 2.5 12a.6667.6667 0 0 1 1.3333 0Z"
    />
  </svg>
);
export default UnorderedList;
