/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import ButtonWithReset from './ButtonWithReset';

const styles: CSSObject = {
  display: 'flex',
  width: '100%',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 'var(--form-control-gap)',
  color: 'var(--button-primary-content)',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--button-primary-border-color)',
  background: 'var(--button-primary-background)',
  flex: '1 1 0',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  userSelect: 'none',

  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-semibold)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  '&:hover': {
    borderColor: 'var(--button-primary-border-color-hover)',
    background: 'var(--button-primary-background-hover)',
    color: 'var(--button-primary-content-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
    background: 'var(--button-primary-background-hover)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    borderColor: 'var(--button-primary-border-color)',
    background: 'var(--button-primary-background)',
    color: 'var(--button-primary-content)',
  },
};

const slug = 'primaryButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  textWrap?: boolean;
};

const StyledPrimaryButton = ({
  themeV2,
  prefixIcon,
  suffixIcon,
  textWrap,
  children,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset
      css={[overrides, css(styles)]}
      {...props}
      style={{ ...(textWrap && { height: 'unset', width: 'fit-content', whiteSpace: 'unset' }) }}
    >
      {prefixIcon}
      <span
        style={{
          ...(!textWrap && {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }),
        }}
      >
        {children}
      </span>
      {suffixIcon}
    </ButtonWithReset>
  );
};

StyledPrimaryButton.styles = styles;
StyledPrimaryButton.slug = slug;
StyledPrimaryButton.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledPrimaryButton themeV2={{ themeV2: themeV2, mode: mode }}>Button</StyledPrimaryButton>
);

export default StyledPrimaryButton;
