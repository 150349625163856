/**
 * This module provides serialization and deserialization functions for converting
 * between Slate Rich Text Editor data and Markdown. It handles custom elements
 * like mentions and preserves formatting across conversions.
 *
 * markdownToSlate and slateToMarkdown should not be used independently. They are written
 * to complement each other and ensure that data is consistent in a round trip.
 */

import isEqual from 'lodash/isEqual';
import type { Root, Text } from 'mdast';
import markdownParser from 'remark-parse';
import {
  type RemarkToSlateOptions,
  type SlateToRemarkOptions,
  remarkToSlate,
  slateToRemark,
} from 'remark-slate-transformer';
import stringify from 'remark-stringify';
import { type Plugin, unified } from 'unified';
import { visit } from 'unist-util-visit';
import { visitParents } from 'unist-util-visit-parents';

import { ALL_MARKS, EMPTY_VALUE, ElementType, Mark } from '../constants';
import type { Formatting, MarkdownString, MentionElement, ParsedMarkdown, Slate } from '../types';

// Convert 'text' nodes to 'html' nodes to prevent unwanted escaping during stringification.
// This is necessary because the stringify plugin escapes certain characters in 'text' nodes,
// which can interfere with our custom syntax (e.g., mentions).
const converTextNodesToHtmlNodes: Plugin<[], Root> = () => (tree) => {
  visit(tree, ['text'], (node) => {
    node.type = ElementType.Html;
  });
};

// Recursively apply formatting marks (bold, italic) to a node.
// This is used to preserve formatting when serializing custom elements like mentions.
const applyFormattingMarks = (
  node: { type: string; value?: string; children?: unknown[] },
  marks: Mark[],
): { type: string; value?: string; children?: unknown[] } => {
  if (marks.length === 0) {
    return node;
  }

  const [currentMark, ...remainingMarks] = marks;

  switch (currentMark) {
    case Mark.Bold:
      return applyFormattingMarks(
        {
          type: Mark.Bold,
          children: [node],
        },
        remainingMarks,
      );
    case Mark.Italic:
      return applyFormattingMarks(
        {
          type: Mark.Italic,
          children: [node],
        },
        remainingMarks,
      );
    default:
      return applyFormattingMarks(node, remainingMarks);
  }
};

// Custom serializer for mention elements.
// Handles applied marks (bold, italic) and special formatting for code.
// Outputs mentions in the format {{mention_value}} or `{{mention_value}}` for code.
const serializeMention: NonNullable<SlateToRemarkOptions['overrides']>[
  | ElementType.MentionsSelect
  | ElementType.Mention] = (mentionNode) => {
  if (
    mentionNode &&
    typeof mentionNode === 'object' &&
    'value' in mentionNode &&
    'children' in mentionNode &&
    Array.isArray(mentionNode.children)
  ) {
    const [firstChild] = mentionNode.children;
    const appliedMarks = ALL_MARKS.filter((mark): mark is Mark => mark in firstChild);

    return applyFormattingMarks(
      {
        type: ElementType.Html,
        // Code is a special format that does not need to be a separate node.
        // We can simply wrap the value in ticks, and it will render correctly.
        value: appliedMarks.includes(Mark.Code) ? `\`{{${mentionNode.value}}}\`` : `{{${mentionNode.value}}}`,
      },
      appliedMarks,
    );
  }

  return undefined;
};

const serializeHardBreaks: NonNullable<SlateToRemarkOptions['overrides']>[ElementType.Paragraph] = (node, next) => {
  if (node && typeof node === 'object' && 'children' in node && Array.isArray(node.children)) {
    if (
      node.children.every(
        (child: unknown) => child && typeof child === 'object' && 'text' in child && child.text === EMPTY_VALUE,
      )
    ) {
      return {
        ...node,
        children: next(node.children.map((child) => ({ ...child, text: '<br>' }))),
      };
    }

    return {
      ...node,
      children: next(node.children),
    };
  }

  return undefined;
};

// Wrap list item text nodes in paragraphs to ensure correct formatting during stringification.
// This is necessary because Slate and remark have different expectations for list item structure.
// We'll unwrap these during deserialization to maintain compatibility with Slate's structure.
const wrapListItem: NonNullable<SlateToRemarkOptions['overrides']>[ElementType.ListItem] = (node, next) =>
  node && typeof node === 'object' && 'children' in node && Array.isArray(node.children)
    ? {
        ...node,
        children: [
          {
            type: ElementType.Paragraph,
            children: next(node.children),
          },
        ],
      }
    : undefined;

export const slateToMarkdown = (slate: Slate): ParsedMarkdown => {
  const processor = unified()
    /* HACK: "text" nodes are escaped during stringification, and there's no way to configure this.
     * So we change "text" nodes into "html" nodes to bypass the escaping.
     * "text" and "html" nodes are stringified to the same markdown, just with or without escaping
     * certain characters like '['.
     */
    .use(converTextNodesToHtmlNodes)
    // .use(hardBreaks)
    .use(stringify, {
      // Configure the markdown stringifier options
      // Use underscore for emphasis to ensure compatibility with snarkdown HTML renderer
      // See: https://github.com/developit/snarkdown/issues/113
      emphasis: '_',
      bullet: '-',
    });
  const markdownAst = processor.runSync(
    slateToRemark(slate, {
      overrides: {
        [ElementType.Paragraph]: serializeHardBreaks,
        [ElementType.ListItem]: wrapListItem,
        // Mentions are not part of the markdown spec, so we need to write our own serialization.
        [ElementType.MentionsSelect]: serializeMention,
        [ElementType.Mention]: serializeMention,
      },
    }),
  ) as Parameters<typeof processor.stringify>[0];
  const markdown = processor
    .stringify(markdownAst)
    // Post-process the Markdown to ensure mark spacing complies with CommonMark
    .replace(/\*\*_ /g, '** _');

  // An empty editor will have a single paragraph node and hard break serializer will add the break
  // tag. We should treat a single blank paragraph as an empty editor.
  return (markdown === '<br>\n' ? EMPTY_VALUE : markdown) as ParsedMarkdown;
};

const deserializeHardBreaks: NonNullable<SlateToRemarkOptions['overrides']>[ElementType.Html] = (node) =>
  node && typeof node === 'object' && 'value' in node && node.value === '<br>'
    ? {
        type: ElementType.Paragraph,
        children: [{ text: EMPTY_VALUE }],
      }
    : undefined;

// Remove paragraph wrappers from list items during deserialization.
// This aligns the structure with Slate's expectations for list items.
const unwrapListItem: NonNullable<RemarkToSlateOptions['overrides']>[ElementType.ListItem] = (listItemNode, next) => {
  const children = next(listItemNode.children);
  const [firstChild] = children;
  if (children.length === 1 && firstChild.type === ElementType.Paragraph) {
    return {
      ...listItemNode,
      children: firstChild.children,
    };
  }

  return {
    ...listItemNode,
    children,
  };
};

// Regular expression to match mention syntax: {{mention_value}}
const MENTION_PATTERN = /\{\{(.*?)\}\}/g;

interface MentionNode {
  type: ElementType.Mention;
  value: string;
  formatting: Formatting;
  children: Text[];
}

// Parse mentions in text nodes and replace them with structured mention nodes.
// This plugin preserves formatting (bold, italic) applied to mentions.
const convertMentionsToStructuredNodes: Plugin<[], Root> = () => (tree) => {
  visitParents(tree, (node, ancestors) => {
    if (!('value' in node)) return;

    const { value } = node;
    let match = MENTION_PATTERN.exec(value);
    if (!match) return;

    let lastIndex = 0;
    const parsedNodes: (Text | MentionNode)[] = [];

    while (match) {
      const [fullMatch, mentionValue] = match;
      const matchStart = match.index;
      const matchEnd = matchStart + fullMatch.length;

      // Text before the match
      if (matchStart > lastIndex) {
        parsedNodes.push({
          type: 'text',
          value: value.slice(lastIndex, matchStart),
        });
      }

      // Collect formatting from ancestors
      const formatting = ancestors.reduce<Formatting>((prev, { type }) => {
        switch (type) {
          case Mark.Bold:
            return { ...prev, [Mark.Bold]: true };
          case Mark.Italic:
            return { ...prev, [Mark.Italic]: true };
          default:
            return prev;
        }
      }, {});

      parsedNodes.push({
        type: ElementType.Mention,
        value: mentionValue,
        formatting,
        children: [{ type: 'text', value: EMPTY_VALUE }],
      });

      lastIndex = matchEnd;
      match = MENTION_PATTERN.exec(value);
    }

    // Remaining text after last match
    if (lastIndex < value.length) {
      parsedNodes.push({
        type: 'text',
        value: value.slice(lastIndex),
      });
    }

    // Replace the current node with new nodes
    const parent = ancestors[ancestors.length - 1];
    const index = parent.children.findIndex((child) => isEqual(child, node));
    // @ts-expect-error: Mention is not a known node type
    parent.children.splice(index, 1, ...parsedNodes);
  });
};

const mergeSplitHeadings: Plugin<[], Root> = () => (tree) => {
  visit(tree, (node, index, parent) => {
    if (
      typeof index === 'undefined' ||
      node.type !== ElementType.Heading ||
      node.children.length > 0 ||
      !parent ||
      index > parent?.children.length
    )
      return;

    const nextNode = parent.children[index + 1];
    if (nextNode && nextNode.type === ElementType.Paragraph) {
      // Merge the paragraph content into the heading
      node.children = nextNode.children;
      // Remove the paragraph node
      parent.children.splice(index + 1, 1);
      return index;
    }
  });
};

// Custom deserializer for mention nodes.
// Reconstructs the mention element with its value and any applied formatting.
const deserializeMention = (node: unknown): MentionElement | undefined => {
  if (node && typeof node === 'object' && 'value' in node && typeof node.value === 'string') {
    return {
      type: ElementType.Mention,
      value: node.value,
      children: [
        {
          text: EMPTY_VALUE,
          ...('formatting' in node && typeof node.formatting === 'object' ? node.formatting : {}),
        },
      ],
    };
  }

  return undefined;
};

export const markdownToSlate = (markdown: MarkdownString): Slate => {
  const markdownProcessor = unified()
    .use(markdownParser)
    .use(convertMentionsToStructuredNodes)
    .use(mergeSplitHeadings)
    .use(remarkToSlate, {
      overrides: {
        [ElementType.Html]: deserializeHardBreaks,
        [ElementType.ListItem]: unwrapListItem,
        [ElementType.Mention]: deserializeMention,
      },
    });
  return markdownProcessor.processSync(markdown).result as Slate;
};
