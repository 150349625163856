export const EMPTY_VALUE = '';

export enum ElementType {
  Paragraph = 'paragraph',
  Heading = 'heading',
  Code = 'code',
  Link = 'link',
  BlockQuote = 'blockquote',
  List = 'list',
  OrderedList = 'orderedList',
  UnorderedList = 'unorderedList',
  ListItem = 'listItem',
  HorizontalRule = 'thematicBreak',
  Image = 'image',
  Mention = 'mention',
  MentionsSelect = 'mentionSelect',
  Html = 'html',
}

export enum Mark {
  Bold = 'strong',
  Italic = 'emphasis',
  Code = 'inlineCode',
}

export const ALL_MARKS = Object.values(Mark);

export enum HeadingDepth {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

export const IGNORE_OUTSIDE_CLICK_ATTR = 'ignore-outside-click';

export const MENTION_TRIGGER = '@';
