/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import { CSSObject, keyframes, jsx, css } from '@emotion/core';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import useComponentOverrides from '../../helpers/useComponentOverrides';

// Define the keyframes for the pulsing effect
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%, 100% {
    transform: scale(4);
    opacity: 0;
  }
`;

const styles: CSSObject = {
  position: 'relative',
  borderRadius: 'var(--layout-radius-circle)',
  width: 'var(--height-small)',
  height: 'var(--height-small)',
  backgroundColor: 'var(--base-accent)',
  pointerEvents: 'all',
  zIndex: Z.Z_NUDGE,
};

const Beacon = styled.div`
  border-radius: var(--layout-radius-circle);
  width: var(--height-small);
  height: var(--height-small);
  background-color: var(--base-accent);
  position: absolute;
  animation: ${pulseAnimation} 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
`;

const slug = 'beacon';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledNudgeBeacon = ({ themeV2, ...props }: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <div css={[overrides, css(styles)]} {...props}>
      <Beacon />
    </div>
  );
};

StyledNudgeBeacon.styles = styles;
StyledNudgeBeacon.slug = slug;
StyledNudgeBeacon.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledNudgeBeacon themeV2={{ themeV2: themeV2, mode: mode }}>Button</StyledNudgeBeacon>
);

export default StyledNudgeBeacon;
