import React from 'react';
import styled from '@emotion/styled';
import { SortableHandle } from 'react-sortable-hoc';

import { getIcon } from '../../iconHelper';
import { Heading, Collapse as SharedCollapse } from '@commandbar/design-system/components/antd';
import { IArgumentRow } from '../../ArgumentList';
import {
  ArgumentOptionAllowCreate,
  ArgumentOptionLabel,
  ArgumentOptionMultiSelect,
  ArgumentOptionTextInputType,
  ArgumentOptionPreselectedKey,
  ArgumentOptionType,
  ArgumentOptionTypeValue,
  ArgumentOptionAutoChoose,
  ArgumentOptionVideoTypeValue,
  ArgumentOptionHtmlTypeValue,
} from './ArgumentDetailOptions';
import { IArgumentMap, IRecordSettingsByContextKey } from '@commandbar/internal/middleware/types';
import { ArgumentName } from './ArgumentName';
import { ArgumentPrompt } from './ArgumentPrompt';
import { currentType, IOptionType } from '../../argumentUtils';
import { CB_COLORS } from '@commandbar/design-system/components/antd';
import { DragIcon, PanelHeader, PanelLabel } from '../../../../../components/styled';
import { Trash04 } from '@commandbar/design-system/icons/react';
import { CmdButton } from '@commandbar/design-system/cmd';
import Caret from '@commandbar/design-system/icons/react/Caret';

const { Panel: AntdPanel } = SharedCollapse;

const Collapse = styled(SharedCollapse)`
  z-index: 2147483648;
  &.ant-collapse {
    background-color: transparent;
  }
`;

const GhostCollapse = styled(SharedCollapse)`
  z-index: 2147483648;

  & .ant-collapse-header {
    border: none;
    box-shadow: none !important;
  }

  & .ant-collapse-content {
    padding: 0px;
  }

  & .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

const Panel = styled(AntdPanel)`
  margin-bottom: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &.ant-collapse-item-active {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  }

  &.ant-collapse-item-active .ant-collapse-header {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  }

  & .ant-collapse-content {
    padding: 24px;
  }
`;

const KeyTag = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  color: #ffffff;
  background: #000000a6;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 16px;
`;

const IconContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: ${CB_COLORS.primary};
  font-size: 14px;
`;

interface IProps {
  argument: IArgumentRow;
  arguments: IArgumentMap;
  recordSettings: IRecordSettingsByContextKey;
  activeArgName?: string;
  updateName: (oldName: string, newName: string) => void;
  onUpdate: (arg: string, val: any, replaceValue?: boolean) => void;
  onDelete: (argName: string) => void;
}

const DragHandle = SortableHandle(() => <DragIcon />);

export const CollapsibleArgumentEditor = (props: IProps) => {
  const { argument, activeArgName, arguments: args, updateName, onUpdate, onDelete, recordSettings } = props;
  const { name, ...argumentData } = argument;
  const { label } = argumentData;
  const icon = getIcon(argument);
  const type: IOptionType | undefined = name ? currentType(name, args) : undefined;

  const header = (
    <PanelHeader style={{ padding: '0 42px 0 16px' }}>
      <DragHandle />
      <IconContainer>{icon?.icon}</IconContainer>
      <PanelLabel>{label || name}</PanelLabel>
      {name && <KeyTag>{name}</KeyTag>}

      <CmdButton
        icon={<Trash04 />}
        variant="ghost"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(name);
        }}
      />
    </PanelHeader>
  );

  return (
    <Collapse
      ghost
      bordered={false}
      expandIconPosition="end"
      expandIcon={({ isActive }) => <Caret rotate={isActive ? 180 : 0} />}
      defaultActiveKey={activeArgName === name ? 'open' : undefined}
    >
      <Panel header={header} key="open">
        <ArgumentName argumentName={name} arguments={args} updateName={updateName} />

        {!['video', 'html'].includes(type?.key || '') && (
          <ArgumentPrompt argumentName={name} data={argumentData} onUpdate={onUpdate} />
        )}
        <ArgumentOptionType data={argumentData} argumentName={name} type={type} onUpdate={onUpdate} arguments={args} />
        <ArgumentOptionTypeValue
          data={argumentData}
          argumentName={name}
          type={type}
          onUpdate={onUpdate}
          arguments={args}
        />
        <ArgumentOptionVideoTypeValue
          data={argumentData}
          argumentName={name}
          type={type}
          onUpdate={onUpdate}
          arguments={args}
        />
        <ArgumentOptionHtmlTypeValue
          data={argumentData}
          argumentName={name}
          type={type}
          onUpdate={onUpdate}
          arguments={args}
        />

        {!['video', 'html'].includes(type?.key || '') ? (
          <GhostCollapse defaultActiveKey={['1']} ghost bordered={false} expandIconPosition="end">
            <AntdPanel
              header={
                <div style={{ margin: '0px 16px', flex: 1 }}>
                  <Heading text="Optional" />
                </div>
              }
              key="1"
            >
              <ArgumentOptionMultiSelect
                data={argumentData}
                argumentName={name}
                type={type}
                onUpdate={onUpdate}
                arguments={args}
              />

              <ArgumentOptionTextInputType
                data={argumentData}
                argumentName={name}
                type={type}
                onUpdate={onUpdate}
                arguments={args}
              />

              <ArgumentOptionPreselectedKey
                data={argumentData}
                argumentName={name}
                type={type}
                onUpdate={onUpdate}
                arguments={args}
              />

              <ArgumentOptionLabel
                data={argumentData}
                argumentName={name}
                type={type}
                onUpdate={onUpdate}
                arguments={args}
                recordSettings={recordSettings}
              />

              <ArgumentOptionAllowCreate
                data={argumentData}
                argumentName={name}
                type={type}
                onUpdate={onUpdate}
                arguments={args}
              />

              {argumentData.input_type === undefined && (
                <ArgumentOptionAutoChoose
                  data={argumentData}
                  argumentName={name}
                  type={type}
                  onUpdate={onUpdate}
                  arguments={args}
                />
              )}
            </AntdPanel>
          </GhostCollapse>
        ) : null}
      </Panel>
    </Collapse>
  );
};
