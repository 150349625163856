import React from 'react';
import type { RenderElementProps } from 'slate-react';

import { CmdTypography } from '../..';
import { HeadingDepth } from '../constants';
import type { HeadingElement } from '../types';

interface HeadingProps extends RenderElementProps {
  element: HeadingElement;
}

export const Heading = ({ attributes, children, element }: HeadingProps) => {
  switch (element.depth) {
    case HeadingDepth.One:
      return <CmdTypography.H1 {...attributes}>{children}</CmdTypography.H1>;
    case HeadingDepth.Two:
      return (
        <CmdTypography.H2 className="leading-7" {...attributes}>
          {children}
        </CmdTypography.H2>
      );
    case HeadingDepth.Three:
      return (
        <CmdTypography.H3 className="leading-7" {...attributes}>
          {children}
        </CmdTypography.H3>
      );
    default:
      return <CmdTypography.Paragraphy {...attributes}>{children}</CmdTypography.Paragraphy>;
  }
};
