import { DropdownMenu, Modal, Spin } from '@commandbar/design-system/components/antd';
import { ReactComponent as Loading } from '../../img/loading.svg';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';
import { PlusSquare, Pin02, Trash04 } from '@commandbar/design-system/icons/react';
import { DEFAULT_THEME } from '@commandbar/internal/client/themesV2/defaults';

import type { IThemeType } from '@cb/types/entities/theme';

import { THEME_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { useHistory } from 'react-router';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';

export const ColorSwatch = ({
  overlap,
  large,
  color,
  opacity,
  onClick,
}: {
  overlap?: boolean;
  large?: boolean;
  color: string | number | undefined;
  opacity?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  return opacity !== undefined && opacity !== 100 ? (
    <OpacitySwatch color={color?.toString()} opacity={opacity} onClick={onClick} />
  ) : (
    <div
      onMouseDown={onClick}
      style={{
        ...(overlap && { marginLeft: '-4px' }),
        borderRadius: large ? '6px' : '4px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        width: large ? '24px' : '16px',
        height: large ? '24px' : '16px',
        background: color,
      }}
    />
  );
};

export const OpacitySwatch = ({
  color,
  opacity,
  onClick,
}: {
  color: string | number | undefined;
  opacity: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div
      onMouseDown={onClick}
      style={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '4px',
        width: '16px',
        height: '16px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          width: '8px',
          height: '16px',
          background: color?.toString(),
          borderRadius: '4px 0 0 4px',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          borderRight: 'none',
        }}
      />
      <div
        style={{
          position: 'absolute',
          right: '0',
          top: '0',
          width: '8px',
          height: '16px',
          background:
            'conic-gradient(lightgray 90deg, rgba(255, 255, 255, 0.20) 90deg 180deg, lightgray 180deg 270deg, rgba(255, 255, 255, 0.20) 270deg)',
          backgroundRepeat: 'repeat',
          backgroundPosition: 'top left',
          backgroundSize: '8px 8px',
        }}
      />
      <div
        style={{
          position: 'absolute',
          right: '0',
          top: '0',
          width: '8px',
          height: '16px',
          background: color,
          opacity: opacity / 100,
          borderRadius: '0 4px 4px 0',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          borderLeft: 'none',
        }}
      />
    </div>
  );
};

export const LoadingSave = () => <Spin style={{ display: 'flex' }} spinning={true} indicator={<LoadingSpinner />} />;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled(Loading)`
  animation: ${spin} 1s linear infinite;
`;

export const ThemeDropdown = ({ theme, themes }: { theme: IThemeType; themes: IThemeType[] }) => {
  const history = useHistory();
  const { user } = useAuth();

  const {
    dispatch: {
      themes: { save, delete: deleteTheme, reload },
    },
  } = useAppContext();

  const makeDefault = async (theme: IThemeType) => {
    await save({ id: theme.slug, slug: theme.slug, uuid: theme.uuid, default: true });
    await reload();
  };

  const duplicateTheme = async (origin: IThemeType) => {
    const getNameFromSlug = (slug: string) => {
      const parts = slug.split('-');
      for (let i = 0; i < parts.length - 1; i++) {
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
      }
      const lastPart = '(' + parts.pop() + ')';
      return parts.join(' ') + ' ' + lastPart;
    };

    const getUniqueSlug = (
      origin: IThemeType,
      existingThemes: IThemeType[] | undefined,
    ): { slug: string; name: string } => {
      const slugTemplate = `${origin.slug}`;

      const parts = slugTemplate.split('-');
      const endPart = parts.pop();
      const copyNumber = endPart ? parseInt(endPart) : 0;
      let suffix = isNaN(copyNumber) ? 0 : copyNumber;
      let slug = slugTemplate;

      while (!!existingThemes && suffix <= existingThemes.length) {
        const finalSlug = slug;
        if (!existingThemes?.some((theme) => theme.slug === finalSlug))
          return { slug: finalSlug, name: getNameFromSlug(finalSlug) };
        suffix++;
        slug =
          endPart && copyNumber > 0
            ? `${slugTemplate.slice(0, slugTemplate.length - endPart.length)}${suffix}`
            : `${slugTemplate}-${suffix}`;
      }

      throw new Error('Error duplicating theme');
    };

    const { slug, name } = getUniqueSlug(origin, themes);
    const newTheme = await save({
      slug: slug,
      name: name,
      themeV2: origin.themeV2 ?? DEFAULT_THEME,
      themeV2_draft: origin.themeV2_draft ?? DEFAULT_THEME,
    });

    if (newTheme) {
      history.push(`${THEME_ROUTE}/${newTheme.id}/base`);
    }
  };

  const isAdmin = hasRequiredRole(user, 'editor');

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu
        vertical
        keyName="options"
        items={[
          {
            name: 'Duplicate',
            icon: <PlusSquare />,
            onClick: async () => await duplicateTheme(theme),
          },
          ...(theme.default
            ? []
            : [
                {
                  name: 'Make default',
                  icon: <Pin02 />,
                  onClick: () => makeDefault(theme),
                  disabled: !isAdmin,
                },
                {
                  name: 'Delete',
                  icon: <Trash04 />,
                  disabled: !isAdmin,
                  onClick: () =>
                    Modal.confirm({
                      title: 'Are you sure you want to delete this theme? This cannot be undone.',
                      async onOk() {
                        await deleteTheme(theme.slug);
                      },
                    }),
                },
              ]),
        ]}
      />
    </div>
  );
};
