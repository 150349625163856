/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import React from 'react';

import { ICommandCategoryType, IEditorCommandTypeLite } from '@commandbar/internal/middleware/types';

import {
  DropdownMenu,
  Tooltip,
  Badge,
  Space,
  Modal,
  IDropdownMenuRow,
} from '@commandbar/design-system/components/antd';

import CommandGroup from '../CommandGroup';
import EmptyCategoryActionCard from './EmptyCategoryActionCard';
import { useAppContext } from 'editor/src/AppStateContext';
import SessionSettingsProvider from 'editor/src/store/SessionSettingsProvider';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { AlertCircle, Copy06, Settings03, Trash04 } from '@commandbar/design-system/icons/react';

const { confirm } = Modal;

const CategoryGroup = (props: {
  category: ICommandCategoryType | undefined;
  commands: IEditorCommandTypeLite[];
  searchText: string;
  openSettings: (() => void) | null;
}) => {
  const { dispatch, categories } = useAppContext();
  const { commands } = props;

  const onDelete = () => {
    if (props.category === undefined) return;

    confirm({
      title: 'Are you sure you want to delete this category?',
      icon: <AlertCircle className="anticon anticon-exclamation-circle" height={24} width={24} />,
      content: `All commands in this group will also be deleted.`,
      onOk() {
        if (props.category?.id) {
          dispatch.categories.delete(props.category.id);
        }
      },
    });
  };

  const categoryName = props.category?.name || 'Uncategorized';

  const menuItems: IDropdownMenuRow[] = [
    {
      name: 'Copy ID',
      icon: <Copy06 />,
      onClick: () => {
        if (props.category?.id) {
          navigator.clipboard.writeText(`${props.category.id}`).then(function () {
            cmdToast.success('ID copied to clipboard.');
          });
        }
      },
    },
    { name: 'Delete', icon: <Trash04 />, onClick: onDelete },
  ];

  const rightActions = (
    <Space align="center" size="large">
      {!!props.openSettings && (
        <Tooltip content="Edit settings">
          <CmdButton
            icon={<Settings03 />}
            variant="ghost"
            style={{ fontSize: 14, opacity: 0.9, boxShadow: 'none' }}
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              props.openSettings!();
              e.stopPropagation();
            }}
          />
        </Tooltip>
      )}
      {props.category && <DropdownMenu keyName="category-actions" items={menuItems} />}
    </Space>
  );

  const leftActions = (
    <Space align="center">
      <span style={{ fontWeight: 600 }}>{categoryName}</span>
      <Badge
        count={commands.length}
        style={{
          background: 'rgba(24, 144, 255, 0.7)',
          lineHeight: '16px',
          color: '#111',
          marginTop: -3,
        }}
      />
    </Space>
  );

  return (
    <SessionSettingsProvider categories={categories}>
      <CommandGroup
        category={props.category || null}
        commands={commands}
        searchText={props.searchText}
        emptyActions={<EmptyCategoryActionCard category={props.category} />}
        rightActions={rightActions}
        leftActions={leftActions}
      />
    </SessionSettingsProvider>
  );
};

export default CategoryGroup;
