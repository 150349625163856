import {
  Play,
  LinkExternal01,
  BookOpen01,
  BookmarkCheck,
  CursorClick02,
  MessageChatCircle,
  Lightning01,
  ChevronRight,
  ArrowUpRight,
} from '@commandbar/design-system/icons/react';
import CheckSquareBroken from '@commandbar/internal/assets/CheckSquareBroken';
import NotificationBox from '@commandbar/internal/assets/NotificationBox';
import React from 'react';

export const leftIcon = (type: string) => {
  switch (type) {
    case 'video':
      return <Play />;
    case 'link':
      return <LinkExternal01 />;
    case 'helpdoc':
      return <BookOpen01 />;
    case 'answer':
      return <BookmarkCheck />;
    case 'click':
      return <CursorClick02 />;
    case 'open_chat':
      return <MessageChatCircle />;
    case 'questlist':
    case 'checklist':
      return <CheckSquareBroken />;
    case 'nudge':
      return <NotificationBox />;
    default:
      return <Lightning01 />;
  }
};

export const rightIcon = (type: string) => {
  switch (type) {
    case 'link':
      return <ArrowUpRight />;
    case 'click':
    case 'video':
    case 'helpdoc':
    case 'execute_command':
    case 'open_chat':
    case 'questlist':
    case 'checklist':
    case 'nudge':
      return <ChevronRight />;
    default:
      return null;
  }
};
