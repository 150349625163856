import React from 'react';
import type { RenderElementProps } from 'slate-react';

import type { ListElement } from '../types';

interface ListProps extends RenderElementProps {
  element: ListElement;
}

export const List = ({ attributes, children, element }: ListProps) => {
  const Comp = element.ordered ? 'ol' : 'ul';

  return (
    <Comp {...attributes} className="mb-2.5 ps-7">
      {children}
    </Comp>
  );
};
