/** @jsx jsx */
import React from 'react';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import { CSSObject, css, jsx } from '@emotion/core';
import StyledCheckboxIcon from '../shared/StyledCheckboxIcon';
import ButtonWithReset from '../shared/ButtonWithReset';

const checkboxFormStyles: CSSObject = {
  display: 'flex',
  width: '100%',
  height: 'var(--form-control-height)',
  padding: 'var(--form-control-padding-x)',
  alignItems: 'center',
  gap: 'var(--form-control-gap)',
  flexShrink: 0,
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--form-control-border-color)',
  background: 'var(--form-control-background)',
  color: 'var(--content-primary)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  /* label/base/medium */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-base)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    borderColor: 'var(--form-control-border-color-hover)',
    background: 'var(--form-control-background-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    borderColor: 'var(--form-control-border-color)',
    background: 'var(--form-control-background)',
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
};

const checkboxFormSelectedStyles: CSSObject = {
  borderColor: 'var(--base-accent)',
  background: 'var(--form-control-active-background)',
  fill: 'var(--button-primary-background)',

  '&:hover': {
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-active-background)',
  },

  '&:focus': {
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-active-background)',
  },
};

const slug = 'checkboxOption';

const StyledNudgeCheckboxOption = ({
  themeV2,
  selected,
  onClick,
  children,
}: {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  selected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset
      data-testid="survey-option"
      css={[overrides, css(checkboxFormStyles), selected && css(checkboxFormSelectedStyles)].filter(Boolean)}
      onClick={onClick}
    >
      <StyledCheckboxIcon selected={selected} />
      {children}
    </ButtonWithReset>
  );
};

StyledNudgeCheckboxOption.slug = slug;
StyledNudgeCheckboxOption.styles = checkboxFormStyles;
StyledNudgeCheckboxOption.activeStyles = checkboxFormSelectedStyles;
StyledNudgeCheckboxOption.renderMock = (
  themeV2: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode',
  active: boolean,
  onClick: () => void,
) => (
  <StyledNudgeCheckboxOption themeV2={{ themeV2: themeV2, mode: mode }} selected={!!active} onClick={onClick}>
    Checkbox option
  </StyledNudgeCheckboxOption>
);

export default StyledNudgeCheckboxOption;
