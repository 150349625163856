import React from 'react';
import { Shadow, SlashDivider, SpacingHeight02, SpacingWidth02 } from '@commandbar/design-system/icons/react';
import { ReactComponent as CornerDefault } from '../../img/corner_default.svg';
import { ReactComponent as Spacing } from '../../img/spacing.svg';
import { ReactComponent as ArrowsHorizontal } from '../../img/arrows_horizontal.svg';
import { ReactComponent as ArrowsVertical } from '../../img/arrows_vertical.svg';
import { ReactComponent as DefaultState } from '../../img/default_state.svg';
import { ReactComponent as HoverState } from '../../img/hover_state.svg';
import { ReactComponent as FocusState } from '../../img/focus_state.svg';
import { ReactComponent as DisabledState } from '../../img/disabled_state.svg';
import { ReactComponent as ActiveState } from '../../img/active_state.svg';

import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';
import StyledSecondaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryButton';
import StyledTertiaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledTertiaryButton';
import StyledSecondaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryIconButton';
import StyledTertiaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledTertiaryIconButton';
import StyledToggleButton from '@commandbar/internal/client/themesV2/components/shared/StyledToggleButton';
import StyledNudgeRatingNumbers from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingNumbers';
import StyledNudgeRatingStars from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingStars';
import StyledNudgeRatingEmojis from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRatingEmojis';
import StyledInlineButton from '@commandbar/internal/client/themesV2/components/shared/StyledInlineButton';
import StyledBaseInput from '@commandbar/internal/client/themesV2/components/shared/StyledBaseInput';
import StyledTextArea from '@commandbar/internal/client/themesV2/components/shared/StyledTextArea';
import StyledSelect from '@commandbar/internal/client/themesV2/components/shared/StyledSelect';
import StyledNudgeCheckboxOption from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeCheckboxOption';
import StyledNudgeRadioOption from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeRadioOption';
import StyledAssistanceModalHeader from '@commandbar/internal/client/themesV2/components/shared/StyledAssistanceModalHeader';
import StyledChecklistProgressBar from '@commandbar/internal/client/themesV2/components/checklist/StyledChecklistProgressBar';
import StyledNudgeBeacon from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBeacon';
import StyledHelpHubCard from '@commandbar/internal/client/themesV2/components/helphub/StyledHelpHubCard';

import { CSSObject } from '@emotion/styled';

import type { IThemeV2Type } from '@cb/types/entities/theme';
import type { IThemeAnimatedWidgetType } from '@commandbar/internal/middleware/theme';

import { FontFamilyThemeField } from './components/FontFamilyThemeField';
import StyledHelpDocCard from '@commandbar/internal/client/themesV2/components/shared/StyledHelpDocCard';

type EditableThemeFieldBase = {
  label: string;
  tooltip?: string;
  variable: string;
};

export type EditableThemeAnimationField = EditableThemeFieldBase & {
  type: 'animation';
  widget: IThemeAnimatedWidgetType;
};

export type EditableThemeCustomFieldProps = {
  field: EditableThemeCustomField;
  value?: string;
  onChange(value: string): void;
};

export type EditableThemeCustomField = EditableThemeFieldBase & {
  type: 'custom';
  customField: React.ElementType<EditableThemeCustomFieldProps>;
};

export type EditableThemePropertyField = EditableThemeFieldBase & {
  type: 'string' | 'number' | 'color' | 'size';
  icon?: JSX.Element;
  formType: 'textarea' | 'input';
};

export type EditableThemeField = EditableThemeAnimationField | EditableThemeCustomField | EditableThemePropertyField;

export type ThemeFieldGroup = {
  label: string;
  slug: string;
  children: Array<EditableThemeField | ThemeFieldGroup>;
  icon?: JSX.Element;
};

export type ComponentThemeFieldGroup = {
  label: string;
  component: {
    slug: string;
    styles: CSSObject;
    renderMock: (
      themeV2: IThemeV2Type,
      mode: 'light_mode' | 'dark_mode',
      active: boolean,
      onClick: () => void,
    ) => React.ReactElement;
    activeStyles?: CSSObject;
  };
  children: ThemeFieldGroup[];
};

export const editableVarsTree: ThemeFieldGroup[] = [
  {
    label: 'Brand',
    slug: 'brand',
    children: [{ label: 'Accent color', variable: '--base-accent', type: 'color', formType: 'input' }],
  },
  {
    label: 'Typography',
    slug: 'typography',
    children: [
      {
        label: 'Font family',
        variable: '--font-font-family',
        tooltip:
          "External fonts won't be loaded when previewing widgets in Command AI with the exception of select Google Fonts.",
        type: 'custom',
        customField: FontFamilyThemeField,
      },
      {
        label: 'Size',
        slug: 'size',
        children: [
          { label: 'Small', variable: '--font-size-small', type: 'size', formType: 'input' },
          { label: 'Base', variable: '--font-size-base', type: 'size', formType: 'input' },
          { label: 'Large', variable: '--font-size-large', type: 'size', formType: 'input' },
          { label: 'Extra large', variable: '--font-size-extra-large', type: 'size', formType: 'input' },
        ],
      },
      {
        label: 'Weight',
        slug: 'weight',
        children: [
          { label: 'Regular', variable: '--font-weight-regular', type: 'number', formType: 'input' },
          { label: 'Medium', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Semibold', variable: '--font-weight-semibold', type: 'number', formType: 'input' },
          { label: 'Bold', variable: '--font-weight-bold', type: 'number', formType: 'input' },
        ],
      },
      {
        label: 'Line height',
        slug: 'lineHeight',
        children: [
          { label: 'None', variable: '--font-line-height-none', type: 'string', formType: 'input' },
          { label: 'Tight', variable: '--font-line-height-tight', type: 'string', formType: 'input' },
          { label: 'Snug', variable: '--font-line-height-snug', type: 'string', formType: 'input' },
          { label: 'Normal', variable: '--font-line-height-normal', type: 'string', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Content',
    slug: 'content',
    children: [
      { label: 'Primary color', variable: '--content-primary', type: 'color', formType: 'input' },
      { label: 'Secondary color', variable: '--content-secondary', type: 'color', formType: 'input' },
      { label: 'Disabled color', variable: '--content-disabled', type: 'color', formType: 'input' },
      { label: 'Link color', variable: '--content-link', type: 'color', formType: 'input' },
      { label: 'Link hover color', variable: '--content-link-hover', type: 'color', formType: 'input' },
      { label: 'Link visited color', variable: '--content-link-visited', type: 'color', formType: 'input' },
      { label: 'Highlight color', variable: '--highlight-color', type: 'color', formType: 'input' },
    ],
  },
  {
    label: 'Border',
    slug: 'border',
    children: [
      { label: 'Primary color', variable: '--border-primary', type: 'color', formType: 'input' },
      { label: 'Primary hover color', variable: '--border-primary-hover', type: 'color', formType: 'input' },
      { label: 'Disabled color', variable: '--border-primary-disabled', type: 'color', formType: 'input' },
    ],
  },
  {
    label: 'Background',
    slug: 'background',
    children: [
      { label: 'Primary color', variable: '--background-primary', type: 'color', formType: 'input' },
      { label: 'Primary hover color', variable: '--background-primary-hover', type: 'color', formType: 'input' },
      { label: 'Secondary color', variable: '--background-secondary', type: 'color', formType: 'input' },
      { label: 'Secondary hover color', variable: '--background-secondary-hover', type: 'color', formType: 'input' },
      { label: 'Disabled color', variable: '--background-disabled', type: 'color', formType: 'input' },
    ],
  },
  {
    label: 'Form controls',
    slug: 'formControls',
    children: [
      {
        label: 'Height',
        variable: '--form-control-height',
        type: 'size',
        icon: <SpacingHeight02 />,
        formType: 'input',
      },
      {
        label: 'Corner radius',
        variable: '--layout-radius-button',
        type: 'size',
        icon: <CornerDefault />,
        formType: 'input',
      },
      { label: 'Padding', variable: '--form-control-padding-x', type: 'size', icon: <Spacing />, formType: 'input' },
      { label: 'Gaps', variable: '--form-control-gap', type: 'size', icon: <SpacingHeight02 />, formType: 'input' },
      { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
      { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
      { label: 'Background', variable: '--form-control-background', type: 'color', formType: 'input' },
      { label: 'Active background', variable: '--form-control-active-background', type: 'color', formType: 'input' },
      { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
      {
        label: 'Focus ring width',
        variable: '--form-control-focus-ring-width',
        type: 'size',
        icon: <SlashDivider />,
        formType: 'input',
      },
    ],
  },

  {
    label: 'Cards',
    slug: 'cards',
    children: [
      {
        label: 'Corner radius',
        variable: '--layout-radius-card',
        type: 'size',
        icon: <CornerDefault />,
        formType: 'input',
      },
      { label: 'Padding', variable: '--layout-padding', type: 'size', icon: <Spacing />, formType: 'input' },
      { label: 'Gaps', variable: '--layout-gap', type: 'size', icon: <SpacingHeight02 />, formType: 'input' },
      { label: 'Shadow', variable: '--layout-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
      { label: 'Shadow color', variable: '--layout-shadow-color', type: 'color', formType: 'input' },
    ],
  },

  {
    label: 'Widget dimensions',
    slug: 'widgetDimensions',
    children: [
      {
        label: 'Nudge modal',
        slug: 'nudgeModal',
        children: [
          {
            label: 'Max content width',
            variable: '--modal-width',
            type: 'size',
            icon: <ArrowsHorizontal />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Nudge popover / pin',
        slug: 'nudgePopover',
        children: [
          {
            label: 'Max content width',
            variable: '--popover-width',
            type: 'size',
            icon: <ArrowsHorizontal />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Nudge tooltip',
        slug: 'nudgeTooltip',
        children: [
          {
            label: 'Max-width',
            variable: '--tooltip-max-width',
            type: 'size',
            icon: <ArrowsHorizontal />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Checklist',
        slug: 'checklist',
        children: [
          {
            label: 'Width',
            variable: '--checklist-width',
            type: 'size',
            icon: <ArrowsHorizontal />,
            formType: 'input',
          },
          {
            label: 'Max-height',
            variable: '--checklist-max-height',
            type: 'size',
            icon: <ArrowsVertical />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'HelpHub / Copilot',
        slug: 'helpHub',
        children: [
          {
            label: 'Width',
            variable: '--helphub-width',
            type: 'size',
            icon: <ArrowsHorizontal />,
            formType: 'input',
          },
          {
            label: 'Height',
            variable: '--helphub-height',
            type: 'size',
            icon: <ArrowsVertical />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Spotlight',
        slug: 'spotlight',
        children: [
          {
            label: 'Width',
            variable: '--spotlight-width',
            type: 'size',
            icon: <ArrowsHorizontal />,
            formType: 'input',
          },
          {
            label: 'Max-height',
            variable: '--spotlight-max-height',
            type: 'size',
            icon: <ArrowsVertical />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Widget entry animations',
    slug: 'animations',
    children: [
      {
        label: 'Nudge modal',
        slug: 'nudgeModal',
        children: [
          {
            label: 'Animation',
            variable: '',
            widget: 'modal',
            type: 'animation',
          },
        ],
      },
      {
        label: 'Nudge popover / pin',
        slug: 'nudgePopover',
        children: [
          {
            label: 'Animation',
            variable: '',
            widget: 'popover',
            type: 'animation',
          },
        ],
      },
      {
        label: 'Nudge tooltip',
        slug: 'nudgeTooltip',
        children: [
          {
            label: 'Animation',
            variable: '',
            widget: 'tooltip',
            type: 'animation',
          },
        ],
      },
      {
        label: 'Checklist',
        slug: 'checklist',
        children: [
          {
            label: 'Animation',
            variable: '',
            widget: 'checklist',
            type: 'animation',
          },
        ],
      },
      {
        label: 'HelpHub / Copilot',
        slug: 'helpHub',
        children: [
          {
            label: 'Animation',
            variable: '',
            widget: 'helphub',
            type: 'animation',
          },
        ],
      },
      {
        label: 'Spotlight',
        slug: 'spotlight',
        children: [
          {
            label: 'Animation',
            variable: '',
            widget: 'spotlight',
            type: 'animation',
          },
        ],
      },
    ],
  },
];

export const editableComponentStylesTree: ComponentThemeFieldGroup[] = [
  {
    label: 'Primary button',
    component: StyledPrimaryButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-primary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-primary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-primary-content', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-semibold', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-primary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Border color', variable: '--button-primary-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-primary-content-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-primary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Secondary button',
    component: StyledSecondaryButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-secondary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-secondary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-secondary-content', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-semibold', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-secondary-background-hover',
            type: 'color',
            formType: 'input',
          },
          {
            label: 'Border color',
            variable: '--button-secondary-border-color-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Content color', variable: '--button-secondary-content-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-secondary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Tertiary button',
    component: StyledTertiaryButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-tertiary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-tertiary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-semibold', type: 'number', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-tertiary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Border color', variable: '--button-tertiary-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content-hover', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-tertiary-background-hover',
            type: 'color',
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Secondary icon button',
    component: StyledSecondaryIconButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-secondary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-secondary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-secondary-content', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-secondary-background-hover',
            type: 'color',
            formType: 'input',
          },
          {
            label: 'Border color',
            variable: '--button-secondary-border-color-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Content color', variable: '--button-secondary-content-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-secondary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Disabled',
        slug: '&:disabled',
        icon: <DisabledState />,
        children: [
          { label: 'Border color', variable: '--button-secondary-border-color', type: 'color', formType: 'input' },
          { label: 'Background color', variable: '--button-secondary-background', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-secondary-content', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Tertiary icon button',
    component: StyledTertiaryIconButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-tertiary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-tertiary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-tertiary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Border color', variable: '--button-tertiary-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content-hover', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-tertiary-background-hover',
            type: 'color',
            formType: 'input',
          },
        ],
      },
      {
        label: 'Disabled',
        slug: '&:disabled',
        icon: <DisabledState />,
        children: [
          { label: 'Border color', variable: '--button-tertiary-border-color', type: 'color', formType: 'input' },
          { label: 'Background color', variable: '--button-tertiary-background', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Inline button',
    component: StyledInlineButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [{ label: 'Content color', variable: '--content-secondary', type: 'color', formType: 'input' }],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [{ label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' }],
      },
    ],
  },
  {
    label: 'Toggle Button',
    component: StyledToggleButton,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-tertiary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-tertiary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-tertiary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Border color', variable: '--button-tertiary-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-tertiary-content-hover', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Active',
        slug: 'active',
        icon: <ActiveState />,
        children: [
          { label: 'Background color', variable: '--button-primary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-primary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-primary-content', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Form control emojis',
    component: StyledNudgeRatingEmojis,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Width',
            variable: '--form-control-width',
            type: 'size',
            icon: <SpacingWidth02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Active',
        slug: 'active',
        icon: <ActiveState />,
        children: [
          { label: 'Background color', variable: '--form-control-active-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Form control number',
    component: StyledNudgeRatingNumbers,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Width',
            variable: '--form-control-width',
            type: 'size',
            icon: <SpacingWidth02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Active',
        slug: 'active',
        icon: <ActiveState />,
        children: [
          { label: 'Background color', variable: '--form-control-active-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Form control stars',
    component: StyledNudgeRatingStars,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Width',
            variable: '--form-control-width',
            type: 'size',
            icon: <SpacingWidth02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--nudge-rating-star-color', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--nudge-rating-star-color', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Active',
        slug: 'active',
        icon: <ActiveState />,
        children: [
          { label: 'Background color', variable: '--form-control-active-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--nudge-rating-star-color-selected', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Input',
    component: StyledBaseInput,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Placeholder color', variable: '--content-secondary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Text area',
    component: StyledTextArea,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Placeholder color', variable: '--content-secondary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Select input',
    component: StyledSelect,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Form control radio',
    component: StyledNudgeRadioOption,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Active',
        slug: 'active',
        icon: <ActiveState />,
        children: [
          { label: 'Background color', variable: '--form-control-active-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Radio color', variable: '--button-primary-background', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Form control checkbox',
    component: StyledNudgeCheckboxOption,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-height',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Padding',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          { label: 'Background color', variable: '--form-control-background-hover', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Background color', variable: '--form-control-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--form-control-border-color', type: 'color', formType: 'input' },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
      {
        label: 'Active',
        slug: 'active',
        icon: <ActiveState />,
        children: [
          { label: 'Background color', variable: '--form-control-active-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Checkmark color', variable: '--button-primary-background', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Title bar',
    component: StyledAssistanceModalHeader,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          { label: 'Height', variable: '--header-height', type: 'size', icon: <SpacingHeight02 />, formType: 'input' },
          { label: 'Background color', variable: '--form-control-active-background', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--content-primary', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Progress bar',
    component: StyledChecklistProgressBar,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Height',
            variable: '--form-control-padding-x',
            type: 'size',
            icon: <SpacingHeight02 />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-circle',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--background-secondary', type: 'color', formType: 'input' },
          { label: 'Active background color', variable: '--base-accent', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Beacon',
    component: StyledNudgeBeacon,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          { label: 'Height', variable: '--height-small', type: 'size', icon: <SpacingHeight02 />, formType: 'input' },
          {
            label: 'Corner radius',
            variable: '--layout-radius-circle',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--base-accent', type: 'color', formType: 'input' },
        ],
      },
    ],
  },
  {
    label: 'Content grid item',
    component: StyledHelpDocCard,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--button-primary-background', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--button-primary-border-color', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-primary-content', type: 'color', formType: 'input' },
          { label: 'Font weight', variable: '--font-weight-semibold', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--button-primary-background-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Border color', variable: '--button-primary-border-color-hover', type: 'color', formType: 'input' },
          { label: 'Content color', variable: '--button-primary-content-hover', type: 'color', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
  {
    label: 'Content list item',
    component: StyledHelpHubCard,
    children: [
      {
        label: 'Default',
        slug: 'default',
        icon: <DefaultState />,
        children: [
          {
            label: 'Padding',
            variable: '--layout-padding',
            type: 'size',
            icon: <Spacing />,
            formType: 'input',
          },
          {
            label: 'Corner radius',
            variable: '--layout-radius-button',
            type: 'size',
            icon: <CornerDefault />,
            formType: 'input',
          },
          { label: 'Background color', variable: '--background-primary', type: 'color', formType: 'input' },
          { label: 'Border color', variable: '--border-primary', type: 'color', formType: 'input' },
          { label: 'Primary content color', variable: '--content-primary', type: 'color', formType: 'input' },
          {
            label: 'Secondary content color',
            variable: '--content-secondary',
            type: 'color',
            formType: 'input',
          },
          { label: 'Primary font weight', variable: '--font-weight-semibold', type: 'number', formType: 'input' },
          { label: 'Secondary font weight', variable: '--font-weight-medium', type: 'number', formType: 'input' },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Hover',
        slug: '&:hover',
        icon: <HoverState />,
        children: [
          {
            label: 'Background color',
            variable: '--background-primary-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Border color', variable: '--border-primary-hover', type: 'color', formType: 'input' },
          {
            label: 'Primary content color',
            variable: '--content-primary-hover',
            type: 'color',
            formType: 'input',
          },
          {
            label: 'Secondary content color',
            variable: '--content-secondary-hover',
            type: 'color',
            formType: 'input',
          },
          { label: 'Shadow', variable: '--form-control-shadow', type: 'string', icon: <Shadow />, formType: 'input' },
          { label: 'Shadow color', variable: '--form-control-shadow-color', type: 'color', formType: 'input' },
        ],
      },
      {
        label: 'Focus',
        slug: '&:focus',
        icon: <FocusState />,
        children: [
          { label: 'Border color', variable: '--base-accent', type: 'color', formType: 'input' },
          { label: 'Focus ring color', variable: '--form-control-focus-ring-color', type: 'color', formType: 'input' },
          {
            label: 'Focus ring width',
            variable: '--form-control-focus-ring-width',
            type: 'size',
            icon: <SlashDivider />,
            formType: 'input',
          },
        ],
      },
    ],
  },
];
