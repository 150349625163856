import React, { useState } from 'react';
import { Copy07, Edit03, Plus, Trash04 } from '@commandbar/design-system/icons/react';
import { IWorkflow } from '@commandbar/internal/middleware/types';

import {
  FeatureAnnouncementCard,
  Table,
  SimplePanel,
  Row,
  Col,
  DropdownMenu,
  StatusBadge,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { CmdButton, CmdSearchInput, cmdToast } from '@commandbar/design-system/cmd';
import { useHistory, useParams } from 'react-router';
import { COPILOT_WORKFLOWS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import WorkflowDetail from './WorkflowDetail';
import { PaddingContainer } from '../Router';
import FilterSelect, { StatusType } from '../components/FilterSelect';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const Workflows: React.FC = () => {
  const { workflows, dispatch } = useAppContext();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const searchParams = new URLSearchParams(history.location.search);

  const { user } = useAuth();

  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') ?? '1'));
  const [activeWorkflow, setActiveWorkflow] = useState<IWorkflow | undefined>(undefined);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType>('all');

  const updateActiveWorkflow = (workflow: IWorkflow | undefined) => {
    setActiveWorkflow(workflow);

    if (!!workflow) {
      const path = `${COPILOT_WORKFLOWS_ROUTE}/${workflow.id}${history.location.search}`;
      if (history.location.pathname + history.location.search !== path) {
        history.push(path);
      }
    } else {
      history.replace(COPILOT_WORKFLOWS_ROUTE + history.location.search);
    }
  };

  React.useEffect(() => {
    const id = Number(params.id);
    if (!id) {
      updateActiveWorkflow(undefined);
      return;
    } else {
      const workflow = workflows?.find((c) => c.id === id);
      if (workflow) {
        updateActiveWorkflow(workflow);
      }
    }
  }, [params.id, workflows, activeWorkflow]);

  const addNewWorkflow = async () => {
    const emptyWorkflow: IWorkflow = {
      id: -1,
      archived: false,
      is_live: false,
      title: '',
      prompt: '',
      trigger: { type: 'intent', intent_description: '' },
      allow_docs_search: true,
      automatic_handoff: true,
      exit_on_fallback: true,
      audience: { type: 'all_users' },
    };
    updateActiveWorkflow(emptyWorkflow);
  };

  const CreateButton = ({ addNew }: { addNew: () => void }) => {
    const [loading, setLoading] = useState(false);
    return (
      <CmdButton
        onClick={async () => {
          if (loading) return;
          setLoading(true);
          try {
            addNew();
          } catch {
            cmdToast.error('Error creating new Workflow');
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
        icon={<Plus />}
        variant={'primary'}
      >
        New
      </CmdButton>
    );
  };

  const columns = [
    {
      title: 'Workflow',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, workflow: IWorkflow) => {
        return workflow.title.length > 28 ? `${workflow.title.slice(0, 28)}...` : workflow.title;
      },
    },
    {
      title: 'Status',
      key: 'is_live',
      dataIndex: 'is_live',
      render: (_: any, workflow: IWorkflow) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {workflow.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Published" />
          ) : (
            <StatusBadge color="orange" text="Unpublished" />
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, workflow: IWorkflow) => {
        const canEdit = hasRequiredRole(user, workflow.is_live ? 'editor' : 'contributor');

        const dropDownItems = [];

        if (canEdit) {
          dropDownItems.push({
            name: 'Edit',
            icon: <Edit03 />,
            onClick: () => updateActiveWorkflow(workflow),
          });
        }

        if (hasRequiredRole(user, 'contributor')) {
          dropDownItems.push({
            name: 'Duplicate',
            icon: <Copy07 />,
            onClick: () => {
              const newWorkflow = {
                ...workflow,
                id: -1,
                is_live: false,
              };
              updateActiveWorkflow(newWorkflow);
            },
          });

          if (canEdit) {
            dropDownItems.push({
              name: 'Delete',
              icon: <Trash04 />,
              onClick: () => dispatch.workflows.delete(workflow.id),
            });
          }
        }

        return <DropdownMenu keyName="checklist-actions" items={dropDownItems} />;
      },
    },
  ];

  if (activeWorkflow) {
    return (
      <WorkflowDetail
        key={activeWorkflow.id}
        initialWorkflow={activeWorkflow}
        onClose={() => {
          const createdNewWorkflow = activeWorkflow.id > -1;
          createdNewWorkflow || history.length <= 1
            ? history.replace(COPILOT_WORKFLOWS_ROUTE + history.location.search)
            : history.goBack();
        }}
        onDelete={async (workflow: IWorkflow) => {
          await dispatch.workflows.delete(workflow.id);
          history.goBack();
        }}
        onSave={async (workflow: IWorkflow) => {
          dispatch.workflows.save(workflow).then((newWorkflow) => {
            setActiveWorkflow(newWorkflow);
            if (newWorkflow.id !== activeWorkflow.id) {
              history.replace(`${newWorkflow.id}${history.location.search}`);
            }
          });
        }}
      />
    );
  } else {
    return (
      <PaddingContainer style={{ display: 'flex', gap: 16, flexDirection: 'column', marginTop: -8 }}>
        <FeatureAnnouncementCard
          identifier="introducing_workflows"
          title={<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>Introducing Workflows</div>}
          docsLink="https://www.commandbar.com/docs/copilot/personalization/Workflows/"
        >
          <span>
            Workflows allow Copilot to take users down differentiated paths based on their questions and requests.
          </span>
        </FeatureAnnouncementCard>

        <SimplePanel>
          <Row justify="space-between" style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', maxWidth: '100%' }}>
            <Col style={{ display: 'flex', gap: 8 }}>
              <div style={{ flex: 1 }}>
                <CmdSearchInput
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <FilterSelect
                selectedOption={selectedStatus}
                setSelectedOption={setSelectedStatus}
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Published', value: 'published' },
                  { label: 'Unpublished', value: 'unpublished' },
                ]}
              />
            </Col>
            {hasRequiredRole(user, 'contributor') && (
              <Col>
                <CreateButton addNew={addNewWorkflow} />
              </Col>
            )}
          </Row>
          <Table
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              current: currentPage,
              onChange: (page) => {
                const params = new URLSearchParams(history.location.search);
                params.delete('page');
                params.append('page', page.toString());
                history.replace(`${history.location.pathname}?${params}`);
                setCurrentPage(page);
              },
            }}
            rowClassName="editable-row"
            columns={columns}
            dataSource={workflows
              .filter((workflow) => workflow.title.toLowerCase().includes(searchText.toLowerCase()))
              .filter((workflow) => {
                if (selectedStatus === 'all') {
                  return true;
                } else if (selectedStatus === 'published') {
                  return workflow.is_live;
                } else {
                  return !workflow.is_live;
                }
              })}
            onRow={(workflow: IWorkflow) => {
              return {
                onClick: (_e: React.MouseEvent) => {
                  updateActiveWorkflow(workflow);
                },
              };
            }}
          />
        </SimplePanel>
      </PaddingContainer>
    );
  }
};

export default Workflows;
