import React from 'react';
import { type RenderElementProps, useFocused, useSelected } from 'slate-react';

import { cn } from '../../util';
import { Mark } from '../constants';
import type { MentionElement } from '../types';

interface MentionProps extends RenderElementProps {
  element: MentionElement;
}

export const Mention = ({ attributes, children, element }: MentionProps) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span
      {...attributes}
      className={cn(
        'rounded-rounded border-2 border-transparent border-solid bg-orange200 px-1 py-1/2 text-orange900 transition-colors',
        element.children[0][Mark.Bold] && 'font-bold',
        element.children[0][Mark.Italic] && 'italic',
        selected && focused && 'border-orange400 shadow-sm',
      )}
    >
      {element.value}
      {children}
    </span>
  );
};
