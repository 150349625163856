import React from 'react';
import type { RenderPlaceholderProps } from 'slate-react';

export const Placeholder = ({ attributes, children }: RenderPlaceholderProps) => {
  const { style: _style, ...props } = attributes;

  return (
    <span
      {...props}
      className="pointer-events-none absolute top-0 left-0 select-none py-1 font-medium text-base text-contentBase leading-5"
    >
      {children}
    </span>
  );
};
