import { Editor, Element, Range, Transforms } from 'slate';

import { EMPTY_VALUE, ElementType, MENTION_TRIGGER } from '../constants';

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid, insertText } = editor;

  editor.isInline = (element) =>
    element.type === ElementType.Mention || element.type === ElementType.MentionsSelect || isInline(element);

  editor.isVoid = (element) =>
    element.type === ElementType.Mention || element.type === ElementType.MentionsSelect || isVoid(element);

  editor.markableVoid = (element) => element.type === ElementType.Mention || isVoid(element);

  editor.insertText = (text) => {
    if (text === MENTION_TRIGGER && editor.selection && Range.isCollapsed(editor.selection)) {
      const { anchor } = editor.selection;
      const block = Editor.above(editor, {
        match: (node) => Element.isElement(node) && Editor.isBlock(editor, node),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range);

      if (beforeText === EMPTY_VALUE || beforeText.endsWith(' ')) {
        Transforms.insertNodes(editor, {
          type: ElementType.MentionsSelect,
          value: EMPTY_VALUE,
          children: [{ text: EMPTY_VALUE }],
        });
        return;
      }
    }

    insertText(text);
  };

  return editor;
};
