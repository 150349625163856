import { IChecklist } from '@commandbar/internal/middleware/types';
import { useLocation, useParams, useHistory } from 'react-router';
import * as checklistSearchParams from '@commandbar/internal/proxy-editor/questlist_search_params';
import { CHECKLIST_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import React, { useState } from 'react';
import {
  Col,
  DropdownMenu,
  Empty,
  FeatureAnnouncementCard,
  Modal,
  Panel,
  Row,
  SimplePanel,
  StatusBadge,
  Table,
  TabPane,
  Tabs,
  Tooltip,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import questlistAnnouncementGif from '../../img/questlistAnnouncement.gif';
import ChecklistDetail from './ChecklistDetail';
import * as templates from './checklist_templates';
import { UpgradeCTA } from '../components/UpgradeCTA';
import { PaddingContainer } from '../Router';
import {
  AlertTriangle,
  Copy01,
  Copy07,
  Edit03,
  FaceIdSquare,
  Plus,
  Trash04,
} from '@commandbar/design-system/icons/react';
import { CmdButton, CmdTag, cmdToast } from '@commandbar/design-system/cmd';
import collectEditorTags from '../utils/collectEditorTags';
import FilterSelect from '../components/FilterSelect';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import ThemeCustomizationSettings from '../components/ThemeCustomizationSettings';
import { ShareLinkModal } from '../components/ShareLinkModal';

enum TabKey {
  LIST = 'LIST',
  SETTINGS = 'SETTINGS',
}

enum PanelKey {
  THEME = 'THEME',
}

const ChecklistList = () => {
  const location = useLocation<IChecklist>();
  const history = useHistory();
  const params = useParams<{ checklistId?: string }>();
  const { loading: appContextLoading, checklists, organization, isStudio, flags } = useAppContext();
  const { dispatch } = useAppContext();
  const { user } = useAuth();

  const [activeTabKey, setActiveTabKey] = useState(TabKey.LIST);
  const [activeKeys, setActiveKeys] = useState<PanelKey[]>([PanelKey.THEME]);
  const [activeChecklist, setActiveChecklist] = useState<IChecklist | undefined>(location.state);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTag, setSelectedTag] = useState<string>('all');
  const [selectedChecklist, setSelectedChecklist] = useState<IChecklist | null>();
  const [shareLinkModalOpen, setShareLinkModalOpen] = React.useState(false);

  const updateActiveChecklist = (checklist: IChecklist | undefined) => {
    setActiveChecklist(checklist);

    if (!!checklist) {
      const path = `${CHECKLIST_ROUTE}/${checklist.id}`;
      // TODO: Clean up the calls to `updateActiveChecklist` so that it's only
      // called when the path needs to be updated, for now; if we're already
      // on the right path, don't push it again.
      if (history.location.pathname !== path) {
        history.push(path);
      }
    } else {
      history.replace(CHECKLIST_ROUTE);
    }
  };

  React.useEffect(() => {
    if (appContextLoading || !organization) return;

    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get(checklistSearchParams.PAGE);
    page ? setCurrentPage(parseInt(page)) : setCurrentPage(1);

    const template = searchParams.get(checklistSearchParams.QUESTLIST_TEMPLATE);

    if (!params.checklistId && !template) {
      setActiveChecklist(undefined);
      return;
    }

    const checklist = checklists.find((c) => c.id === Number(params.checklistId));
    if (!checklist && !!params.checklistId && Number(params.checklistId) >= 0) {
      history.replace(CHECKLIST_ROUTE);
    }
    if (checklist) {
      if (activeChecklist === undefined || activeChecklist.id !== checklist.id) updateActiveChecklist(checklist);
      return;
    }

    switch (template) {
      case checklistSearchParams.QUESTLIST_EMPTY_TEMPLATE_PARAM_VALUE: {
        history.replace(CHECKLIST_ROUTE);
        return updateActiveChecklist(templates.emptyChecklist);
      }
      default: {
        break;
      }
    }
  }, [organization, location.search, activeChecklist, params.checklistId, appContextLoading]);

  if (appContextLoading || !organization) {
    return null;
  }

  const tags = collectEditorTags({ checklists }).map((tag) => ({ label: tag, value: tag }));

  const handleDuplication = async (checklist: IChecklist) => {
    try {
      const newCheckList = {
        ...checklist,
        id: -1,
        title: `Copy of ${checklist.title}`,
        template_source: 'copy',
        is_live: false,
        items: checklist.items.map((item) => ({
          ...item,
          id: -1,
        })),
      };
      const savedChecklist = await dispatch.checklists.save(newCheckList);

      history.push(`${CHECKLIST_ROUTE}/${savedChecklist.id}`);
      return savedChecklist;
    } catch (err) {
      cmdToast.error('Error duplicating checklist.');
    }
    return null;
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Tags',
      key: 'tags',
      hidden: checklists.every((checklist) => checklist.editor_tags?.length === 0),
      render: (_: any, checklist: IChecklist) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '300px', gap: '8px', overflow: 'hidden' }}>
            {checklist.editor_tags.map((tag, index) => (
              <CmdTag key={index}>{tag}</CmdTag>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'is_live',
      dataIndex: 'is_live',
      render: (_: any, checklist: IChecklist) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {checklist.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
          ) : (
            <StatusBadge color="orange" text="Draft" />
          )}
          {checklist.copilot_suggest && (
            <Tooltip inline content={'Suggested in Copilot'}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaceIdSquare style={{ flexShrink: 0, marginLeft: '8px' }} width="16px" height="16px" />
              </div>
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, checklist: IChecklist) => {
        const canEdit = hasRequiredRole(user, checklist.is_live ? 'editor' : 'contributor');

        const dropDownItems = [];

        if (canEdit) {
          dropDownItems.push({
            name: 'Edit',
            icon: <Edit03 />,
            onClick: () => updateActiveChecklist(checklist),
          });
        }
        if (hasRequiredRole(user, 'contributor')) {
          dropDownItems.push({
            name: 'Duplicate',
            icon: <Copy07 />,
            onClick: () => {
              handleDuplication(checklist);
            },
          });
        }

        dropDownItems.push({
          name: 'Copy id',
          icon: <Copy01 />,
          onClick: () => {
            navigator.clipboard.writeText(`${checklist.id}`).then(function () {
              cmdToast.success('ID copied to clipboard.');
            });
          },
        });

        dropDownItems.push({
          name: 'Trigger link...',
          icon: <Copy07 />,
          onClick: (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setShareLinkModalOpen(true);
            setSelectedChecklist(checklist);
          },
        });

        if (canEdit) {
          dropDownItems.push({
            name: 'Delete',
            icon: <Trash04 />,
            onClick: () => {
              Modal.confirm({
                icon: (
                  <AlertTriangle
                    height={22}
                    width={22}
                    color="#FAAD14"
                    style={{
                      float: 'left',
                      marginRight: '16px',
                    }}
                  />
                ),
                title: 'Are you sure you want to delete this checklist? This cannot be undone.',
                async onOk() {
                  dispatch.checklists.delete(checklist.id);
                },
              });
            },
          });
        }

        return <DropdownMenu keyName="checklist-actions" items={dropDownItems} vertical />;
      },
    },
  ];

  const onDelete = (id: number) => {
    if (id > 0) {
      Modal.confirm({
        icon: (
          <AlertTriangle
            height={22}
            width={22}
            color="#FAAD14"
            style={{
              float: 'left',
              marginRight: '16px',
            }}
          />
        ),
        title: 'Are you sure you want to delete this checklist? This cannot be undone.',
        async onOk() {
          await dispatch.checklists.delete(id);
          updateActiveChecklist(undefined);
        },
      });
    }
  };

  if (activeChecklist) {
    return (
      <ChecklistDetail
        key={activeChecklist.id}
        initialChecklist={activeChecklist}
        setInitial={updateActiveChecklist}
        onClose={() => {
          if (history.length <= 1) {
            history.replace(CHECKLIST_ROUTE);
          } else {
            history.goBack();
          }
        }}
        onDelete={onDelete}
        allChecklists={checklists}
        onDuplicate={handleDuplication}
      />
    );
  }

  return (
    <>
      {selectedChecklist && (
        <ShareLinkModal
          shareableEntity={selectedChecklist}
          isOpen={shareLinkModalOpen}
          setIsOpen={setShareLinkModalOpen}
          onClose={() => {
            setShareLinkModalOpen(false);
            setSelectedChecklist(null);
          }}
        />
      )}
      <Tabs
        activeKey={activeTabKey}
        onChange={(key) => setActiveTabKey(key as TabKey)}
        isStudio={isStudio}
        type="card"
        tabBarStyle={{
          paddingTop: isStudio ? '9px' : 0,
          marginTop: -10,
          paddingLeft: '16px',
          display: 'block',
        }}
        destroyInactiveTabPane
      >
        <TabPane tab={'Checklists'} key={TabKey.LIST}>
          <PaddingContainer>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <FeatureAnnouncementCard
                identifier="questlists"
                title={<Row align="middle">Introducing Checklists</Row>}
                img={{
                  src: questlistAnnouncementGif,
                  alt: 'Onboard your customers with feature-rich, interactive checklists',
                }}
                docsLink="https://www.commandbar.com/docs/nudges/nudges/checklists/overview/"
              >
                <span>Onboard your customers with feature-rich, interactive checklists.</span>
              </FeatureAnnouncementCard>

              <UpgradeCTA product="checklists" />

              <SimplePanel>
                <Row justify="end" style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', maxWidth: '100%' }}>
                  {tags.length ? (
                    <Col>
                      <FilterSelect
                        selectLabel="Tag"
                        selectedOption={selectedTag}
                        setSelectedOption={setSelectedTag}
                        options={[{ label: 'All', value: 'all' }, ...tags]}
                      />
                    </Col>
                  ) : null}
                  {hasRequiredRole(user, 'contributor') && (
                    <Col>
                      <CmdButton
                        onClick={() => {
                          history.push(`${editorRoutes.CHECKLIST_ROUTE}/new`);
                        }}
                        icon={<Plus />}
                        variant="primary"
                      >
                        New
                      </CmdButton>
                    </Col>
                  )}
                </Row>
                <Table
                  pagination={{
                    pageSize: 10,
                    hideOnSinglePage: true,
                    current: currentPage,
                    onChange: (page) => {
                      history.replace(`${history.location.pathname}?page=${page}`);
                      setCurrentPage(page);
                    },
                  }}
                  rowClassName="editable-row"
                  columns={columns.filter((c) => !c.hidden)}
                  dataSource={checklists
                    .filter((n) => {
                      return selectedTag === 'all' || n.editor_tags.includes(selectedTag);
                    })
                    .map((n) => ({ ...n, key: n.id }))}
                  onRow={(checklist: IChecklist, _rowIndex: any) => {
                    return {
                      onClick: (_e: React.MouseEvent) => {
                        updateActiveChecklist(checklist);
                      },
                    };
                  }}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                          "You don't have any checklists yet. Create one by clicking the 'Create Checklist' button above."
                        }
                      />
                    ),
                  }}
                />
              </SimplePanel>
            </div>
          </PaddingContainer>
        </TabPane>

        {flags['release-themes-v2'] && hasRequiredRole(user, 'editor') && (
          <TabPane tab="Settings" key="settings">
            <PaddingContainer>
              <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.THEME} header="Theme">
                <ThemeCustomizationSettings
                  themeUUID={organization.checklists_custom_theme}
                  setThemeUUID={(themeUUID) => {
                    dispatch.organization.updateSetting({ checklists_custom_theme: themeUUID });
                  }}
                />
              </Panel>
            </PaddingContainer>
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export default ChecklistList;
