import React from 'react';
import { useHistory } from 'react-router';

import {
  Trash01,
  Copy03,
  Pin01,
  Plus,
  Hand,
  ArrowNarrowRight,
  InfoHexagon,
} from '@commandbar/design-system/icons/react';
import {
  DropdownMenu,
  Modal,
  FeatureAnnouncementCard,
  Row,
  SimplePanel,
  PaddingContainer,
} from '@commandbar/design-system/components/antd';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';

import { Skin } from '@commandbar/internal/middleware/skin';
import { ISkinType } from '@commandbar/internal/middleware/types';
import * as axiosInstance from '@commandbar/internal/middleware/network';

import SkinPreview from './SkinPreview';
import Sender from '../../management/Sender';
import { useReportEvent } from '../../hooks/useEventReporting';
import { ScrollContainer } from '../Router';
import { Alert, AlertTitle, CmdButton, CmdTag } from '@commandbar/design-system/cmd';
import { reportErrorToUser } from '../utils/ErrorReporting';
import { useAppContext } from 'editor/src/AppStateContext';

const SkinList = ({
  skins,
  refreshSkins,
  isThemeSetProgrammatically,
}: {
  skins: ISkinType[];
  refreshSkins: () => Promise<void>;
  isThemeSetProgrammatically: boolean;
}) => {
  const { dispatch } = useAppContext();
  const history = useHistory();

  const setActiveSkin = (skin: ISkinType) => {
    history.push(`${editorRoutes.DESIGN_ROUTE}/skins/${skin.id}`);
  };

  const { reportEvent } = useReportEvent();

  const makeDefault = async (slug: string) => {
    try {
      await axiosInstance.patch(`skins/${slug}/`, { default: true });

      refreshSkins();

      Sender.setTheme('reset');
      Sender.setTheme(slug);

      reportEvent('skin made default', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: slug,
      });
    } catch (err) {
      reportErrorToUser(err);
    }
  };

  const createSkin = async () => {
    try {
      const skin = await Skin.create({});
      await refreshSkins();

      setActiveSkin(skin);
      reportEvent('skin created', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: skin?.slug,
      });
    } catch (err) {
      reportErrorToUser(err);
    }
  };

  const duplicateSkin = async (origin: ISkinType) => {
    const getUniqSlugAndId = (origin: ISkinType, existingSkins: ISkinType[]): { id: string; slug: string } => {
      const idTemplate = `copy-of-${origin.id}`;
      const slugTemplate = `copy-of-${origin.slug}`;

      if (existingSkins.findIndex(({ id, slug }: ISkinType) => id === idTemplate || slug === slugTemplate) !== -1) {
        for (let i = 1; i <= existingSkins.length; i++) {
          if (
            existingSkins.findIndex(
              ({ id, slug }: ISkinType) => id === `${idTemplate}-${i + 1}` || slug === `${slugTemplate}-${i + 1}`,
            ) === -1
          ) {
            return {
              id: `${idTemplate}-${i + 1}`,
              slug: `${slugTemplate}-${i + 1}`,
            };
          }
        }
      }

      return {
        id: idTemplate,
        slug: slugTemplate,
      };
    };

    try {
      const { id, slug } = getUniqSlugAndId(origin, skins);

      // We do this since for a new user, the default is {}
      // if "skin" is empty, the backend will pick some random colors for the new skin
      // When duplicating an existing skin, we don't want random colors.
      const skin_ = Object.keys(origin.skin).length === 0 ? { base: {} } : origin.skin;

      const skin = await Skin.create({
        id,
        slug,
        logo: origin.logo || '',
        name: `Copy of ${origin.name}`,
        skin: skin_,
      });
      await refreshSkins();

      setActiveSkin(skin);

      reportEvent('skin duplicated', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: skin.slug,
      });
    } catch (err) {
      reportErrorToUser(err);
    }
  };

  const deleteSkin = async (slug: string) => {
    try {
      await Skin.delete(slug);
      await refreshSkins();
      reportEvent('skin deleted', {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: slug,
      });
    } catch (err) {
      reportErrorToUser(err);
    }
  };

  const onTryThemesV2 = async () => {
    await axiosInstance.post('/organizations/create_default_theme_and_localized_messages/').catch((_err) => {
      return;
    });
    dispatch.themes.reload();

    localStorage.setItem('commandbar.flags:release-themes-v2', 'true');
    await dispatch.flags.reload();
    await Sender.reload(['reloadFlags']);
    history.push(editorRoutes.THEME_ROUTE);
  };

  return (
    <ScrollContainer>
      <PaddingContainer>
        <Alert className="justify-between align-center">
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Hand style={{ rotate: '45deg' }} />
            <AlertTitle>We're upgrading your themes experience soon.</AlertTitle>
          </div>
          <CmdButton id="try-themes-v2-button" onClick={onTryThemesV2}>
            Try it now <ArrowNarrowRight />
          </CmdButton>
        </Alert>
        <FeatureAnnouncementCard identifier="skins" title={<Row align="middle">Editing styles</Row>}>
          You can edit the theme of the Bar, Nudges, Checklists, and HelpHub to match the aesthetic of your app.
        </FeatureAnnouncementCard>
        <SimplePanel>
          {isThemeSetProgrammatically && (
            <div style={{ margin: '0px auto 10px auto', maxWidth: 350, textAlign: 'center' }}>
              <Alert variant="warning">
                <InfoHexagon />
                <AlertTitle>The current theme was set programmatically</AlertTitle>
              </Alert>
            </div>
          )}
          <div>
            <div>
              {skins.map((skin) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '8px 0px',
                    }}
                    key={`skin-${skin.slug}`}
                  >
                    <SkinPreview
                      skin={skin}
                      onClick={() => {
                        setActiveSkin(skin);
                      }}
                    />
                    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                      {skin.default && <CmdTag>Default skin</CmdTag>}
                      <DropdownMenu
                        keyName="options"
                        items={[
                          {
                            name: 'Duplicate',
                            icon: <Copy03 width={14} height={14} />,
                            onClick: () => duplicateSkin(skin),
                          },
                          ...(skin.default
                            ? []
                            : [
                                {
                                  name: 'Make default',
                                  icon: <Pin01 width={14} height={14} />,
                                  onClick: () => makeDefault(skin.slug),
                                },
                                {
                                  name: 'Delete',
                                  icon: <Trash01 width={14} height={14} />,
                                  onClick: () =>
                                    Modal.confirm({
                                      title: 'Are you sure you want to delete this skin? This cannot be undone.',
                                      async onOk() {
                                        await deleteSkin(skin.slug);
                                      },
                                    }),
                                },
                              ]),
                        ]}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <CmdButton icon={<Plus />} onClick={createSkin} style={{ marginTop: '16px' }}>
              Create a new skin
            </CmdButton>
          </div>
        </SimplePanel>
      </PaddingContainer>
    </ScrollContainer>
  );
};

export default SkinList;
