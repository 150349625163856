import * as t from 'io-ts';

import * as generics from './generics';
import { LabeledActionV } from '@cb/types/entities/command/actions';
import { HelpHubLauncherPositionsV, HelpHubLauncherTypesV, HelpHubSearchActionsV } from './helpHub';
import { CopilotPersonalityV } from './helpers/copilotPersonality';
import { AudienceV } from './helpers/audience';

const OrganizationSettingsKeys = {
  end_user_limit: t.number,
  user_seat_limit: t.number,
  analytics_dashboard_execution_limit: t.number,
  analytics_dashboard_deadend_limit: t.number,
  silent_mode: t.boolean,
  allow_event_handlers: t.boolean,
  event_attribute_block_list: t.array(t.string),
  skins_limit: t.number,
  skins_field_set: t.union([t.literal('pro'), t.literal('basic'), t.literal('none')]),
  show_countdown_banner: t.boolean,
  can_install_foobar: t.boolean,
  releases_available: t.boolean,
  releases_enabled: t.boolean,
  releases_environments: t.array(t.string),
  in_bar_feedback: t.boolean,
  force_end_user_identity_verification: t.boolean,
  force_end_user_identity_verification_for_helphub: t.boolean,
  summon_hotkey_override: t.union([t.string, t.null]),
  end_user_shortcuts_enabled: t.boolean,
  fallback_commands: t.array(t.number),
  end_user_recents_enabled: t.boolean,
  tab_direction: t.union([t.literal('horizontal'), t.literal('vertical')]),
  recommended_tab_enabled: t.boolean,
  recents_tab_enabled: t.boolean,
  recommended_tab_instruction: t.string,
  recents_tab_instruction: t.string,
  hotload_help_docs: t.boolean,
  slash_filters_enabled: t.boolean,
  recents_sort_key: t.union([t.number, t.null]),
  recommended_sort_key: t.union([t.number, t.null]),
  experiences_sort_key: t.union([t.number, t.null]),
  num_live_commands: t.number,
  help_center_sync: t.union([t.literal('upload-once'), t.literal('manual'), t.literal('automatic')]),
  show_suggested_synonyms: t.boolean,
  bar_enabled: t.boolean,
  in_bar_doc_search: t.boolean,
  featured_item_cards: t.boolean,
  chat_system_prompt_modifier: t.string,
  helphub_chat_welcome_message: t.string,
  helphub_suggested_queries_enabled: t.boolean,
  helphub_continuations_enabled: t.boolean,
  helphub_manual_suggested_queries: t.array(t.string),
  helphub_custom_theme: t.union([t.string, t.null]),

  product_tours_custom_theme: t.union([t.string, t.null]),
  announcements_custom_theme: t.union([t.string, t.null]),
  surveys_custom_theme: t.union([t.string, t.null]),
  checklists_custom_theme: t.union([t.string, t.null]),

  copilot_name: t.string,
  copilot_avatar: t.string,
  copilot_avatar_v2: t.union([t.type({ src: t.string, file_name: t.string, size: t.string }), t.null]),
  copilot_personality: CopilotPersonalityV,
  copilot_chat_history_enabled: t.boolean,
  helphub_enabled: t.boolean,
  helphub_launcher_type: HelpHubLauncherTypesV,
  helphub_launcher_position: HelpHubLauncherPositionsV,
  helphub_launcher_offset_x: t.number,
  helphub_launcher_offset_y: t.number,
  helphub_launcher_anchor: t.string,
  helphub_mobile_launcher_type: HelpHubLauncherTypesV,
  helphub_mobile_launcher_position: HelpHubLauncherPositionsV,
  helphub_mobile_launcher_offset_x: t.number,
  helphub_mobile_launcher_offset_y: t.number,
  helphub_mobile_launcher_anchor: t.string,
  helphub_chat_fallback_message: t.string,
  helphub_chat_fallback_actions: t.array(LabeledActionV),
  helphub_search_action: HelpHubSearchActionsV,
  copilot_negative_feedback_fallback_enabled: t.boolean,
  copilot_negative_feedback_fallback_message: t.union([t.string, t.null]),
  copilot_negative_feedback_fallback_actions: t.array(LabeledActionV),
  copilot_expand_read_more_by_default: t.boolean,
  nudge_rate_limit: t.union([t.null, t.number]),
  bar_hide_completed_nudges_questlists: t.boolean,
  nudge_rate_period: t.union([t.literal('day'), t.literal('week'), t.literal('session')]),
  share_link_param: t.string,
  spotlight_ask_copilot_enabled: t.boolean,
  spotlight_ask_copilot_label: t.union([t.string, t.null]),
  spotlight_custom_theme: t.union([t.string, t.null]),
  spotlight_ask_copilot_audience: t.union([AudienceV, t.null]),
  themes_v2_self_serve_migration_date: t.union([t.null, t.string]),

  copilot_enabled: t.boolean,

  /** @deprecated Replaced by the `copilot_enabled` field */
  helphub_ai_enabled: t.boolean,
  /** @deprecated Removed entirely */
  copilot_experiences_enabled: t.boolean,
  /** @deprecated Replaced by the combination of `copilot_enabled` and `helphub_enabled` */
  helphub_chat_only_mode: t.boolean,
};

export const OrganizationSettingsV = t.type(OrganizationSettingsKeys);
const OrganizationSettingsPartialV = t.partial(OrganizationSettingsKeys);

export const read = generics.read(OrganizationSettingsV, 'org-settings');
export const update = generics.update(OrganizationSettingsV, OrganizationSettingsPartialV, 'org-settings');
