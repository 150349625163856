import { computePosition, offset } from '@floating-ui/dom';
import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor, useFocused, useSlate } from 'slate-react';

import {
  CmdFloatingToolbar,
  CmdFloatingToolbarProvider,
  type EditorLayout,
  useCmdFloatingToolbar,
} from '@commandbar/design-system/cmd';
import { useMouseDragState } from '@commandbar/internal/hooks/useMouseDragState';
import { Commands } from '../../commands';
import { EMPTY_VALUE, IGNORE_OUTSIDE_CLICK_ATTR } from '../../constants';
import type { LinkElement } from '../../types';
import { EditLink } from './EditLink';
import { PreviewLink } from './PreviewLink';
import { TextSelection } from './TextSelection';

export enum ToolbarType {
  Selection = 'selection',
  PreviewLink = 'previewLink',
  EditLink = 'editLink',
}

interface ToolbarContextState<T = ToolbarType, U = LinkElement | null> {
  editorLayout: EditorLayout;
  type: T;
  setType: Dispatch<SetStateAction<T>>;
  linkElement: U;
  setLinkElement: Dispatch<SetStateAction<U>>;
  insertLink?: () => void;
  close?: () => void;
}

const EditorToolbarContext = createContext<ToolbarContextState | undefined>(undefined);

export const useEditorToolbar = () => {
  const editor = useSlate();
  const floatingToolbarContext = useCmdFloatingToolbar();
  const editorToolbarContext = useContext(EditorToolbarContext);

  if (editorToolbarContext == null) {
    throw new Error('Editor Toolbar components must be wrapped in <EditorToolbar />');
  }

  const close = useCallback(() => {
    floatingToolbarContext.close();
    editorToolbarContext.setLinkElement(null);
  }, [floatingToolbarContext.close, editorToolbarContext.setLinkElement]);

  const updateLink = useCallback(
    (url: string) => {
      if (editorToolbarContext.linkElement) {
        const path = ReactEditor.findPath(editor, editorToolbarContext.linkElement);
        Commands.updateLinkAtPath(editor, url, path);
      } else {
        Commands.applyLink(editor, url);
      }

      close();
      Commands.focusEditor(editor);
    },
    [close, editor, editorToolbarContext.linkElement],
  );

  const removeLink = useCallback(() => {
    if (editorToolbarContext.linkElement) {
      const path = ReactEditor.findPath(editor, editorToolbarContext.linkElement);
      Commands.removeLinkAtPath(editor, path);
    } else {
      Commands.removeLink(editor);
    }

    close();
    Commands.focusEditor(editor);
  }, [editor, editorToolbarContext.linkElement, close]);

  return {
    ...floatingToolbarContext,
    ...editorToolbarContext,
    close,
    updateLink,
    removeLink,
  };
};

interface EditorToolbarProviderProps {
  editorLayout?: EditorLayout;
}

export const EditorToolbarProvider: FC<EditorToolbarProviderProps> = ({ children, editorLayout = 'multi-line' }) => {
  const [type, setType] = useState(ToolbarType.Selection);
  const [linkElement, setLinkElement] = useState<LinkElement | null>(null);

  return (
    <CmdFloatingToolbarProvider>
      <EditorToolbarContext.Provider value={{ editorLayout, type, setType, linkElement, setLinkElement }}>
        {children}
      </EditorToolbarContext.Provider>
    </CmdFloatingToolbarProvider>
  );
};

const computePositionConfig: Parameters<typeof computePosition>[2] = {
  placement: 'top',
  middleware: [offset(4)],
};

const useSelectionAnchoring = () => {
  const editor = useSlate();
  const isEditorFocused = useFocused();
  const isMouseDragging = useMouseDragState();
  const { openAtPosition, toolbarRef, setType, type, isOpen, close } = useEditorToolbar();

  const { selection } = editor;

  // Computes the position of the selection relative to the toolbar element.
  // Uses a virtual element representing the current selection range.
  // This is necessary because the selection may not correspond to a single DOM element.
  // Reference: https://floating-ui.com/docs/virtual-elements
  const computeSelectionPosition = useCallback((element: HTMLElement) => {
    const domSelection = window.getSelection();
    if (!domSelection) return;

    // Create a virtual element that mimics the selection's bounding rectangle.
    const virtualSelectionElement: Parameters<typeof computePosition>[0] = {
      getBoundingClientRect: () => domSelection.getRangeAt(0).getBoundingClientRect(),
    };

    return computePosition(virtualSelectionElement, element, computePositionConfig);
  }, []);

  useEffect(() => {
    // Exit early if:
    // - Toolbar or selection is unavailable.
    // - Editor is not focused.
    // - No text is selected.
    // - User is still dragging to select text.
    if (
      !(selection && toolbarRef?.current && isEditorFocused) ||
      Range.isCollapsed(selection) || // No text is selected.
      Editor.string(editor, selection) === EMPTY_VALUE ||
      isMouseDragging // Selection is still being modified.
    ) {
      return;
    }

    setType(ToolbarType.Selection);

    // Position the toolbar relative to the current selection.
    computeSelectionPosition(toolbarRef.current)?.then(({ x, y }) => {
      openAtPosition({ x, y });
    });
  }, [
    computeSelectionPosition,
    editor,
    isEditorFocused,
    isMouseDragging,
    openAtPosition,
    selection,
    toolbarRef,
    setType,
  ]);

  useEffect(() => {
    // It's possible for an external event to remove the selection.
    // If that happens, we need to close the toolbar.
    if (selection && Range.isCollapsed(selection) && type === ToolbarType.Selection && isOpen) {
      close();
    }
  }, [close, selection, isOpen, type]);
};

export const EditorToolbar = () => {
  const editor = useSlate();
  const { close, type } = useEditorToolbar();

  useSelectionAnchoring();

  const renderBody = () => {
    switch (type) {
      case ToolbarType.PreviewLink:
        return <PreviewLink />;
      case ToolbarType.EditLink:
        return <EditLink />;
      default:
        return <TextSelection />;
    }
  };

  return (
    <CmdFloatingToolbar
      onKeyDown={(event) => {
        if (event.key !== 'Escape') return;

        close();
      }}
      onOutsideClick={(event) => {
        const isAnchorClick =
          // eslint-disable-next-line commandbar/no-event-target
          event.target instanceof HTMLElement && event.target.closest(`[data-${IGNORE_OUTSIDE_CLICK_ATTR}]`) !== null;

        // clicking a link opens the link preview toolbar
        // we want to leave the toolbar open rather than closing,
        // then reopening it
        if (isAnchorClick) return;

        close();
        Commands.collapseSelection(editor);
      }}
    >
      {renderBody()}
    </CmdFloatingToolbar>
  );
};
