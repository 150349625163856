import { useCallback, useRef } from 'react';

import { useEventListener } from './useEventListener';

export const useOutsideClick = (
  outsideClickHandler: (event: MouseEvent | TouchEvent) => void,
  eventType: 'mousedown' | 'touchstart' = 'mousedown',
) => {
  const ref = useRef<HTMLElement>(null);

  const handleOutsideClick: EventListener = useCallback(
    (event) => {
      // eslint-disable-next-line commandbar/no-event-target
      if (!((event instanceof MouseEvent || event instanceof TouchEvent) && event.target instanceof Node)) return;

      const element = ref.current;
      // eslint-disable-next-line commandbar/no-event-target
      if (!element || element.contains(event.target)) return;

      outsideClickHandler(event);
    },
    [outsideClickHandler],
  );

  useEventListener(eventType, handleOutsideClick);

  return ref;
};
