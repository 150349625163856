import React from 'react';
import {
  Calendar,
  Copy02,
  Data,
  FileCheck02,
  LayoutAlt01,
  Link01,
  Monitor01,
  PieChart02,
  Scan,
  Tablet01,
  Translate01,
  User03,
} from '@commandbar/design-system/icons/react';
import { IOrganizationType, IRuleExpression } from '@commandbar/internal/middleware/types';
import { walkConditions } from '@commandbar/internal/client/RulesParser';
import { Condition } from '@commandbar/internal/middleware/conditions';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { Flags } from '@cb/types/flags';

export const CONDITION_TYPE_CATEGORY_NAMES = [
  'Custom properties',
  'Built-in properties',
  'Experimentation',
  'CommandBar interactions',
  'Activity' /* @deprecated */,
  'Page',
  'Integrations',
  'Event',
] as const;

export type ConditionTypeCategoryName = (typeof CONDITION_TYPE_CATEGORY_NAMES)[number];

// "who" targeting doesn't include Page nor Event
export const CONDITION_TYPE_CATEGORIES_FOR_TARGETING_WHO: ConditionTypeCategoryName[] = [
  'Custom properties',
  'Built-in properties',
  'Experimentation',
  'CommandBar interactions',
  'Activity' /* @deprecated */,
  'Integrations',
];

// legacy ("who" and "where" combined, used in the "availability" components)
export const CONDITION_TYPE_CATEGORIES_FOR_LEGACY_AVAILABILITY: ConditionTypeCategoryName[] = [
  'Custom properties',
  'Built-in properties',
  'Experimentation',
  'CommandBar interactions',
  'Activity' /* @deprecated */,
  'Page',
  'Integrations',
];

export type ConditionTypeOption = {
  label: string;
  value: Condition['type'];
  description: string;
  disabled: boolean;
  icon?: any;
  disabledDescription?: string;
  multiple?: boolean;
};

export type ConditionTypeCategory = {
  category: ConditionTypeCategoryName;
  options: ConditionTypeOption[];
};

export const isConditionTypeInActivityGroup = (type: Condition['type']) => {
  return ['shortcuts', 'opens', 'deadends', 'executions'].includes(type);
};

export const containsConditionType = (expr: IRuleExpression, type: Condition['type']): boolean => {
  let contains = false;

  walkConditions(expr, (condition) => {
    if (condition.type === type) {
      contains = true;
    }
  });

  return contains;
};

export const defaultConditionType = 'first_seen';

export const defaultConditions: Record<Condition['type'], Condition> = {
  always: { type: 'always' },
  audience: { type: 'audience', operator: 'is', audience_id: -1 },
  shortcuts: { type: 'shortcuts', operator: 'isGreaterThan', value: '0' },
  opens: { type: 'opens', operator: 'isGreaterThan', value: '0' },
  deadends: { type: 'deadends', operator: 'isGreaterThan', value: '0' },
  executions: { type: 'executions', operator: 'isGreaterThan', value: '0' },
  heap: { type: 'heap', operator: 'isTrue', field: '' },
  hubspot: { type: 'hubspot', operator: 'isTrue', field: '' },
  amplitude: { type: 'amplitude', operator: 'isTrue', field: '' },
  user_property: { type: 'user_property', operator: 'is', field: '', value: '' },
  named_rule: { type: 'named_rule', rule_id: -1 },
  context: { type: 'context', operator: 'is', field: '', value: '' },
  browser: { type: 'browser', operator: 'includes', values: [] },
  language: { type: 'language', operator: 'includes', values: [] },
  os: { type: 'os', operator: 'includes', values: [] },
  device_type: { type: 'device_type', operator: 'is', value: '' },
  ab_test: { type: 'ab_test', operator: 'is', value: 'A', field: '' },
  first_seen: { type: 'first_seen', operator: 'isGreaterThan', value: '1' },
  last_seen: { type: 'last_seen', operator: 'isGreaterThan', value: '1' },
  sessions: { type: 'sessions', operator: 'isGreaterThan', value: '1' },
  url: { type: 'url', operator: 'includes', value: '' },
  hostname: { type: 'hostname', operator: 'includes', value: '' },
  element: { type: 'element', operator: 'idOnPage', value: '' },
  nudge_interaction: { type: 'nudge_interaction', operator: 'is', value: 'viewed', nudge_id: -1 },
  questlist_interaction: { type: 'questlist_interaction', operator: 'is', value: 'viewed', questlist_id: -1 },
  event_property: { type: 'event_property', operator: 'is', field: '', value: '' },
  intent: { type: 'intent', operator: 'is', values: [] },
  help_doc_interaction: { type: 'help_doc_interaction', operator: 'is', interaction_type: 'viewed', help_doc_id: -1 },
  video_interaction: { type: 'video_interaction', operator: 'is', interaction_type: 'viewed', video_id: -1 },
  survey_response: {
    type: 'survey_response',
    operator: 'is',
    nudge_id: -1,
    nudge_step_id: -1,
    response_operator: 'is',
    response_type: 'text',
    value: '',
  },
};

export const getConditionTypeCategories = (
  organization: IOrganizationType,
  flags: Flags | undefined,
  expr: IRuleExpression,

  includeCategories: ConditionTypeCategoryName[] | null,
  excludeCategories: ConditionTypeCategoryName[],
  excludeConditionTypes: Condition['type'][],

  isSilentMode: boolean,
): { categories: ConditionTypeCategory[]; newConditionDefaultType: Condition['type'] } => {
  const silentModeDisabledReason = 'Requires disabling Silent mode.';

  const baseGroups: ConditionTypeCategory[] = [
    {
      category: 'Activity',
      options: [
        {
          label: 'Shortcuts Executed',
          value: 'shortcuts',
          description: 'Number of times a user has used keyboard shortcuts.',
          icon: <Data style={{ marginRight: '5px' }} />,
          disabledDescription: silentModeDisabledReason,
          disabled: isSilentMode,
        },
        {
          label: 'Opens',
          value: 'opens',
          description: 'Number of CommandBar searches performed by a user.',
          icon: <Data style={{ marginRight: '5px' }} />,
          disabled: isSilentMode,
          disabledDescription: silentModeDisabledReason,
        },
        {
          label: 'Deadends',
          value: 'deadends',
          description: 'Number of deadends generated by a user.',
          icon: <Data style={{ marginRight: '5px' }} />,
          disabled: isSilentMode,
          disabledDescription: silentModeDisabledReason,
        },
        {
          label: 'Commands Executed',
          value: 'executions',
          description: 'How often a user has executed a command.',
          icon: <Data style={{ marginRight: '5px' }} />,
          disabled: isSilentMode,
          disabledDescription: silentModeDisabledReason,
        },
      ],
    },
    {
      category: 'Integrations',
      options: [
        {
          label: 'Heap Segment',
          value: 'heap',
          description: 'Heap Segment a user may or may not be part of',
          icon: <PieChart02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'HubSpot List',
          value: 'hubspot',
          description: 'HubSpot List a user may or may not be part of',
          icon: <PieChart02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Amplitude Cohorts',
          value: 'amplitude',
          description: 'Amplitude Cohort a user may or may not be part of',
          icon: <PieChart02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    },
    {
      category: 'Custom properties',
      options: [
        {
          label: 'User property',
          value: 'user_property',
          description: '',
          icon: <User03 style={{ marginRight: '5px' }} />,
          disabled: isSilentMode || (getConditions(expr).length > 1 && containsConditionType(expr, 'context')),
          disabledDescription: isSilentMode
            ? 'Cannot set this property when silent mode is enabled'
            : 'Cannot use this property in combination with metadata',
        },
        {
          label: 'Audience',
          value: 'audience',
          description: '',
          icon: <FileCheck02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Rule',
          value: 'named_rule',
          description: '',
          icon: <FileCheck02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Metadata',
          value: 'context',
          description: '',
          icon: <Scan style={{ marginRight: '5px' }} />,
          disabled: getConditions(expr).length > 1 && containsConditionType(expr, 'user_property'),
          disabledDescription: 'Cannot use this property in combination with a user property',
        },
      ],
    },
    {
      category: 'CommandBar interactions',
      options: [
        {
          label: 'Intent (chats, searches)',
          value: 'intent',
          description: '',
          disabled: false,
        },
        {
          label: 'Nudge interactions',
          value: 'nudge_interaction',
          description: '',
          disabled: false,
        },
        {
          label: 'Survey responses',
          value: 'survey_response',
          description: '',
          disabled: false,
        },
        {
          label: 'Article or file views',
          value: 'help_doc_interaction',
          description: '',
          disabled: false,
        },
        // XXX: Our video watched events are unintuitive, so for now we're hiding this condition (https://github.com/tryfoobar/monobar/pull/6266)
        // {
        //   label: 'Video',
        //   value: 'video_interaction',
        //   description: '',
        //   disabled: false,
        // },
      ],
    },
    {
      category: 'Built-in properties',
      options: [
        {
          label: 'Browser',
          value: 'browser',
          description: '',
          icon: <LayoutAlt01 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Language',
          value: 'language',
          description: '',
          icon: <Translate01 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Operating System',
          value: 'os',
          description: '',
          icon: <Monitor01 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Device Type',
          value: 'device_type',
          description: '',
          icon: <Tablet01 style={{ marginRight: '5px' }} />,
          disabled: false,
        },

        {
          label: 'First Seen',
          value: 'first_seen',
          description: 'When a user was first seen in the app in relative days.',
          icon: <Calendar style={{ marginRight: '5px' }} />,
          disabled: isSilentMode,
          disabledDescription: silentModeDisabledReason,
        },
        {
          label: 'Last Seen',
          value: 'last_seen',
          description: 'When a user was last seen in the app in relative days.',
          icon: <Calendar style={{ marginRight: '5px' }} />,
          disabled: isSilentMode,
          disabledDescription: silentModeDisabledReason,
        },
        {
          label: 'Sessions',
          value: 'sessions',
          description: 'Number of sessions logged for a user.',
          icon: <Data style={{ marginRight: '5px' }} />,
          disabled: isSilentMode,
          disabledDescription: silentModeDisabledReason,
        },
      ] as ConditionTypeOption[],
    },
    {
      category: 'Experimentation',
      options: [
        {
          label: 'A/B Test',
          value: 'ab_test',
          description: '',
          icon: <User03 style={{ marginRight: '5px' }} />,
          disabled: isSilentMode || containsConditionType(expr, 'context'),
        },
      ],
    },
    {
      category: 'Page',
      options: [
        {
          label: 'Current URL Path',
          value: 'url',
          description: '',
          icon: <Link01 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'Current Domain',
          value: 'hostname',
          description: '',
          icon: <Link01 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
        {
          label: 'DOM Element',
          value: 'element',
          description: '',
          icon: <Copy02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    },
    {
      category: 'Event',
      options: [
        {
          label: 'Event property',
          value: 'event_property',
          description: '',
          icon: <Copy02 style={{ marginRight: '5px' }} />,
          disabled: false,
        },
      ],
    },
  ];

  // Filter out certain conditions and categories based on various checks

  // Activity category is only shown if a condition group already contains an activity condition
  if (!getConditions(expr).some((cond) => isConditionTypeInActivityGroup((cond as Condition).type))) {
    excludeCategories = [...excludeCategories, 'Activity'];
  }

  // Custom properties category is only shown if both the 'user_property_targeting' and 'ab_test_conditions' settings are enabled
  if (!organization?.user_property_targeting || !organization?.ab_test_conditions) {
    excludeConditionTypes = [...excludeConditionTypes, 'ab_test'];
  }

  // Heap is only shown if the Heap integration is enabled
  if (!organization?.integrations?.heap) {
    excludeConditionTypes = [...excludeConditionTypes, 'heap'];
  }

  // HubSpot is only shown if the HubSpot integration is enabled
  if (!organization?.integrations?.hubspot) {
    excludeConditionTypes = [...excludeConditionTypes, 'hubspot'];
  }

  // Amplitude is only shown if the Amplitude integration is enabled
  if (!organization?.integrations?.amplitude) {
    excludeConditionTypes = [...excludeConditionTypes, 'amplitude'];
  }

  // User property condition is only shown if the user_property_targeting setting is enabled.
  // Currently, this is only disabled for orgs on foobar
  if (!organization?.user_property_targeting) {
    excludeConditionTypes = [...excludeConditionTypes, 'user_property'];
  }

  const categories = baseGroups
    .filter((c) => !includeCategories || includeCategories.includes(c.category))
    .filter((c) => !excludeCategories || !excludeCategories.includes(c.category))
    .map((c) => ({
      ...c,
      options: c.options.filter((o) => !excludeConditionTypes || !excludeConditionTypes.includes(o.value)),
    }))
    .filter((c) => c.options.length);

  const availableConditions = categories
    .flatMap((c) => c.options)
    .filter((o) => !o.disabled)
    .map((o) => o.value);

  // FIXME: this is a bit complex, but we have custom logic to make sure we don't default to
  // context or user_property when it's invalid. It's not captured in `disabled` because `disabled`
  // is a bit more flexible to allow changing properties if the user is choosing the first condition
  let newConditionDefaultType = availableConditions[0] || defaultConditionType;
  if (newConditionDefaultType === 'user_property' && containsConditionType(expr, 'context')) {
    newConditionDefaultType = 'context';
  } else if (newConditionDefaultType === 'context' && containsConditionType(expr, 'user_property')) {
    newConditionDefaultType = 'user_property';
  }
  return { categories, newConditionDefaultType };
};
