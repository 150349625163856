/******************************************************************/
/* Imports
/******************************************************************/

/* External imports */
import * as React from 'react';

import useOS from './useOS';

interface IOption {
  trigger?: boolean;
  shift?: boolean;
  override?: boolean;
  useCtrl?: boolean;
  useCapture?: boolean;
  preventDefault?: boolean;
  stopPropagation?: boolean;
}

const useHotkey = (
  hotkey: string,
  callback: (event: KeyboardEvent) => void,
  {
    trigger = true,
    shift = false,
    override = false,
    useCtrl = false,
    useCapture = false,
    preventDefault = true,
    stopPropagation = true,
  }: IOption = {},
) => {
  const { getEventTrigger } = useOS();

  React.useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      const eventTrigger = !!trigger ? (!!useCtrl ? e.ctrlKey : getEventTrigger(e)) : true;

      let condition;

      if (!!shift) {
        condition = e.key === hotkey && eventTrigger && e.shiftKey;
      } else {
        condition = e.key === hotkey && eventTrigger && !e.shiftKey;
      }

      if (condition && !override) {
        if (preventDefault) {
          e.preventDefault();
        }
        if (stopPropagation) {
          e.stopPropagation();
        }

        callback(e);
      }
    };
    document.addEventListener('keydown', handleKeydown, useCapture);
    return () => {
      document.removeEventListener('keydown', handleKeydown, useCapture);
    };
  });

  return;
};

export default useHotkey;
