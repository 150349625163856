import { IAPI, IChecklist, IEditorCommandTypeLite, INudgeType, IWorkflow } from '@commandbar/internal/middleware/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: has no exported member 'mapPlainTextIndex' and 'markupToRegex'
import { MentionsInput, Mention, mapPlainTextIndex, markupToRegex } from 'react-mentions';
import styled from '@emotion/styled';
import _ from 'lodash';
import React from 'react';
import { Tooltip, Modal, Container, HeaderRow, Dropdown, Select } from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';

import {
  Trash04,
  FlipBackward,
  AlertTriangle,
  AtSign,
  Edit03,
  Passcode,
  Settings03,
  InfoCircle,
  Bookmark,
} from '@commandbar/design-system/icons/react';
import {
  CmdAccordion,
  CmdButton,
  CmdLabel,
  CmdSwitch,
  CmdTag,
  CmdTextarea,
  CmdTypography,
  cmdToast,
} from '@commandbar/design-system/cmd';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { useReportEvent } from 'editor/src/hooks/useEventReporting';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useModS } from '@commandbar/internal/hooks/useModS';

import {
  DetailTabPane,
  DetailTabPaneInner,
  DetailTabs,
  MenuIconContainer,
  OverlaySettingsContainer,
  OverlaySettingsSection,
  StyledSelect,
} from '../components/styled';
import { AudienceSelect } from '../AudienceSelector';
import { CaretDown } from '../helphub/shared';
import { getDisplayTitle } from '@commandbar/internal/util/nudges';
import { allAPIAndWorkflowToolNames, convertTitleToToolName } from './utils';
import ResponsiveInput from 'editor/src/components/Forms/ResponsiveInput';

type SuggestionDataItem = { id: string | number; display?: string };

// There is something weird going on that prevents our normal tab styles from applying
// TODO figure this out
const StyledDetailTabs = styled(DetailTabs, { shouldForwardProp: (prop) => prop !== 'isStudio' })`
  .ant-tabs-tab {
    border: ${(props: { isStudio?: boolean }) => (props?.isStudio ? '1px solid transparent !important' : '')};
    transition: ${(props: { isStudio?: boolean }) =>
      props?.isStudio ? 'border-bottom 0s !important' : 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'};
    padding: 12px 16px;
    margin: 0px;
    margin-left: 4px;
    background: transparent;
  }

  .ant-tabs-tab-active {
    border: ${(props: { isStudio?: boolean }) => (props?.isStudio ? '1px solid #e5e4e7 !important' : '0 !important')};
    border-bottom: ${(props: { isStudio?: boolean }) => (props?.isStudio ? 'none !important' : '')};
    background: ${(props: { isStudio?: boolean }) =>
      props?.isStudio ? '#f9f9f9 !important' : 'transparent !important'};
  }
`;

const IconContainer = styled.div`
  display: flex;
  height: 20px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #e7e8ea;
`;

const InputWrapper = styled.div`
  textarea {
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03);

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
    &:focus {
      box-shadow: inset 0px -1px 3px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 1px 0px rgba(0, 0, 0, 0.03),
        0px 0px 0px 3px rgba(39, 84, 238, 0.16);
      border: 1px solid rgb(39, 84, 238);
      outline: none;
    }
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const DropdownStyled = styled(Dropdown)`
  position: absolute;
  top: 6px;
  right: 16px;
  z-index: 10;
`;

const StyledMention = styled(Mention)`
  position: relative;
  z-index: 1;
  color: #9a3412;
  background-color: #ffedd5;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  font: inherit;
  pointer-events: none;
`;

const promptPlaceholder = `Workflows are instructions for how Copilot should handle a specific task. You can tag actions, like calling an API, showing a Nudge, or chatting with an agent. To do this, type '@' and select an option. Your instructions should be concise and clear.

For example, a Workflow to give customers details about their order might look like this:

1. Ask the customer for their order number if they haven't provided it.
2. Check their order status using [@API: Get order status], then summarize the order details including shipping status, estimated arrival date, and tracking number. 
3. If the customer expresses any concerns about shipping delays, suggest that they chat with a human.`;

interface WorkflowDetailProps {
  initialWorkflow: IWorkflow;
  onClose: () => void;
  onDelete: (workflow: IWorkflow) => void;
  onSave: (workflow: IWorkflow) => Promise<void>;
}

enum WorkflowFormTabs {
  DETAILS = 'tab-details',
  TARGETING = 'tab-targeting',
}

const getErrors = (dirty: IWorkflow, isAllowedToSave: boolean, existingToolNames: string[]) => {
  const allErrors: string[] = [];
  if (!dirty.title.trim().length || !dirty.title.trim().length || dirty.title.trim() === '') {
    allErrors.push('Name is required.');
  }
  if (dirty.title.toLowerCase().trim() === 'exit' || /^action_|^tour_|^checklist_/.test(dirty.title.toLowerCase())) {
    allErrors.push('Name is a reserved value - choose something different.');
  }
  if (existingToolNames.includes(convertTitleToToolName(dirty.title))) {
    allErrors.push('Name must be unique between APIs and Workflows.');
  }
  if (!dirty.trigger.intent_description.trim().length || !dirty.title.trim().length) {
    allErrors.push('Intent trigger in the Targeting tab is required.');
  }
  if (!isAllowedToSave) {
    allErrors.push('You do not have permission to save this workflow');
  }
  if (dirty.prompt.length > 4000) {
    allErrors.push('Prompt must be 4000 characters or less.');
  }
  if (dirty.trigger.intent_description.length > 800) {
    allErrors.push('Intent trigger must be 800 characters or less.');
  }
  return allErrors;
};

export const getOptionsList = (
  apis: IAPI[],
  workflows: IWorkflow[],
  commands: IEditorCommandTypeLite[],
  nudges: INudgeType[],
  checklists: IChecklist[],
  currentWorkflowId?: number,
) => {
  const APIsOptionsList: SuggestionDataItem[] = apis
    .filter((a) => a.is_live && !a.archived)
    .map((api) => ({
      id: `api: ${api.id}`,
      display: `API: ${api.title}`,
    }));

  const workflowsOptionsList: SuggestionDataItem[] = workflows
    .filter((w) => w.is_live && !w.archived && w.id !== currentWorkflowId)
    .map((workflow) => ({
      id: `workflow: ${workflow.id}`,
      display: `Workflow: ${workflow.title}`,
    }));

  const actionsOptionsList: SuggestionDataItem[] = commands
    .filter(
      (c) => c.template.type !== 'helpdoc' && c.template.type !== 'video' && c.template.type !== 'link' && c.is_live,
    )
    .map((c) => ({
      id: `action: {"type": "execute_command", "meta": {"command": "${c.id}"}}`,
      display: `Action: ${c.text}`,
    }));

  const pagesOptionsList: SuggestionDataItem[] = commands
    .filter((c) => c.template.type === 'link' && c.is_live)
    .map((c) => ({
      id: `action: {"type": "execute_command", "meta": {"command": "${c.id}"}}`,
      display: `Page: ${c.text}`,
    }));

  const nudgesOptionsList: SuggestionDataItem[] = nudges
    .filter((n) => n.is_live)
    .map((nudge) => ({
      id: `action: {"type": "nudge", "value":${nudge.id}}`,
      display: `Nudge: ${getDisplayTitle(nudge)}`,
    }));

  const checklistsOptionsList: SuggestionDataItem[] = checklists
    .filter((c) => c.is_live)
    .map((checklist) => ({
      id: `action: {"type": "questlist", "value":${checklist.id}}`,
      display: `Checklist: ${checklist.title}`,
    }));

  const otherOptions = [
    { id: 'exit', display: 'Exit Workflow' },
    // open_chat
    { id: 'action: {"type": "open_chat", "meta": {"type": "intercom"}}', display: 'Open third-party chat: Intercom' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "helpscout"}}', display: 'Open third-party chat: Helpscout' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "freshdesk"}}', display: 'Open third-party chat: Freshdesk' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "freshchat"}}', display: 'Open third-party chat: Freshchat' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "gist"}}', display: 'Open third-party chat: Gist' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "crisp"}}', display: 'Open third-party chat: Crisp' },
    {
      id: 'action: {"type": "open_chat", "meta": {"type": "talkdesk_v2"}}',
      display: 'Open third-party chat: Talkdesk',
    },
    { id: 'action: {"type": "open_chat", "meta": {"type": "zendesk"}}', display: 'Open third-party chat: Zendesk' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "liveChat"}}', display: 'Open third-party chat: LiveChat' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "olark"}}', display: 'Open third-party chat: Olark' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "hubspot"}}', display: 'Open third-party chat: HubSpot' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "drift"}}', display: 'Open third-party chat: Drift' },
    { id: 'action: {"type": "open_chat", "meta": {"type": "pylon"}}', display: 'Open third-party chat: Pylon' },
    { id: 'action: {"type": "open_bar"}', display: 'Open Spotlight' },
    // chat_handoff
    { id: 'action: {"type": "chat_handoff", "meta": {"type": "zendesk"}}', display: 'Chat handoff: Zendesk' },
    { id: 'action: {"type": "chat_handoff", "meta": {"type": "freshchat"}}', display: 'Chat handoff: Freshchat' },
    { id: 'action: {"type": "chat_handoff", "meta": {"type": "intercom"}}', display: 'Chat handoff: Intercom' },
    { id: 'action: {"type": "chat_handoff", "meta": {"type": "sfdc_chat"}}', display: 'Chat handoff: Salesforce Chat' },
    {
      id: 'action: {"type": "chat_handoff", "meta": {"type": "sfdc_messaging"}}',
      display: 'Chat handoff: Salesforce Messaging',
    },
  ];

  const actionDropdownOptions: SuggestionDataItem[] = APIsOptionsList.concat(
    workflowsOptionsList,
    actionsOptionsList,
    pagesOptionsList,
    nudgesOptionsList,
    checklistsOptionsList,
    otherOptions,
  );

  return actionDropdownOptions;
};

const WorkflowDetail = (props: WorkflowDetailProps) => {
  const { onClose, initialWorkflow, onDelete, onSave } = props;
  const { hasUnsavedChangesRef, rules, APIs, workflows, commands, flags, nudges, checklists } = useAppContext();

  const existingToolNames = allAPIAndWorkflowToolNames(APIs, workflows, initialWorkflow.title);

  const [dirty, setDirty] = React.useState(() => _.cloneDeep(initialWorkflow));
  const { user } = useAuth();

  const [isSaving, setIsSaving] = React.useState(false);
  const { reportEvent } = useReportEvent();

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const isNewWorkflow = initialWorkflow.id < 0;
  const isDirty = !_.isEqual(initialWorkflow, dirty) || isNewWorkflow;

  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const actionDropdownOptions = getOptionsList(APIs, workflows, commands, nudges, checklists, dirty.id);

  const fallbackDisplayName = 'Action no longer available';

  const getDisplayNameFromId = (id: string) => {
    return actionDropdownOptions.find((option) => option.id === id)?.display ?? fallbackDisplayName;
  };

  React.useEffect(() => {
    hasUnsavedChangesRef.current = isDirty;
  }, [isDirty]);

  const saveWorkflow = async (workflow: IWorkflow) => {
    setIsSaving(true);

    try {
      await onSave(workflow);
    } catch {
      cmdToast.error('Error saving workflow');
    } finally {
      setIsSaving(false);
    }
  };

  useModS(() => saveWorkflow(dirty));

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, props.initialWorkflow.is_live ? 'editor' : 'contributor');

  const allErrors = getErrors(dirty, isAllowedToSave, existingToolNames);

  const handleInsertAtCursor = (insertText: string) => {
    if (inputRef.current) {
      // set the prompt value
      const start = mapPlainTextIndex(
        dirty.prompt,
        [
          {
            markup: '<|__id__|>',
            regex: markupToRegex('<|__id__|>'),
            displayTransform: (id: string) => ` ${getDisplayNameFromId(id)} `,
          },
        ],
        inputRef.current.selectionStart,
        'START',
      );
      const end = mapPlainTextIndex(
        dirty.prompt,
        [
          {
            markup: '<|__id__|>',
            regex: markupToRegex('<|__id__|>'),
            displayTransform: (id: string) => ` ${getDisplayNameFromId(id)} `,
          },
        ],
        inputRef.current.selectionEnd,
        'END',
      );
      const previousLetter = dirty.prompt.slice(start - 1, start);
      const toInsert = insertText === '@' && previousLetter !== ' ' ? ' @' : insertText;
      const newDirtyPrompt = dirty.prompt.slice(0, start) + toInsert + dirty.prompt.slice(end);
      setDirty((dirty) => ({ ...dirty, prompt: newDirtyPrompt }));

      // Update the cursor position to be after the inserted text
      const newCursorPosition = inputRef.current.selectionStart + toInsert.length;

      // Set the cursor position and focus the input after the value has been updated
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      });
    }
  };

  return (
    <Container style={{ background: 'white' }}>
      <HeaderRow style={{ justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <CmdButton onClick={onClose}>
            <FlipBackward />
          </CmdButton>
          <ResponsiveInput
            value={dirty.title}
            onChange={(e) => setDirty({ ...dirty, title: e.target.value })}
            placeholder="Workflow name"
            accessoryRight={<Edit03 className="icon" />}
          />
        </div>
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Tooltip
            content={"You don't have permission to save this workflow"}
            showIf={!isAllowedToPublish}
            placement="top"
          >
            <CmdSwitch
              checked={!!dirty.is_live}
              onCheckedChange={async (e: boolean) => {
                setDirty({ ...dirty, is_live: e });

                if (e) {
                  reportEvent('Workflow published', {
                    segment: true,
                    highlight: true,
                    slack: true,
                    payloadMessage: dirty.title,
                  });
                }
              }}
              onLabel="Live"
              offLabel="Draft"
              disabled={!isAllowedToPublish}
            />
          </Tooltip>

          {dirty.id !== -1 && isAllowedToSave ? (
            <CmdButton
              variant="ghost"
              onClick={() => {
                Modal.confirm({
                  icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
                  title: `Are you sure you'd like to delete '${dirty.title}'?`,
                  async onOk() {
                    try {
                      onDelete(initialWorkflow);
                    } catch {
                      cmdToast.error('Error deleting workflow');
                    }
                  },
                });
              }}
              icon={<Trash04 />}
            />
          ) : (
            <div style={{ width: 12 }} />
          )}
          <Tooltip showIf={allErrors.length > 0} content={allErrors.join('\n')} placement="left">
            <CmdButton
              onClick={() => saveWorkflow(dirty)}
              disabled={!isDirty || isSaving || allErrors.length > 0}
              variant={'primary'}
            >
              Save <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
            </CmdButton>
          </Tooltip>
        </div>
      </HeaderRow>

      <FlexColumn>
        <DropdownStyled
          trigger={['click']}
          placement="bottomLeft"
          overlay={
            <OverlaySettingsContainer width="288px" ref={containerRef}>
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  Search Content
                  <Tooltip content="Copilot can search your Sources and Answers when in this Workflow to find information.">
                    <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                  </Tooltip>
                </div>
                <CmdSwitch
                  checked={dirty.allow_docs_search}
                  onCheckedChange={(checked) =>
                    setDirty({
                      ...dirty,
                      allow_docs_search: checked,
                    })
                  }
                />
              </OverlaySettingsSection>
              <OverlaySettingsSection
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div style={{ display: 'flex', gap: '8px' }}>
                  Workflow Auto-handoff
                  <Tooltip content="If the user is within this Workflow, automatically switch to another Workflow if its trigger is more relevant to the user's query. This allows all Workflows to be switched to. If you only want to allow certain Workflows to be switched to, you can tag those Workflows in the prompt.">
                    <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                  </Tooltip>
                </div>
                <CmdSwitch
                  checked={dirty.automatic_handoff}
                  onCheckedChange={(checked) =>
                    setDirty({
                      ...dirty,
                      automatic_handoff: checked,
                    })
                  }
                />
              </OverlaySettingsSection>
              {flags?.['copilot-v-3'] && (
                <OverlaySettingsSection
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div style={{ display: 'flex', gap: '8px' }}>
                    Exit on fallback
                    <Tooltip content="Exit this Workflow if the user's query is unrelated to the Workflow prompt.">
                      <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
                    </Tooltip>
                  </div>
                  <CmdSwitch
                    checked={dirty.exit_on_fallback}
                    onCheckedChange={(checked) =>
                      setDirty({
                        ...dirty,
                        exit_on_fallback: checked,
                      })
                    }
                  />
                </OverlaySettingsSection>
              )}
            </OverlaySettingsContainer>
          }
        >
          <MenuIconContainer
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Settings03 />
          </MenuIconContainer>
        </DropdownStyled>
        <StyledDetailTabs destroyInactiveTabPane={true} type="card" isStudio={false}>
          <DetailTabPane tab={'Workflow'} key={WorkflowFormTabs.DETAILS} style={{ padding: '16px' }}>
            <DetailTabPaneInner style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <CmdAccordion defaultValue="prompt" collapsible>
                <CmdAccordion.Item value="prompt">
                  <CmdAccordion.Trigger style={{ height: 'fit-content' }}>
                    <CmdLabel>Prompt</CmdLabel>
                    <CmdTypography style={{ textAlign: 'start' }} variant="secondary">
                      Share a step by step workflow in natural language
                    </CmdTypography>
                  </CmdAccordion.Trigger>

                  <CmdAccordion.Content style={{ overflow: 'revert' }}>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                      }}
                    >
                      <CmdButton
                        iconOnly
                        variant="ghost"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleInsertAtCursor('@');
                        }}
                        style={{
                          position: 'absolute',
                          zIndex: 1,
                          right: '8px',
                          top: '8px',
                          height: '32px',
                          width: '32px',
                          cursor: 'pointer',
                        }}
                        icon={<Passcode />}
                      />

                      <InputWrapper>
                        <MentionsInput
                          spellCheck="false"
                          inputRef={inputRef}
                          onKeyDown={(
                            e: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (e.key === 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              handleInsertAtCursor('\t');
                            }
                          }}
                          style={{
                            width: '100%',
                            minHeight: '400px',
                            padding: '2px 8px 2px 8px',
                            borderRadius: '6px',
                            fontWeight: '500',
                            fontSize: '14px',
                            font: 'inherit',

                            input: {
                              borderRadius: '6px',
                              padding: '2px 8px 2px 8px',
                              fontWeight: '500',
                              fontSize: '14px',
                              color: '#0D0E10',
                              font: 'inherit',
                            },

                            suggestions: {
                              borderRadius: '10px',
                              marginTop: '24px',

                              list: {
                                padding: '4px',
                                borderRadius: '10px',
                                boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 4px 16px 0px rgba(0, 0, 0, 0.24)',
                                width: '280px',
                                maxHeight: '300px',
                                overflowY: 'auto',
                              },
                              item: {
                                padding: '4px 8px',
                                borderRadius: '6px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                '&focused': {
                                  backgroundColor: CB_COLORS.neutral300,
                                },
                              },
                            },
                          }}
                          value={dirty.prompt}
                          onChange={(_event: { target: { value: string } }, newValue: string) =>
                            setDirty({ ...dirty, prompt: newValue })
                          }
                          placeholder={promptPlaceholder}
                          allowSpaceInQuery
                        >
                          <StyledMention
                            trigger="@"
                            markup="<|__id__|>"
                            data={actionDropdownOptions}
                            displayTransform={(id: string) => ` ${getDisplayNameFromId(id)} `}
                          />
                        </MentionsInput>
                      </InputWrapper>

                      <CmdTag
                        variant="info"
                        prefixElement={<Bookmark style={{ minWidth: 14 }} />}
                        style={{ padding: 6, marginTop: -6, width: '100%' }}
                        dismissibleKey="copilot-workflow-learn-more"
                      >
                        Tips for writing Copilot prompts.{' '}
                        <span
                          onClick={() => window.CommandBar.openHelpHub({ articleId: 633367 })}
                          style={{ marginLeft: 3, color: 'inherit', fontWeight: 600, cursor: 'pointer' }}
                        >
                          Learn more.
                        </span>
                      </CmdTag>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          whiteSpace: 'nowrap',
                          overflow: 'auto',
                          margin: '0 4px',
                        }}
                      >
                        <CmdTypography>Use the</CmdTypography>
                        <IconContainer>
                          <AtSign />
                        </IconContainer>
                        <CmdTypography>hotkey or the</CmdTypography>
                        <IconContainer>
                          <Passcode />
                        </IconContainer>
                        <CmdTypography>button to insert suggested actions.</CmdTypography>
                      </div>
                    </div>
                  </CmdAccordion.Content>
                </CmdAccordion.Item>
              </CmdAccordion>
            </DetailTabPaneInner>
          </DetailTabPane>
          <DetailTabPane tab={'Targeting'} key={WorkflowFormTabs.TARGETING} style={{ padding: '16px' }}>
            <DetailTabPaneInner style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <CmdAccordion defaultValue="audience" collapsible>
                <CmdAccordion.Item value="audience">
                  <CmdAccordion.Trigger style={{ height: 'fit-content' }}>
                    <CmdLabel>Who</CmdLabel>
                  </CmdAccordion.Trigger>

                  <CmdAccordion.Content>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '12px' }}>
                      <AudienceSelect
                        hasCustomAudience={false}
                        rules={rules}
                        audience={dirty.audience || { type: 'all_users' }}
                        onChange={(audience) => setDirty({ ...dirty, audience })}
                        showOnlyServerSideAudiences={true}
                      />
                    </div>
                  </CmdAccordion.Content>
                </CmdAccordion.Item>
              </CmdAccordion>
              <CmdAccordion defaultValue="trigger" collapsible>
                <CmdAccordion.Item value="trigger">
                  <CmdAccordion.Trigger style={{ height: 'fit-content' }}>
                    <CmdLabel>When</CmdLabel>
                  </CmdAccordion.Trigger>

                  <CmdAccordion.Content>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '12px' }}>
                      <CmdLabel>Trigger</CmdLabel>
                      <StyledSelect
                        disabled={true}
                        suffixIcon={<CaretDown />}
                        value={'user_intent'}
                        style={{ maxWidth: '100%', width: '100%' }}
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.Option label="User intent" value="user_intent">
                          User intent
                        </Select.Option>
                      </StyledSelect>
                      <CmdLabel>Intent description</CmdLabel>
                      <CmdTextarea
                        fullWidth
                        placeholder="I.e. user asked to speak with an agent"
                        value={dirty.trigger.intent_description}
                        onChange={(e) =>
                          setDirty({ ...dirty, trigger: { ...dirty.trigger, intent_description: e.target.value } })
                        }
                      />
                    </div>
                  </CmdAccordion.Content>
                </CmdAccordion.Item>
              </CmdAccordion>
            </DetailTabPaneInner>
          </DetailTabPane>
        </StyledDetailTabs>
      </FlexColumn>
    </Container>
  );
};

export default WorkflowDetail;
