import { useCallback, useEffect, useRef, useState } from 'react';

import { useCmdFloatingToolbar } from '@commandbar/design-system/cmd';
import { useEventListener } from '@commandbar/internal/hooks/useEventListener';
import { useMouseDragState } from '@commandbar/internal/hooks/useMouseDragState';

interface Point {
  x: number;
  y: number;
}

// Computes a bounding rectangle that encompasses both the hover element and the toolbar.
const getCombinedRect = (rect1: DOMRect, rect2: DOMRect): DOMRect => {
  const left = Math.min(rect1.left, rect2.left);
  const right = Math.max(rect1.right, rect2.right);
  const top = Math.min(rect1.top, rect2.top);
  const bottom = Math.max(rect1.bottom, rect2.bottom);

  return new DOMRect(left, top, right - left, bottom - top);
};

// Determines if a given point lies within a specified rectangle.
const isPointInRect = (point: Point, rect: DOMRect): boolean =>
  point.x >= rect.left && point.x <= rect.right && point.y >= rect.top && point.y <= rect.bottom;

type HoverCallback = (event: MouseEvent) => void;

export const useHover = ({
  onHover,
  offHover,
  delay,
}: Partial<{ onHover: HoverCallback; offHover: HoverCallback; delay: number }>) => {
  const hoverDelay = delay ?? 100;
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);
  const targetRef = useRef<HTMLElement>(null);
  const { toolbarRef } = useCmdFloatingToolbar();
  const isDragging = useMouseDragState();

  const hoverTimerRef = useRef<number | null>(null);

  const handleMouseEnter = useCallback(
    (event: MouseEvent) => {
      if (isDragging) return; // Do nothing while dragging

      if (hoverTimerRef.current) {
        window.clearTimeout(hoverTimerRef.current);
      }

      // Starts a timer to trigger the hover effect after a delay.
      hoverTimerRef.current = window.setTimeout(() => {
        setIsToolbarVisible(true);
        onHover?.(event);
        hoverTimerRef.current = null;
      }, hoverDelay);
    },
    [onHover, hoverDelay, isDragging],
  );

  const handleMouseLeave = useCallback(() => {
    if (hoverTimerRef.current) {
      window.clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = null;
    }
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isToolbarVisible) return;

      const hoverElement = targetRef.current;
      const toolbarElement = toolbarRef?.current;
      if (!(hoverElement && toolbarElement)) return;

      const point: Point = { x: event.clientX, y: event.clientY };
      const hoverRect = hoverElement.getBoundingClientRect();
      const toolbarRect = toolbarElement.getBoundingClientRect();
      const combinedRect = getCombinedRect(hoverRect, toolbarRect);

      const isWithinCombinedRect = isPointInRect(point, combinedRect);

      // Hides the toolbar if the mouse is outside both the hover element and the toolbar.
      if (!isWithinCombinedRect) {
        offHover?.(event);
        setIsToolbarVisible(false);
      }
    },
    [toolbarRef, isToolbarVisible, offHover],
  );

  // Attaches 'mouseenter' and 'mouseleave' events to the target element.
  useEventListener('mouseenter', handleMouseEnter, targetRef.current);
  useEventListener('mouseleave', handleMouseLeave, targetRef.current);
  // Attaches 'mousemove' event globally to track cursor position.
  useEventListener('mousemove', handleMouseMove, document);

  useEffect(() => {
    // Clears hover timers when dragging starts
    if (isDragging && hoverTimerRef.current) {
      window.clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = null;
    }

    return () => {
      // Cleans up any pending hover timers on unmount.
      if (hoverTimerRef.current) {
        window.clearTimeout(hoverTimerRef.current);
      }
    };
  }, [isDragging]);

  return { targetRef, isVisible: isToolbarVisible };
};
