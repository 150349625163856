import React from 'react';
import styled from '@emotion/styled';

import { Input } from '@commandbar/design-system/components/antd';
import { CB_COLORS } from '@commandbar/design-system/colors';

type Props = {
  title?: string;
  accessoryRight?: React.ReactNode;
  placeholder: string;
  containerStyles?: React.CSSProperties;
  inputStyles?: React.CSSProperties;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  isStudio?: boolean;
};

const Container = styled('div', { shouldForwardProp: (prop) => prop !== 'isStudio' })<{ isStudio?: boolean }>`
  display: flex;
  align-items: center;
  height: 32px;
  position: relative;
  padding: 5px 8px;
  border-radius: 5px;

  .icon {
    position: absolute;
    right: 8px;
  }

  &:hover {
    background-color: #f9f9f9;
  }

  &:focus-within {
    background-color: unset;
    outline: 1px solid #2046c6;
    box-shadow: 0px 0px 0px 3px rgb(39 84 238 / 16%);

    input {
      padding-right: 0px !important;
      width: ${({ isStudio }) => (isStudio ? '180px' : '210px')};
    }

    .icon {
      display: none;
      color: inherit;
    }
  }
`;

const Title = styled.span`
  position: absolute;
  top: -8px;
  left: 5px;
  font-size: 10px;
  line-height: 12px;
  color: rgb(122, 122, 133);
  padding: 0px 3px;
  background-color: #ffffff;
`;

const CustomInput = styled(Input, { shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>`
  padding: unset;
  padding-right: 18px !important;
  min-height: 0 !important;
  height: unset !important;
  font-size: 16px;
  line-height: 18px !important;
  color: ${CB_COLORS.neutral1000};
  border: 1px solid ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};
  resize: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  z-index: 1;
  width: 180px;
`;

const ResponsiveInput = (props: Props) => {
  const { title, accessoryRight, placeholder, containerStyles, inputStyles, value, onChange, error, isStudio } = props;

  return (
    <Container style={containerStyles} isStudio={isStudio}>
      {title && <Title>{title}</Title>}
      <CustomInput
        style={inputStyles}
        value={value}
        onChange={onChange}
        bordered={false}
        spellCheck={false}
        placeholder={placeholder}
        error={error}
      />
      {accessoryRight}
    </Container>
  );
};

export default ResponsiveInput;
