import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router';
import * as nudgeSearchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import {
  Modal,
  Row,
  Table,
  Empty,
  StatusBadge,
  FeatureAnnouncementCard,
  Tabs,
  TabPane,
  SubHeading,
  InputNumber,
  Select,
  SimplePanel,
  PaddingContainer,
  Panel,
  Tooltip,
  CB_COLORS,
  Col,
} from '@commandbar/design-system/components/antd';
import { useAppContext } from 'editor/src/AppStateContext';
import { useReportEvent } from '../../hooks/useEventReporting';
import NudgeDetail from './NudgeDetail';
import * as templates from './nudge_templates';

import nudgeGif from '../../img/nudges.gif';

import type { INudgeStepType, INudgeType } from '@commandbar/internal/middleware/types';
import useNudgeSettings from '../useEditor/useNudgeSettings';
import {
  AlertTriangle,
  Contrast01,
  Copy02,
  Copy06,
  DotsVertical,
  FaceIdSquare,
  InfoCircle,
  PencilLine,
  Plus,
  PlusSquare,
  Trash04,
} from '@commandbar/design-system/icons/react';
import { UpgradeCTA } from '../components/UpgradeCTA';
import { isPastScheduledPeriod } from '@commandbar/internal/util/time';
import Z from '@commandbar/internal/client/Z';
import { getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import {
  CmdSwitch,
  CmdButton,
  CmdTag,
  CmdDropdown,
  CmdDivider,
  CmdSearchInput,
  cmdToast,
} from '@commandbar/design-system/cmd';
import FilterSelect, { StatusType } from '../components/FilterSelect';
import { duplicateNudge, getDisplayTitle } from '@commandbar/internal/util/nudges';
import Sender from '../../management/Sender';
import collectEditorTags from '../utils/collectEditorTags';
import { PreviewProvider } from 'editor/src/hooks/usePreview';
import { useCreateFromTemplate } from './New';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import ThemeCustomizationSettings from '../components/ThemeCustomizationSettings';
import { ShareLinkModal } from '../components/ShareLinkModal';

export const nudgeTitleDisplay = ({ slug, steps }: INudgeType) => {
  let titleDisplay = slug || (steps.length === 1 ? steps[0].title : 'untitled');

  titleDisplay = titleDisplay.length > 40 ? `${titleDisplay.slice(0, 40)}...` : titleDisplay;

  return titleDisplay + (steps.length === 1 ? ' (1 step)' : ` (${steps.length} steps)`);
};

export const nudgeStepTitleDisplay = (step: INudgeStepType, index: number) => {
  const titleDisplay = step.title;
  return `${index + 1} - ${titleDisplay.length > 40 ? `${titleDisplay.slice(0, 40)}...` : titleDisplay}`;
};

enum PanelKey {
  RATE_LIMITING,
  THEME,
}

export type NudgeType = 'announcement' | 'survey' | 'product_tour';

export const nudgeTypeDisplay = (type: NudgeType | null) => {
  switch (type) {
    case 'announcement':
      return 'Announcement';
    case 'product_tour':
      return 'Tour';
    case 'survey':
      return 'Survey';
    default:
      return 'Nudge';
  }
};

const NudgeTypeThemeCustomizationSettings = ({ type }: { type: NudgeType }) => {
  const {
    organization,
    dispatch: {
      organization: { updateSetting },
    },
  } = useAppContext();

  const orgSettingsKey = (() => {
    switch (type) {
      case 'announcement':
        return 'announcements_custom_theme';
      case 'survey':
        return 'surveys_custom_theme';
      case 'product_tour':
        return 'product_tours_custom_theme';
    }
  })();

  return (
    <ThemeCustomizationSettings
      themeUUID={organization[orgSettingsKey]}
      setThemeUUID={(themeUUID) => {
        updateSetting({ [orgSettingsKey]: themeUUID });
      }}
    />
  );
};

const NudgeList = ({ type }: { type: NudgeType }) => {
  const location = useLocation<INudgeType>();
  const history = useHistory();
  const { loading: appContextLoading, nudges, organization, dispatch, isStudio, flags } = useAppContext();
  const { limit, period, actions } = useNudgeSettings();
  const { reportEvent } = useReportEvent();
  const params = useParams<{ nudgeId?: string; stepIndex?: string }>();
  const { user } = useAuth();

  const [activeNudge, setActiveNudge] = useState<INudgeType | undefined>(location.state);
  const [activeKeys, setActiveKeys] = useState<PanelKey[]>([PanelKey.RATE_LIMITING, PanelKey.THEME]);
  const [activeTabKey, setActiveTabKey] = useState(type);
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<StatusType | 'scheduled'>('all');
  const [selectedTag, setSelectedTag] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNudge, setSelectedNudge] = useState<INudgeType | null>();
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);

  const filteredNudges = nudges.filter((n) => n.type === type);
  const pageTitle = nudgeTypeDisplay(type) + 's';

  const getPublicationEvent = (oldNudge: INudgeType, newNudge: INudgeType) => {
    const isScheduled = !oldNudge.is_scheduled && newNudge.is_scheduled;
    const isNotScheduled = !newNudge.is_scheduled;
    if (isScheduled) return 'scheduled';
    if (isNotScheduled) return newNudge.is_live ? 'published' : 'unpublished';
    return '';
  };

  const createFromTemplate = useCreateFromTemplate(type, { rebaseHistoryToNudgeRoute: true });

  useEffect(() => {
    setActiveTabKey(type);
    setSearchText('');
    setSelectedStatus('all');
    setSelectedTag('all');
  }, [type]);

  useEffect(() => {
    if (appContextLoading || !organization) return;

    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get(nudgeSearchParams.PAGE);
    page ? setCurrentPage(parseInt(page)) : setCurrentPage(1);

    const template = searchParams.get(nudgeSearchParams.NUDGE_TEMPLATE);

    if (!(params.nudgeId || params.stepIndex || template)) {
      setActiveNudge(undefined);
      return;
    }

    const nudge = filteredNudges.find((n) => n.id === Number(params.nudgeId));
    if (!!nudge && (activeNudge === undefined || activeNudge.id !== nudge.id)) {
      setActiveNudge(nudge);
      history.replace(`${getNudgeRoute(nudge)}/${nudge.id}?${searchParams.toString()}`);
      return;
    }

    switch (template) {
      case nudgeSearchParams.NUDGE_ANNOUNCEMENT_TEMPLATE_PARAM_VALUE: {
        createFromTemplate(templates.announcementNudge);
        break;
      }
      case nudgeSearchParams.NUDGE_TOUR_TEMPLATE_PARAM_VALUE: {
        createFromTemplate(templates.productTour);
        break;
      }
      case nudgeSearchParams.NUDGE_FEATURE_HIGHLIGHT_TEMPLATE_PARAM_VALUE: {
        createFromTemplate(templates.featureHighlightNudge);
        break;
      }
      case nudgeSearchParams.NUDGE_FEEDBACK_TEMPLATE_PARAM_VALUE: {
        createFromTemplate(templates.feedback);
        break;
      }
      case nudgeSearchParams.NUDGE_RATING_TEMPLATE_PARAM_VALUE: {
        createFromTemplate(templates.rating);
        break;
      }
      case nudgeSearchParams.NUDGE_NPS_TEMPLATE_PARAM_VALUE: {
        createFromTemplate(templates.nps);
        break;
      }
      default: {
        break;
      }
    }
  }, [organization, location.search, params, appContextLoading, !!activeNudge]);

  useEffect(() => {
    setActiveKeys((keys) =>
      organization.nudge_rate_limit !== null
        ? [...keys, PanelKey.RATE_LIMITING]
        : keys.filter((k) => k !== PanelKey.RATE_LIMITING),
    );
  }, [organization.nudge_rate_limit]);

  useEffect(() => {
    if (!activeNudge) {
      Sender.closeNudgeMocks();
    }

    return () => {
      if (isStudio) {
        Sender.closeNudgeMocks();
      }
    };
  }, [activeNudge?.id, isStudio]);

  const onSave = async (nudge: INudgeType) => {
    nudge.type = type;
    const oldNudge = nudges.find((oldNudge) => oldNudge.id === nudge.id);
    const newNudge = await dispatch.nudges.save(nudge);

    if (newNudge.id !== nudge.id) {
      // update the url to reflect the new nudge id
      history.replace(`${getNudgeRoute(newNudge)}/${newNudge.id}`);
    } else if (oldNudge) {
      if (oldNudge.is_live !== newNudge.is_live || oldNudge.is_scheduled !== newNudge.is_scheduled) {
        const publicationEvent = getPublicationEvent(oldNudge, newNudge);
        publicationEvent &&
          reportEvent(`nudge ${publicationEvent}`, {
            segment: true,
            highlight: true,
            slack: true,
            eventProps: {
              id: newNudge.id,
              item_count: newNudge.steps.length,
              audience: newNudge.audience?.type,
              trigger: newNudge.trigger,
            },
          });
      }
    }
    setActiveNudge(newNudge);
    return newNudge;
  };

  const onDelete = async (nudge: INudgeType) => {
    Modal.confirm({
      zIndex: Z.Z_MODALS,
      icon: <AlertTriangle height={22} width={22} className="anticon anticon-warning" />,
      title: 'Are you sure you want to delete this nudge? This cannot be undone.',
      async onOk() {
        await dispatch.nudges.delete(nudge);
        setActiveNudge(undefined);
        history.replace(getNudgeRoute(nudge));
      },
    });
  };

  const onDuplicate = async (nudge: INudgeType) => {
    try {
      const name = getDisplayTitle(nudge);
      const copyNumber = filteredNudges.filter((n) => n.slug.startsWith(`copy-of-${name}`)).length;

      const newNudge = duplicateNudge(nudge, `copy-of-${name}${copyNumber > 0 ? `-${copyNumber}` : ''}`, {
        template_source: 'copy',
      });

      const savedNudge = await dispatch.nudges.save(newNudge);
      history.push(`${getNudgeRoute(nudge)}/${savedNudge.id}`);
      return savedNudge;
    } catch (err) {
      cmdToast.error('Error duplicating nudge.');
    }
    return null;
  };

  if (appContextLoading || !organization) {
    return null;
  }

  const tags = collectEditorTags({ nudges: filteredNudges }).map((tag) => ({ label: tag, value: tag }));

  const columns = [
    {
      title: pageTitle,
      dataIndex: 'title',
      key: 'title',
      render: (_: any, nudge: INudgeType) => {
        return nudgeTitleDisplay(nudge);
      },
    },
    {
      title: 'Tags',
      key: 'tags',
      hidden: filteredNudges.every((nudge) => nudge.editor_tags?.length === 0),
      render: (_: any, nudge: INudgeType) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '300px', gap: '8px', overflow: 'hidden' }}>
            {nudge.editor_tags.map((tag, index) => (
              <CmdTag key={index}>{tag}</CmdTag>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'is_live',
      dataIndex: 'is_live',
      render: (_: any, nudge: INudgeType) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {nudge.is_live ? (
            <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Published" />
          ) : nudge.is_scheduled && !isPastScheduledPeriod(nudge) ? (
            <StatusBadge color="blue" text="Scheduled" style={{ display: 'flex', alignItems: 'center' }} />
          ) : (
            <StatusBadge color="orange" text="Unpublished" style={{ display: 'flex', alignItems: 'center' }} />
          )}
          {nudge.copilot_suggest && (
            <Tooltip inline content={'Suggested in Copilot'}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FaceIdSquare style={{ flexShrink: 0, marginLeft: '8px' }} width="16px" height="16px" />
              </div>
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: 'options',
      key: 'options',
      align: 'center' as const,
      render: (_: any, nudge: INudgeType) => {
        const canEdit = hasRequiredRole(user, nudge.is_live ? 'editor' : 'contributor');

        return (
          <CmdDropdown.Menu>
            <CmdDropdown.Trigger>
              <CmdButton variant="ghost" className="px-sm">
                <DotsVertical width={'16px'} height={'16px'} />
              </CmdButton>
            </CmdDropdown.Trigger>
            <CmdDropdown.Content>
              {canEdit && (
                <CmdDropdown.Item
                  onClick={async (e) => {
                    e.stopPropagation();
                    setActiveNudge(nudge);
                    history.push(`${getNudgeRoute(nudge)}/${nudge.id}`);
                  }}
                >
                  <PencilLine /> Edit
                </CmdDropdown.Item>
              )}
              {hasRequiredRole(user, 'contributor') && (
                <CmdDropdown.Item
                  onClick={async (e) => {
                    e.stopPropagation();
                    await onDuplicate(nudge);
                  }}
                >
                  <Copy02 /> Duplicate
                </CmdDropdown.Item>
              )}
              <CmdDropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(`${nudge.id}`).then(function () {
                    cmdToast.success('Nudge id copied to clipboard.');
                  });
                }}
              >
                <Copy06 /> Copy ID
              </CmdDropdown.Item>
              <CmdDropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                  setShareLinkModalOpen(true);
                  setSelectedNudge(nudge);
                }}
              >
                <Copy06 /> Nudge link...
              </CmdDropdown.Item>

              {hasRequiredRole(user, 'contributor') && (
                <>
                  <CmdDivider />
                  <CmdDropdown.Item
                    className="cursor-pointer"
                    onClick={async (e) => {
                      e.stopPropagation();
                      const template = await dispatch.templates.save({
                        id: -1,
                        type: type,
                        data: duplicateNudge(nudge, `${getDisplayTitle(nudge)} template`, {
                          template_source: 'custom',
                        }),
                      });

                      if (template) {
                        history.push(`${getNudgeRoute(nudge)}/template/${template.id}`);
                      }
                    }}
                  >
                    <PlusSquare /> Create a Template
                  </CmdDropdown.Item>
                </>
              )}
              {canEdit && (
                <>
                  <CmdDivider />
                  <CmdDropdown.Item
                    onClick={async (e) => {
                      e.stopPropagation();
                      onDelete(nudge);
                    }}
                  >
                    <Trash04 /> Delete
                  </CmdDropdown.Item>
                </>
              )}
              {hasRequiredRole(user, 'contributor') && nudge.is_live && (
                <>
                  <CmdDivider />
                  <CmdDropdown.Item
                    onClick={async (e) => {
                      e.stopPropagation();
                      onSave({ ...nudge, is_live: false });
                    }}
                  >
                    <Contrast01 width={16} /> Unpublished
                  </CmdDropdown.Item>
                </>
              )}
            </CmdDropdown.Content>
          </CmdDropdown.Menu>
        );
      },
    },
  ];

  if (activeNudge) {
    return (
      <PreviewProvider onSave={onSave}>
        <NudgeDetail
          initialNudge={activeNudge}
          onClose={() => {
            if (history.length <= 1) {
              history.replace(`${getNudgeRoute(activeNudge)}`);
            } else {
              history.goBack();
            }
          }}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
          onSave={onSave}
          type={type}
        />
      </PreviewProvider>
    );
  }

  return (
    <>
      {selectedNudge && (
        <ShareLinkModal
          shareableEntity={selectedNudge}
          isOpen={shareLinkModalOpen}
          setIsOpen={setShareLinkModalOpen}
          onClose={() => {
            setShareLinkModalOpen(false);
            setSelectedNudge(null);
          }}
        />
      )}

      <Tabs
        activeKey={activeTabKey}
        onChange={(key) => setActiveTabKey(key as NudgeType)}
        isStudio={isStudio}
        type="card"
        tabBarStyle={{
          paddingTop: isStudio ? '9px' : 0,
          marginTop: -10,
          paddingLeft: '16px',
          display: 'block',
        }}
        destroyInactiveTabPane
      >
        <TabPane tab={pageTitle} key={type}>
          <PaddingContainer>
            <FeatureAnnouncementCard
              identifier={type}
              title={<Row align="middle">Introducing {pageTitle}</Row>}
              img={{
                src: nudgeGif,
                alt: `Use ${pageTitle.toLowerCase()} to highlight content to your users`,
              }}
              docsLink={`https://www.commandbar.com/docs/nudges/nudges/${pageTitle.toLowerCase()}/overview/`}
            >
              <span>
                {type === 'announcement'
                  ? 'Guide your customers to success with targeted and timely announcements.'
                  : type === 'product_tour'
                  ? 'Show off your latest feature via tours.'
                  : 'Receive user feedback with surveys.'}
              </span>
            </FeatureAnnouncementCard>

            <UpgradeCTA product="nudges" />

            <SimplePanel>
              <Row style={{ marginBottom: '8px', padding: '0 8px', gap: '8px', maxWidth: '100%' }}>
                <Col style={{ flex: 1 }}>
                  <CmdSearchInput value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </Col>
                {tags.length ? (
                  <Col>
                    <FilterSelect
                      selectLabel="Tag"
                      selectedOption={selectedTag}
                      setSelectedOption={setSelectedTag}
                      options={[{ label: 'All', value: 'all' }, ...tags]}
                    />
                  </Col>
                ) : null}
                <Col>
                  <FilterSelect
                    selectedOption={selectedStatus}
                    setSelectedOption={(status) => setSelectedStatus(status)}
                    options={[
                      { label: 'All', value: 'all' },
                      { label: 'Published', value: 'published' },
                      { label: 'Unpublished', value: 'unpublished' },
                      { label: 'Scheduled', value: 'scheduled' },
                    ]}
                  />
                </Col>
                {hasRequiredRole(user, 'contributor') && (
                  <Col>
                    <CmdButton
                      onClick={() => {
                        history.push(`${getNudgeRoute({ type })}/new`);
                      }}
                      icon={<Plus />}
                      variant="primary"
                    >
                      New
                    </CmdButton>
                  </Col>
                )}
              </Row>
              <Table
                pagination={{
                  defaultPageSize: 10,
                  hideOnSinglePage: true,
                  current: currentPage,
                  onChange: (page) => {
                    history.replace(`${history.location.pathname}?page=${page}`);
                    setCurrentPage(page);
                  },
                }}
                columns={columns.filter((c) => !c.hidden)}
                dataSource={filteredNudges
                  .filter((n) => {
                    const displayName = getDisplayTitle(n);
                    return searchText.length === 0 || displayName.toLowerCase().includes(searchText.toLowerCase());
                  })
                  .filter((n) => {
                    const nudgeStatus = n.is_live
                      ? 'published'
                      : n.is_scheduled && !isPastScheduledPeriod(n)
                      ? 'scheduled'
                      : 'unpublished';
                    return selectedStatus === 'all' || selectedStatus === nudgeStatus;
                  })
                  .filter((n) => {
                    return selectedTag === 'all' || n.editor_tags.includes(selectedTag);
                  })
                  .map((n) => ({ ...n, key: `${n.id}-${n.old_nudge_id}` }))}
                onRow={(nudge: INudgeType, _rowIndex: any) => {
                  const canEdit = hasRequiredRole(user, nudge.is_live ? 'editor' : 'contributor');
                  return {
                    onClick: (_e: React.MouseEvent) => {
                      setActiveNudge(nudge);
                      history.push(`${getNudgeRoute(nudge)}/${nudge.id}`);
                    },
                    className: canEdit ? 'editable-row' : 'cursor-default',
                  };
                }}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={`You don't have any ${pageTitle.toLowerCase()} yet. Create one by clicking the 'New' button above.`}
                    />
                  ),
                }}
              />
            </SimplePanel>
          </PaddingContainer>
        </TabPane>
        {hasRequiredRole(user, 'editor') && (
          <TabPane tab="Settings" key="settings">
            <PaddingContainer>
              <Panel
                activeKeys={activeKeys}
                setActiveKeys={setActiveKeys}
                panelKey={PanelKey.RATE_LIMITING}
                header={
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <span>Rate Limiting</span>
                    <Tooltip
                      placement="top"
                      content="Limit the number of tours, announcements, and surveys your users will see in a given time period."
                    >
                      <InfoCircle style={{ width: 14, height: 14 }} />
                    </Tooltip>
                  </div>
                }
                actions={
                  <CmdSwitch
                    checked={organization.nudge_rate_limit !== null}
                    onCheckedChange={(isChecked) => {
                      actions.limit.update(isChecked ? 1 : null);
                    }}
                  />
                }
              >
                <div style={{ display: 'flex', gap: 8 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
                    <SubHeading>Limit</SubHeading>
                    <InputNumber
                      value={limit}
                      onChange={actions.limit.update}
                      min={1}
                      style={{
                        width: '100%',
                        height: '32px',
                        fontSize: 14,
                        border: `1px solid ${CB_COLORS.neutral300}`,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      placeholder="No limit"
                      size="small"
                    />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
                    <SubHeading>Period</SubHeading>
                    <Select
                      value={period}
                      options={[
                        { label: 'Day', value: 'day' },
                        { label: 'Week', value: 'week' },
                        { label: 'Session', value: 'session' },
                      ]}
                      onChange={actions.period.update}
                    />
                  </div>
                </div>
              </Panel>

              {flags['release-themes-v2'] && (
                <Panel activeKeys={activeKeys} setActiveKeys={setActiveKeys} panelKey={PanelKey.THEME} header="Theme">
                  <NudgeTypeThemeCustomizationSettings type={type} />
                </Panel>
              )}
            </PaddingContainer>
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export default NudgeList;
