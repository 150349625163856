import { CBStore } from '@commandbar/commandbar/shared/store/global-store';
import { IThemeAnimatedWidgetType, IThemeAnimationType } from '@commandbar/internal/middleware/theme';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { DEFAULT_THEME } from '../../defaults';

export const convertCSSDurationToMilliseconds = (
  timeStr: string | number | undefined,
  defaultDuration: string | number = 260,
): number => {
  const defaultDurationMS =
    parseFloat(defaultDuration.toString()) * (/\ds$/.test(defaultDuration.toString()) ? 1000 : 1);

  if (timeStr) {
    const num = parseFloat(timeStr.toString()) * (/\ds$/.test(timeStr.toString()) ? 1000 : 1);
    if (!isNaN(num)) {
      return num;
    }
  }

  return defaultDurationMS;
};

export const getAnimationVariables = (widget: IThemeAnimatedWidgetType) => {
  const transitionProperty = `--${widget}-anim-transition-property`;
  const transitionTiming = `--${widget}-anim-transition-timing`;
  const transitionDuration = `--${widget}-anim-transition-duration`;
  return { transitionProperty, transitionTiming, transitionDuration };
};

export const getAnimationPresetVariables = (type: IThemeAnimationType) => {
  const transitionProperty = `--anim-transition-property--${type}`;
  const transitionTiming = `--anim-transition-timing--${type}`;
  const transformInitial = `--anim-transform-initial--${type}`;
  const transformAnimated = `--anim-transform-animated--${type}`;

  return { transitionProperty, transitionTiming, transformInitial, transformAnimated };
};

export const getAnimationType = (
  widget: IThemeAnimatedWidgetType,
  themeV2?: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode' = 'light_mode',
) => {
  const vars = getAnimationVariables(widget);
  const transitionProperty = themeV2 ? themeV2[mode]?.var_overrides[vars.transitionProperty] : undefined;
  const transitionTiming = themeV2 ? themeV2[mode]?.var_overrides[vars.transitionTiming] : undefined;

  const animationTypes: IThemeAnimationType[] = ['instant', 'fade', 'spring', 'scale'];
  return (
    animationTypes.find((type) => {
      const presetVars = getAnimationPresetVariables(type);

      return (
        themeV2 &&
        transitionProperty === getDefaultCSSVarValue(themeV2, mode, presetVars.transitionProperty) &&
        transitionTiming === getDefaultCSSVarValue(themeV2, mode, presetVars.transitionTiming)
      );
    }) ?? 'instant'
  );
};

export type Positions =
  | 'center'
  | 'top-left'
  | 'top-right'
  | 'bottom-right'
  | 'bottom-left'
  | 'bottomRight'
  | 'bottomLeft';

export const convertWidgetPositionToTransformOrigin = (position: Positions) => {
  if (position.includes('-')) {
    const [x, y] = position.split('-');
    return `${y} ${x}`;
  }

  switch (position) {
    case 'center':
      return 'center center';
    case 'bottomRight':
      return 'bottom right';
    case 'bottomLeft':
      return 'bottom left';
  }

  return position;
};

const getAdditionalAnimationProperties = (
  _: CBStore,
  widget: IThemeAnimatedWidgetType,
  type: IThemeAnimationType,
  position?: Positions,
) => {
  const additional = {
    transformOrigin: 'center',
  };

  if (type !== 'scale') {
    return additional;
  }

  switch (widget) {
    case 'modal':
      additional.transformOrigin = 'center center';
      break;
    case 'popover':
      additional.transformOrigin = convertWidgetPositionToTransformOrigin(position ?? 'center');
      break;
    case 'helphub':
    case 'checklist':
      additional.transformOrigin = convertWidgetPositionToTransformOrigin(position ?? 'bottomRight');
      break;
  }

  return additional;
};

export const getCSSVarValue = (theme: IThemeV2Type | undefined, mode: 'light_mode' | 'dark_mode', cssVar: string) => {
  return (
    theme?.[mode].var_overrides[cssVar] ??
    theme?.[mode].var_defaults[cssVar] ??
    DEFAULT_THEME?.[mode].var_defaults[cssVar]
  );
};

/**
 * Gets the CSS Var Value from specifically the theme defaults, not including the overrides
 */
export const getDefaultCSSVarValue = (
  theme: IThemeV2Type | undefined,
  mode: 'light_mode' | 'dark_mode',
  cssVar: string,
) => {
  return theme?.[mode].var_defaults[cssVar] ?? DEFAULT_THEME?.[mode].var_defaults[cssVar];
};

export const getAnimationConfig = (
  _: CBStore,
  widget: IThemeAnimatedWidgetType,
  themeV2?: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode' = 'light_mode',
  position?: Positions,
) => {
  const type = getAnimationType(widget, themeV2, mode);
  const vars = getAnimationVariables(widget);
  const presetVars = getAnimationPresetVariables(type);
  const additional = getAdditionalAnimationProperties(_, widget, type, position);

  // Calculate a numerical duration to track an animation progress of sorts to keep track of "applied" vs "unapplied" state,
  // as close as possible to what is being rendered
  const durationMS = convertCSSDurationToMilliseconds(
    getCSSVarValue(themeV2, mode, vars.transitionDuration),
    getDefaultCSSVarValue(themeV2, mode, '--anim-transition-duration'),
  );

  return { type, vars, presetVars, additional, durationMS };
};
