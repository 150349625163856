import React from 'react';
import type { RenderElementProps } from 'slate-react';

import { CmdTypography } from '../..';

export const Paragraph = ({ attributes, children }: RenderElementProps) => (
  <CmdTypography.Paragraphy variant="contentMid" className="mb-2.5" {...attributes}>
    {children}
  </CmdTypography.Paragraphy>
);
