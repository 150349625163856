import React, { useMemo } from 'react';

import { CmdTag } from '@commandbar/design-system/cmd';
import { InputContainer, Select, StyledLabel, SubHeading, Tooltip } from '@commandbar/design-system/components/antd';
import { Bookmark, InfoCircle } from '@commandbar/design-system/icons/react';
import { type TriggerableEntity, isNudge } from '@commandbar/internal/middleware/helpers/pushTrigger';
import {
  type ExpressionCondition,
  type RuleExpression,
  getConditions,
} from '@commandbar/internal/middleware/helpers/rules';
import { useAppContext } from 'editor/src/AppStateContext';
import { Panel, hasAnchoredStep } from '.';
import ConditionGroupEditor from '../../conditions/ConditionGroupEditor';
import { AvailabilityDependency } from '../../conditions/components/AvailabilityDependency';
import { nudgeTypeDisplay } from '../../nudges/NudgeList';
import { StyledSelect } from '../styled';
import { Section } from './Section';
import { BorderedContainer } from './styled';

import { ReactComponent as CaretDown } from '../../../img/caret_down.svg';
import TextAreaWithInterpolation from '../TextAreaWithInterpolation';

const getFirstCondition = (expr: RuleExpression): ExpressionCondition => getConditions(expr)?.[0];

interface PageTargetingProps<T> {
  dirty: T;
  handleOnChange: (changes: T) => void;
  startPageUrlError: { isValid: boolean; error: string };
  onStartClickRecorder?: (field: string, skipPrompt?: boolean) => void;
}

export const PageTargeting = <T extends TriggerableEntity>({
  dirty,
  handleOnChange,
  startPageUrlError,
  onStartClickRecorder,
}: PageTargetingProps<T>) => {
  const { isStudio } = useAppContext();

  const pageTargetingSelectValue =
    dirty.show_expression.type === 'LITERAL' && !hasAnchoredStep(dirty) ? 'all_pages' : 'custom';

  const verbosePageTargeting = useMemo(() => {
    const condition = getFirstCondition(dirty.show_expression);

    if (!condition || pageTargetingSelectValue === 'all_pages') {
      if (!hasAnchoredStep(dirty)) return 'All pages';
      return 'On all pages with target elements';
    }

    if (condition.type === 'url') {
      return `URL ${condition.operator} ${condition.value}`;
    }
    if (condition.type === 'element') {
      let value = condition.value;
      if (
        condition.value &&
        (condition.operator === 'selectorOnPage' || condition.operator === 'selectorNotOnPage') &&
        condition.value.length > 50
      ) {
        value = `${condition.value?.substring(0, 50)}...`;
      }
      return `DOM Element ${condition.operator} ${value}`;
    }

    if (hasAnchoredStep(dirty)) return 'Custom page targeting with required target elements';
    return 'Custom page targeting';
  }, [dirty, pageTargetingSelectValue]);

  const entityLabel = isNudge(dirty) ? nudgeTypeDisplay(dirty.type) : 'Checklist';

  return (
    <Section sectionKey={Panel.PageTargeting} heading="Where" subheading={verbosePageTargeting}>
      <InputContainer>
        <StyledLabel>Show</StyledLabel>
        <StyledSelect
          value={pageTargetingSelectValue}
          style={{ width: '100%' }}
          onChange={(e) => {
            if (e === 'custom') {
              handleOnChange({
                ...dirty,
                show_expression: {
                  type: 'AND',
                  exprs: [{ type: 'CONDITION', condition: { type: 'url', operator: 'includes', value: '' } }],
                },
              });
            } else if (e === 'all_pages') {
              handleOnChange({ ...dirty, show_expression: { type: 'LITERAL', value: true } });
            }
          }}
          suffixIcon={<CaretDown />}
        >
          <Select.Option
            disabled={hasAnchoredStep(dirty)}
            title={
              hasAnchoredStep(dirty)
                ? 'Pin and tooltip steps require their targets to be present on the page'
                : 'On all pages'
            }
            value="all_pages"
          >
            On all pages
          </Select.Option>
          <Select.Option value={'custom'}>If...</Select.Option>
        </StyledSelect>
        {pageTargetingSelectValue === 'custom' && (
          <>
            <BorderedContainer>
              {hasAnchoredStep(dirty) && (
                <AvailabilityDependency
                  type="Targets"
                  field="are"
                  operator="present on page"
                  inputStyle={{ height: '24px' }}
                  message={<span>Pin and Tooltip steps require their targets to be present on the page.</span>}
                />
              )}
              <ConditionGroupEditor
                onChange={(expr) => handleOnChange({ ...dirty, show_expression: expr })}
                expr={dirty.show_expression}
                path="show_expression"
                includeCategories={['Page']}
                onStartClickRecorder={isStudio ? onStartClickRecorder : undefined}
              />
            </BorderedContainer>

            <SubHeading style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '4px' }}>Start Page</div>
              <Tooltip
                content={`Teleport users to a specific page or URL when this ${entityLabel} is triggered from HelpHub, Copilot, Spotlight, or another experience.`}
              >
                <InfoCircle height={16} style={{ marginBottom: '-3px' }} />
              </Tooltip>
            </SubHeading>

            <TextAreaWithInterpolation
              onChange={(newValue) => {
                handleOnChange({ ...dirty, share_page_url_or_path: newValue });
              }}
              error={!startPageUrlError.isValid}
              errorMessage={`Invalid start page: ${startPageUrlError.error}`}
              value={dirty.share_page_url_or_path}
              placeholder="URL or path"
              small
            />
            <CmdTag
              variant="info"
              prefixElement={<Bookmark style={{ minWidth: 14 }} />}
              style={{ padding: 6 }}
              dismissibleKey="copilot-personality-learn-more"
            >
              A start page is recommended.{' '}
              <a
                href="https://www.commandbar.com/docs/nudges/targeting/who-where-when/#start-page"
                target="_blank"
                style={{ marginLeft: 3, color: 'inherit', fontWeight: 600 }}
                rel="noreferrer"
              >
                Learn more.
              </a>
            </CmdTag>
          </>
        )}
      </InputContainer>
    </Section>
  );
};
