import {
  announcementNudge,
  featureHighlightNudge,
  upgradeNudge,
  simpleNotification,
  productTour,
  feedback,
  rating,
  nps,
  bannerNudge,
  tooltipNudge,
} from './nudge_templates';
import { Template } from '../components/templates/templates';
import { INudgeType } from '@commandbar/internal/middleware/types';

import { ReactComponent as UpgradeNudgeSVG } from '../../img/upgrade_nudge.svg';
import { ReactComponent as SimpleNotificationSVG } from '../../img/simple_notification.svg';
import { ReactComponent as ProductTourSVG } from '../../img/product_tour.svg';
import { ReactComponent as NPSSurveySVG } from '../../img/nps_survey.svg';
import { ReactComponent as RatingSurveySVG } from '../../img/nudge_rating_survey.svg';
import { ReactComponent as FeedbackSurveySVG } from '../../img/feedback_survey.svg';
import { ReactComponent as AnnouncementSVG } from '../../img/announcement.svg';
import { ReactComponent as FeatureHighlightSVG } from '../../img/feature_highlight.svg';
import { ReactComponent as BannerSVG } from '../../img/banner.svg';
import { ReactComponent as TooltipSVG } from '../../img/tooltip.svg';

export const getTemplateCards = (type: string): Template<INudgeType>[] => {
  switch (type) {
    case 'announcement': {
      return [
        {
          title: 'Announcement',
          svg: AnnouncementSVG,
          category: 'announcement',
          template: announcementNudge,
        },
        {
          title: 'Upgrade Nudge',
          svg: UpgradeNudgeSVG,
          category: 'upgrade_nudge',
          template: upgradeNudge,
        },
        {
          title: 'Simple Notification',
          svg: SimpleNotificationSVG,
          category: 'simple_notification',
          template: simpleNotification,
        },
        {
          title: 'Banner',
          svg: BannerSVG,
          category: 'banner',
          template: bannerNudge,
        },
      ];
    }
    case 'product_tour': {
      return [
        {
          title: 'Tour',
          svg: ProductTourSVG,
          category: 'product_tour',
          template: productTour,
        },
        {
          title: 'Feature Highlight',
          svg: FeatureHighlightSVG,
          category: 'feature_highlight',
          template: featureHighlightNudge,
        },
        {
          title: 'Tooltip',
          svg: TooltipSVG,
          category: 'tooltip',
          template: tooltipNudge,
        },
      ];
    }
    case 'survey': {
      return [
        {
          title: 'User feedback',
          svg: FeedbackSurveySVG,
          category: 'feedback',
          template: feedback,
        },
        {
          title: 'Rating',
          svg: RatingSurveySVG,
          category: 'rating',
          template: rating,
        },
        {
          title: 'NPS',
          svg: NPSSurveySVG,
          category: 'nps',
          template: nps,
        },
      ];
    }
    default: {
      return [];
    }
  }
};
