import { CmdButton, CmdDropdown } from '@commandbar/design-system/cmd';
import {
  Announcement02,
  BookOpen01,
  Check,
  CheckSquareBroken,
  ChevronLeft,
  ChevronRight,
  FaceIdSquare,
  FilterLines,
  Flag03,
  MessageSmileSquare,
  SearchSm,
} from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';
import React from 'react';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import type { WidgetTableauSelection, WidgetType } from '@cb/types/misc/widgetTableau';
import { useAppContext } from '../AppStateContext';
import { nudgeTitleDisplay } from '../editor/nudges/NudgeList';

const nudge_items: (WidgetTableauSelection & { icon: React.JSXElementConstructor<any>; title: string })[] = [
  {
    icon: Flag03,
    title: 'Tours',
    type: 'nudge',
    id: 'tour',
  },
  {
    icon: Announcement02,
    title: 'Announcements',
    type: 'nudge',
    id: 'announcement',
  },
  {
    icon: MessageSmileSquare,
    title: 'Surveys',
    type: 'nudge',
    id: 'survey',
  },
  {
    icon: CheckSquareBroken,
    title: 'Checklists',
    type: 'checklist',
    id: 'checklist',
  },
];
const assist_items: (WidgetTableauSelection & { icon: React.JSXElementConstructor<any>; title: string })[] = [
  {
    icon: FaceIdSquare,
    title: 'Copilot',
    type: 'copilot',
    id: '',
  },
  {
    icon: BookOpen01,
    title: 'HelpHub',
    type: 'helphub',
    id: '',
  },
  {
    icon: SearchSm,
    title: 'Spotlight',
    type: 'spotlight',
    id: '',
  },
];

// combine the two arrays
const all_items = [...nudge_items, ...assist_items];

interface TableauWidgetSelectorProps {
  widgetSelection: WidgetTableauSelection;
  setWidgetSelection: (widgetSelection: WidgetTableauSelection) => void;
}

const TableauWidgetSelector: React.FC<TableauWidgetSelectorProps> = ({ widgetSelection, setWidgetSelection }) => {
  // rest of your component

  const { checklists, nudges, organization } = useAppContext();

  let currentSelectionLabel = 'All enabled widgets';

  let nudge: undefined | INudgeType = undefined;
  let isShowingSpecificNudge = false;

  const enabledWidgets: WidgetType[] = [
    'announcement',
    'tour',
    'survey',
    'checklist',
    ...(organization?.bar_enabled ? ['spotlight'] : []),
    ...(organization?.helphub_enabled ? ['helphub'] : []),
    ...(organization?.copilot_enabled ? ['copilot'] : []),
  ] as WidgetType[];

  const shownWidgets: WidgetType[] = widgetSelection.widgets || enabledWidgets;

  if (typeof widgetSelection.id === 'number' || !Number.isNaN(parseInt(widgetSelection.id))) {
    const stringId = widgetSelection.id.toString();
    const nudgeId = parseInt(stringId);
    if (widgetSelection.type === 'nudge') {
      nudge = nudges.find((nudge) => nudge.id === nudgeId);
      if (nudge) {
        currentSelectionLabel = nudgeTitleDisplay(nudge);
        isShowingSpecificNudge = true;
      }
    } else if (widgetSelection.type === 'checklist') {
      const checklist = checklists.find((checklist) => checklist.id === nudgeId);
      if (checklist) {
        currentSelectionLabel = checklist.title;
        isShowingSpecificNudge = true;
      }
    }
  } else {
    if (widgetSelection.type === 'collection') {
      if (!widgetSelection.widgets || widgetSelection.widgets.length === 0) {
        currentSelectionLabel = 'None';
      } else if (widgetSelection.widgets.length > 1) {
        const areWidgetsEqual =
          widgetSelection.widgets.length === enabledWidgets.length &&
          widgetSelection.widgets.every((widget) => enabledWidgets.includes(widget));

        if (areWidgetsEqual) {
          currentSelectionLabel = 'All enabled widgets';
        } else {
          currentSelectionLabel = 'Multiple widgets';
        }
      } else {
        for (const item of all_items) {
          if (item.id === widgetSelection.widgets[0] || item.type === widgetSelection.widgets[0]) {
            currentSelectionLabel = item.title;
          }
        }
      }
    } else if (widgetSelection.type === 'all') {
      currentSelectionLabel = 'All enabled widgets';
    } else {
      currentSelectionLabel =
        all_items.find((o) => o.type === widgetSelection.type && o.id === widgetSelection.id)?.title ||
        'All enabled widgets';
    }
  }

  const nudgeStepIndex = widgetSelection.nudgeStepIndex || 0;

  return (
    <div
      style={{
        zIndex: Z.Z_EDITOR,
        position: 'absolute',
        top: '64px',
        left: '16px',
        display: 'flex',
        gap: 8,
        alignItems: 'center',
      }}
    >
      <CmdDropdown.Menu>
        <CmdDropdown.SelectTrigger>
          <FilterLines />
          {currentSelectionLabel}
        </CmdDropdown.SelectTrigger>
        <CmdDropdown.Content align="start" style={{ width: '220px' }}>
          <CmdDropdown.Item
            onClick={(e) => {
              if (currentSelectionLabel === 'All enabled widgets') {
                setWidgetSelection({ type: 'collection', id: '', widgets: [] });
              } else {
                setWidgetSelection({ type: 'all', id: '' });
              }

              e.stopPropagation();
            }}
          >
            <div
              style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', gap: 8 }}
            >
              {'All enabled widgets'}
              {currentSelectionLabel === 'All enabled widgets' && <Check />}
            </div>
          </CmdDropdown.Item>
          <CmdDropdown.Separator />

          <CmdDropdown.Label>Nudge</CmdDropdown.Label>

          {nudge_items.map((item) => (
            <CmdDropdown.Item
              key={item.title}
              onClick={(e) => {
                setWidgetSelection({ type: item.type, id: item.id });

                const currentWidgets = shownWidgets;

                const type = item.id as 'announcement' | 'tour' | 'survey' | 'checklist';

                if (currentWidgets.includes(type)) {
                  currentWidgets.splice(currentWidgets.indexOf(type), 1);
                } else {
                  currentWidgets.push(type);
                }

                setWidgetSelection({ type: 'collection', id: '', widgets: currentWidgets });

                e.stopPropagation();
              }}
            >
              <div
                style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center', gap: 8 }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  {item.icon && <item.icon height={16} width={16} />}
                  {item.title}
                </div>
                {shownWidgets.includes(item.id as WidgetType) && !isShowingSpecificNudge && <Check />}
              </div>
            </CmdDropdown.Item>
          ))}

          <CmdDropdown.Separator />
          <CmdDropdown.Label>Assist</CmdDropdown.Label>
          {assist_items.map((item) => (
            <CmdDropdown.Item
              key={item.title}
              onClick={(e) => {
                const currentWidgets: WidgetTableauSelection['widgets'] = shownWidgets;

                const type = item.type as 'helphub' | 'copilot' | 'spotlight';

                if (currentWidgets.includes(type)) {
                  currentWidgets.splice(currentWidgets.indexOf(type), 1);
                } else {
                  currentWidgets.push(type);
                }

                setWidgetSelection({ type: 'collection', id: '', widgets: currentWidgets });
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'space-between',
                  color: !enabledWidgets.includes(item.type as WidgetType) ? 'rgb(121 124 133)' : undefined,
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  {item.icon && <item.icon height={16} width={16} />}
                  {item.title}
                </div>
                {shownWidgets.includes(item.type as WidgetType) && !isShowingSpecificNudge && <Check />}
              </div>
            </CmdDropdown.Item>
          ))}
        </CmdDropdown.Content>
      </CmdDropdown.Menu>
      {nudge && (
        <div
          style={{
            alignItems: 'center',
            height: '32px',
            background: 'white',
            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 1px 3px 0px rgba(0, 0, 0, 0.08)',
            display: 'flex',
            padding: '2px',
            border: '1px solid #D6D6D6',
            color: '#797C85',
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '6px',
          }}
        >
          <CmdButton
            variant="ghost"
            disabled={nudgeStepIndex === 0}
            onClick={() => {
              setWidgetSelection({ ...widgetSelection, nudgeStepIndex: nudgeStepIndex - 1 });
            }}
          >
            <ChevronLeft />
          </CmdButton>
          Step {nudgeStepIndex + 1}/{nudge?.steps.length}
          <CmdButton
            variant="ghost"
            disabled={nudgeStepIndex + 1 >= nudge?.steps.length}
            onClick={() => {
              setWidgetSelection({ ...widgetSelection, nudgeStepIndex: nudgeStepIndex + 1 });
            }}
          >
            <ChevronRight />
          </CmdButton>
        </div>
      )}
    </div>
  );
};

export default TableauWidgetSelector;
