/** @jsx jsx */
import React from 'react';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { ChevronDown } from '@commandbar/design-system/icons/react';
import ButtonWithReset from './ButtonWithReset';

const styles: CSSObject = {
  border: 'none',
  background: 'none',
  display: 'inline-flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '4px',
  borderRadius: 'var(--layout-radius-button)',
  cursor: 'pointer',

  color: 'var(--content-primary)',

  /* label/sm/medium */
  fontFamily: 'var(--font-font-family)',
  fontSize: 'var(--font-size-small)',
  fontWeight: 'var(--font-weight-medium)' as unknown as number,
  lineHeight: 'var(--font-line-height-normal)',

  '&:hover': {
    color: 'var(--content-secondary)',
  },

  '&:focus': {
    color: 'var(--content-primary)',
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    color: 'var(--content-secondary)',
  },
};

const slug = 'inlineButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  filled?: boolean;
};

const StyledInlineButton = ({
  themeV2,
  filled,
  children,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <ButtonWithReset css={[overrides, css(styles)]} {...props}>
      {children}
    </ButtonWithReset>
  );
};

StyledInlineButton.slug = slug;
StyledInlineButton.styles = styles;
StyledInlineButton.renderMock = (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
  <StyledInlineButton themeV2={{ themeV2: themeV2, mode: mode }}>
    Inline button <ChevronDown />
  </StyledInlineButton>
);

export default StyledInlineButton;
