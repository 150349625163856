import { CmdButton } from '@commandbar/design-system/cmd';
import { ChevronRight, ChevronLeft } from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';
import { INudgeType } from '@commandbar/internal/middleware/types';
import React from 'react';

export const NudgeStepChangeFloatingWidget = ({
  nudge,
  activeNudgeStepIndex,
  setActiveNugeStepIndex,
}: {
  nudge: INudgeType;
  activeNudgeStepIndex: number;
  setActiveNugeStepIndex: (index: number) => void;
}) => {
  return (
    <div
      style={{
        zIndex: Z.Z_EDITOR,
        position: 'absolute',
        top: 16,
        right: 132,
        left: 16,
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        width: 'fit-content',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          height: '32px',
          background: 'white',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.03), 0px 1px 3px 0px rgba(0, 0, 0, 0.08)',
          display: 'flex',
          padding: '2px',
          border: '1px solid #D6D6D6',
          color: '#797C85',
          fontSize: '14px',
          fontWeight: 500,
          borderRadius: '6px',
        }}
      >
        <CmdButton
          variant="ghost"
          disabled={activeNudgeStepIndex === 0}
          onClick={() => {
            const newVal = activeNudgeStepIndex - 1;
            setActiveNugeStepIndex(newVal);
          }}
        >
          <ChevronLeft />
        </CmdButton>
        Step {activeNudgeStepIndex + 1}/{nudge?.steps.length}
        <CmdButton
          variant="ghost"
          disabled={activeNudgeStepIndex + 1 >= nudge?.steps.length}
          onClick={() => {
            const newVal = activeNudgeStepIndex + 1;
            setActiveNugeStepIndex(newVal);
          }}
        >
          <ChevronRight />
        </CmdButton>
      </div>
    </div>
  );
};
