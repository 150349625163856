import { Condition } from '@commandbar/internal/middleware/conditions';
import {
  IEditorAvailabilityRule,
  IRuleOperator as OperatorType,
  operators,
} from '@commandbar/internal/middleware/helpers/rules';

export type { IAvailabilityRule } from '@commandbar/internal/middleware/helpers/rules';
export type { IRecommendationRule } from '@commandbar/internal/middleware/helpers/rules';
export type { IRule as ConditionRule } from '@commandbar/internal/middleware/helpers/rules';
export type { IRuleOperator as OperatorType } from '@commandbar/internal/middleware/helpers/rules';
export type { IAvailabilityRuleType as AvailabilityRuleType } from '@commandbar/internal/middleware/helpers/rules';
export type { IRecommendationRuleType as RecommendationRuleType } from '@commandbar/internal/middleware/helpers/rules';

export const numericOperatorTypes: OperatorType[] = ['isLessThan', 'isGreaterThan'];
export const trueFalseOperatorTypes: OperatorType[] = ['isTrue', 'isFalse'];
export const booleanOperatorTypes: OperatorType[] = [
  'isTrue',
  'isFalse',
  'isTruthy',
  'isFalsy',
  'isDefined',
  'isNotDefined',
];
export const audienceIntegrationOperatorTypes: OperatorType[] = ['isTrue', 'isFalse'];
export const elementOperatorTypes: OperatorType[] = [
  'classnameOnPage',
  'idOnPage',
  'selectorOnPage',
  'classnameNotOnPage',
  'idNotOnPage',
  'selectorNotOnPage',
];
export const listOperatorTypes: OperatorType[] = ['isInList', 'isNotInList'];
export const legacyListOperatorTypes: OperatorType[] = ['includes', 'doesNotInclude'];
export const stringOperatorTypes: OperatorType[] = ['startsWith', 'endsWith', 'matchesRegex'];
export const equalityOperatorTypes: OperatorType[] = ['is', 'isNot'];

export const ruleTypes: Array<{ value: IEditorAvailabilityRule['type']; label: string }> = [
  {
    value: 'url',
    label: 'Current URL Path',
  },
  {
    value: 'context',
    label: 'Metadata',
  },
  {
    value: 'element',
    label: 'DOM element',
  },
  {
    value: 'named_rule',
    label: 'Rule',
  },
  {
    value: 'executions',
    label: 'Commands Executed',
  },
  {
    value: 'shortcuts',
    label: 'Shortcuts Executed',
  },
  {
    value: 'opens',
    label: 'Opens',
  },
  {
    value: 'deadends',
    label: 'Deadends',
  },
  {
    value: 'sessions',
    label: 'Sessions',
  },
  {
    value: 'first_seen',
    label: 'First Seen',
  },
  {
    value: 'last_seen',
    label: 'Last Seen',
  },
];

export const operatorTypes = operators;

export enum AVAILABILITY_TYPE {
  Standard,
  Custom,
}

export interface IAvailabilityDependency {
  type: string;
  field: string;
  operator: string;
  message: JSX.Element;
}

export type ConditionProps<T extends Condition> = {
  condition: T;
};
