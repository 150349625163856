import { defaultFlags, type Flags } from '@cb/types/flags';

import { getProxySDK } from '../client/proxy';
import { _sentry } from '../client/symbols';
import { post } from './network';

/**
 * XXX: This is a temporary hack so that we can remove the `Hub` type from the build output of the commandbar package.
 * Because this file is imported by middleware/types.tsx, which is imported everywhere, we need to make sure it doesn't contain the `Hub` type.
 * Once we break up the types.tsx file into smaller files, we can remove this hack.
 */
const getSentryForFlags = () => {
  return getProxySDK()[_sentry] as {
    captureException: (error: unknown) => void;
  };
};

const _fetchFlags = async (request: {
  batch: { key: string; default: any }[];
  organization_id: string;
}): Promise<{ flags: { [key: string]: any } }> => {
  const sentry = getSentryForFlags();

  try {
    const response = await post('/flags/', request, {
      credentials: 'include',
    });
    if (response.status !== 200) {
      throw new Error(`Failed to fetch flags: ${response.statusText}`);
    }
    if (response.data.error) {
      throw new Error(response.data.error);
    }
    return response.data as { flags: { [key: string]: any } };
  } catch (error) {
    sentry?.captureException(error);

    // return default values for all flags
    return { flags: Object.fromEntries(request.batch.map((b) => [b.key, b.default])) };
  }
};

export const fetchFlags = async (orgID: string): Promise<Flags> => {
  const batch = Object.entries(defaultFlags).map(([key, defaultValue]) => ({ key, default: defaultValue }));
  const { flags } = await _fetchFlags({ organization_id: orgID, batch });

  // Override flags with values from local storage if present
  for (const flag in flags) {
    const localStorageValue = localStorage.getItem(`commandbar.flags:${flag}`);
    if (localStorageValue !== null) {
      flags[flag] = JSON.parse(localStorageValue);
    }
  }

  return { ...defaultFlags, ...flags };
};

export const fetchFlag = async (orgID: string, flag: string): Promise<boolean> => {
  const isValidFlag = (flag: string): flag is keyof Flags => {
    return Object.keys(defaultFlags).includes(flag);
  };
  if (!isValidFlag(flag)) return false;

  const batch = [{ key: flag, default: defaultFlags[flag] }];
  const { flags } = await _fetchFlags({ organization_id: orgID, batch });
  return flags[flag];
};
