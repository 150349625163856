/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import StyledHelpDocCard from '../shared/StyledHelpDocCard';
import { leftIcon, rightIcon } from '../../helpers/shared';
import StyledIconContainer from '../shared/StyledIconContainer';
import Icon from '@commandbar/internal/client/Icon';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { CSSObject, jsx, css } from '@emotion/core';
import ButtonWithReset from '../shared/ButtonWithReset';
import { BookOpen01, ChevronRight } from '@commandbar/design-system/icons/react';

const styles: CSSObject = {
  display: 'flex',
  minHeight: '64px',
  padding: 'var(--layout-padding)',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  textAlign: 'left',
  cursor: 'pointer',

  borderRadius: 'var(--layout-radius-button)',
  border: '1px solid var(--border-primary)',
  background: 'var(--background-primary)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  '&:hover': {
    border: '1px solid var(--border-primary-hover)',
    background: 'var(--background-primary-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
    '--content-primary': 'var(--content-primary-hover)',
    '--content-secondary': 'var(--content-secondary-hover)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
    border: '1px solid var(--base-accent)',
  },

  '&:focus-visible': {
    outline: 'none',
  },

  mark: {
    padding: 0,
    color: 'var(--content-secondary)',
    borderRadius: '2px',
    backgroundColor: 'var(--highlight-color)',
  },
};

const answerStyles: CSSObject = {
  background: 'linear-gradient(to right, var(--form-control-active-background), var(--background-primary))',

  '&:hover': {
    background: 'var(--form-control-focus-ring-color)',
  },
};

const loadingStyles: CSSObject = {
  background: 'linear-gradient(89deg, #d9d9d9 13.8%, #c9c9c9 30.92%, #d9d9d9 48.56%)',
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 1 0;

  overflow: hidden;
`;

const Title = styled.div`
  color: var(--content-primary);

  /* label/base/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-normal);

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Content = styled.div`
  color: var(--content-secondary);

  /* label/base/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SearchContent = styled(Content)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal; /* Override to allow wrapping */
`;

const LoadingTitle = styled.div`
  width: 195.55px;
  height: 16px;
  border-radius: 4px;
  background: var(--base-white);
`;

const LoadingContent = styled.div`
  width: 289.834px;
  height: 12px;
  border-radius: 4px;
  background: var(--base-white);
`;

const AnswerIconContainer = styled(StyledIconContainer)`
  color: var(--base-accent);
`;

const DefaultIconContainer = styled(StyledIconContainer)`
  color: var(--content-secondary);
`;

type RecommendationCardProps = {
  type: string;
  label: string;
  content: string;
  loading?: boolean;
  featured?: boolean;
  active: boolean;
  page: 'search' | 'home';
  backgroundImage?: string;
  icon: string | null | undefined;
  iconColor: string | null | undefined;
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const slug = 'contentListItem';

const StyledHelpHubCard = Object.assign(
  React.forwardRef<HTMLButtonElement, RecommendationCardProps & React.HTMLAttributes<HTMLButtonElement>>(
    ({ children, type, label, content, loading, featured, active, page, icon, iconColor, themeV2, ...props }, ref) => {
      const { overrides } = useComponentOverrides(slug, themeV2);

      const LeftIcon = icon ? (
        <Icon
          icon={icon}
          style={{
            ...(iconColor && { color: iconColor }),
            // reset featured icon color to white if it is currently black
            ...(featured && iconColor === '#000000' && { color: 'var(--base-white)' }),
          }}
        />
      ) : (
        leftIcon(type)
      );

      return !!featured ? (
        <StyledHelpDocCard
          ref={ref}
          type={type}
          loading={!!loading}
          label={label}
          icon={LeftIcon}
          themeV2={themeV2}
          style={{ height: '150px' }}
          {...props}
        />
      ) : !!loading ? (
        <ButtonWithReset css={[overrides, css(styles), css(loadingStyles)]}>
          <LoadingTitle />
          {page === 'search' && <LoadingContent />}
        </ButtonWithReset>
      ) : type === 'answer' ? (
        <ButtonWithReset css={[overrides, css(styles), css(answerStyles)]} ref={ref} {...props}>
          <AnswerIconContainer>{LeftIcon}</AnswerIconContainer>
          <TextContainer>
            <Title>{label}</Title>
            {page === 'search' ? (
              <SearchContent dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <Content dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </TextContainer>
          <DefaultIconContainer>{active && rightIcon(type)}</DefaultIconContainer>
        </ButtonWithReset>
      ) : (
        <ButtonWithReset css={[overrides, css(styles)]} ref={ref} {...props}>
          <DefaultIconContainer>{LeftIcon}</DefaultIconContainer>
          <TextContainer>
            <Title>{label}</Title>
            {page === 'search' ? (
              <SearchContent dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <Content dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </TextContainer>
          <DefaultIconContainer>{active && rightIcon(type)}</DefaultIconContainer>
        </ButtonWithReset>
      );
    },
  ),
  {
    slug: slug,
    styles: styles,
    renderMock: (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
      <StyledHelpHubCardMock themeV2={themeV2} mode={mode} />
    ),
  },
);

const StyledHelpHubCardMock = ({ themeV2, mode }: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const { overrides } = useComponentOverrides(slug, { themeV2: themeV2, mode: mode });

  return (
    <ButtonWithReset css={[overrides, css(styles)]}>
      <DefaultIconContainer>
        <BookOpen01 />
      </DefaultIconContainer>
      <TextContainer>
        <Title>{'Content item title'}</Title>
        <Content dangerouslySetInnerHTML={{ __html: 'Search match or beginning of this content item' }} />
      </TextContainer>
      <DefaultIconContainer>
        <ChevronRight />
      </DefaultIconContainer>
    </ButtonWithReset>
  );
};

export default StyledHelpHubCard;
