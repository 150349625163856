import { type Editor, Element, Transforms } from 'slate';

import { ElementType } from '../constants';
import type { HtmlElement, ParagraphElement } from '../types';

/* Line breaks can be handled differently depending on whether we're working
 * with regular paragraph nodes or HTML. We do some very basic parsing to keep
 * the node types set correctly.
 */
export const withHtml = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node] = entry;

    if (
      Element.isElementType<HtmlElement>(node, ElementType.Html) &&
      node.children.every((child) => !child.text.startsWith('<'))
    ) {
      Transforms.setNodes(editor, { type: ElementType.Paragraph });

      return;
    }

    if (
      Element.isElementType<ParagraphElement>(node, ElementType.Paragraph) &&
      node.children.every((child) => 'text' in child && child.text.startsWith('<'))
    ) {
      Transforms.setNodes(editor, { type: ElementType.Html });

      return;
    }

    normalizeNode(entry);
  };

  return editor;
};
