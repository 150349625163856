import React, { useRef, useCallback } from 'react';
import type { RenderElementProps } from 'slate-react';

import { useMergeRefs } from '@commandbar/internal/hooks/useMergeRefs';
import type { LinkElement } from '../types';
import { ToolbarType, useEditorToolbar } from './toolbar';
import { useHover } from './toolbar/useHover';

interface LinkProps extends RenderElementProps {
  element: LinkElement;
}

export const Link = ({ attributes, children, element }: LinkProps) => {
  const anchorRef = useRef<HTMLElement>(null);
  const { type, setType, openAtAnchor, setLinkElement, close, isOpen } = useEditorToolbar();

  const openLinkPreview = useCallback(() => {
    setType(ToolbarType.PreviewLink);
    setLinkElement(element);
    openAtAnchor(anchorRef);
  }, [element, openAtAnchor, setType, setLinkElement]);

  const handleClick = useCallback(() => {
    if (type === ToolbarType.Selection) return;

    openLinkPreview();
  }, [type, openLinkPreview]);

  // Use custom hover hook to manage hover state and toolbar interactions.
  const { targetRef: hoverRef } = useHover({
    onHover: () => {
      // if the editor is open, we don't want to switch editor by hovering a link
      if (isOpen) return;

      openLinkPreview();
    },
    offHover: () => {
      // if we're not previewing a link, we don't want to close the editor by moving the mouse out of bounds
      if (type !== ToolbarType.PreviewLink && isOpen) return;

      close();
    },
  });

  // Merges multiple refs into one to ensure all ref callbacks are called.
  const mergedRef = useMergeRefs(attributes.ref, anchorRef, hoverRef);

  return (
    <a
      {...attributes}
      onClick={handleClick}
      ref={mergedRef}
      href={element.url}
      className="text-blue800 hover:text-blue900"
      data-ignore-outside-click
    >
      {children}
    </a>
  );
};
