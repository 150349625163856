import { Col, Select } from '@commandbar/design-system/components/antd';
import React from 'react';
import { camelCaseToRegular } from '../helpers';
import { availableOperatorTypes, pastTenseOperators } from '../operators';
import { useConditionEditor } from '../state/useConditionEditor';
import { IUserProperty } from '@commandbar/internal/middleware/userProperty';
import {
  booleanOperatorTypes,
  equalityOperatorTypes,
  legacyListOperatorTypes,
  listOperatorTypes,
  numericOperatorTypes,
  stringOperatorTypes,
} from '../types';

interface OperatorSelectProps {
  propertyType?: IUserProperty['type'] | null;
}

export const OperatorSelect: React.FC<OperatorSelectProps> = ({ propertyType }) => {
  const { condition, onConditionChange, disabled, setHasBlurred } = useConditionEditor();

  const options = availableOperatorTypes(condition)
    .filter((operator) => {
      if (condition.type === 'user_property') {
        if (propertyType === 'string') {
          return [
            'isTruthy',
            'isFalsy',
            'isDefined',
            'isNotDefined',
            ...stringOperatorTypes,
            ...listOperatorTypes,
            ...equalityOperatorTypes,
            ...legacyListOperatorTypes,
          ].includes(operator);
        } else if (propertyType === 'numeric') {
          return [
            'isTruthy',
            'isFalsy',
            'isDefined',
            'isNotDefined',
            ...numericOperatorTypes,
            ...listOperatorTypes,
            ...equalityOperatorTypes,
          ].includes(operator);
        } else if (propertyType === 'boolean') {
          return booleanOperatorTypes.includes(operator);
        } else if (propertyType === 'array' || propertyType === 'object') {
          return ['isTruthy', 'isFalsy', 'isDefined', 'isNotDefined', ...legacyListOperatorTypes].includes(operator);
        }
        // TODO: not sure which operators to allow for datetime
        else if (propertyType === 'datetime') {
          return ![...legacyListOperatorTypes].includes(operator);
        }
      }

      return true;
    })
    .map((availableOperator) => {
      const isPastTense = [
        'nudge_interaction',
        'questlist_interaction',
        'video_interaction',
        'help_doc_interaction',
      ].includes(condition.type);
      const tensedOperator = isPastTense
        ? pastTenseOperators[availableOperator] ?? availableOperator
        : availableOperator;
      const displayValue = camelCaseToRegular(tensedOperator);

      return {
        label: displayValue,
        value: availableOperator,
      };
    });

  if (!('operator' in condition)) {
    throw new Error('OperatorSelect: condition does not have an operator');
  }

  return (
    <Col flex="1 0 auto" style={{ overflow: 'hidden' }}>
      <Select
        disabled={disabled}
        value={condition.operator}
        onChange={(operator) => onConditionChange({ ...condition, operator })}
        style={{ maxWidth: '100%', width: '100%' }}
        dropdownMatchSelectWidth={false}
        onBlur={() => setHasBlurred(true)}
        size="small"
        options={options}
      />
    </Col>
  );
};
