import React, { useEffect, useRef, useState } from 'react';
import Hotkey from '@commandbar/internal/client/Hotkey';

import { ICommandCategoryType, IEditorCommandTypeLite, IUserType } from '@commandbar/internal/middleware/types';
import { StatusBadge, commandDefault, Code, Icon, Modal } from '@commandbar/design-system/components/antd';
import { getOperatingSystem, PlatformType } from '@commandbar/internal/util/operatingSystem';
import { deleteCommandWithWarnings } from './utils';
import * as Command from '@commandbar/internal/middleware/command';
import { ContextDataObject } from '../context/contextSettings/useContextPartition';
import { ICommandRow } from './types';
import {
  AlertTriangle,
  Copy02,
  Copy06,
  DotsVertical,
  Edit03,
  FaceIdSquare,
  TerminalSquare,
  Trash04,
} from '@commandbar/design-system/icons/react';
import { RouterHistory } from '@sentry/react/types/reactrouter';
import { getCommandRoute } from '../PagesOrActions';
import { CmdButton, CmdDropdown, CmdTag, CmdTooltip, cmdToast } from '@commandbar/design-system/cmd';
import { AppState } from 'editor/src/AppStateContext';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

const { info } = Modal;

const generateCommandCode = (command: IEditorCommandTypeLite) => {
  // Custom parsing is applied here in order to compose object structure we need.
  // The following solution is used instead of pretty simple trick with JSON.stringify(command, null, 2) to have more control on output string,
  // namely using single quotes instead of double, do not use quotes for object key names, set custom line breaks and white spaces

  const argWhitelist = [
    'type',
    'input_type',
    'preselected_key',
    'value',
    'order_key',
    'label',
    'dateTimeArgumentTypeId',
  ];

  const formatArguments = (args: { [key: string]: any }): string =>
    Object.entries(args).reduce((acc, [key, value], index, arr) => {
      acc += `\n   ${key}: ${JSON.stringify(value, argWhitelist, 4).replace(/"/g, "'").replace('\n}', '\n   }')}${
        index < arr.length - 1 ? ',' : '\n'
      }`;
      return acc;
    }, '') + '  }';

  return `window.CommandBar.addCommand({
  text: '${command.text}',
  name: '${command.text.toLowerCase().trim().replace(/\s/g, '_')}',
  category: ${Number(command.category) ? Number(command.category) : undefined},
  arguments: {${Object.keys(command.arguments).length ? formatArguments(command.arguments) : '}'},
  template: {
    type: '${command.template.type}',
    value: '${command.template.value}',
    operation: '${command.template.operation}',
  }${!!command.icon ? `,\n icon: '${command.icon}'` : ''}
});`;
};

const checkOverflow = (el: HTMLElement) => {
  const curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

  const isOverflowing = el.clientWidth < el.scrollWidth;

  el.style.overflow = curOverflow;

  return isOverflowing;
};

const Hotkeys = ({ children }: { children: React.ReactNode }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }

    setShowTooltip(checkOverflow(ref.current as HTMLElement));
  }, []);

  const content = (
    <div
      ref={ref}
      style={{ width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontWeight: 600 }}
    >
      {children}
    </div>
  );

  if (showTooltip) {
    return (
      <CmdTooltip message={children}>
        <div>{content}</div>
      </CmdTooltip>
    );
  }

  return content;
};

export const renderHotkeysColumn = (command: IEditorCommandTypeLite) => {
  const defaultKey = 'hotkey_mac';
  const os = getOperatingSystem();

  const keys: Record<PlatformType, 'hotkey_mac' | 'hotkey_win'> = {
    mac: 'hotkey_mac',
    ios: 'hotkey_mac',
    windows: 'hotkey_win',
    linux: 'hotkey_win',
    android: 'hotkey_win',
  };

  const key = keys[os] || defaultKey;
  const object = command as Record<string, any>;

  const hotkey = Hotkey.toArray(object[key]).map((k: string, i, arr) => {
    return Hotkey.translateToPlatformSymbol(
      k,
      ['windows', 'linux', 'android'].includes(os) ? 'win' : 'mac',
      i === arr.length - 1 || arr[i + 1] === 'then',
    );
  });

  return <Hotkeys>{hotkey}</Hotkeys>;
};

export const renderThirdPartySourceColumn = (third_party_source: string | null | undefined) => {
  if (third_party_source === undefined || third_party_source === null || third_party_source === '') {
    return;
  }
  return <CmdTag variant="info">{third_party_source}</CmdTag>;
};

export const renderTextColumn = (
  text: string,
  command: IEditorCommandTypeLite,
  records: ContextDataObject[],
  category: ICommandCategoryType | null,
) => {
  const icon = category && category.icon ? category.icon : command.icon;
  const icon_color = category && category.icon_color ? category.icon_color : command.icon_color;

  // FIXME: TMP for command category migration
  const objectKey = command.template.object;
  const isObjectCommandWithoutCategory = !!objectKey && !command.category;
  const CATEGORY_WARNING_MESSAGE = `This command is missing a category.
  Please assign this command to a category by clicking on it and updating the "Category" field.`;

  const isRecordAction = !!records.find((v) => Command.isRecordAction(command, v.key));
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        icon={icon || commandDefault(command)}
        style={{ fontSize: 12, flexShrink: 0, color: icon_color || undefined }}
        useDefaultSVGColor
        allowDefaultSVGColorOverride
      />
      <span style={{ marginLeft: '5px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {text}
      </span>
      {isRecordAction && <CmdTag style={{ marginLeft: '5px' }}>Record action</CmdTag>}
      &nbsp;&nbsp;&nbsp;
      {isObjectCommandWithoutCategory && (
        <CmdTooltip message={CATEGORY_WARNING_MESSAGE}>
          <AlertTriangle color="orange" />
        </CmdTooltip>
      )}
    </div>
  );
};

export const renderStatusColumn = (is_live: boolean, record: ICommandRow) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center', minWidth: 45 }}>
      {is_live ? (
        <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Live" />
      ) : (
        <StatusBadge color="orange" text="Draft" />
      )}
      {record.copilot_suggest && (
        <CmdTooltip message="Suggested in Copilot">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaceIdSquare style={{ flexShrink: 0, marginLeft: '8px' }} />
          </div>
        </CmdTooltip>
      )}
    </span>
  );
};

export const renderOptionsColumn = ({
  command,
  appState,
  history,
  user,
}: {
  command: IEditorCommandTypeLite;
  appState: AppState;
  history: RouterHistory;
  user: IUserType | null;
}) => {
  const commandToDelete = command;
  const dispatch = appState.dispatch;
  const canEdit = hasRequiredRole(user, command.is_live ? 'editor' : 'contributor');

  const availableMenuItems = [];
  if (canEdit) {
    availableMenuItems.push({
      name: 'Edit',
      icon: <Edit03 />,
      onClick: () => {
        history.push(`${getCommandRoute(command)}/${command.id}${history.location?.search || ''}`);
      },
    });
  }

  if (hasRequiredRole(user, 'contributor')) {
    availableMenuItems.push({
      name: 'Duplicate',
      icon: <Copy02 />,
      onClick: async () => {
        const c = await Command.get(command.id.toString());
        dispatch.commands.save(
          {
            ...c,
            id: -1,
            text: `Copy of ${c.text}`,
            is_live: false,
          },
          { notify: true, throttle: true },
        );
      },
    });
  }

  availableMenuItems.push({
    name: 'Copy ID',
    icon: <Copy06 />,
    onClick: () => {
      navigator.clipboard.writeText(`${command.id}`).then(function () {
        cmdToast.success('ID copied to clipboard.');
      });
    },
  });

  availableMenuItems.push({
    name: 'Show code',
    icon: <TerminalSquare />,
    onClick: () => {
      const commandCode = generateCommandCode(command);

      info({
        title: 'Code',
        icon: null,
        maskClosable: true,
        content: (
          <div>
            {`Below is a code example to recreate this command from the API. For more information about
            programmatic commands, check out `}
            <a
              href="https://www.commandbar.com/docs/sdk/pages/addcommand/"
              target="_blank"
              rel="noreferrer noopener"
              style={{ textDecoration: 'underline' }}
            >
              this page
            </a>
            .
            <div
              style={{
                padding: '10px',
                margin: '10px 0 0 0',
                background: 'rgb(242, 242, 242)',
              }}
            >
              <Code content={commandCode} />
            </div>
          </div>
        ),
      });
    },
  });

  if (canEdit) {
    availableMenuItems.push({
      name: 'Delete',
      icon: <Trash04 />,
      onClick: async () => {
        await deleteCommandWithWarnings({
          commandToDelete,
          appState,
        });
      },
    });
  }

  return (
    <CmdDropdown.Menu>
      <CmdDropdown.Trigger onClick={(e) => e.stopPropagation()}>
        <CmdButton icon={<DotsVertical />} variant="ghost" />
      </CmdDropdown.Trigger>
      <CmdDropdown.Content style={{ width: '150px' }}>
        {availableMenuItems.map((item) => (
          <CmdDropdown.Item
            key={item.name}
            onClick={(e) => {
              e.stopPropagation();
              item.onClick();
            }}
          >
            {item.icon}
            {item.name}
          </CmdDropdown.Item>
        ))}
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};
