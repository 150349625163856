import React from 'react';
import { Releases } from '@commandbar/internal/middleware/releases';
import { Download01 } from '@commandbar/design-system/icons/react';
import { IOrganizationType, IRelease } from '@commandbar/internal/middleware/types';
import { FormRow, Select, Space } from '@commandbar/design-system/components/antd';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';

const AirgapControls = (props: { organization: IOrganizationType }) => {
  const { organization } = props;
  const [releases, setReleases] = React.useState<IRelease[]>([]);
  const [environments, setEnvironments] = React.useState<string[]>([]);
  const [selectedVersion, setSelectedVersion] = React.useState<string | number | undefined>(undefined);

  React.useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const view = await Releases.readView();
        const envs = view.environments.map((envInfo) => envInfo.environment);
        setEnvironments(envs);
      } catch (error) {
        console.error('Error fetching environments:', error);
      }
    };

    const fetchReleases = async () => {
      try {
        const releases = await Releases.listReleases();
        const latestReleases = releases.slice(0, 5);
        setReleases(latestReleases);
      } catch (error) {
        console.error('Error fetching releases:', error);
      }
    };

    fetchReleases();
    fetchEnvironments();
  }, [organization.id]);

  const handleConfigDownload = (envOrVersion?: string | number) => {
    let url = `${process.env.REACT_APP_API_URL}/organizations/${organization.id.toString()}/config/`;
    let fileName = 'config.json';
    if (!envOrVersion) {
      /** Default to latest */
      url += '?env=latest';
      fileName = 'latest_' + fileName;
    } else if (typeof envOrVersion === 'number') {
      /** Release version */
      url += `?version=v${envOrVersion}`;
      fileName = `v${envOrVersion}_` + fileName;
    } else {
      /** env */
      url += `?env=${envOrVersion}`;
      fileName = `${envOrVersion}_` + fileName;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const jsonData = JSON.stringify(data);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
      .catch((error) => {
        cmdToast.error(`Error downloading config: ${error}`);
      });
  };

  return (
    <FormRow
      title="Download config"
      input={
        <Space size="middle">
          <Select value={selectedVersion || ''} onChange={(e) => setSelectedVersion(e)} style={{ minWidth: 125 }}>
            <Select.Option value="">Latest</Select.Option>
            {environments.map((env) => (
              <Select.Option key={`env_${env}`} value={env}>{`Env: ${env}`}</Select.Option>
            ))}
            {releases.map((release) => (
              <Select.Option
                key={`release_${release.id}`}
                value={release.history_event.version_num}
              >{`v${release.history_event.version_num}: ${release.notes}`}</Select.Option>
            ))}
          </Select>
          <CmdButton variant="ghost" icon={<Download01 />} onClick={() => handleConfigDownload(selectedVersion)} />
        </Space>
      }
    />
  );
};

export default AirgapControls;
