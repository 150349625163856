import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { CmdModal } from '../cmd';

// Define the type for the confirmModal options
interface ConfirmModalOptions {
  title: string;
  content: ReactNode;
  onOk?: () => Promise<void> | void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
}

// Utility function to handle Modal.confirm API
const confirmModal = ({
  title,
  content,
  onOk,
  onCancel,
  okText = 'Ok',
  cancelText = 'Cancel',
}: ConfirmModalOptions): void => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const handleClose = (): void => {
    ReactDOM.unmountComponentAtNode(container);
    container.remove();
  };

  const handleOk = async (): Promise<void> => {
    if (onOk) {
      await onOk();
    }
    handleClose();
  };

  const handleCancel = (): void => {
    if (onCancel) {
      onCancel();
    }
    handleClose();
  };

  ReactDOM.render(
    <CmdModal defaultOpen={true} onOpenChange={(open) => !open && handleCancel()}>
      <CmdModal.Content style={{ width: '400px' }}>
        <CmdModal.Title>{title}</CmdModal.Title>
        <CmdModal.Description>{content}</CmdModal.Description>
        <CmdModal.Footer>
          <CmdModal.Cancel onClick={handleCancel}>{cancelText}</CmdModal.Cancel>
          <CmdModal.Action onClick={handleOk} buttonText={okText} variant="destructive" />
        </CmdModal.Footer>
      </CmdModal.Content>
    </CmdModal>,
    container,
  );
};

// Exporting the function
export const Modal = {
  confirm: confirmModal,
};
