/** @jsx jsx */
import { type CSSObject, css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';

import type { IThemeV2Type } from '@cb/types/entities/theme';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import ButtonWithReset from './ButtonWithReset';
import StyledIconContainer from './StyledIconContainer';
import { BookOpen01 } from '@commandbar/design-system/icons/react';

const getFeaturedCardStyles = (backgroundImage?: string): CSSObject => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  padding: '0px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  textAlign: 'left',
  flex: '1 0 0',
  maxWidth: 'calc(50% - var(--layout-gap) / 2)',
  cursor: 'pointer',

  borderRadius: 'var(--layout-radius-button)',
  border: '1px solid var(--button-primary-border-color)',
  background: 'var(--button-primary-background)',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  overflow: 'hidden',

  ...(backgroundImage
    ? {
        backgroundImage: `url('${encodeURI(backgroundImage)}')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }
    : {}),

  '&:hover': {
    ...(!backgroundImage
      ? {
          background: 'var(--button-primary-background-hover)',
        }
      : {}),
    border: '1px solid var(--button-primary-border-color-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
    '--button-primary-content': 'var(--button-primary-content-hover)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
});

const loadingStyles: CSSObject = {
  background: 'linear-gradient(89deg, #d9d9d9 13.8%, #c9c9c9 30.92%, #d9d9d9 48.56%)',
};

const FeaturedTitle = styled.div`
  color: var(--button-primary-content);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  /* label/xl/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-extra-large);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-tight);
`;

const FeaturedIconContainer = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  position: absolute;
  right: 12px;
  bottom: 12px;

  border-radius: 100px;
  background: var(--base-black);
`;

const FeaturedIcon = styled(StyledIconContainer)`
  color: var(--base-white);
`;

type HelpDocCardProps = {
  type: string;
  label: string;
  loading: boolean;
  backgroundImage?: string;
  icon: JSX.Element;
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const slug = 'contentGridItem';

export const StyledHelpDocCard = Object.assign(
  React.forwardRef<HTMLButtonElement, HelpDocCardProps & React.HTMLAttributes<HTMLButtonElement>>(
    ({ children, type, label, loading, backgroundImage, icon, themeV2, style, ...props }, ref) => {
      const { overrides } = useComponentOverrides(slug, themeV2);

      return (
        <ButtonWithReset
          css={[overrides, css(getFeaturedCardStyles(backgroundImage)), loading && css(loadingStyles)].filter(Boolean)}
          ref={ref}
          style={style}
          {...props}
        >
          {!loading && <FeaturedTitle>{label}</FeaturedTitle>}
          <FeaturedIconContainer>
            <FeaturedIcon>{icon}</FeaturedIcon>
          </FeaturedIconContainer>
        </ButtonWithReset>
      );
    },
  ),
  {
    slug: slug,
    styles: getFeaturedCardStyles(),
    renderMock: (themeV2: IThemeV2Type, mode: 'light_mode' | 'dark_mode') => (
      <StyledHelpDocCardMock themeV2={themeV2} mode={mode} />
    ),
  },
);

const StyledHelpDocCardMock = ({ themeV2, mode }: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' }) => {
  const { overrides } = useComponentOverrides(slug, { themeV2: themeV2, mode: mode });

  return (
    <ButtonWithReset
      style={{ height: '150px', maxWidth: '150px' }}
      css={[overrides, css(getFeaturedCardStyles())].filter(Boolean)}
    >
      <FeaturedTitle>{'Grid item title'}</FeaturedTitle>
      <FeaturedIconContainer>
        <FeaturedIcon>
          <BookOpen01 />
        </FeaturedIcon>
      </FeaturedIconContainer>
    </ButtonWithReset>
  );
};

export default StyledHelpDocCard;
