import { flip, hide, offset, useFloating } from '@floating-ui/react-dom';
import * as Portal from '@radix-ui/react-portal';
import React, {
  type InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  type HTMLAttributes,
  type ComponentProps,
  useLayoutEffect,
} from 'react';
import { type RenderElementProps, useSlate } from 'slate-react';

import { useEventListener } from '@commandbar/internal/hooks/useEventListener';
import { useMergeRefs } from '@commandbar/internal/hooks/useMergeRefs';
import { cn } from '../../util';
import { Commands } from '../commands';
import { EMPTY_VALUE, MENTION_TRIGGER } from '../constants';
import type { MentionsSelectElement } from '../types';
import { createHotkeyAction } from '../utils';

enum Aria {
  ListId = 'cmd-rich-text-editor-mentions-list',
  ComboboxId = 'cmd-rich-text-editor-mentions-combobox',
  InputLabel = 'Suggestions',
  NoResultsId = 'mentions-list-no-results',
  MentionItemId = 'mention-options',
}

interface MentionInputProps extends HTMLAttributes<HTMLInputElement> {
  value: string;
  handleChange: NonNullable<InputHTMLAttributes<HTMLInputElement>['onChange']>;
  handleKeyDown: NonNullable<InputHTMLAttributes<HTMLInputElement>['onKeyDown']>;
  expanded: boolean;
  activeDescendantId?: string;
}

// MentionInput: Renders the input field for mention search
const MentionInput = forwardRef<HTMLInputElement, MentionInputProps>(
  ({ value, handleChange, handleKeyDown, activeDescendantId, expanded }, ref) => {
    const editor = useSlate();
    const inputRef = useRef<HTMLInputElement>(null);
    const hiddenSpanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      // Ensure the input is focused when the component mounts
      inputRef.current?.focus();

      return () => {
        Commands.focusEditor(editor);
      };
    }, [editor]);

    useLayoutEffect(() => {
      // Dynamically adjust the width of the input based on its value
      if (hiddenSpanRef.current && inputRef.current) {
        const width = hiddenSpanRef.current.offsetWidth;
        inputRef.current.style.width = `${width}px`;
      }
    }, [value]);

    const mergedInputRef = useMergeRefs(ref, inputRef);

    return (
      <>
        {/* Hidden span used to calculate the width of the input */}
        <span ref={hiddenSpanRef} className="invisible absolute top-0 left-0 h-0 whitespace-pre" aria-hidden>
          {value}
        </span>
        <input
          id={Aria.ComboboxId}
          ref={mergedInputRef}
          type="text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="m-0 min-w-[1ch] border-none p-0 outline-none"
          role="combobox"
          aria-autocomplete="list"
          aria-controls={Aria.ListId}
          aria-expanded={expanded}
          aria-activedescendant={activeDescendantId}
          aria-label={Aria.InputLabel}
        />
      </>
    );
  },
);

interface MentionsListProps<T = MentionsSelectElement['value'], U = number> extends HTMLAttributes<HTMLUListElement> {
  mentions: T[];
  handleSelect: (mention: T) => void;
  activeIndex: U;
  setActiveIndex: (index: U) => void;
}

// MentionsList: Renders the list of mention suggestions
const MentionsList = forwardRef<HTMLUListElement, MentionsListProps>(
  ({ mentions, handleSelect, activeIndex, setActiveIndex, style }, ref) => {
    const listRef = useRef<HTMLUListElement>();
    const mergedRef = useMergeRefs(listRef, ref);

    useEffect(() => {
      // Scroll the active item into view if necessary
      const listElement = listRef.current;
      const activeElement = listElement?.querySelector(`#${Aria.MentionItemId}-${activeIndex}`);

      if (!(listElement && activeElement)) return;

      const listRect = listElement.getBoundingClientRect();
      const activeRect = activeElement.getBoundingClientRect();

      if (activeRect.bottom > listRect.bottom) {
        listElement.scrollTop += activeRect.bottom - listRect.bottom;
      } else if (activeRect.top < listRect.top) {
        listElement.scrollTop -= listRect.top - activeRect.top;
      }
    }, [activeIndex]);

    return (
      <ul
        ref={mergedRef}
        id={Aria.ListId}
        role="listbox"
        className="z-max flex max-h-40 cursor-pointer flex-col overflow-y-auto rounded-sm border bg-gray100 p-0.5 shadow-md"
        tabIndex={-1}
        style={style}
      >
        {mentions.length > 0 ? (
          mentions.map((mention, index) => (
            <li
              key={mention}
              id={`${Aria.MentionItemId}-${index}`}
              role="option"
              aria-selected={index === activeIndex}
              className={cn(
                'relative flex cursor-default select-none items-center rounded-sm px-1 py-px text-sm leading-0 outline-none',
                index === activeIndex && 'bg-gray200',
              )}
              onClick={() => {
                handleSelect(mention);
              }}
              onMouseOver={() => {
                setActiveIndex(index);
              }}
            >
              {mention}
            </li>
          ))
        ) : (
          <li
            id={Aria.NoResultsId}
            className="relative flex cursor-default select-none items-center rounded-sm px-1 py-px text-sm leading-0 outline-none"
          >
            No results found
          </li>
        )}
      </ul>
    );
  },
);

interface MentionsInputProps extends RenderElementProps {
  element: MentionsSelectElement;
  mentions: MentionsSelectElement['value'][];
}

// MentionsSelect: Main component for the mentions feature
export const MentionsSelect = ({ element, attributes, mentions, children }: MentionsInputProps) => {
  const { ref, ...selectProps } = attributes;

  const editor = useSlate();
  const [inputValue, setInputValue] = useState(EMPTY_VALUE);
  const [activeIndex, setActiveIndex] = useState(0);

  // Filter mentions based on the search input
  const filteredMentions = useMemo(
    () => mentions.filter((c) => c.toLowerCase().includes(inputValue.toLowerCase())),
    [mentions, inputValue],
  );

  const selectMention = useCallback(
    (mention: MentionsSelectElement['value']) => {
      Commands.applyMention(editor, element, mention);
    },
    [editor, element],
  );

  const handleChange: NonNullable<InputHTMLAttributes<HTMLInputElement>['onChange']> = useCallback(({ target }) => {
    setInputValue(target.value);
    setActiveIndex(0);
  }, []);

  // Handle keyboard navigation and selection
  const handleKeyDown: ComponentProps<typeof MentionInput>['handleKeyDown'] = useCallback(
    (event) => {
      const execAction = createHotkeyAction(event);

      switch (event.key) {
        case 'ArrowDown':
          return execAction(() => {
            setActiveIndex((prevIndex) => (prevIndex === filteredMentions.length - 1 ? 0 : prevIndex + 1));
          });
        case 'ArrowUp':
          return execAction(() => {
            setActiveIndex((prevIndex) => (prevIndex === 0 ? filteredMentions.length - 1 : prevIndex - 1));
          });
        case 'Tab':
        case 'Enter': {
          if (filteredMentions.length > 0) {
            return execAction(() => {
              selectMention(filteredMentions[activeIndex]);
            });
          }

          return;
        }
        case 'Escape':
          return execAction(() => {
            Commands.deleteActiveBlock(editor, element);
            Commands.insertText(editor, `${MENTION_TRIGGER}${inputValue}`);
          });
        case 'Backspace': {
          if (inputValue.length === 0) {
            return execAction(() => {
              Commands.deleteActiveBlock(editor, element);
            });
          }

          return;
        }
        default:
          return;
      }
    },
    [editor, filteredMentions, element, inputValue, activeIndex, selectMention],
  );

  const mentionsListRef = useRef<HTMLUListElement>(null);

  // Handle clicks outside the mentions component
  const handleClickOutside = useCallback(
    ({ target }: MouseEvent) => {
      if (!(target instanceof HTMLElement)) return;

      const isClickInsideMentionsSelect = ref.current?.contains(target);
      const isClickInsideMentionsList = mentionsListRef.current?.contains(target);

      if (!(isClickInsideMentionsSelect || isClickInsideMentionsList)) {
        Commands.deleteActiveBlock(editor, element);
        Commands.insertText(editor, `${MENTION_TRIGGER}${inputValue}`);
      }
    },
    [editor, element, ref.current, inputValue],
  );

  useEventListener('mousedown', handleClickOutside, document);

  // Use floating-ui for positioning the mentions list
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [hide(), offset({ mainAxis: 4 }), flip()],
  });

  const expanded = filteredMentions.length > 0;
  const activeDescendantId = expanded ? `${Aria.MentionItemId}-${activeIndex}` : undefined;

  const mergedListRef = useMergeRefs(refs.setFloating, mentionsListRef);

  return (
    <span {...selectProps} ref={ref}>
      {MENTION_TRIGGER}
      <span className="relative">
        <MentionInput
          ref={refs.setReference}
          value={inputValue}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          activeDescendantId={activeDescendantId}
          expanded={expanded}
        />

        <Portal.Root>
          <MentionsList
            ref={mergedListRef}
            mentions={filteredMentions}
            handleSelect={selectMention}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            style={floatingStyles}
          />
        </Portal.Root>
      </span>
      {children}
    </span>
  );
};
