import { useCallback, useState } from 'react';
import { useEventListener } from './useEventListener';

export const useMouseDragState = () => {
  const [isDragging, setIsDragging] = useState(false);

  const handlePointerUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handlePointerDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  useEventListener('pointerdown', handlePointerDown, document);
  useEventListener('pointerup', handlePointerUp, document);
  useEventListener('pointercancel', handlePointerUp, document);

  return isDragging;
};
