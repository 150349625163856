/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { CSSObject, css, jsx } from '@emotion/core';
import useComponentOverrides from '../../helpers/useComponentOverrides';
import ButtonWithReset from '../shared/ButtonWithReset';

const RatingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--form-control-gap);
  align-self: stretch;
  user-select: none;
`;

const styles: CSSObject = {
  display: 'flex',
  height: 'var(--form-control-height)',
  width: 'var(--form-control-width)',
  padding: 'var(--form-control-padding-x)',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'var(--layout-radius-button)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--form-control-border-color)',
  background: 'var(--form-control-background)',
  cursor: 'pointer',
  boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',

  '&:hover': {
    borderColor: 'var(--form-control-border-color-hover)',
    background: 'var(--form-control-background-hover)',
    boxShadow: 'var(--form-control-shadow) var(--form-control-shadow-color)',
  },

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
};

const selectedStyles: CSSObject = {
  borderColor: 'var(--base-accent)',
  background: 'var(--form-control-active-background)',

  '&:hover': {
    borderColor: 'var(--base-accent)',
    background: 'var(--form-control-active-background)',
  },
};

const slug = 'nudgeRatingEmojis';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  numOptions: number;
  selected: (index: number) => boolean;
  onClick: (index: number) => void;
  emojis: string[];
};

const StyledNudgeRatingEmojis = ({ themeV2, numOptions, selected, onClick, emojis }: Props) => {
  const { overrides } = useComponentOverrides(slug, themeV2);

  return (
    <RatingContainer>
      {[...Array(numOptions)].map((_, zeroBasedIndex) => {
        const oneBasedIndex = zeroBasedIndex + 1;

        return (
          <ButtonWithReset
            key={oneBasedIndex}
            data-testid={'survey-option'}
            css={[overrides, css(styles), selected(oneBasedIndex) && css(selectedStyles)].filter(Boolean)}
            onClick={() => onClick(oneBasedIndex)}
          >
            {emojis.length > zeroBasedIndex ? emojis[zeroBasedIndex] : ' '}
          </ButtonWithReset>
        );
      })}
    </RatingContainer>
  );
};

StyledNudgeRatingEmojis.slug = slug;
StyledNudgeRatingEmojis.styles = styles;
StyledNudgeRatingEmojis.activeStyles = selectedStyles;
StyledNudgeRatingEmojis.renderMock = (
  themeV2: IThemeV2Type,
  mode: 'light_mode' | 'dark_mode',
  active: boolean,
  onClick: () => void,
) => (
  <StyledNudgeRatingEmojis
    themeV2={{ themeV2: themeV2, mode: mode }}
    numOptions={1}
    emojis={['🙂']}
    selected={() => !!active}
    onClick={onClick}
  />
);

export default StyledNudgeRatingEmojis;
