import styled from '@emotion/styled';
import React from 'react';

import { CmdButton, CmdInput, CmdLabel, CmdTextarea, cmdToast } from '@commandbar/design-system/cmd';
import { Modal } from '@commandbar/design-system/components/antd';
import { ArrowUpRight, Copy02, XClose } from '@commandbar/design-system/icons/react';
import { buildShareLink } from '@commandbar/internal/client/share_links';
import { isNudge } from '@commandbar/internal/middleware/helpers/pushTrigger';
import type { IChecklist, INudgeType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import { useAppContext } from 'editor/src/AppStateContext';
import { InterpolationWarning } from '../ShareLinkButton/Warnings';

const StyledModal = styled(Modal)`
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  & .ant-modal-header {
    padding: 16px;
  }
  & .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

type ShareableEntity = INudgeType | IChecklist;

interface ShareLinkModalProps {
  shareableEntity: ShareableEntity;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
}

export const ShareLinkModal = ({ shareableEntity, isOpen, setIsOpen, onClose }: ShareLinkModalProps) => {
  const { organization } = useAppContext();

  if (!isOpen) return null;

  const hasAnchoredStep =
    isNudge(shareableEntity) &&
    shareableEntity.steps.some(({ form_factor }) => form_factor.type === 'pin' || form_factor.type === 'tooltip');

  const pageTargetingSelectValue =
    shareableEntity.show_expression.type === 'LITERAL' && !hasAnchoredStep ? 'all_pages' : 'custom';

  const shareLinkObject = buildShareLink(
    organization,
    pageTargetingSelectValue === 'all_pages' ? '' : shareableEntity.share_page_url_or_path,
    isNudge(shareableEntity) ? 'n' : 'q',
    shareableEntity.id,
  );
  const shareLinkFragment = `?${shareLinkObject?.param}`;

  return (
    <StyledModal
      title="Trigger with a link"
      footer={null}
      closeIcon={<CmdButton variant="ghost" size="sm" icon={<XClose />} />}
      onCancel={() => {
        setIsOpen(false);
        onClose?.();
      }}
      style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}
      open={isOpen}
      closable
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <CmdLabel>Link</CmdLabel>
        <span style={{ color: '#797C85', fontSize: '12px', fontWeight: 500 }}>
          Use anyhwere you share links - Slack, email, etc
        </span>

        {shareLinkObject?.url ? (
          <div style={{ position: 'relative' }}>
            <CmdTextarea style={{ height: '52px' }} fullWidth value={shareLinkObject.url} />

            <CmdButton
              style={{ position: 'absolute', top: '4px', right: '0px' }}
              size="sm"
              variant="ghost"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(shareLinkObject.url);
                  cmdToast.success('Link copied to clipboard!');
                } catch (e) {
                  Logger.error(e);
                  cmdToast.error('Unable to copy link to clipboard. Please try again');
                }
              }}
            >
              <Copy02 />
            </CmdButton>
          </div>
        ) : (
          <div
            style={{
              background: '#F9F9F9',
              borderRadius: '6px',
              padding: '16px',
              marginTop: '8px',
              display: 'flex',
              gap: 10,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CmdButton onClick={() => window.open(`${process.env.REACT_APP_DASHBOARD_URL}/configure`, '_blank')}>
              Configure your app domain to use links
              <ArrowUpRight />
            </CmdButton>
          </div>
        )}

        {shareableEntity.share_page_url_or_path.includes('{{') && <InterpolationWarning />}
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <CmdLabel>Fragment</CmdLabel>
        <span style={{ color: '#797C85', fontSize: '12px', fontWeight: 500 }}>
          Or add this to the end of any of your app links:
        </span>
        <CmdInput
          fullWidth
          suffixElement={
            <CmdButton
              size="sm"
              style={{ padding: '0px' }}
              variant="ghost"
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(shareLinkFragment);
                  cmdToast.success('Link fragment copied to clipboard!');
                } catch (e) {
                  Logger.error(e);
                  cmdToast.error('Unable to copy link to clipboard. Please try again');
                }
              }}
            >
              <Copy02 />
            </CmdButton>
          }
          value={shareLinkFragment}
        />
      </div>
    </StyledModal>
  );
};
