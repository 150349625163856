import { Editor, Element, Point, Range, Transforms } from 'slate';

import { ElementType } from '../constants';
import type { LinkElement } from '../types';

const isValidAbsoluteUrl = (url: string): boolean => {
  try {
    const parsed = new URL(url);
    return parsed.protocol === 'http:' || parsed.protocol === 'https:';
  } catch {
    return false;
  }
};

/**
 * A plugin that enhances the editor with link handling capabilities.
 * It treats Link elements as inline elements and adjusts text insertion
 * to prevent users from unintentionally typing inside a Link element
 * when the cursor is at its end. Additionally, it handles paste events
 * to convert selected text into links when a valid URL is pasted.
 */
export const withLinks = (editor: Editor) => {
  const { isInline, insertText, insertData } = editor;

  // Extend isInline to recognize Link elements as inline elements
  editor.isInline = (element) => Element.isElementType(element, ElementType.Link) || isInline(element);

  // Override insertText to handle text insertion at the end of a Link element
  editor.insertText = (text) => {
    const { selection } = editor;

    // Proceed only if there is a collapsed selection (no text is selected)
    if (selection && Range.isCollapsed(selection)) {
      // Check if the cursor is inside a Link element
      const [linkNode] = Editor.nodes<LinkElement>(editor, {
        match: (node) => Element.isElementType(node, ElementType.Link),
      });

      if (linkNode) {
        const [, linkPath] = linkNode;
        const end = Editor.end(editor, linkPath);

        // If the cursor is at the very end of the Link element
        if (Point.equals(selection.anchor, end)) {
          // Move the cursor after the Link element to prevent typing inside it
          const after = Editor.after(editor, end);
          if (after) {
            Transforms.select(editor, after);
            insertText(text);
            return;
          }
        }
      }
    }

    // Default behavior if not at the end of a Link element
    insertText(text);
  };

  // Override insertData to handle paste events
  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (isValidAbsoluteUrl(text)) {
      const { selection } = editor;

      if (selection && !Range.isCollapsed(selection)) {
        // Replace the selected text with a LinkElement
        Transforms.wrapNodes(
          editor,
          { type: ElementType.Link, url: text, children: [] },
          {
            split: true,
            at: selection,
          },
        );
        Transforms.collapse(editor, { edge: 'end' });

        return;
      }
    }

    insertData(data);
  };

  return editor;
};
