import { type ComputePositionConfig, autoUpdate, computePosition, offset, shift } from '@floating-ui/dom';
import * as Portal from '@radix-ui/react-portal';
import { Slot } from '@radix-ui/react-slot';
import React, {
  type FC,
  useState,
  useRef,
  type RefObject,
  createContext,
  useContext,
  useCallback,
  type ComponentProps,
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from 'react';

import { useMergeRefs } from '@commandbar/internal/hooks/useMergeRefs';
import { useOutsideClick } from '@commandbar/internal/hooks/useOutsideClick';
import { CmdButton, CmdToolbar } from '..';
import { cn } from '../util';

enum ToolbarState {
  Open = 'open',
  Closed = 'closed',
}

const positionComputeConfig: Partial<ComputePositionConfig> = {
  placement: 'top',
  middleware: [
    offset(4),
    shift({
      padding: 4,
    }),
  ],
};

export interface Coordinates {
  x: number;
  y: number;
}

interface ToolbarContextState {
  isOpen: boolean;
  position: Coordinates;
  setPosition: Dispatch<SetStateAction<Coordinates>>;
  openAtPosition: (position: Coordinates) => void;
  close: () => void;
  openAtAnchor: (anchorRef: RefObject<HTMLElement>) => void;
  toolbarRef: RefObject<HTMLDivElement> | null;
}

const CmdFloatingToolbarContext = createContext<ToolbarContextState | undefined>(undefined);

const useCmdFloatingToolbar = () => {
  const context = useContext(CmdFloatingToolbarContext);

  if (context == null) {
    throw new Error('Floating Toolbar components must be wrapped in <CmdFloatingToolbar />');
  }

  return context;
};

type ContextValue = NonNullable<ComponentProps<typeof CmdFloatingToolbarContext.Provider>['value']>;

const CmdFloatingToolbarProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: -1, y: -1 });
  const toolbarRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLElement | null>(null);

  // Opens the toolbar at a specific position
  const openAtPosition: ContextValue['openAtPosition'] = useCallback((newPosition) => {
    setPosition(newPosition);
    setIsOpen(true);
  }, []);

  // Opens the toolbar anchored to a specific element
  const openAtAnchor: ContextValue['openAtAnchor'] = useCallback(
    (ref) => {
      if (!(ref.current && toolbarRef.current)) return;

      anchorRef.current = ref.current; // Set the anchorRef internally

      computePosition(ref.current, toolbarRef.current, positionComputeConfig)
        .then(({ x, y }) => {
          openAtPosition({ x, y });
        })
        .catch((error) => {
          console.error('Failed to compute toolbar position:', error);
        });
    },
    [openAtPosition],
  );

  // Closes the toolbar and resets its state
  const close: ContextValue['close'] = useCallback(() => {
    setIsOpen(false);
    anchorRef.current = null;
  }, []);

  // Set up autoUpdate when the toolbar is open
  useEffect(() => {
    if (!(isOpen && anchorRef.current && toolbarRef.current)) return;

    const cleanup = autoUpdate(anchorRef.current, toolbarRef.current, () => {
      if (!(anchorRef.current && toolbarRef.current)) return;

      computePosition(anchorRef.current, toolbarRef.current, positionComputeConfig)
        .then(({ x, y }) => {
          setPosition({ x, y });
        })
        .catch((error) => {
          console.error('Failed to compute toolbar position:', error);
        });
    });

    return () => {
      cleanup();
    };
  }, [isOpen]);

  return (
    <CmdFloatingToolbarContext.Provider
      value={{
        toolbarRef,
        position,
        setPosition,
        isOpen,
        openAtPosition,
        openAtAnchor,
        close,
      }}
    >
      {children}
    </CmdFloatingToolbarContext.Provider>
  );
};

interface CmdFloatingToolbarProps extends ComponentPropsWithoutRef<typeof CmdToolbar> {
  onOutsideClick?: (event: MouseEvent | TouchEvent) => void;
}

const CmdFloatingToolbar = forwardRef<ElementRef<typeof CmdToolbar>, CmdFloatingToolbarProps>(
  ({ className, style, children, onOutsideClick, ...props }, ref) => {
    const { toolbarRef, position, setPosition, isOpen, close } = useCmdFloatingToolbar();
    const outsideClickRef = useOutsideClick(onOutsideClick ?? close);
    const mergedRef = useMergeRefs(toolbarRef, outsideClickRef, ref);

    const onAnimationEnd = useCallback(() => {
      if (!isOpen) {
        setPosition({ x: -1, y: -1 });
      }
    }, [isOpen, setPosition]);

    return (
      <Portal.Root>
        <CmdToolbar
          ref={mergedRef}
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
            ...style,
          }}
          className={cn(
            '-top-full -left-full absolute z-max rounded-md border border-elementBase border-solid shadow-lg',
            isOpen
              ? 'fade-in zoom-in-95 slide-in-from-bottom-2 visible animate-in'
              : 'fade-out zoom-out-95 slide-out-to-bottom-2 invisible animate-out duration-100',
            className,
          )}
          data-state={isOpen ? ToolbarState.Open : ToolbarState.Closed}
          onAnimationEnd={onAnimationEnd}
          {...props}
        >
          {children}
        </CmdToolbar>
      </Portal.Root>
    );
  },
);

const CmdFloatingToolbarTrigger = forwardRef<ElementRef<typeof CmdButton>, ComponentPropsWithoutRef<typeof CmdButton>>(
  ({ children, asChild = false, ...props }, ref) => {
    const triggerRef = useRef<HTMLButtonElement>(null);
    const mergedRef = useMergeRefs(ref, triggerRef);
    const { openAtAnchor, isOpen, close } = useCmdFloatingToolbar();

    const Comp = asChild ? Slot : CmdButton;

    return (
      <Comp
        ref={mergedRef}
        type="button"
        onClick={() => {
          if (isOpen) {
            close();
          } else {
            openAtAnchor(triggerRef);
          }
        }}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

export { CmdFloatingToolbar, CmdFloatingToolbarTrigger, CmdFloatingToolbarProvider, useCmdFloatingToolbar };
