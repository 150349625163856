import * as React from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { VariantProps } from 'class-variance-authority';
import { cn } from '../util';
import { tabVariants } from './styles';

const ToggleGroupContext = React.createContext<VariantProps<typeof tabVariants>>({
  variant: 'default',
});

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> & VariantProps<typeof tabVariants>
>(({ className, variant, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root ref={ref} className={cn('flex items-center justify-center gap-1', className)} {...props}>
    <ToggleGroupContext.Provider value={{ variant }}>{children}</ToggleGroupContext.Provider>
  </ToggleGroupPrimitive.Root>
));

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> & VariantProps<typeof tabVariants>
>(({ className, children, variant, ...props }, ref) => {
  const context = React.useContext(ToggleGroupContext);

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        tabVariants({
          variant: context.variant || variant,
        }),
        className,
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  );
});

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;
interface ITabs {
  label: React.ReactNode;
  key: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface CmdButtonTabsProps<T = string> {
  variant?: 'tab' | 'group';
  onChange?: (value: string) => void;
  disabled?: boolean;
  tabs: ITabs[];
  activeKey: T;
  className?: string;
}

export const CmdButtonTabs = ({
  variant = 'group',
  activeKey,
  onChange,
  tabs,
  disabled = false,
  className,
}: CmdButtonTabsProps) => {
  return (
    <ToggleGroup
      type="single"
      disabled={disabled}
      value={activeKey}
      onValueChange={(e) => {
        if (e === activeKey || e === '') return;
        if (onChange) onChange(e);
      }}
      className={cn(variant === 'group' ? 'w-fit rounded-md bg-surfaceMid p-xs' : '', className)}
    >
      {tabs.map((tab) => (
        <ToggleGroupItem
          key={tab.key}
          value={tab.key}
          onClick={tab.onClick}
          className={'gap-1 select-none flex-grow items-center justify-center flex-1'}
        >
          {tab.label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
