import React, { useCallback, type ComponentProps, forwardRef, type ElementRef, useMemo, useEffect } from 'react';
import { createEditor, Element } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, useSlate, withReact } from 'slate-react';

import { cn, compose } from '../util';
import { Commands } from './commands';
import { BlockQuote } from './components/BlockQuote';
import { Code } from './components/Code';
import { Heading } from './components/Heading';
import { HorizontalRule } from './components/HorizontalRule';
import { Leaf } from './components/Leaf';
import { Link } from './components/Link';
import { List } from './components/List';
import { ListItem } from './components/ListItem';
import { Mention } from './components/Mention';
import { MentionsSelect } from './components/MentionsInput';
import { Paragraph } from './components/Paragraph';
import { Placeholder } from './components/Placeholder';
import { EditorToolbar, EditorToolbarProvider, ToolbarType, useEditorToolbar } from './components/toolbar';
import { EMPTY_VALUE, ElementType, Mark } from './constants';
import { withHtml } from './plugins/html';
import { withLinks } from './plugins/links';
import { decreaseIndentation, increaseIndentation, withLists } from './plugins/lists';
import { withMarkdownShortcutHandling } from './plugins/markdownShortcuts';
import { withMentions } from './plugins/mentions';
import { withSingleLineLayout } from './plugins/singleLineLayout';
import type { MentionElement } from './types';
import { createHotkeyAction } from './utils';

export type EditorLayout = 'multi-line' | 'single-line';

interface EditorProps extends ComponentProps<typeof Editable> {
  layout: EditorLayout;
  mentions?: MentionElement['value'][];
}

const Editor = forwardRef<ElementRef<typeof Editable>, EditorProps>(
  ({ className, mentions, layout, ...editableProps }, ref) => {
    const editor = useSlate();
    const { close, setType } = useEditorToolbar();

    useEffect(() => {
      // ensure the initial value conforms to a valid schema
      editor.normalize({ force: true });
    }, [editor.normalize]);

    // INFO: renderElement maps Slate block types to their display components
    // https://docs.slatejs.org/concepts/09-rendering
    const renderElement: NonNullable<ComponentProps<typeof Editable>['renderElement']> = useCallback(
      (props) => {
        switch (props.element.type) {
          case ElementType.Heading:
            return <Heading {...props} element={props.element} />;
          case ElementType.Code:
            return <Code {...props} />;
          case ElementType.Link:
            return <Link {...props} element={props.element} />;
          case ElementType.BlockQuote:
            return <BlockQuote {...props} />;
          case ElementType.List:
            return <List {...props} element={props.element} />;
          case ElementType.ListItem:
            return <ListItem {...props} />;
          case ElementType.HorizontalRule:
            return <HorizontalRule {...props} />;
          case ElementType.Mention:
            return <Mention {...props} element={props.element} />;
          case ElementType.MentionsSelect: {
            if (mentions) {
              return <MentionsSelect {...props} mentions={mentions} element={props.element} />;
            }

            return <Paragraph {...props} />;
          }
          default:
            return <Paragraph {...props} />;
        }
      },
      [mentions],
    );

    // INFO: Leaves are groups of formatted characters. Ex: bold, italic, code.
    // https://docs.slatejs.org/concepts/09-rendering#leaves
    const renderLeaf: NonNullable<ComponentProps<typeof Editable>['renderLeaf']> = useCallback(
      (props) => <Leaf {...props} />,
      [],
    );

    const renderPlaceholder: NonNullable<ComponentProps<typeof Editable>['renderPlaceholder']> = useCallback(
      (props) => {
        const [firstChild] = editor.children;

        if (!(Element.isElementType(firstChild, ElementType.Paragraph) && editor.isEmpty(firstChild))) {
          return null;
        }

        return <Placeholder {...props} />;
      },
      [editor],
    );

    const handleKeyDown: NonNullable<ComponentProps<typeof Editable>['onKeyDown']> = useCallback(
      (event) => {
        const execAction = createHotkeyAction(event);

        if (event.metaKey) {
          switch (event.key) {
            case 'e':
              return execAction(() => {
                Commands.toggleMark(editor, Mark.Code);
              });
            case 'b':
              return execAction(() => {
                Commands.toggleMark(editor, Mark.Bold);
              });
            case 'i':
              return execAction(() => {
                Commands.toggleMark(editor, Mark.Italic);
              });
            case 'k':
              return execAction(() => {
                setType(ToolbarType.EditLink);
              });
            default:
              return;
          }
        }

        switch (event.key) {
          case 'Shift':
          case 'Control':
          case 'Alt':
            return;
          case 'Tab': {
            if (event.shiftKey) {
              return execAction(() => {
                decreaseIndentation(editor);
              });
            }
            // INFO: currently a noop since snarkdown does not support nested lists
            return execAction(() => {
              increaseIndentation(editor);
            });
          }
          case 'Enter':
            if (layout === 'single-line') {
              event.preventDefault();
            }
            break;
          default:
            return close();
        }
      },
      [editor, close, setType, layout],
    );

    return (
      <Editable
        ref={ref}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        renderPlaceholder={renderPlaceholder}
        onKeyDown={handleKeyDown}
        className={cn(
          'h-16 overflow-y-auto overflow-x-hidden rounded-button bg-gray100 p-1 font-medium text-base text-contentMid leading-5 selection:bg-blue200 selection:text-contentMid selection:mix-blend-multiply',
          className,
        )}
        {...editableProps}
      />
    );
  },
);

const defaultInitialValue: ComponentProps<typeof Slate>['initialValue'] = [
  {
    type: ElementType.Paragraph,
    children: [{ text: EMPTY_VALUE }],
  },
];

type CmdRichTextEditorProps = ComponentProps<typeof Editable> & {
  initialValue?: ComponentProps<typeof Slate>['initialValue'];
  onValueChange?: ComponentProps<typeof Slate>['onChange'];
  mentions?: MentionElement['value'][];
  layout?: EditorLayout;
};

export const CmdRichTextEditor = forwardRef<ElementRef<typeof Editable>, CmdRichTextEditorProps>(
  ({ initialValue = defaultInitialValue, onValueChange, layout = 'multi-line', ...editorProps }, ref) => {
    const enhancedEditor = useMemo(() => {
      let withPlugins = compose(withLinks, withReact, withHistory);

      if (editorProps.mentions) {
        withPlugins = compose(withMentions, withPlugins);
      }

      if (layout === 'single-line') {
        withPlugins = compose(withSingleLineLayout, withPlugins);
      } else {
        withPlugins = compose(withHtml, withMarkdownShortcutHandling, withLists, withPlugins);
      }

      return withPlugins(createEditor());
    }, [layout]);

    const handleEditorChange: NonNullable<ComponentProps<typeof Slate>['onChange']> = useCallback(
      (value) => {
        // ignore changes in editor state like selection
        if (!Commands.isAstChange(enhancedEditor)) return;

        onValueChange?.(value);
      },
      [onValueChange, enhancedEditor],
    );

    return (
      <Slate editor={enhancedEditor} initialValue={initialValue} onChange={handleEditorChange}>
        <EditorToolbarProvider editorLayout={layout}>
          <EditorToolbar />
          <Editor ref={ref} {...editorProps} layout={layout} />
        </EditorToolbarProvider>
      </Slate>
    );
  },
);
