import React, { useState } from 'react';
import ThemeComponentEditor, { ThemeAccordion } from '../themes/ThemeComponentEditor';
import { editableComponentStylesTree, editableVarsTree, ThemeFieldGroup } from '../themes/editableFieldTrees';
import { CmdButton, CmdButtonTabs, CmdDivider, CmdLabel } from '@commandbar/design-system/cmd';
import Rows01 from '@commandbar/design-system/icons/react/Rows01';
import Globe01 from '@commandbar/design-system/icons/react/Globe01';
import Sun from '@commandbar/design-system/icons/react/Sun';
import Moon02 from '@commandbar/design-system/icons/react/Moon02';
import { getUpdatedTheme, revertAllVariables, revertVariable } from '../themes/helpers';
import Sender from 'editor/src/management/Sender';
import { useAppContext } from 'editor/src/AppStateContext';
import { useHistory } from 'react-router';
import { IThemeType, IThemeV2Type } from '@cb/types/entities/theme';

interface NudgeThemeCustomizePaneProps {
  handleSave: (theme: IThemeType) => void;
  onSave: () => void;
  onCancel: (theme: IThemeType) => void;
  overrideTheme: IThemeType;
  mode: 'light_mode' | 'dark_mode';
  setMode: (mode: 'light_mode' | 'dark_mode') => void;
}

type Tabs = 'base' | 'components';

const LABELS_EXCLUDED_FOR_NUDGES = ['Checklist', 'HelpHub / Copilot', 'Spotlight'];
const LABELS_EXCLUDED_FORCHECKLISTS = [
  'Nudge tooltip',
  'Nudge popover / pin',
  'Nudge modal',
  'HelpHub / Copilot',
  'Spotlight',
];

const COMPONENTS_EXCLUDED_FOR_NUDGES = [
  'Checklist',
  'HelpHub / Copilot',
  'Spotlight',
  'Content grid item',
  'Content list item',
  'Title bar',
  'Progress bar',
  'Inline button',
  'Toggle Button',
];
const COMPONENTS_EXCLUDED_FOR_CHECKLISTS = [
  'Secondary button',
  'Tertiary button',
  'Inline button',
  'Toggle Button',
  'Form control emojis',
  'Form control number',
  'Form control stars',
  'Input',
  'Text area',
  'Text Select input',
  'Form control radio',
  'Form control checkbox',
  'Title bar',
  'Beacon',
  'Select input',
  'Content grid item',
  'Content list item',
];

const NudgeThemeCustomizePane: React.FC<NudgeThemeCustomizePaneProps> = (props) => {
  const { flags } = useAppContext();
  const history = useHistory();

  const _revertVariable = (
    variable: string | undefined,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => {
    return revertVariable(
      props.overrideTheme,
      (theme: IThemeType) => {
        props.handleSave(theme);
      },
      props.mode,
      variable,
      parentSlug,
      stateSlug,
      currentDraftTheme,
    );
  };

  const _revertAllVariables = (
    category: ThemeFieldGroup,
    parentSlug?: string,
    stateSlug?: string,
    currentDraftTheme?: IThemeV2Type,
  ) => {
    return revertAllVariables(
      props.overrideTheme,
      (theme: IThemeType) => {
        props.handleSave(theme);
      },
      props.mode,
      category,
      parentSlug,
      stateSlug,
      currentDraftTheme,
    );
  };

  const [currTab, setCurrTab] = useState<Tabs>('base');
  const [overrideEntryState, _setOverrideEntryState] = useState<IThemeType>(props.overrideTheme);

  const handleSave = (overrides: Record<string, string>, parentSlug?: string, stateSlug?: string) => {
    const updatedTheme = getUpdatedTheme(props.overrideTheme, props.mode, overrides, parentSlug, stateSlug);
    if (updatedTheme) {
      props.handleSave(updatedTheme);
    }
  };

  const isChecklistEditing = history.location.pathname.includes('checklists');

  let _editableVarsTree = !!flags['release-widget-animations']
    ? editableVarsTree
    : editableVarsTree.filter((category) => category.slug !== 'animations');

  _editableVarsTree = _editableVarsTree.map((category) => {
    return {
      ...category,
      children: category.children.filter((child) => {
        if (isChecklistEditing) {
          return !LABELS_EXCLUDED_FORCHECKLISTS.includes(child.label);
        } else {
          return !LABELS_EXCLUDED_FOR_NUDGES.includes(child.label);
        }
      }),
    };
  });

  const _editableComponentStylesTree = editableComponentStylesTree.filter((component) => {
    if (isChecklistEditing) {
      return !COMPONENTS_EXCLUDED_FOR_CHECKLISTS.includes(component.label);
    } else {
      return !COMPONENTS_EXCLUDED_FOR_NUDGES.includes(component.label);
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#ffffff',
        height: '100%',
      }}
    >
      <div style={{ padding: '12px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
          <CmdButtonTabs
            variant="group"
            key={currTab}
            activeKey={currTab}
            onChange={(e) => {
              if (e === 'base' || e === 'components') {
                setCurrTab(e);
              }
            }}
            tabs={[
              {
                label: (
                  <CmdLabel
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Globe01 />
                    Base
                  </CmdLabel>
                ),
                key: 'base',
              },
              {
                label: (
                  <CmdLabel
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Rows01 />
                    Components
                  </CmdLabel>
                ),
                key: 'components',
              },
            ]}
          />
        </div>

        <div style={{ display: 'flex', flex: 1, justifyContent: 'end', gap: '8px', alignItems: 'center' }}>
          <CmdButtonTabs
            variant="group"
            activeKey={props.mode}
            onChange={(e) => {
              const mode = e as 'light_mode' | 'dark_mode';
              props.setMode(mode);
              Sender.setThemeV2(props.overrideTheme.themeV2_draft, mode);
            }}
            tabs={[
              {
                label: <Sun />,
                key: 'light_mode',
              },
              {
                label: <Moon02 />,
                key: 'dark_mode',
              },
            ]}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          overflowY: 'auto',
          padding: '0 12px 12px',
        }}
      >
        {currTab === 'base'
          ? _editableVarsTree.map((category) => (
              <ThemeAccordion
                theme={props.overrideTheme}
                mode={'light_mode'}
                category={category}
                revertVariable={_revertVariable}
                revertAllVariables={_revertAllVariables}
                handleSave={handleSave}
                defaultValue="Brand"
              />
            ))
          : _editableComponentStylesTree.map((component) => (
              <ThemeComponentEditor
                key={component.label + '-' + props.overrideTheme.slug}
                theme={props.overrideTheme}
                mode={props.mode}
                component={component}
                revertVariable={_revertVariable}
                revertAllVariables={_revertAllVariables}
                handleSave={handleSave}
                showPreview={false}
              />
            ))}
      </div>
      <CmdDivider style={{ marginTop: 'auto' }} />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '8px', padding: '16px' }}>
        <CmdButton key="close" onClick={() => props.onCancel(overrideEntryState)}>
          Cancel
        </CmdButton>
        <CmdButton key="ok" variant="primary" onClick={props.onSave}>
          Done
        </CmdButton>
      </div>
    </div>
  );
};

export default NudgeThemeCustomizePane;
