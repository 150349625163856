import { INudgeType } from '@commandbar/internal/middleware/types';
import { NudgeType } from './NudgeList';

export const emptyNudge = (type: NudgeType): INudgeType => {
  return {
    id: -1 * new Date().getTime(),
    organization: '',
    steps: [
      {
        id: -1 * new Date().getTime(),
        title: '',
        content: [{ type: 'markdown', sort_key: 0, meta: { value: '' } }],
        is_live: false,
        preview_url: null,
        form_factor: {
          type: 'modal',
        },
      },
    ],
    slug: '',
    template_source: 'none',
    audience: { type: 'all_users' },
    show_expression: { type: 'LITERAL', value: true },
    trigger: { type: 'when_conditions_pass' },
    frequency_limit: 'until_interaction',
    show_step_counter: false,
    dismissible: true,
    snoozable: false,
    snoozable_on_all_steps: true,
    is_live: false,
    share_page_url_or_path: '',
    show_in_spotlight_search: type === 'product_tour',
    show_in_helphub_search: type === 'product_tour',
    copilot_suggest: false,
    copilot_cta_label: '',
    copilot_description: '',
    is_scheduled: false,
    scheduled_start_time: null,
    scheduled_end_time: null,
    snooze_label: 'Snooze',
    snooze_duration: {
      interval: 'day',
      value: 3,
    },
    type: type,
    editor_tags: [],
    preview_url: null,
    custom_theme: null,
  };
};

export const announcementNudge: INudgeType = {
  ...emptyNudge('announcement'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Announcement',
      content: [{ type: 'markdown', sort_key: 0, meta: { value: '' } }],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'modal',
      },
    },
  ],
  template_source: 'announcement',
};

export const bannerNudge: INudgeType = {
  ...emptyNudge('announcement'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Banner',
      content: [{ type: 'markdown', sort_key: 0, meta: { value: '' } }],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'banner',
        position: 'top',
        placement: 'default',
      },
    },
  ],
  template_source: 'banner',
};

export const featureHighlightNudge: INudgeType = {
  ...emptyNudge('product_tour'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Feature highlight',
      content: [{ type: 'markdown', sort_key: 0, meta: { value: '' } }],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'pin',
        anchor: '',
        advance_trigger: '',
        is_open_by_default: true,
        offset: {
          x: '0px',
          y: '0px',
        },
      },
    },
  ],
};

export const tooltipNudge: INudgeType = {
  ...emptyNudge('product_tour'),
  frequency_limit: 'no_limit',
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Tooltip title',
      content: [{ type: 'markdown', sort_key: 0, meta: { value: 'Tooltip body' } }],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'tooltip',
        anchor: '',
        show_on: 'hover',
        marker: { type: 'icon', icon: 'helpCircle', positioning: { position: 'left', offset: { x: '', y: '' } } },
        layout: 'classic',
      },
    },
  ],
};

export const upgradeNudge: INudgeType = {
  ...emptyNudge('announcement'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Go Premium',
      content: [
        {
          type: 'markdown',
          sort_key: 0,
          meta: {
            value: 'Try Premium – all our best features, free for 14 days.',
          },
        },
        {
          type: 'button',
          sort_key: 1,
          meta: {
            button_type: 'primary',
            label: 'Start your trial',
            action: { type: 'no_action' },
          },
        },
      ],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'popover',
        position: 'top-left',
      },
    },
  ],
  template_source: 'upgrade_nudge',
};

export const simpleNotification: INudgeType = {
  ...emptyNudge('announcement'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Notification',
      content: [{ type: 'markdown', sort_key: 0, meta: { value: '' } }],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'popover',
        position: 'top-right',
      },
    },
  ],
  template_source: 'feature_highlight',
};

export const productTour: INudgeType = {
  ...emptyNudge('product_tour'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Step 1',
      content: [
        { type: 'markdown', sort_key: 0, meta: { value: '' } },
        {
          type: 'button',
          sort_key: 1,
          meta: { label: 'Continue', button_type: 'primary', action: { type: 'no_action' } },
        },
      ],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'popover',
        position: 'top-left',
      },
    },
    {
      id: -2,
      title: 'Step 2',
      content: [
        { type: 'markdown', sort_key: 0, meta: { value: '' } },
        { type: 'button', sort_key: 1, meta: { label: 'Done', button_type: 'primary', action: { type: 'no_action' } } },
      ],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'pin',
        anchor: '',
        advance_trigger: '',
        is_open_by_default: true,
        offset: {
          x: '0px',
          y: '0px',
        },
      },
    },
  ],
  template_source: 'tour',
};

export const feedback: INudgeType = {
  ...emptyNudge('survey'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'Feedback',
      content: [
        { type: 'markdown', sort_key: 0, meta: { value: '' } },
        { type: 'survey_text', sort_key: 1, meta: { prompt: 'How can we make FeatureX twice as good?' } },
        {
          type: 'button',
          sort_key: 2,
          meta: { label: 'Share Feedback', button_type: 'primary', action: { type: 'no_action' } },
        },
      ],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'popover',
        position: 'center',
      },
    },
  ],
  template_source: 'feedback',
};

export const rating: INudgeType = {
  ...emptyNudge('survey'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'How are we doing?',
      content: [
        { type: 'markdown', sort_key: 0, meta: { value: '' } },
        {
          type: 'survey_rating',
          sort_key: 1,
          meta: {
            type: 'emojis',
            lower_label: 'Awful',
            upper_label: 'Amazing',
            options: 3,
            emojis: ['😡', '🙂', '🤩'],
          },
        },
        {
          type: 'button',
          sort_key: 2,
          meta: { label: 'Share Feedback', button_type: 'primary', action: { type: 'no_action' } },
        },
      ],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'popover',
        position: 'center',
      },
    },
  ],
  template_source: 'rating',
};

export const nps: INudgeType = {
  ...emptyNudge('survey'),
  steps: [
    {
      id: -1 * new Date().getTime(),
      title: 'How likely are you to recommend us to a friend or colleague?',
      content: [
        { type: 'markdown', sort_key: 0, meta: { value: '' } },
        {
          type: 'survey_rating',
          sort_key: 1,
          meta: {
            type: 'nps',
            lower_label: 'Not likely at all',
            upper_label: 'Extremely likely',
            options: 10,
          },
        },
        {
          type: 'button',
          sort_key: 2,
          meta: { label: 'Submit', button_type: 'primary', action: { type: 'no_action' } },
        },
      ],
      is_live: false,
      preview_url: null,
      form_factor: {
        type: 'popover',
        position: 'center',
      },
    },
  ],
  template_source: 'nps',
};
